import React, { useCallback, useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

import s from './InputLabel.module.scss'

type Props = {
  title: string
  name?: string
  type?: string
  icon?: string
  iconContainerClass?: string
  iconClass?: string
  className?: string
  defaultValue?: string
  value: string
  refer?: any
  autocomplete?: string
  readonly?: boolean
  onBlur?: Function
  wrong?: boolean
  onChange: Function
  grayStyle?: boolean
  onClick?: Function
}
const InputLabel = ({
  icon,
  iconClass,
  iconContainerClass,
  className,
  onChange,
  title,
  refer,
  autocomplete,
  readonly,
  wrong,
  value,
  type,
  grayStyle,
  onClick,
  onBlur,
}: Props) => {
  const [focused, setFocused] = useState<boolean>(false)

  const onFocus = useCallback(() => {
    setFocused(true)
  }, [])
  const onLabelBlur = useCallback(() => {
    if (!value || value.length === 0) {
      setFocused(false)
    }
    if (onBlur) {
      onBlur()
    }
  }, [value, onBlur])

  useEffect(() => {
    if (value && value.length) {
      onFocus()
    }
  }, [value, onFocus])

  return (
    <label
      className={`${s.input__item} ${s.input_label} ${grayStyle ? s.gray_input_label : ''} ${
        className ? className : ''
      } ${icon ? s.with_icon : ''} ${focused ? s.focus : ''} ${wrong ? s.wrong : ''}`}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <div className={s.little_text}>{title}</div>
      {icon && (
        <div className={`${s.icon} ${iconContainerClass ? iconContainerClass : ''}`}>
          <img src={icon ? icon : ''} alt="" className={iconClass ? iconClass : ''} />
        </div>
      )}
      {type === 'password' ? (
        <input
          value={value}
          ref={refer ? refer : undefined}
          onChange={(e: any) => {
            if (onChange) onChange(e.target.value)
          }}
          className={s.input}
          onFocus={onFocus}
          onBlur={onLabelBlur}
          autoComplete={autocomplete ? autocomplete : 'on'}
          readOnly={readonly}
          type="password"
        />
      ) : (
        <TextareaAutosize
          value={value}
          ref={refer ? refer : undefined}
          onChange={(e: any) => {
            if (onChange) onChange(e.target.value)
          }}
          className={s.input}
          onFocus={onFocus}
          onBlur={onLabelBlur}
          autoComplete={autocomplete ? autocomplete : 'on'}
          readOnly={readonly}
        />
      )}
    </label>
  )
}

export default React.memo(InputLabel)
