import React from 'react'
import { Link } from 'react-router-dom'

import s from './FaqCategory.module.scss'

export type Category = {
  id: number
  name: string
  parent_id: number | null
  slug: string
  preview: string | null
  meta_desc: string | null
  articles?: ReadonlyArray<any>
  subcategories: ReadonlyArray<Category>
}

type Props = {
  category: Category
  subCategory: Category
}

const FaqCategory = ({ category, subCategory }: Props): React.ReactElement => {
  return (
    <Link to={`/faq/${category.slug}`} className={`${s.item} ${s.item_mobile}`}>
      <div className={s.left}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 0C11.3476 0 7.5625 3.78506 7.5625 8.4375C7.5625 13.0899 11.3476 16.875 16 16.875C20.6524 16.875 24.4375 13.0899 24.4375 8.4375C24.4375 3.78506 20.6524 0 16 0ZM16 15C12.3814 15 9.4375 12.0561 9.4375 8.4375C9.4375 4.81894 12.3814 1.875 16 1.875C19.6186 1.875 22.5625 4.81894 22.5625 8.4375C22.5625 12.0561 19.6186 15 16 15Z"
            fill="#E0E0E0"
          />
          <path
            d="M18.6146 18.8125H13.3854C9.06025 18.8125 4.79181 20.8094 1.9375 25.0911V32H30.0625V25.0911C27.2123 20.8156 22.9451 18.8125 18.6146 18.8125ZM28.1876 30.125H28.1875H3.8125V25.6648C6.00194 22.5427 9.5595 20.6875 13.3854 20.6875H18.6146C22.4406 20.6875 25.9981 22.5427 28.1876 25.6648V30.125Z"
            fill="#E0E0E0"
          />
        </svg>
      </div>
      <div className={s.right}>
        <div className={s.headline}>{category.name}</div>
        <div className={s.desc}>
          {subCategory.articles
            ? subCategory.articles.map((item: any, i: number) => {
                return i < 3 ? (
                  <div className={s['desc-item']}>
                    <div className={s.before}>-</div>
                    <div className={s.txt}>{item.title}</div>
                  </div>
                ) : (
                  ''
                )
              })
            : ''}
        </div>

        <div className={s['right-arrow']}>
          <div className={s.skybox2} />
          <img loading="lazy" src="/images/icons/red-next-arrow.svg" alt="arrow" />
          <div className={s.skybox} />
        </div>
      </div>
    </Link>
  )
}

export default React.memo(FaqCategory)
