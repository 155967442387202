import React from 'react'
import 'moment/locale/ru'
import { HeadProvider } from 'react-head'
import { QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
//@ts-ignore
import Framework7 from 'framework7/lite-bundle'
import Framework7React, { App as Framework7App, View } from 'framework7-react'
import { MwProvider } from 'application/presentation/common/ModalWindows/MWProvider'
import { Authorization as AuthProvider } from 'application/presentation/app/AuthContext'
import moment from 'moment'
import { store } from 'application/domain/store/reduxStore'
import queryClient from 'application/data/apiClient/queryClient'

import AppView from './AppView'

moment.locale('ru')

Framework7.use(Framework7React)

if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual'
}

const f7params = {
  // Array with app routes
  // routes,
  // App Name
  name: 'Wish.men',
  // App id
  id: 'com.myapp.test',
  theme: 'ios',
  // ...
  clicks: {
    externalLinks: 'a[href^="/"], a[href^="http"]', // all links as external, this is to enable normal behavior
  },
}

function App() {
  return (
    <HeadProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter
        // basename="/ru"
        >
          <Provider store={store()}>
            <Framework7App {...f7params}>
              <View main>
                <AuthProvider>
                  <MwProvider>
                    <AppView />
                  </MwProvider>
                </AuthProvider>
              </View>
            </Framework7App>
          </Provider>
        </BrowserRouter>
      </QueryClientProvider>
    </HeadProvider>
  )
}

export default React.memo(App)
