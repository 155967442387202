import React, { useState } from 'react'
import { InputLabel, InputLabelHorizontal } from 'application/presentation/common/InputComponents'
import { Button } from 'application/presentation/common/ActionComponents'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import apiClient from 'application/data/apiClient'
import { setUser, setUserToken } from 'application/domain/store/userReducer'

import s from './Registration.module.scss'

type Props = {
  dataToken: string
  setStartAnim: Function
  setUsername: Function
  setUserNickname: Function
}

const Registration = ({ setStartAnim, dataToken, setUsername, setUserNickname }: Props) => {
  const dispatch = useDispatch()
  const [regIsLoading, setRegIsLoading] = useState<boolean>(false)

  const [name, setName] = useState<string>('')
  const [birthdate, setBirthdate] = useState<any>('')
  const [nickname, setNickname] = useState<string>('')

  const [passwordError, setPasswordError] = useState<string>('')
  const [error, setError] = useState<string>('')

  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')

  const validateFields = () => {
    if ((!password || password.length < 8) && (!passwordConfirm || passwordConfirm.length < 8)) {
      setPasswordError('Длина пароля меньше 8 символов')
    } else if (password !== passwordConfirm) {
      setPasswordError('Пароли не совпадают')
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      registration()
    }
  }
  const registration = () => {
    let birth = null
    if (birthdate) {
      const dateParts = birthdate.split('.')
      birth = moment(new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])).format('YYYY-MM-DD')
    }
    if (dataToken && dataToken !== '') {
      const userToken = dataToken
      setRegIsLoading(true)
      const sendData = {
        name: name,
        birthdate: birth || null,
        password: password,
        nickname: nickname,
      }
      apiClient({
        url: '/api/v1/user/edit',
        method: 'post',
        data: sendData,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then((response) => {
          setRegIsLoading(false)
          setUsername(
            response.data.success.name
              ? response.data.success.name
              : response.data.success.nickname,
          )
          setUserNickname(response.data.success.nickname)
          if (response.data.success) {
            setStartAnim(true)
            setTimeout(async () => {
              const userData = JSON.stringify(response.data.success)
              localStorage.setItem('userToken', userToken)
              localStorage.setItem('userData', userData)
              apiClient.defaults.headers.Authorization = `Bearer ${userToken}`
              setUser(response.data.success)(dispatch)
              setUserToken(dataToken)(dispatch)
            }, 750)
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .catch((error) => {
          setRegIsLoading(false)
          if (error.response.data.error) {
            if (error.response.data.error.nickname) {
              setError('nickname')
            }
            if (error.response.data.error.email) {
              setError('email')
            }
          } else {
            //   toast.show('Произошла ошибка, попробуйте еще раз', 'error')
          }
          console.log(error)
        })
    }
  }

  return (
    <div className={s.login_section}>
      <div className={s.reg_container}>
        {/* <div className={s.title}>Регистрация</div> */}
        <InputLabelHorizontal
          title="Введите имя"
          value={name}
          placeholder="Имя"
          onChange={setName}
        />
        <InputLabelHorizontal
          title="Никнейм"
          value={nickname}
          placeholder="Введите никнейм"
          onChange={setNickname}
          subtitle=""
          componentType="nickname"
        />
        {error ? <div className={s.msg}>Этот никнейм уже занят</div> : ''}
        <InputLabelHorizontal
          title="Дата рождения"
          value={birthdate}
          onChange={setBirthdate}
          subtitle="Отображается день и месяц"
          componentType="date"
          className={s.bday_input}
        />
        <InputLabel title="Пароль" value={password} onChange={setPassword} type="password" />
        <InputLabel
          title="Повторите пароль"
          value={passwordConfirm}
          onChange={setPasswordConfirm}
          type="password"
        />
        {passwordError !== '' ? <div className={s.msg}>{passwordError}</div> : ''}
        <Button
          title="Зарегистрироваться"
          color="red"
          isLoading={regIsLoading}
          containerClassName={s.btn_container}
          className={s.btn}
          onClick={() => validateFields()}
        />
      </div>
    </div>
  )
}

export default React.memo(Registration)
