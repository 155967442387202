import React, { useEffect, useRef, useContext, useState } from 'react'
import gsap from 'gsap'
import MotionPathPlugin from 'gsap/MotionPathPlugin'
import ProgressBar from 'react-customizable-progressbar'
import { NavLink } from 'react-router-dom'

import s from './FirstBlock.module.scss'

gsap.registerPlugin(MotionPathPlugin)

const FirstBlock = () => {
  const [price, setPrice] = useState<number>(0)
  const [circleProcent, setCircleProcent] = useState<number>(0)
  const [count, setCount] = useState<number>(0)
  const [completed, setCompleted] = useState<boolean>(false)
  const img1 = useRef(null)
  const img2 = useRef(null)
  const img5 = useRef(null)
  const img7 = useRef(null)
  const product = useRef<any>(null)

  const animate = () => {
    if (product && product.current) {
      const rect =
        product && product.current
          ? product.current.getBoundingClientRect()
          : { height: 0, widht: 0 }

      const tl = gsap.timeline()
      tl.to(img1.current, 1, {
        x: 0,
        y: rect.height / 3,
        onComplete() {
          setCount(1)
        },
      })
        .to(img2.current, 0.5, {
          x: 0,
          y: rect.height / 3,
          onComplete() {
            setCount(2)
          },
        })
        .to(img5.current, 0.5, {
          x: 0,
          y: rect.height / 3,
          onComplete() {
            setCount(3)
          },
        })
        .to(img7.current, 0.5, {
          x: 0,
          y: rect.height / 3,
          onComplete() {
            setCount(4)
            setTimeout(() => {
              setCompleted(true)
              // if (!isMobile) {
              //   frame()
              // }
            }, 1000)
          },
        })
    }
  }

  const [x1, setX1] = useState<number>(0)
  const [y1, setY1] = useState<number>(0)

  useEffect(() => {
    const rect = product.current.getBoundingClientRect()
    setX1(rect.width / 2)
    setY1(rect.height / 2)
    setTimeout(() => {
      animate()
    }, 1500)
  }, [])

  useEffect(() => {
    if (count === 1) {
      setCircleProcent(55)
      setPrice(4900)
    } else if (count === 2) {
      setCircleProcent(75)
      setPrice(10900)
    } else if (count === 3) {
      setCircleProcent(85)
      setPrice(14900)
    } else if (count === 4) {
      setCircleProcent(100)
      setPrice(19900)
    }
  }, [count, price])

  return (
    <div className={s.container}>
      {/* {completed ? (
        <img className={s.video_gif} src="/images/mainPage/confetti.gif" alt="" />
      ) : (
        <></>
      )} */}
      <div className={s.donates}>
        <div className={s.top_donates} />
        <div className={s.product}>
          <div
            className={`${s.item} ${s.item1}`}
            ref={img1}
            style={{
              transform: `translate(${`${x1 / 10 + 3.5}`}rem, ${`${y1 / 10 - 10}`}rem)`,
            }}
          >
            <div className={s.img_block_pic}>
              <img src="/images/mainPage/user-pic1.png" alt="" />
            </div>
            <div className={s.price_pic}>+ 500 руб</div>
          </div>
          <div
            className={`${s.item} ${s.item2} item2`}
            ref={img2}
            style={{
              transform: `translate(${`${x1 / 10 + 1}`}rem, ${`${y1 / 10 + 8}`}rem)`,
            }}
          >
            <div className={s.img_block_pic}>
              <img src="/images/mainPage/user-pic2.png" alt="" />
            </div>
            <div className={s.price_pic}>+ 500 руб</div>
          </div>
          <div
            className={`${s.item} ${s.item5} item5`}
            ref={img5}
            style={{
              transform: `translate(${`${x1 / 10 - 21}`}rem, ${`${y1 / 10 + 8}`}rem)`,
            }}
          >
            <div className={s.img_block_pic}>
              <img src="/images/mainPage/user-pic5.png" alt="" />
            </div>
            <div className={s.price_pic}>+ 500 руб</div>
          </div>
          <div
            className={`${s.item} ${s.item7} item7`}
            ref={img7}
            style={{
              transform: `translate(${`${x1 / 10 - 23.5}`}rem, ${`${y1 / 10 - 10}`}rem)`,
            }}
          >
            <div className={s.img_block_pic}>
              <img src="/images/mainPage/user-pic.png" alt="" />
            </div>
            <div className={s.price_pic}>+ 500 руб</div>
          </div>
          <div ref={product} className="circle">
            <ProgressBar
              className={s.circle_progress}
              progress={circleProcent}
              radius={205}
              strokeWidth={20}
              trackStrokeWidth={26}
              initialAnimation
              transition="1.5s ease 0.1s"
              trackTransition="0s ease"
              strokeColor={completed ? "url('#gradientSVG')" : '#FF3E6C'}
            />
          </div>

          {completed ? (
            <div className={s.blur}>
              <div className={s.blur_img}>
                <img src="/images/mainPage/check-complete.svg" alt="" />
              </div>
              <div className={s.blur_text}>
                Желание <br /> исполнено!
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className={s.white}>
            <img src="/images/mainPage/white.png" alt="" />
          </div>
          <div className={s.img_block}>
            <img src="/images/mainPage/airpods.svg" alt="" />
          </div>
          {!completed ? <div className={s.price}>{price} руб</div> : <></>}
        </div>
      </div>
      <div className={s.title_mob}>Пришло время исполнить твои желания</div>
      <div className={s.btn}>
        <NavLink to="/login">Начать</NavLink>
      </div>

      <div className={s.subtitle}>Must Have приложение на День рождения</div>

      <div className={s.ios_android}>
        <a
          href="https://apps.apple.com/ru/app/wishmen-cpisok-zelanii/id1569348715"
          className={s.link}
        >
          <div className={s.item}>
            <img src="/images/mainPage/apple-main.png" alt="" />
          </div>
        </a>
        <a href="https://play.google.com/store/apps/details?id=wish.men" className={s.link}>
          <div className={s.item}>
            <img src="/images/mainPage/google-main.png" alt="" />
          </div>
        </a>
      </div>
    </div>
  )
}

export default React.memo(FirstBlock)
