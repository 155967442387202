import apiClient from 'application/data/apiClient'
import { useAppSelector } from 'application/domain/store/hooks'
import Loader from 'application/presentation/common/Loader'
import BottomSheet from 'application/presentation/common/ModalWindows/BottomSheet'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { NavLink } from 'react-router-dom'

import s from './ReferalUsersBS.module.scss'

type Props = {
  opened: boolean
  onClose: () => void
}

const fetchReferals = async () => {
  const data: any = await apiClient.get('/api/v1/user/referrals')

  return data.data
}

const ReferalUsersBS = ({ opened, onClose }: Props) => {
  const [sheetOpened, setSheetOpened] = useState(opened)

  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))

  const { data, isLoading } = useQuery(['referrals'], fetchReferals, {
    enabled: user && opened ? true : false,
  })

  const handleSheetClosed = () => {
    setSheetOpened(false)

    if (opened) {
      onClose()
    }
  }

  useEffect(() => {
    setSheetOpened(opened)
  }, [opened])

  return (
    <BottomSheet opened={sheetOpened} onSheetClosed={handleSheetClosed}>
      <div className={s.content}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {data?.users.map((item: any, i: number) => {
              return (
                <div className={s.user_ref} key={i}>
                  <NavLink to={`/${item.nickname}`}>
                    <div>
                      <div className={s.item_content}>
                        <div className={s.user_img}>
                          <img src={item.photo ? item.photo : 'photo'} alt="" />
                        </div>
                        <div className={s.user_name}>
                          <div>{item.name ? item.name : item.nickname}</div>
                          <div className={s.user_teg}>
                            <span>@</span>
                            <span>{item.nickname}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
              )
            })}
          </>
        )}
      </div>
    </BottomSheet>
  )
}

export default React.memo(ReferalUsersBS)
