import { useAppSelector } from 'application/domain/store/hooks'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import s from './BurgerMenu.module.scss'

type Props = {
  opened?: boolean
}

const BurgerMenu = ({ opened }: Props) => {
  const navigate = useNavigate()
  const { user, notificationsCount } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
    notificationsCount: userReducer.newNotificationsCount,
  }))

  const openMenu = () => {
    navigate(`/${user?.nickname}/menu`)
  }

  const closeMenu = () => {
    navigate(-1)
  }

  return (
    <div className={s.burger_menu}>
      {!opened && notificationsCount > 0 && (
        <div className={s.count}>
          <span>{notificationsCount}</span>
        </div>
      )}
      <button
        type="button"
        className={`${s.burger} ${opened ? s.open : ''}`}
        onClick={() => (opened ? closeMenu() : openMenu())}
      >
        <span />
        <span />
        <span />
      </button>
    </div>
  )
}

export default React.memo(BurgerMenu)
