/* eslint-disable react/no-unescaped-entities */
import apiClient from 'application/data/apiClient'
import { setUser, setUserToken } from 'application/domain/store/userReducer'
import { AuthContext } from 'application/presentation/app/AuthContext'
import { Button } from 'application/presentation/common/ActionComponents'
import {
  InputCode,
  InputPassword,
  InputPhone,
} from 'application/presentation/common/InputComponents'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/no-redeclare
import Animation from './Animation'
import s from './AuthPage.module.scss'
import Registration from './Registration'

const AuthPage = () => {
  const navigate = useNavigate()
  const authContext = useContext(AuthContext)
  const dispatch = useDispatch()
  const [isRegistered, setIsRegistered] = useState<boolean>(true)
  const [phoneComplited, setPhoneComplited] = useState<boolean>(false)
  const [phoneCheckIsLoading, setPhoneCheckIsLoading] = useState<boolean>(true)
  const [phone, setPhone] = useState<string>('')
  const [username, setUsername] = useState<string>('')
  const [userNickname, setUserNickname] = useState<string>('')
  const [startAnim, setStartAnim] = useState<boolean>(false)
  const [redirectToPage, setRedirectToPage] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loginMsg, setLoginMsg] = useState<string>('')
  const [phoneCode, setPhoneCode] = useState<boolean>(false)

  const [registration, setRegistration] = useState<boolean>(false)
  const [dataToken, setDataToken] = useState<any>('')

  const auth = useCallback(
    (phoneNumber: string, passwordVal?: string) => {
      authContext.login(
        { phone: `+${phoneNumber.replace(/[\s-+]/g, '')}`, password: passwordVal || '' },
        () => {
          setIsLoading(true)
        },
        async (response: any) => {
          setIsLoading(false)
          if (response.title === 'sent' || response.code || response.success) {
            if (response.title === 'sent') {
              localStorage.setItem('last_sms_date', new Date().toString())
              setPhoneCode(true)
            }
            if (response.code) {
              setLoginMsg(response.detail)
            }
            if (response.success && response.success.user) {
              setUsername(
                response.success.user.name
                  ? response.success.user.name
                  : response.success.user.nickname,
              )
              setUserNickname(response.success.user.nickname)
              setStartAnim(true)

              setTimeout(async () => {
                const userData = JSON.stringify(response.success.user)
                const userToken = response.success.user.api_token
                localStorage.setItem('userToken', userToken)
                localStorage.setItem('userData', userData)
                apiClient.defaults.headers.Authorization = `Bearer ${userToken}`
                setUser(response.success.user)(dispatch)
                setUserToken(response.success.user.api_token)(dispatch)
              }, 750)
            }
          } else {
            //   toast.show('Произошла ошибка, попробуйте позже')
          }
        },
      )
    },
    [authContext, dispatch],
  )

  useEffect(() => {
    if (redirectToPage) {
      navigate(`/${userNickname}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToPage])

  return (
    <section className={s.auth_section}>
      {!registration ? (
        <div className={`${s.login_section} login-section`}>
          <div className={s.auth_logo}>
            <img
              loading="lazy"
              className={`${s.colorful_logo} colorful-logo`}
              // ref={colorful_logo}
              src="/images/icons/newlogo.svg"
              alt=""
            />
            <img
              loading="lazy"
              className={`${s.logo_white} logo-white`}
              // ref={logo_white}
              src="/images/icons/wishmen-white.svg"
              alt=""
            />
          </div>
          <form className={`${s.auth_block} auth-block`} id="auth-block">
            <div
              className={`${s.title} title`}
              //   ref={title}
            >
              {isRegistered ? (
                <>
                  Вход по номеру <br />
                  телефона
                </>
              ) : (
                'Зарегистрируйтесь'
              )}
            </div>
            <div className={`${s.subtitle} subtitle`}>
              Если у Вас еще нет профиля, <br /> он будет создан
            </div>
            <div
              //   ref={phoneBlock}
              className="input-block"
            >
              <InputPhone
                setIsRegistered={setIsRegistered}
                setPhoneComplited={setPhoneComplited}
                setPhoneCheckIsLoading={setPhoneCheckIsLoading}
                onChange={setPhone}
              />
            </div>

            <div
              className={`input-block ${
                phoneComplited && !phoneCheckIsLoading && isRegistered ? s.visible : s.hidden
              }`}
              // ref={passwordBlock}
            >
              <InputPassword
                onChange={(val: string) => {
                  setPassword(val)
                  setLoginMsg('')
                }}
              />
              {loginMsg && <div className={s.msg}>{loginMsg}</div>}
              <button
                type="button"
                className={`${s.password_recovery} pass_recovery_desktop`}
                // onClick={this.props.passwordRecovery}
              >
                Восстановить пароль
              </button>
              <Button
                title="Войти"
                color="red"
                isLoading={isLoading}
                containerClassName={s.btn_container}
                className={s.btn}
                onClick={() => auth(phone, password)}
              />
            </div>

            {!phoneComplited && (
              <div className={`${s.social} social`}>
                <div className={s.headline}>или войдите через соц сети</div>
                <div className={s.soc_block}>
                  <a
                    href="/api/v1/social/auth/vkontakte/redirect"
                    className={`${s.item} ${s.vk}`}
                    rel="nofollow"
                    target="_blank"
                  >
                    <img src="/images/icons/vk-white-icon.svg" alt="" />
                  </a>
                  <a
                    href="/api/v1/social/auth/facebook/redirect"
                    className={`${s.item} ${s.fb}`}
                    rel="nofollow"
                    target="_blank"
                  >
                    <img src="/images/icons/fb-white-icon.svg" alt="" />
                  </a>
                </div>
              </div>
            )}

            <div
              className={`
              ${phoneComplited && !phoneCheckIsLoading && !isRegistered ? s.visible : s.hidden} ${
                phoneCode ? s.hidden : s.visible
              }
            `}
            >
              <Button
                title="Отправить смс"
                color="red"
                isLoading={isLoading}
                containerClassName={s.btn_container}
                className={s.btn}
                onClick={() => {
                  auth(phone)
                }}
              />
            </div>
            {phoneCode ? (
              <InputCode
                phone={phone}
                onComplete={(data: any) => {
                  if (!data.errors) {
                    setRegistration(true)
                    setDataToken(data.api_token)
                  }
                }}
                authAfterDispatch
              />
            ) : (
              <></>
            )}
          </form>
          <div className={`${s.bottom_info} bottom-info`}>ООО "Исполнение желаний"</div>
        </div>
      ) : (
        <Registration
          dataToken={dataToken}
          setStartAnim={setStartAnim}
          setUsername={setUsername}
          setUserNickname={setUserNickname}
        />
      )}
      {startAnim && <Animation setRedirectToPage={setRedirectToPage} username={username} />}
    </section>
  )
}

export default React.memo(AuthPage)
