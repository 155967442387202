/* eslint-disable react/no-danger */
import React from 'react'
import cn from 'classnames'
import apiClient from 'application/data/apiClient'
import { useQuery } from '@tanstack/react-query'
import { Category } from 'application/presentation/pages/FaqPage/FaqCategory'
import { ArticleLayout, ArticleLayoutContent } from 'application/presentation/common/ArticleLayout'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import ArticleContent from 'application/presentation/common/ArticleContent'
import Collapse from 'react-css-collapse'
import { Title } from 'react-head'
import { MetaDescriptionFaq, MetaTitleFaq, MetaOG } from 'application/presentation/app/MetaTags'
import { useTranslation } from 'react-i18next'
import Loader from 'application/presentation/common/Loader'
import ContentLayout from 'application/presentation/common/ContentLayout'
import { useParams } from 'react-router-dom'

import s from './FaqQuestionsPage.module.scss'

const fetchFaqQuestions = (categorySlug?: string) => async (): Promise<Category | null> => {
  if (typeof categorySlug === 'undefined') {
    return null
  }
  const response = await apiClient.get<Category>(`/api/v1/article/category/${categorySlug}`)

  return response.data
}

const FaqQuestionsPage = (): React.ReactElement => {
  const params = useParams<{ slug?: string }>()
  const { t } = useTranslation()
  const { isLoading, data } = useQuery<Category | null>(
    ['faq_questions', params.slug],
    fetchFaqQuestions(params.slug),
  )
  const [currentQuestion, setCurrentQuestion] = React.useState<string>()

  if (isLoading) {
    return <Loader />
  }

  const handleQuestionClick = (id: string) => (): void => {
    setCurrentQuestion((prevId) => {
      if (prevId === id) {
        return undefined
      }

      return id
    })
  }

  return (
    <ContentLayout>
      <MetaTitleFaq />
      <MetaDescriptionFaq />
      <MetaOG
        title={t('faq_page.meta.title') || ''}
        description={t('faq_page.meta.description') || ''}
      />
      <ArticleLayout>
        <ArticleLayoutContent>
          {data && <Title>{data.name}</Title>}
          {data && <HeaderMobileTitle.Source>{data.name}</HeaderMobileTitle.Source>}
          <ArticleContent className={s.viewMobile}>
            {data && (
              <div className={s.container}>
                <div className={s.questions}>
                  {data.articles &&
                    data.articles.map((question) => (
                      <div
                        key={question.id}
                        className={cn({
                          [s.question]: true,
                          [s.question_open]: question.id === currentQuestion,
                        })}
                      >
                        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
                        <div className={s.questionTitle} onClick={handleQuestionClick(question.id)}>
                          {question.title}
                        </div>
                        <Collapse
                          isOpen={question.id === currentQuestion}
                          aria-hidden={question.id === currentQuestion ? 'false' : 'true'}
                        >
                          <div
                            className={s.questionContent}
                            dangerouslySetInnerHTML={{ __html: question.content }}
                          />
                        </Collapse>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </ArticleContent>
        </ArticleLayoutContent>
      </ArticleLayout>
    </ContentLayout>
  )
}

export default React.memo(FaqQuestionsPage)
