import { useAppSelector } from 'application/domain/store/hooks'
import { setWishMoreMwData } from 'application/domain/store/modalReducer'
import { useComments } from 'application/domain/useCase/comments/getComments'
import useWish from 'application/domain/useCase/wish/getWish'
import { WishLikeMutation } from 'application/domain/useCase/wish/likeWish'
import { HeaderFooterContext } from 'application/presentation/app/HeaderFooter'
import { ShareButton } from 'application/presentation/common/ActionComponents'
import { HeaderMobileRightButton, HeaderMobileTitle } from 'application/presentation/common/Header'
import Loader from 'application/presentation/common/Loader'
import DeleteWishMW from 'application/presentation/common/ModalWindows/DeleteWishMW'
import WishMoreMW from 'application/presentation/common/ModalWindows/WishMoreMW'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { clearPhotoInComment, getPhotoUrlFromComment } from '../CommentsPage/parseComment'

import Content from './components/Content'
import TopBlock from './components/TopBlock'
import s from './WishPage.module.scss'

const WishPage = () => {
  const FooterContext = useContext(HeaderFooterContext)
  const params = useParams<{ wishid?: string }>()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    FooterContext?.showFooter(false)
    return () => FooterContext?.showFooter(true)
  }, [FooterContext])

  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const wishid = useMemo(() => {
    return params && params.wishid
      ? params.wishid.toString().startsWith('!')
        ? params.wishid.toString().replace('!', '')
        : params.wishid
      : '0'
  }, [params])
  const isShortLink = useMemo(() => {
    return !!(params && params.wishid && params.wishid.toString().startsWith('!'))
  }, [params])
  const { data, isLoading, isFetching, refetch } = useWish(parseInt(wishid, 10), isShortLink)

  const wishData = useMemo(() => {
    return data ? data : undefined
  }, [data])

  const likeMutation = WishLikeMutation(
    wishData ? wishData.id : 0,
    wishData ? wishData.user.nickname : '',
  )

  const { data: commentsData } = useComments(
    !isShortLink ? parseInt(wishid, 10) : wishData && wishData.id ? wishData.id : 0,
  )

  const isIdea: boolean = useMemo(() => {
    return data && data.type === 'idea' ? true : false
  }, [data])

  const isMyWish = useMemo(
    () => !!(user && wishData && wishData.user && user.id === wishData.user.id),
    [user, wishData],
  )
  const firstComment = useMemo(() => {
    return commentsData && commentsData.pages[0].data && commentsData.pages[0].data.length
      ? {
          ...commentsData.pages[0].data[0],
          text: getPhotoUrlFromComment(commentsData.pages[0].data[0].text)
            ? `${clearPhotoInComment(commentsData.pages[0].data[0].text)} (Добавлено фото)`
            : commentsData.pages[0].data[0].text,
        }
      : null
  }, [commentsData])

  const totalComments = useMemo(() => {
    return commentsData ? commentsData.pages[0].total : 0
  }, [commentsData])

  const moreClickHandler = useCallback(() => {
    if (wishData) {
      setWishMoreMwData({
        wish_id: wishData.id,
        is_public: wishData.is_public ? true : false,
      })(dispatch)
      navigate(`#more_mw`)
    }
  }, [dispatch, navigate, wishData])

  return (
    <div>
      <HeaderMobileTitle.Source>Желание</HeaderMobileTitle.Source>
      <HeaderMobileRightButton.Source>
        {!isIdea ? (
          <div className={s.modal_more} onClick={moreClickHandler}>
            <div className={s.icon}>
              <img loading="lazy" src="/images/icons/3points.svg" alt="" />
            </div>
          </div>
        ) : (
          <></>
        )}
        <ShareButton
          shareType="wish"
          nickname={wishData ? wishData.user.nickname : ''}
          wishId={wishData ? wishData.id : undefined}
        />
      </HeaderMobileRightButton.Source>
      {isLoading || !wishData ? (
        <Loader size={32} />
      ) : (
        <div>
          <TopBlock wishData={wishData} isIdea={isIdea} />
          <Content
            wishData={wishData}
            firstComment={firstComment}
            totalComments={totalComments}
            user={user}
            likeWish={likeMutation.mutate}
          />
        </div>
      )}
      <WishMoreMW />
      <DeleteWishMW />
    </div>
  )
}

export default React.memo(WishPage)
