import Footer from 'application/presentation/common/Footer'
import Header from 'application/presentation/common/Header'
import React, { useRef, useMemo, useState } from 'react'

type HeaderFooterContextType = {
  showFooter: (show: boolean) => void
  headerBgColor: (color: boolean) => void
} | null

export const HeaderFooterContext = React.createContext<HeaderFooterContextType>(null)

export const HeaderFooterProvider = ({ children }: any) => {
  const [showFooter, setShowFooter] = useState<boolean>(true)
  const [headerBgColor, setHeaderBgColor] = useState<boolean>(false)

  const headerFooterFunc = useMemo(
    () => ({
      showFooter: (show: boolean) => {
        setShowFooter(show)
      },
      headerBgColor: (color: boolean) => {
        setHeaderBgColor(color)
      },
    }),
    [],
  )

  return (
    <HeaderFooterContext.Provider value={headerFooterFunc}>
      <Header headerBgColor={headerBgColor} />
      {children}
      <Footer showFooter={showFooter} />
    </HeaderFooterContext.Provider>
  )
}

export const { Consumer } = HeaderFooterContext
