import { List } from 'framework7-react'
import React, { useCallback, useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { WishCategoryWithWishes } from 'application/domain/entity/wish/WishCategory'
import { useNavigate } from 'react-router-dom'

import WishItem from './components/WishItem'
import s from './WishlistCategory.module.scss'

type Props = {
  data: WishCategoryWithWishes & { type?: string }
  isMyProfile?: boolean
}

const WishlistCategory = ({ data, isMyProfile }: Props) => {
  const [opened, setOpened] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (data && data.wish) {
      let count = 0
      for (let i = 0; i < data.wish.length; i++) {
        const wish = data.wish[i].status === 'delivered' ? 1 : 0
        count += wish
      }
      if (count !== 0) {
        setOpened(false)
      }
    }
  }, [data])

  const moreBtnClickHandler = useCallback(
    () => navigate(`?category_id=${data.id}#category_more`),
    [data.id, navigate],
  )

  return (
    <div className={`${s.container} mycategory_section`} data-id={data.id}>
      <div className={`${s.headline} ${!opened ? s.closed : ''}`}>
        <div className={s.title} onClick={() => setOpened(!opened)}>
          <span>{data.name}</span>
          <img
            loading="lazy"
            className={`${s.mycateg_arrow} `}
            src="/images/icons/mycateg-arrow.svg"
            alt=""
          />
          {data.type === 'completed_wish' ? (
            <div className={s.wish_count}>{data.wish.length}</div>
          ) : (
            <></>
          )}
          {data.permission && data.permission.type === 'deny' ? (
            <img
              loading="lazy"
              className={s.hide_icon}
              src="/images/icons/hide-icon-gray.svg"
              alt=""
            />
          ) : (
            ''
          )}
        </div>
        {isMyProfile && data.type !== 'completed_wish' && (
          <div className={s.menu} onClick={moreBtnClickHandler}>
            <img src="/images/icons/3points.svg" alt="" />
          </div>
        )}
      </div>

      <AnimatePresence initial={false}>
        {opened && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            // style={{ overflow: 'hidden' }}
            variants={{
              open: {
                opacity: 1,
                height: 'auto',
              },
              collapsed: { opacity: 0, height: 0, overflow: 'hidden' },
            }}
            transition={{ duration: 0.5 }}
          >
            <List className={`${s.wish_list}`}>
              {data.wish && data.wish.length ? (
                data.wish.map((item: any) =>
                  item.is_main !== 1 ? (
                    <WishItem data={item} isMyProfile={isMyProfile} key={`wish_${item.id}`} />
                  ) : (
                    <></>
                  ),
                )
              ) : (
                <></>
              )}
            </List>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  )
}

export default React.memo(WishlistCategory)
