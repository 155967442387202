import apiClient from '../apiClient'

export const fetchArticle = async (id: string) => {
  const { data } = await apiClient.get(`/api/v1/article/${id}`)
  return data
}

export const fetchArticleCategory = async (id: number | string) => {
  const { data } = await apiClient.get(`/api/v1/article/category/${id}`)
  return data
}

export const fetchArticleList = async () => {
  const { data } = await apiClient.get(`/api/v1/article/category/2`)
  return data
}

export const fetchGuaranty = async () => {
  const { data } = await apiClient.get(`/api/v1/article/category/10`)
  return data
}

export const fetchFaqCategories = async () => {
  const { data } = await apiClient.get('/api/v1/article/category/3')
  return data
}

export const fetchCollections = async () => {
  const { data } = await apiClient.get('/api/v1/article/category/2')
  return data
}
