import splitByTwo from 'application/presentation/utils/splitByTwo'
import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import s from './ShopCategoryPage.module.scss'

type Props = {
  data: any
}

const CategItem = ({ item }: any) => {
  return (
    <NavLink className={s.categ_card} to={`/shop/category/${item.slug}`}>
      <div className={s.categ_img_block}>
        <img src={item.photo ? item.photo : ''} alt="" className={s.categ_img} />
      </div>
      <div className={s.categ_name}>
        <span>{item.name}</span>
      </div>
    </NavLink>
  )
}

const SubCategories = ({ data }: Props) => {
  const categData = useMemo(() => {
    return splitByTwo(data || [])
  }, [data])
  return (
    <div className={s.sub_categories_container}>
      <div className={s.flat_list}>
        {categData ? (
          categData.map((item: any, i: number) => (
            <div key={`subcateg_${i}`}>
              {item[0] ? <CategItem item={item[0]} /> : <></>}
              {item[1] ? <CategItem item={item[1]} /> : <></>}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
export default React.memo(SubCategories)
