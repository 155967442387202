import React, { useMemo, useState } from 'react'
// @ts-ignore
import { VelocityTransitionGroup } from 'velocity-react'

import s from './MultipleSelector.module.scss'

type SelectedValType = {
  value: number
  label: string
  data?: Array<SelectedValType>
}
type Props = {
  item: SelectedValType
  onClick: Function
  closeSelector: Function
  selected: SelectedValType
}

const SelectItem = ({ onClick, item, closeSelector, selected }: Props) => {
  const [opened, setOpened] = useState<boolean>(false)
  const isMultipleList = useMemo(() => {
    return !!(item.data && item.data.length)
  }, [item])

  return (
    <div className={s.item}>
      <div
        className={`${s.item_name} ${item.value === selected.value ? s.active : ''}`}
        onClick={() => {
          if (isMultipleList) {
            setOpened(!opened)
          } else {
            onClick(item)
            closeSelector()
          }
        }}
      >
        <div className={`${s.item_title} ${!isMultipleList ? s.full_width : ''}`}>
          {item.label ? item.label : item.value}
        </div>
        {!isMultipleList && item.value === selected.value ? (
          <div className={s.select_icon}>
            <img src="/images/icons/red_accept.svg" alt="" />{' '}
          </div>
        ) : (
          <></>
        )}
        {isMultipleList ? (
          <div className={`${s.red_trigger} ${opened ? s.opened : ''}`}>
            <svg
              width="5"
              height="8"
              viewBox="0 0 5 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 0L5 4L0 8L0 0Z" fill="#FF004C" />
            </svg>
          </div>
        ) : (
          <></>
        )}
      </div>

      <VelocityTransitionGroup enter={{ animation: 'slideDown' }} leave={{ animation: 'slideUp' }}>
        {opened && (
          <div className={s.sub_items_container}>
            {isMultipleList && item.data ? (
              item.data.map((item2: SelectedValType, i: number) => (
                <div
                  key={`sub_item_${i}`}
                  className={`${s.sub_item} ${item2.value === selected.value ? s.active : ''}`}
                  onClick={() => {
                    onClick(item2)
                    closeSelector()
                  }}
                >
                  <div className={s.sub_item_name}>{item2.label}</div>
                  {item2.value === selected.value ? (
                    <div className={s.select_icon}>
                      <img src="/images/icons/red_accept.svg" alt="" />{' '}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
        )}
      </VelocityTransitionGroup>
    </div>
  )
}

export default React.memo(SelectItem)
