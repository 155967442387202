import React, { useContext } from 'react'
import 'moment/locale/ru'
import moment from 'moment'
import { NavLink } from 'react-router-dom'
import { FriendsActionButton } from 'application/presentation/common/ActionComponents'
import { MwContext } from 'application/presentation/common/ModalWindows/MWProvider'
import _ from 'lodash'
import { User } from 'application/domain/entity/user/User'

import s from './FriendItem.module.scss'

type Props = {
  item: User
  isMyProfile: boolean
  isSearch?: boolean
  isNotRegistered?: boolean
  searchVal?: string
  fullBottomBorder?: boolean
  isFromContacts?: boolean
  type?: string
  actionInBottom?: boolean
  bday?: boolean
}

const FriendItem = ({
  item,
  isMyProfile,
  isSearch,
  isNotRegistered,
  searchVal,
  fullBottomBorder,
  isFromContacts,
  type,
  actionInBottom,
  bday,
}: Props) => {
  const mwContext = useContext(MwContext)

  return (
    <>
      <NavLink
        to={`/${item.nickname}`}
        className={`${s.item} ${isNotRegistered ? s.bottom_padding : ''} ${
          fullBottomBorder ? s.full_border : ''
        } ${actionInBottom ? s.bottom_action : ''} ${bday ? s.bday : ''}`}
      >
        <div className={s.user_touchable}>
          <div className={s.user_img_container}>
            <img src={item.photo ? item.photo : ''} alt="" className={s.user_img} />
          </div>
          <div className={s.center}>
            <div className={s.name_container}>
              <div className={s.name}>
                {!isNotRegistered
                  ? `${item.name ? item.name : item.nickname}`
                  : `${item.name ? item.name : searchVal}`}
              </div>
              {item.is_validated ? (
                <div className={s.verify_icon_container}>
                  <img src="/images/icons/verify_icon.svg" alt="" />
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className={s.teg}>
              {!isNotRegistered ? `@${item.nickname}` : 'Пригласить в сервис'}
            </div>
            {item.wish_ideas ? (
              <div className={s.red_txt}>
                {item.wish_ideas} иде
                {item.wish_ideas === 1 ? 'я' : item.wish_ideas <= 4 ? 'и' : 'й'}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {!isNotRegistered ? (
          <div className={s.right}>
            {item.relationship === 'friends' ? (
              <>
                <div className={s.menu_container}>
                  <div
                    className={s.inner_container}
                    onClick={(e: any) => {
                      e.preventDefault()
                      mwContext?.openFriendsActionMW(true, {
                        name: item.name ? item.name : item.nickname,
                        nickname: item.nickname,
                      })
                    }}
                  >
                    <img src="/images/icons/3points.svg" alt="" />
                  </div>
                </div>
                {item.birthdate && (
                  <div className={s.birthdate}>{moment(item.birthdate).format('D MMMM')}</div>
                )}
              </>
            ) : (
              <div className={s.btn_container}>
                <FriendsActionButton
                  data={item}
                  withoutMarginAndPadding
                  isMyProfile={isMyProfile}
                  isSearch={isSearch}
                  type={type}
                />
              </div>
            )}
          </div>
        ) : (
          <div className={s.bottom_container}></div>
        )}
      </NavLink>

      {/* {bday && openedWish && (
        <div className={s.bday_border}>
          <button
            type="button"
            className={s.donate_btn}
            onClick={(e: any) => {
              // history.push(`${history.location.pathname}/donate`)
              e.preventDefault()
              e.stopPropagation()
              history.push(`/${item.nickname}/${openedWish.id}/donate`)
            }}
          >
            <span className={s.img_donate}>
              <img src="/images/icons/rub_icon.svg" alt="" />
            </span>{' '}
            <span className={s.text_donate}>Поздравить с ДР</span>
          </button>
        </div>
      )} */}
    </>
  )
}

export default React.memo(FriendItem)
