import { useOrder } from 'application/domain/useCase/order/getOrder'
import BottomSheet from 'application/presentation/common/ModalWindows/BottomSheet'
import React, { useEffect, useState } from 'react'

import UserOrder from './UserOrder'

type Props = {
  orderId: number
  opened: boolean
  onClose: () => void
}

const UserOrderBottomSheet = ({ orderId, opened, onClose }: Props): React.ReactElement => {
  const orderQuery = useOrder(orderId, { enabled: false })
  const handleSheetOpened = () => {
    orderQuery.refetch()
  }

  const [sheetOpened, setSheetOpened] = useState(opened)
  const handleSheetClosed = () => {
    setSheetOpened(false)

    if (opened) {
      onClose()
    }
  }

  useEffect(() => {
    setSheetOpened(opened)
  }, [opened])

  return (
    <BottomSheet
      opened={sheetOpened}
      onSheetClosed={handleSheetClosed}
      onSheetOpened={handleSheetOpened}
    >
      <UserOrder order={orderQuery.data} />
    </BottomSheet>
  )
}

export default React.memo(UserOrderBottomSheet)
