import cn from 'classnames'
import React, { useMemo } from 'react'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import NavTabs from 'application/presentation/common/NavTabs'
import NavTab from 'application/presentation/common/NavTabs/NavTab'
import { useSwipeable } from 'react-swipeable'
import { Title } from 'react-head'
import { Order } from 'application/domain/entity/order/Order'
import { useLocation, useNavigate } from 'react-router-dom'

import s from './UserOrders.module.scss'
import UserOrdersItem from './UserOrdersItem'

type Props = {
  orders?: ReadonlyArray<Order>
  t: any
}

const UserOrders = ({ orders, t }: Props): React.ReactElement => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isOnlyCompleted = pathname.indexOf('/orders/completed') !== -1
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (!isOnlyCompleted) {
        navigate('/orders/completed', { replace: true })
      }
    },
    onSwipedRight: () => {
      if (isOnlyCompleted) {
        navigate('/orders', { replace: true })
      }
    },
  })

  const ordersFiltered =
    (isOnlyCompleted
      ? orders?.filter((order) => order.status === 'delivered' || order.status === 'processed')
      : orders?.filter((order) => order.status !== 'delivered' && order.status !== 'processed')) ??
    []

  const completedCount = useMemo(() => {
    if (orders) {
      return orders?.filter((order) => order.status === 'delivered' || order.status === 'processed')
        .length
    }
    return 0
  }, [orders])

  return (
    <div className={cn({ [s.container]: true, [s.viewMobile]: true })}>
      <Title>
        {t('orders_page.my_orders')} –{' '}
        {isOnlyCompleted ? t('orders_page.completed') : t('orders_page.actual')}
      </Title>
      <HeaderMobileTitle.Source>{t('orders_page.my_orders')}</HeaderMobileTitle.Source>

      <NavTabs>
        <NavTab
          to="/orders"
          // isActive={() => !isOnlyCompleted}

          replace
        >
          {t('orders_page.actual')}
        </NavTab>
        <NavTab to="/orders/completed" replace>
          {t('orders_page.completed')} {completedCount ? `(${completedCount})` : ''}
        </NavTab>
      </NavTabs>
      {ordersFiltered.length === 0 ? (
        <div className={s.empty}>{t('orders_page.empty')}</div>
      ) : (
        <div className={s.orders} {...swipeHandlers}>
          {ordersFiltered.map((order) => (
            <UserOrdersItem key={order.id} order={order} t={t} />
          ))}
        </div>
      )}
    </div>
  )
}

export default React.memo(UserOrders)
