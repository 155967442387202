import React from 'react'

import s from './SearchField.module.scss'

type Props = {
  onChange: Function
  searchVal: string
}

const SearchField = ({ onChange, searchVal }: Props) => {
  return (
    <div className={s.search_block}>
      <div className={`${s.search_relative_container} search_relative_container`}>
        <div className={`${s.search_friend} second-step`}>
          <img loading="lazy" className={s.scope} alt="" src="/images/icons/gray-scope.svg" />
          <input
            type="text"
            id="friend_search"
            name="search"
            placeholder="Поиск"
            onChange={(e: any) => {
              onChange(e?.target!.value!)
            }}
            value={searchVal}
          />
          {searchVal ? (
            <button type="button">
              <img
                src="/images/icons/clear-input-icon.svg"
                alt=""
                onClick={() => {
                  onChange('')
                }}
              />
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(SearchField)
