import { SearchField } from 'application/presentation/common/ActionComponents'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Loader from 'application/presentation/common/Loader'
import ContentLayout from 'application/presentation/common/ContentLayout'
import ProductItem from 'application/presentation/common/ProductItem'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSearch } from 'application/domain/useCase/search/useSearch'
import splitByTwo from 'application/presentation/utils/splitByTwo'

import s from './SearchResultPage.module.scss'
import TopBlock from './components/TopBlock'
import CategoryItem from './components/CategoryItem'
import TopBlockProducts from './components/TopBlockProducts'

const SearchResultPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [categoryName, setCategoryName] = useState<string>('')
  const [searchVal, setSearchVal] = useState<string>('')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const queryVal = params.get('query')
    if (queryVal) {
      setSearchVal(queryVal)
    }
  }, [location.search])

  const isSearchInCategory = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const queryVal = params.get('category')
    return !!queryVal
  }, [location])

  const { data, isLoading, hasNextPage, fetchNextPage } = useSearch({
    searchVal,
  })

  const searchResult = useMemo(() => {
    return data && data.pages
      ? isSearchInCategory
        ? splitByTwo(data.pages.flatMap((page: any) => page.data))
        : data.pages.flatMap((page: any) => page.data)
      : []
  }, [data, isSearchInCategory])

  const doSearchInCategory = useCallback(
    (categ_id: string, categ_name: string) => {
      setCategoryName(categ_name)
      const params = new URLSearchParams(location.search)
      const queryVal = params.get('query')
      navigate(`/shop/results?query=${queryVal}&category=${categ_id}`)
    },
    [location.search, navigate],
  )
  const doSearch = useCallback(() => {
    if (searchVal) {
      navigate(`/shop/results?query=${searchVal}&group-by=category`)
    }
  }, [searchVal, navigate])

  return (
    <ContentLayout>
      <InfiniteScroll
        dataLength={searchResult.length}
        next={() => fetchNextPage()}
        style={{ overflow: 'visible' }}
        hasMore={!!hasNextPage}
        loader={
          <div className="pagination_loader">
            <Loader size={16} />
          </div>
        }
        scrollableTarget="page_content"
      >
        <SearchField
          containerClassName={s.search_container}
          value={searchVal}
          onChange={setSearchVal}
          onSubmit={doSearch}
          onSearchBtnClick={doSearch}
        />
        {isLoading ? (
          <Loader size={16} />
        ) : searchResult && searchResult.length ? (
          isSearchInCategory ? (
            <>
              <TopBlockProducts
                total={data && data.pages && data.pages[0].total ? data.pages[0].total : 0}
                categoryName={categoryName}
              />
              {searchResult.map((item: any, i: number) => (
                <div className={s.row} key={`row_${i}`}>
                  {item[0] ? <ProductItem item={item[0]} adaptive /> : <></>}
                  {item[1] ? <ProductItem item={item[1]} adaptive isLastItem /> : <></>}
                </div>
              ))}
            </>
          ) : (
            <>
              <TopBlock data={searchResult} doSearchInCategory={doSearchInCategory} />
              {searchResult.map((item: any) => (
                <CategoryItem data={item} key={item.id} />
              ))}
            </>
          )
        ) : (
          <div className={s.empty_container}>
            <div className={s.empty_txt}>По вашему запросу ничего не найдено</div>
          </div>
        )}
      </InfiniteScroll>
    </ContentLayout>
  )
}

export default React.memo(SearchResultPage)
