import { configureStore } from '@reduxjs/toolkit'

import userReducer from './userReducer'
import payReducer from './payReducer'
import modalReducer from './modalReducer'

export const store = () =>
  configureStore({
    reducer: {
      payReducer,
      userReducer,
      modalReducer,
    },
  })

export type AppStore = ReturnType<typeof store>
export type RootState = ReturnType<AppStore['getState']>
