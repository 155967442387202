import Loader from 'application/presentation/common/Loader'
import SelectorMW from 'application/presentation/common/ModalWindows/SelectorMW'
import React, { useRef, useState } from 'react'

import s from './Selector.module.scss'

type Props = {
  data: Array<{ id: number; name: string }>
  title: string
  value: number
  isLoading?: boolean
  withoutMargin?: boolean
  onChange?: Function
  roundedStyle?: boolean
}

const Selector = ({
  data,
  title,
  value,
  isLoading,
  withoutMargin,
  onChange,
  roundedStyle,
}: Props) => {
  const [opened, setOpened] = useState<boolean>(false)
  const selectedItem = data ? data.filter((item: any) => item.id === value) : 0
  return (
    <div className={`${s.input_block} ${withoutMargin ? s.without_margin : ''}`}>
      <div
        className={`${s.input_block_inner} ${roundedStyle ? s.rounded_inner : ''}`}
        onClick={() => {
          setOpened(true)
          // openMW.openPaySelectorMW(true)
        }}
      >
        <div className={`${s.bold_txt} ${roundedStyle ? s.black_txt : ''}`}>{title}</div>
        <div className={s.selected_val}>
          <div className={s.sel_txt}>
            {isLoading ? (
              <Loader size={10} color="#eaeaea" />
            ) : (
              <>{selectedItem && selectedItem[0] ? selectedItem[0].name : ''}</>
            )}
          </div>
          <div className={s.trigger}>
            <svg
              width="10"
              height="6"
              viewBox="0 0 10 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.84016 0.540049L9.5126 0.210209C9.40951 0.1072 9.27235 0.0504519 9.12577 0.0504519C8.97926 0.0504519 8.84194 0.1072 8.73885 0.210209L5.00224 3.94699L1.26131 0.206063C1.15838 0.103054 1.02107 0.0463867 0.87456 0.0463867C0.728055 0.0463867 0.590655 0.103054 0.487646 0.206063L0.160001 0.53387C-0.0533338 0.747043 -0.0533338 1.09428 0.160001 1.30745L4.61402 5.77749C4.71695 5.88042 4.8541 5.95294 5.00191 5.95294H5.00362C5.1502 5.95294 5.28736 5.88034 5.39029 5.77749L9.84016 1.31957C9.94325 1.21664 9.99984 1.07534 10 0.928833C10 0.782246 9.94325 0.642896 9.84016 0.540049Z"
                fill="#CECECE"
              />
            </svg>
          </div>
        </div>
      </div>
      <SelectorMW
        opened={opened}
        data={data}
        value={value}
        onChange={onChange}
        title={title}
        setOpened={setOpened}
      />
    </div>
  )
}

export default React.memo(Selector)
