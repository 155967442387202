/* eslint-disable react/no-danger */
import React from 'react'
import cn from 'classnames'
import { ArticleLayout, ArticleLayoutContent } from 'application/presentation/common/ArticleLayout'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import ArticleContent from 'application/presentation/common/ArticleContent'
import { Title } from 'react-head'
import { useTranslation } from 'react-i18next'
import BePartnerMW from 'application/presentation/common/ModalWindows/BePartnerMW'
import Button from 'application/presentation/common/ActionComponents/Button'

import s from './ForPartnersPage.module.scss'

const ForPartnersPage = (): React.ReactElement => {
  const { t } = useTranslation()

  const handleChatClick = () => {
    if (typeof window.jivo_api !== 'undefined') {
      window.jivo_api.open()
    }
  }

  return (
    <ArticleLayout>
      <ArticleLayoutContent>
        <Title>{t('for_partners_page.title')}</Title>
        <HeaderMobileTitle.Source>{t('for_partners_page.title')}</HeaderMobileTitle.Source>

        <ArticleContent className={s.viewMobile}>
          <div className={s['block-first']}>
            <div className={s.left}>
              <div
                className={s.text}
                dangerouslySetInnerHTML={{ __html: t('for_partners_page.text') }}
              />
              <div className={s['btn-block']}>
                <a href="/partners/wishmen.pdf" className={cn(s.btn, s['white-btn'])}>
                  <img src="/images/icons/pdf-icon.svg" alt="icon" />
                  <span>{t('for_partners_page.download_presentation')}</span>
                </a>
              </div>
            </div>
            <div className={s.right}>
              <div className={s['circle-diag']}>
                <div className={s.innercircle} />
                <div className={s.percent}>+10%</div>
              </div>
              <div className={s['info-text-flex']}>
                <div className={s['left-half']}>
                  <div className={s.line} />
                  <div className={s.point} />
                  <div dangerouslySetInnerHTML={{ __html: t('for_partners_page.your_ca') }} />
                </div>
                <div className={s['right-half']}>
                  <div className={s.line} />
                  <div dangerouslySetInnerHTML={{ __html: t('for_partners_page.new_ca') }} />
                </div>
              </div>
            </div>
          </div>
          <div className={s.separator} />
          <div className={s.advantages}>
            <div className={s.title}>{t('for_partners_page.second_block.title')}</div>
            <div className={s['advantages-flex']}>
              <div className={s.item}>
                <div className={s.icon}>
                  <img src="/images/icons/white-add-plus.svg" alt="icon" />
                </div>
                <div
                  className={cn(s.title, s.red)}
                  dangerouslySetInnerHTML={{
                    __html: t('for_partners_page.second_block.first_item.title'),
                  }}
                />
                <div className={s.subtitle}>
                  {t('for_partners_page.second_block.first_item.text')}
                </div>
              </div>
              <div className={s.item}>
                <div className={s.icon}>
                  <img src="/images/icons/share-icon-app.svg" alt="icon" />
                </div>
                <div className={cn(s.title, s.red)}>
                  {t('for_partners_page.second_block.second_item.title')}
                </div>
                <div className={s.subtitle}>
                  {t('for_partners_page.second_block.second_item.text')}
                </div>
              </div>
              <div className={s.item}>
                <div className={s.icon}>
                  <img src="/images/icons/rub-in-red-circle.svg" alt="icon" />
                </div>
                <div
                  className={cn(s.title, s.red)}
                  dangerouslySetInnerHTML={{
                    __html: t('for_partners_page.second_block.third_item.title'),
                  }}
                />
                <div className={s.subtitle}>
                  {t('for_partners_page.second_block.third_item.text')}
                </div>
              </div>
            </div>
          </div>
          <div className={s.separator} />
          <div className={s.wantbepartner}>
            <div className={s.title}>{t('for_partners_page.third_block.title')}</div>
            <div className={s.subtitle}>{t('for_partners_page.third_block.text')}</div>

            <div className={s['buttons-flex']}>
              <Button
                isLink
                to="#bepartner"
                title={t('general.join_to_wishmen') || ''}
                color="red"
                className={s.btn}
                containerClassName={s.btn_container}
              />
              <div className={s.or}>{t('general.or_text')}</div>
              <Button
                title={t('general.ask_a_question') || ''}
                color="white"
                className={s.btn}
                containerClassName={s.btn_container}
                iconUrl="/images/icons/black-chat.svg"
                onClick={handleChatClick}
              />
            </div>
          </div>
        </ArticleContent>
      </ArticleLayoutContent>
      <BePartnerMW />
    </ArticleLayout>
  )
}

export default React.memo(ForPartnersPage)
