import React from 'react'
import { NavLink } from 'react-router-dom'

import s from './ThirdBlock.module.scss'

const ThirdBlock = () => {
  return (
    <div className={s.container}>
      <div className={s.inner_content}>
        <div className={s.title}>Как это работает?</div>
        <div className={s.item}>
          <div className={s.left}>
            <div className={s.title}>Хотите что-то конкретное?</div>
            <div className={s.subtitle}>
              На платформе Wishmen сотни магазинов с тысячами товаров. Любой из них может стать
              реальным желанием в вашем вишлисте. Если еще не знаете, что хотите - мы предожим
              варианты.
            </div>
          </div>
          <div className={s.right}>
            <div className={s.img_block}>
              <img src="/images/mainPage/third-main1.png" loading="lazy" alt="" />
            </div>
          </div>
        </div>

        <div className={`${s.item} ${s.item_reverse}`}>
          <div className={s.left}>
            <div className={s.title}>Принимайте и делайте донаты, получайте кэшбеки</div>
            <div className={s.subtitle}>
              Wishmen — круговорот подарков, идей и добрых поступков. Можно собрать донаты на свое
              желание, а можно скинуться кому-то на исполнение мечты.
            </div>
          </div>
          <div className={s.right}>
            <div className={s.img_block}>
              <img src="/images/mainPage/third-main2.png" loading="lazy" alt="" />
            </div>
          </div>
        </div>

        <div className={s.item}>
          <div className={s.left}>
            <div className={s.title}>Предлагайте идеи для подарка своим знакомым</div>
            <div className={s.subtitle}>
              Хотите сделать сюрприз? Выберите идею из каталога и добавьте ее в профиль друга,
              соберите друзей, сделайте подарок, о котором друг даже не подозревал!
            </div>
          </div>
          <div className={s.right}>
            <div className={s.img_block}>
              <img src="/images/mainPage/third-main3.png" loading="lazy" alt="" />
            </div>
          </div>
        </div>
        <div className={s.btn_block}>
          <div className={s.btn_title}>Пора начать желать</div>
          <div className={s.btn_subtitle}>Добавь первое желание в свой вишлист!</div>
          <NavLink to="/wishlist">Давай начнем!</NavLink>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ThirdBlock)
