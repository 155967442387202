import React, { useCallback, useRef } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'

import s from './GestureController.module.scss'

type Props = {
  index: number
  setIndex: Function
  elemCount: number
  goNextSlide: Function
  goPrevSlide: Function
  setPaused: Function
  paused: boolean
  setIsTouch: Function
}

const GestureController = ({
  index,
  setIndex,
  elemCount,
  goNextSlide,
  goPrevSlide,
  paused,
  setPaused,
  setIsTouch,
}: Props) => {
  const navigate = useNavigate()
  let mousedownId = useRef<any>()
  const goNext = useCallback(() => {
    if (index < elemCount - 1) {
      setIndex(index + 1)
    } else {
      goNextSlide()
    }
  }, [index, setIndex, elemCount, goNextSlide])

  const goPrev = useCallback(() => {
    if (index > 0) {
      setIndex(index - 1)
    } else {
      goPrevSlide()
    }
  }, [index, setIndex, goPrevSlide])

  const debouncePause = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      mousedownId.current = setTimeout(() => {
        setIsTouch(true)
        setPaused(true)
      }, 200)
    },
    [mousedownId, setIsTouch, setPaused],
  )

  const mouseUp = useCallback(
    (e: React.MouseEvent | React.TouchEvent, type: string) => {
      e.preventDefault()
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      clearTimeout(mousedownId.current)
      if (paused) {
        setPaused(false)
        setIsTouch(false)
      } else {
        if (type === 'next') {
          goNext()
        } else {
          goPrev()
        }
      }
    },
    [mousedownId, goNext, goPrev, paused, setIsTouch, setPaused],
  )
  const onTouchMove = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      clearTimeout(mousedownId.current)
      setIsTouch(true)
      setPaused(true)
    },
    [mousedownId, setIsTouch, setPaused],
  )

  return (
    <div className={s.gesture_container}>
      <div className={cn(s.close_button, paused ? s.hidden : '')} onClick={() => navigate(-1)}>
        <img src="/images/icons/white_close_icon.svg" alt="" />
      </div>
      <div
        className={s.left}
        onTouchStart={debouncePause}
        onTouchMove={onTouchMove}
        onTouchEnd={(e: any) => mouseUp(e, 'prev')}
      ></div>
      <div
        className={s.right}
        onTouchStart={debouncePause}
        onTouchMove={onTouchMove}
        onTouchEnd={(e: any) => mouseUp(e, 'next')}
      ></div>
    </div>
  )
}

export default React.memo(GestureController)
