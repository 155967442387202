import React from 'react'
import cn from 'classnames'

import s from './ArticleContent.module.scss'

type Props = {
  children?: React.ReactNode
  className?: string
  html?: string
}

const ArticleContent = ({ children, className, html }: Props): React.ReactElement => {
  const props = html
    ? {
        dangerouslySetInnerHTML: { __html: html },
      }
    : { children }

  return (
    <div className={cn({ [s.content]: true, [s.content_mobile]: true }, className)} {...props} />
  )
}

export default ArticleContent
