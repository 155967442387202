import { SearchField } from 'application/presentation/common/ActionComponents'
import ProductItem from 'application/presentation/common/ProductItem'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import TopCategories from './components/TopCategories'
import s from './ShopContent.module.scss'

type Props = {
  data: any
  fetchNextPage: Function
  hasNextPage?: boolean
}

const ShopContent = ({ data, fetchNextPage, hasNextPage }: Props) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={() => fetchNextPage()}
      style={{ overflow: 'visible' }}
      hasMore={!!hasNextPage}
      loader={
        <div className="pagination_loader">
          <img loading="lazy" src="/images/loader.gif" alt="" />
        </div>
      }
      scrollableTarget="page_content"
    >
      <SearchField containerClassName={s.search_container} openSearchOnClick />
      {data.map((item: any, index: number) => {
        return index === 0 ? (
          <TopCategories data={item} key={`shopItem_${index}`} />
        ) : (
          <div key={`shopItem_${index}`}>
            {index === 1 && (
              <div className={`${s.title_container} ${s.bottom_title_container}`}>
                <div className={s.red_title}>Товары добавленные пользователями</div>
              </div>
            )}

            <div className={s.row}>
              {item[0] ? <ProductItem item={item[0]} adaptive /> : <></>}
              {item[1] ? <ProductItem item={item[1]} adaptive isLastItem /> : <></>}
            </div>
          </div>
        )
      })}
    </InfiniteScroll>
  )
}

export default React.memo(ShopContent)
