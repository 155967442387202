import { GradientSVG } from 'application/presentation/common/GradientSVG'
import { Page, PageContent } from 'framework7-react'
import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { HeaderFooterProvider } from 'application/presentation/app/HeaderFooter'
import apiClient from 'application/data/apiClient'
import { useDispatch } from 'react-redux'
import fetchSession, { calculateNotifications, editUserData } from 'userControl'
import MainNavigation from 'application/presentation/navigation/MainNavigation'
import { useAppSelector } from 'application/domain/store/hooks'
import {
  setNewNotificationsCount,
  setUser,
  setUserToken,
} from 'application/domain/store/userReducer'
import { setPayMethods } from 'application/domain/store/payReducer'
import useNewNotifications from 'application/domain/useCase/notifications/newNotifications'
import { useQuery } from '@tanstack/react-query'
import { usePayMethods } from 'application/domain/useCase/payments/getPayments'
import { ToastContainer, Zoom } from 'react-toastify'
import { Portal } from 'react-portal'

import s from './AppView.module.scss'

const AppView = () => {
  const dispatch = useDispatch()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const { data: payMethods } = usePayMethods()

  const { data: sessionData, status } = useQuery(
    ['session', user ? user.nickname : ''],
    () => fetchSession(),
    {
      refetchInterval: 10000,
      enabled: !!(user && user.id),
    },
  )
  const { data: notificationsData, refetch: notificationRefetch }: any = useNewNotifications(
    'update',
    user ? user.id : 0,
  )

  useEffect(() => {
    const bootstrapAsync = async () => {
      const userToken = localStorage.getItem('userToken')

      if (userToken) {
        const userSessionData = await fetchSession(userToken)
        if (userSessionData.toString().startsWith('Error')) {
          // toast.show('Потеряно соединение с сервером, пожалуйста, повторите попытку позже')
          setUser(null)(dispatch)
        } else if (userSessionData) {
          localStorage.setItem('userToken', userToken)
          localStorage.setItem('userData', JSON.stringify(userSessionData.success))
          apiClient.defaults.headers.Authorization = `Bearer ${userToken}`
          setUser(userSessionData.success)(dispatch)
          setUserToken(userSessionData.success.api_token)(dispatch)
        }
      } else {
        setUser(null)(dispatch)
      }
      // payMethods(dispatch)
    }
    bootstrapAsync().finally(async () => {
      console.log('load done')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (payMethods) {
      const payArr: any[] = []
      payMethods.map((item: any) => {
        if (item.id !== 2 && item.id !== 3 && item.id !== 5) {
          payArr.push(item)
        }
        if (item.id === 2 && window.ApplePaySession) {
          payArr.push(item)
        }
        if (item.id === 3) {
          payArr.push(item)
        }
        return item
      })
      setPayMethods(payArr)(dispatch)
    }
  }, [payMethods, dispatch])

  useEffect(() => {
    if (notificationsData) {
      setNewNotificationsCount(calculateNotifications(notificationsData))(dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationsData])

  useEffect(() => {
    if (user && sessionData && sessionData.success) editUserData(sessionData.success, dispatch)
  }, [user, sessionData, dispatch])

  return (
    <Page pageContent={false}>
      <PageContent id="page_content">
        <HeaderFooterProvider>
          <div className={s.page_content}>
            <Routes>
              <Route path="/*" element={<MainNavigation />} index />
            </Routes>
            {/* <Switch>
              <Route path={['/', '/home']} exact component={MainPage} />
              <Route path="/login" exact component={AuthPage} />
              <Route path="/shop" exact component={ShopPage} />
              <Route path="/shop/category" exact>
                <Redirect to="/shop" />
              </Route>
              <Route path={['/collections/:category/:article_id?']} exact component={ArticlePage} />
              <PrivateRoute path="/notification" exact component={NotificationsPage} />
              <PrivateRoute path="/feed" exact component={FeedPage} />
              <PrivateRoute path="/balance/:filter(in|out|)?/:id?" exact>
                <BalanceAndRefPage />
              </PrivateRoute>
              <PrivateRoute path="/referral/:info(info|list)?/:id?" exact>
                <BalanceAndRefPage />
              </PrivateRoute>
              <PrivateRoute path="/:nickname/menu" exact component={MenuPage} />
              <PrivateRoute path="/:nickname/settings" exact component={SettingsPage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/documentation" component={AboutMenu} />
              <Route path="/for_partners" component={ForPartnersPage} />
              <Route path="/faq/:slug" component={FaqQuestionsPage} />
              <Route path="/faq" component={FaqPage} />
              <Route path="/contacts" component={ContactsPage} />
              <Route path="/bonus" component={BonusPage} />
              <Route path="/shop/category/:slug/filter" exact component={FilterProductPage} />
              <Route path="/shop/category/:slug/:id/:buy(buy)?" exact component={ProductPage} />
              <Route path="/shop/category/:slug/:id/cashback" exact component={CashbackPage} />
              <Route path="/shop/category/:slug" exact component={ShopCategoryPage} />
              <Route path="/shop/categories" exact component={ShopListPage} />
              <Route path="/shop/results" exact component={SearchResultPage} />

              <Route path="/shop/partner" exact component={PartnerList} />
              <Route path="/shop/partner/:id" exact component={PartnersPageMobile} />

              <Route path="/location" exact component={SelectLocationPage} />

              <PrivateRoute path="/orders/:filter(completed)?/:id?" exact>
                <UserOrdersPage />
              </PrivateRoute>

              <PrivateRoute path="/donates" exact>
                <Redirect to="/donates/in" />
              </PrivateRoute>
              <PrivateRoute path="/donates/:filter(out|in)/:id?">
                <UserDonatesPage />
              </PrivateRoute>

              <PrivateRoute path="/wishlist" exact>
                <Redirect to={`/${user ? user.nickname : ''}`} />
              </PrivateRoute>

              <PrivateRoute path="/:nickname/menu" exact>
                <MenuPage />
              </PrivateRoute>
              <PrivateRoute path="/:nickname/settings" exact>
                <SettingsPage />
              </PrivateRoute>
              <Route path="/:nickname/ideas/:wishid/order" component={OrderPage} />
              <Route path="/:nickname/ideas/:wishid/comments" component={CommentsPage} />
              <Route path="/:nickname/ideas/:wishid/donate" component={DonatePage} />
              <Route path="/:nickname/ideas/:wishid" component={WishPage} />
              <Route path="/:nickname/ideas" component={UserIdeasPage} />
              <Route path="/:nickname/friends/add" exact component={AddToFriendsPage} />
              <Route path="/:nickname/friends/new" exact component={UserFriendsRequestPage} />
              <Route path="/:nickname/friends/calendar" exact component={UserFriendsCalendar} />
              <Route path="/:nickname/friends" exact component={UserFriendsPage} />
              <Route path="/:nickname" exact component={PublicProfilePage} />

              <Route path="/:nickname/:wishid/comments" component={CommentsPage} />
              <Route path="/:nickname/:wishid/donate" component={DonatePage} />
              <Route path="/:nickname/:wishid/order" component={OrderPage} />

              <Route
                path={['/:nickname/:wishid/edit', '/shop/category/:slug/:productid/add']}
                component={AddWishPage}
              />
              <Route path="/:nickname/:wishid" component={WishPage} />
            </Switch> */}
          </div>
        </HeaderFooterProvider>
      </PageContent>
      <GradientSVG idCSS="gradientSVG" startColor="#AF12FF" endColor="#10DDFF" rotation={0} />
      <Portal>
        <ToastContainer transition={Zoom} limit={1} autoClose={100} />
      </Portal>
    </Page>
  )
}

export default React.memo(AppView)
