import Loader from 'application/presentation/common/Loader'
import React from 'react'
import { NavLink } from 'react-router-dom'

import s from './Button.module.scss'

type Props = {
  title?: string | React.ReactElement
  color?: 'red' | 'white'
  isLink?: boolean
  to?: string
  containerClassName?: string
  className?: string
  iconUrl?: string
  iconSize?: {
    width: string
    height: string
  }
  withoutShadow?: boolean
  onClick?: Function
  isLoading?: boolean
  children?: React.ReactNode
}

const Button = ({
  title,
  isLink,
  to,
  color,
  containerClassName,
  className,
  iconUrl,
  iconSize,
  withoutShadow,
  onClick,
  isLoading,
  children,
}: Props) => {
  if (isLink) {
    return (
      <div className={`${s.btn_container} ${containerClassName || ''}`}>
        <NavLink
          to={to || ''}
          className={`${s.btn} ${color === 'red' ? s.red : color === 'white' ? s.white : ''} ${
            iconUrl ? s.with_icon : ''
          } ${withoutShadow ? s.withoutShadow : ''} ${className || ''} ${
            isLoading ? s.disabled : ''
          }`}
        >
          {isLoading ? (
            <Loader color="#ffffff" size={16} />
          ) : (
            children || (
              <>
                {iconUrl ? <img src={iconUrl} alt="" /> : <></>}
                <span>{title}</span>
              </>
            )
          )}
        </NavLink>
      </div>
    )
  }
  return (
    <div className={`${s.btn_container} ${containerClassName || ''}`}>
      <button
        type="button"
        disabled={isLoading}
        className={`${s.btn} ${color === 'red' ? s.red : color === 'white' ? s.white : ''} ${
          iconUrl ? s.with_icon : ''
        } ${withoutShadow ? s.withoutShadow : ''} ${className || ''} ${
          isLoading ? s.disabled : ''
        }`}
        onClick={() => (onClick ? onClick() : {})}
      >
        {isLoading ? (
          <Loader color="#ffffff" size={12} />
        ) : (
          children || (
            <>
              {iconUrl ? (
                <img
                  src={iconUrl}
                  alt=""
                  style={iconSize ? { width: iconSize.width, height: iconSize.height } : {}}
                />
              ) : (
                <></>
              )}
              <span>{title}</span>
            </>
          )
        )}
      </button>
    </div>
  )
}

export default React.memo(Button)
