import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import moment from 'moment'
import { NavLink, useNavigate } from 'react-router-dom'
import { User } from 'application/domain/entity/user/User'
import wordDeclination from 'application/presentation/utils/wordDeclination'
import _flatMap from 'lodash/flatMap'
import _filter from 'lodash/filter'
import { ReactComponent as RubSvg } from 'images/icons/rub-in-red-circle.svg'

import { Button } from '../ActionComponents'

import s from './ProfileContent.module.scss'

type Props = {
  user: User
  openFriendBlock?: boolean
  isMyProfile?: boolean
}

const ProfileContent = ({ user, openFriendBlock, isMyProfile }: Props) => {
  const [toggle, setToggle] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const aboutHeightRef = useRef<any>()
  const navigate = useNavigate()

  useEffect(() => {
    if (aboutHeightRef != null) {
      if (aboutHeightRef.current?.scrollHeight > 60) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }, [aboutHeightRef])

  const frienReqBlock = useMemo(() => {
    return user &&
      (user.relationship === 'outcoming_request' || user.relationship === 'incoming_request')
      ? true
      : false
  }, [user])

  const withOutCounts = useMemo(() => {
    return moment(user?.birthdate).format('D MMMM') === 'Invalid date' && user.friends.quantity < 1
  }, [user?.birthdate, user.friends])

  const openedWishes = useMemo(() => {
    if (user.wish.items.length) {
      const flatten = _flatMap(user.wish.items, (item) => item.wish)
      return _filter(flatten, (item) => item.status === 'opened')
    }
    return []
  }, [user.wish])

  const showSupplyBtn = useMemo(() => {
    return !isMyProfile && openedWishes.length > 0 ? true : false
  }, [isMyProfile, openedWishes])

  const openRandomWish = useCallback(() => {
    if (openedWishes.length) {
      const index = Math.floor(Math.random() * openedWishes.length)
      const item = openedWishes[index]
      navigate(`/${user.nickname}/${item.id}`)
    }
  }, [openedWishes, navigate, user.nickname])

  return (
    <div
      className={`${s.profile} ${frienReqBlock ? s.pad : ''} ${
        withOutCounts ? s.without_counts_pad : ''
      }`}
    >
      <div className={`${s.container} ${withOutCounts ? s.without_counts_mrg : ''}`}>
        <div className={s.left}>
          <div className={s.name}>{user?.name}</div>
          <div className={`${s.bio} ${toggle ? s.open : ''}`} ref={aboutHeightRef}>
            {user?.about}
          </div>
          {show && (
            <div
              className={`${s.toggle} ${toggle ? s.hide : ''}`}
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? 'скрыть' : 'еще'}
            </div>
          )}
        </div>
        <div className={s.right}>
          <div className={s.avatar}>
            <img src={user?.photo} alt="" />
          </div>
        </div>
      </div>
      <div className={s.counts}>
        {user.friends.quantity > 0 ? (
          <NavLink to={`/${user?.nickname}/friends`} className={s.friends}>
            <>
              <div className={s.images}>
                <img
                  src={user?.friends?.items[0]?.photo}
                  alt=""
                  className={`${s.photo} ${s.photo1}`}
                />
                <img
                  src={user?.friends?.items[1]?.photo}
                  alt=""
                  className={`${s.photo} ${s.photo2}`}
                />
              </div>
              <div className={s.count}>
                {user.friends.quantity}{' '}
                {user.friends.quantity ? (
                  <>{wordDeclination(user?.friends?.quantity, ['друг', 'друга', 'друзей'])}</>
                ) : (
                  ''
                )}
              </div>
            </>
          </NavLink>
        ) : (
          <div></div>
        )}

        {moment(user?.birthdate).format('D MMMM') === 'Invalid date' ? (
          <div></div>
        ) : (
          <div className={s.bday}>
            <div className={s.img_block}>
              <img src="/images/icons/salute-icon.svg" alt="" />
            </div>
            <div className={s.date}>{moment(user?.birthdate).format('D MMMM')}</div>
          </div>
        )}
      </div>
      {showSupplyBtn && (
        <Button color="red" onClick={openRandomWish} containerClassName={s.supply_btn}>
          <RubSvg />
          <div>Поддержать</div>
        </Button>
      )}
    </div>
  )
}

export default React.memo(ProfileContent)
