import { User } from 'application/domain/entity/user/User'
import React from 'react'
import { NavLink } from 'react-router-dom'

import s from './ActionsBlock.module.scss'

type Props = {
  requestCount: number
  user: User | null
}

const ActionsBlock = ({ user, requestCount }: Props) => {
  return (
    <div className={s.add_friend_container}>
      <NavLink to={`/${user ? user.nickname : ''}/friends/add`} className={s.item}>
        <div className={s.img_block}>
          <img src="/images/icons/add-user-icon.svg" alt="" />
        </div>
        <div className={s.text}>Добавить друга</div>
      </NavLink>
      <NavLink to={`/${user ? user.nickname : ''}/friends/new`} className={s.item}>
        {requestCount > 0 ? <div className={s.new_count}>{requestCount}</div> : <></>}
        <div className={s.img_block}>
          <img src="/images/icons/new-user-icon.svg" alt="" />
        </div>
        <div className={s.text}>Заявки в друзья</div>
      </NavLink>
    </div>
  )
}

export default React.memo(ActionsBlock)
