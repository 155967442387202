import { IconSvg } from 'application/presentation/common/uiComponents'
import React, { useCallback } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import s from './SearchField.module.scss'

type Props = {
  withFilter?: boolean
  withBackBtn?: boolean
  containerClassName?: string
  openSearchOnClick?: boolean
  onChange?: Function
  inputRef?: any
  value?: string
  onSearchBtnClick?: Function
  onSubmit?: Function
  placeholder?: string
  withoutSearchBtn?: boolean
  withIconBefore?: boolean
}

const SearchField = ({
  withFilter,
  containerClassName,
  withBackBtn,
  openSearchOnClick,
  onChange,
  inputRef,
  value,
  onSearchBtnClick,
  onSubmit,
  placeholder,
  withoutSearchBtn,
  withIconBefore,
}: Props) => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()

  const openSearchSheet = useCallback(() => {
    navigate(`${pathname}#search`)
  }, [navigate, pathname])

  return (
    <div className={`${containerClassName || ''}`}>
      <div className={`${s.container} `}>
        {withBackBtn && (
          <div className={s.back_btn} onClick={() => navigate(-1)}>
            <svg
              width="13"
              height="22"
              viewBox="0 0 13 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.7995 3.29436C12.3853 2.70858 12.3853 1.75883 11.7995 1.17304C11.2137 0.587257 10.264 0.587257 9.67819 1.17304L1.97005 8.88118C1.72463 9.1266 1.58203 9.43591 1.54226 9.75558C1.22259 9.79535 0.91328 9.93795 0.667855 10.1834C0.0820691 10.7692 0.0820689 11.7189 0.667855 12.3047L9.19512 20.832C9.7809 21.4177 10.7306 21.4177 11.3164 20.832C11.9022 20.2462 11.9022 19.2964 11.3164 18.7106L3.82221 11.2164C3.91787 11.1569 4.00828 11.0856 4.09138 11.0025L11.7995 3.29436Z"
                fill="#D4D4D4"
              />
            </svg>
          </div>
        )}
        <div className={`${s.search_product}`}>
          <div className={`${s.search_block} ${withIconBefore ? s.withBefore : ''}`}>
            {withIconBefore && <IconSvg src="/images/icons/scope_gray.svg" />}
            <input
              type="text"
              placeholder={placeholder || 'Я хочу...'}
              autoComplete="off"
              ref={inputRef}
              value={value || ''}
              readOnly={!!openSearchOnClick}
              onKeyUp={(e: any) => {
                if (e.keyCode === 13) {
                  e.preventDefault()
                  if (onSubmit) {
                    onSubmit()
                  }
                }
              }}
              onChange={(e: any) => {
                if (onChange) {
                  onChange(e.target.value)
                }
              }}
              onClick={() => {
                if (openSearchOnClick) {
                  openSearchSheet()
                }
              }}
            />
            {withFilter ? (
              <NavLink to={`${pathname}/filter${search ? search : ''}`} className={s.filter}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.2836 2.27186H6.57445C6.28003 1.39741 5.45292 0.765625 4.48045 0.765625C3.50799 0.765625 2.68088 1.39741 2.38645 2.27186H0.714844C0.326531 2.27186 0.0117188 2.58667 0.0117188 2.97498C0.0117188 3.3633 0.326531 3.67811 0.714844 3.67811H2.3865C2.68092 4.55256 3.50803 5.18434 4.4805 5.18434C5.45297 5.18434 6.28008 4.55256 6.5745 3.67811H17.2836C17.6719 3.67811 17.9867 3.3633 17.9867 2.97498C17.9867 2.58667 17.6719 2.27186 17.2836 2.27186ZM4.48045 3.77809C4.03763 3.77809 3.67734 3.41781 3.67734 2.97498C3.67734 2.53216 4.03763 2.17188 4.48045 2.17188C4.92328 2.17188 5.28356 2.53216 5.28356 2.97498C5.28356 3.41781 4.92328 3.77809 4.48045 3.77809Z"
                    fill="#747474"
                  />
                  <path
                    d="M17.2836 8.29725H15.6119C15.3175 7.4228 14.4903 6.79102 13.5179 6.79102C12.5455 6.79102 11.7184 7.4228 11.424 8.29725H0.714844C0.326531 8.29725 0.0117188 8.61206 0.0117188 9.00037C0.0117188 9.38869 0.326531 9.7035 0.714844 9.7035H11.424C11.7184 10.578 12.5455 11.2097 13.518 11.2097C14.4904 11.2097 15.3175 10.578 15.612 9.7035H17.2836C17.6719 9.7035 17.9867 9.38869 17.9867 9.00037C17.9867 8.61206 17.6719 8.29725 17.2836 8.29725ZM13.518 9.80348C13.0751 9.80348 12.7148 9.4432 12.7148 9.00037C12.7148 8.55755 13.0751 8.19727 13.518 8.19727C13.9608 8.19727 14.3211 8.55755 14.3211 9.00037C14.3211 9.4432 13.9608 9.80348 13.518 9.80348Z"
                    fill="#747474"
                  />
                  <path
                    d="M17.2836 14.3217H9.58697C9.29255 13.4472 8.46544 12.8154 7.49297 12.8154C6.5205 12.8154 5.69339 13.4472 5.39897 14.3217H0.714844C0.326531 14.3217 0.0117188 14.6365 0.0117188 15.0248C0.0117188 15.4131 0.326531 15.7279 0.714844 15.7279H5.39897C5.69339 16.6024 6.5205 17.2341 7.49297 17.2341C8.46544 17.2341 9.29255 16.6024 9.58697 15.7279H17.2836C17.6719 15.7279 17.9867 15.4131 17.9867 15.0248C17.9867 14.6365 17.6719 14.3217 17.2836 14.3217ZM7.49297 15.8279C7.05014 15.8279 6.68986 15.4677 6.68986 15.0248C6.68986 14.582 7.05014 14.2217 7.49297 14.2217C7.9358 14.2217 8.29608 14.582 8.29608 15.0248C8.29608 15.4676 7.9358 15.8279 7.49297 15.8279Z"
                    fill="#747474"
                  />
                </svg>
              </NavLink>
            ) : (
              <></>
            )}
            {!withoutSearchBtn && (
              <button
                type="button"
                onClick={() =>
                  openSearchOnClick ? openSearchSheet() : onSearchBtnClick ? onSearchBtnClick() : {}
                }
              >
                <img loading="lazy" src="/images/icons/scope.svg" alt="" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SearchField)
