import React, { useContext, useMemo } from 'react'
import { Sheet, PageContent } from 'framework7-react'
import { useLocation, useNavigate } from 'react-router-dom'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { FriendActionMutation } from 'application/domain/useCase/friends/friendsActions'

import style from '../MW.module.scss'
import { MwContext } from '../MWProvider'

import s from './FriendsActionMW.module.scss'

type Props = {
  data: {
    name: string
    nickname: string
  }
}

const FriendsActionMW = ({ data }: Props) => {
  const mwContext = useContext(MwContext)
  const navigate = useNavigate()
  const { hash } = useLocation()
  const opened = useMemo(() => {
    return hash.indexOf('#friend') !== -1
  }, [hash])

  const friendMutation = FriendActionMutation('profile')

  return (
    <div>
      <Sheet
        className={`${style.sheet_modal} ${style.auto_height}`}
        swipeToClose
        backdrop
        opened={opened}
        onSheetClosed={() => mwContext?.openFriendsActionMW(false)}
      >
        <div className={`${style.top_close_button} top-close-button`}>
          <div />
        </div>
        <PageContent className={s.wishBS_page_content}>
          <div className={s.title}>{data.name}</div>
          <div className={s.item}>
            <div
              className={s.item_inner}
              onClick={() => {
                if (data) {
                  friendMutation.mutate({
                    friend: data,
                    method: 'DELETE',
                    callback: () => {
                      ToastNotify('Пользователь удален из друзей!')
                      navigate(-1)
                    },
                  })
                }
              }}
              // onPress={() => {
              //   if (friend) {
              // friendMutation.mutate({
              //   friend: friend,
              //   method: 'DELETE',
              //   isMyProfile: isMyProfile,
              //   callback: () => {
              //     mwContext?.openFriendsActionMW(false)
              //     // mwRef.current.close()
              //     // toast.show('Пользователь удален из друзей!')
              //   },
              // })
              //   }
              // }}
            >
              <div className={s.item_txt}>Удалить из друзей</div>
              <div className={s.img}>
                <img src="/images/icons/red-close-button.svg" alt="" />
              </div>
            </div>
          </div>
        </PageContent>
      </Sheet>
    </div>
  )
}

export default React.memo(FriendsActionMW)
