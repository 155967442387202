/* eslint-disable @typescript-eslint/naming-convention */
import { useAppSelector } from 'application/domain/store/hooks'
import { useUser } from 'application/domain/useCase/user/getUser'
import { useWishIdeas } from 'application/domain/useCase/wish_ideas/getWishIdeas'
import { ShareButton } from 'application/presentation/common/ActionComponents'
import ContentLayout from 'application/presentation/common/ContentLayout'
import { HeaderMobileRightButton, HeaderMobileTitle } from 'application/presentation/common/Header'
import IdeaContent from 'application/presentation/common/IdeaContent'
import Loader from 'application/presentation/common/Loader'
import React, { useMemo, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import TopBlock from './TopBlock'

const UserIdeasPage = () => {
  const navigate = useNavigate()
  const params = useParams<{ nickname?: string }>()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const user_nickname = useMemo(() => {
    return params && params.nickname ? params.nickname : user ? user.nickname : ''
  }, [params, user])
  const { data, isLoading, isFetching, refetch } = useUser(user_nickname)
  const {
    data: ideas,
    isLoading: ideasIsLoading,
    isFetching: isIdeasFetching,
    refetch: ideaRefetch,
  } = useWishIdeas(user_nickname)

  useEffect(() => {
    if (params.nickname === user?.nickname) {
      navigate(`/${user?.nickname}`, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, user])

  return (
    <ContentLayout>
      <HeaderMobileTitle.Source>Идеи {user_nickname}</HeaderMobileTitle.Source>
      <HeaderMobileRightButton.Source>
        <ShareButton shareType="ideas" nickname={user_nickname} />
      </HeaderMobileRightButton.Source>
      {ideasIsLoading || isLoading ? (
        <Loader />
      ) : (
        <div>
          {data ? <TopBlock user={data} /> : <></>}
          <IdeaContent data={ideas} user_nickname={user_nickname} />
        </div>
      )}
    </ContentLayout>
  )
}

export default React.memo(UserIdeasPage)
