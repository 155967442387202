import React from 'react'
import Skeleton from 'react-loading-skeleton'

import s from '../SettingsPage.module.scss'

const SettingsLoaderPage = () => {
  return (
    <div className={s.wishlist_block}>
      <Skeleton
        style={{
          display: 'inline-block',
          height: '1px',
          width: '100%',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      />
      <Skeleton
        style={{
          display: 'inline-block',
          height: '74px',
          width: '100%',
          marginTop: '15px',
        }}
      />
      <Skeleton
        style={{
          display: 'inline-block',
          height: '52px',
          width: '100%',
          marginTop: '15px',
        }}
      />
      <Skeleton
        style={{
          display: 'inline-block',
          height: '67px',
          width: '100%',
          marginTop: '15px',
        }}
      />
      <Skeleton
        style={{
          display: 'inline-block',
          height: '67px',
          width: '100%',
          marginTop: '15px',
        }}
      />
      <Skeleton
        style={{
          display: 'inline-block',
          height: '102px',
          width: '100%',
          marginTop: '15px',
        }}
      />
      <Skeleton
        style={{
          display: 'inline-block',
          height: '52px',
          width: '100%',
          marginTop: '15px',
        }}
      />
      <Skeleton
        style={{
          display: 'inline-block',
          height: '21px',
          width: '100%',
          marginTop: '45px',
          marginBottom: '15px',
        }}
      />
      <Skeleton
        style={{
          display: 'inline-block',
          height: '1px',
          width: '100%',
          marginTop: '15px',
          marginBottom: '15px',
        }}
      />
      <Skeleton
        style={{
          display: 'inline-block',
          height: '80px',
          width: '100%',
          marginTop: '15px',
        }}
      />
    </div>
  )
}

export default React.memo(SettingsLoaderPage)
