import React, { useCallback, useState } from 'react'
import { Sheet, PageContent } from 'framework7-react'
import cn from 'classnames'
import apiClient from 'application/data/apiClient'
import ToastNotify from 'application/presentation/app/ToastNotify'
import Loader from 'application/presentation/common/Loader'
import { useAppSelector } from 'application/domain/store/hooks'
import { SendCommentMutation } from 'application/domain/useCase/comments/commentsActions'

import stylebs from '../MW.module.scss'

import s from './WishPhotoWithCommentMW.module.scss'

type Props = {
  photo: any
  wishId: any
  setUploadedPhoto: any
  setOpenedWishPhoto: Function
  openedWishPhoto: boolean
  setOpened: Function
}

const WishPhotoWithCommentMW = ({
  photo,
  wishId,
  setUploadedPhoto,
  setOpenedWishPhoto,
  openedWishPhoto,
  setOpened,
}: Props) => {
  const [comment, setComment] = useState<string>('')
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)

  const sendComment = SendCommentMutation(wishId, () => {
    setSaveIsLoading(false)
    setComment('')
    setOpenedWishPhoto(false)
    ToastNotify('Отправлено')
  })

  const changePhoto = useCallback(() => {
    setOpenedWishPhoto(false)
    setOpened(true)
  }, [setOpened, setOpenedWishPhoto])

  const saveChanges = useCallback(() => {
    const formData = new FormData()
    if (photo) {
      formData.append('images[]', photo)
      if (wishId) {
        setSaveIsLoading(true)
        apiClient({
          method: 'POST',
          url: `/api/v1/wish/${wishId}/edit`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(({ data }) => {
            sendComment.mutate({ commentText: `${comment} [!image (${data.photo})]`, mentions: [] })
          })
          .catch(() => {
            setSaveIsLoading(false)
          })
      }
    }
  }, [wishId, photo, comment, sendComment])

  return (
    <Sheet
      className={`${stylebs.sheet_modal} ${stylebs.auto_height}`}
      swipeToClose
      backdrop
      opened={openedWishPhoto}
      onSheetClosed={() => {
        setOpenedWishPhoto(false)
      }}
    >
      <div className={stylebs.top_close_button}>
        <div />
      </div>
      <PageContent className={s.container_page}>
        <div className={s.wrapper}>
          <div className={s.title}>Фотография вашего желания:</div>
          {setUploadedPhoto ? (
            <div className={cn(s.photos)}>
              <div className={s.photo_img}>
                <img alt="cam" src={setUploadedPhoto} />

                <div className={s.close}>
                  <button type="button" onClick={() => changePhoto()}>
                    Изменить
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className={s.subtitle}>
            Здесь вы можете оствить комментарий благодарность. Его увидят все, кто помог Вам
            осуществить желание
          </div>
          <div className={s.comment}>
            <div className={s.comment_wrapper}>
              <div className={s.user_img}>
                <img src={user?.photo} alt="user photo" />
              </div>
              <div className={s.input_container}>
                <input
                  type="text"
                  value={comment}
                  onChange={(e: any) => setComment(e.target.value)}
                  placeholder="Введите комментарий"
                  className={s.input}
                />
              </div>
            </div>
          </div>
          <button type="button" className={s.send_btn} onClick={() => saveChanges()}>
            <span className={s.text_completed}>
              {saveIsLoading ? <Loader color="#FFFFFF" size={16} /> : 'Отправить фото'}
            </span>
          </button>
        </div>
      </PageContent>
    </Sheet>
  )
}

export default React.memo(WishPhotoWithCommentMW)
