import React, { useCallback, useEffect, useState } from 'react'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { NavLink, useLocation } from 'react-router-dom'
import { User } from 'application/domain/entity/user/User'
import { useAppSelector } from 'application/domain/store/hooks'
import { FriendActionMutation } from 'application/domain/useCase/friends/friendsActions'

import s from './FriendsHeaderButton.module.scss'

type Props = {
  data: User
  isMyProfile?: boolean
  type?: string
  setOpenFriendBlock: Function
  openFriendBlock: boolean
}

const FriendsHeaderButton = ({
  data,
  isMyProfile,
  type: friendRelation,
  setOpenFriendBlock,
}: Props) => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const { pathname } = useLocation()

  const friendMutation = FriendActionMutation('profile')
  const [relationship, setRelationship] = useState<any>(null)

  useEffect(() => {
    if (data) {
      setRelationship(data.relationship)
    }
  }, [data])

  const action = useCallback(
    (type: string, e: any) => {
      e.preventDefault()

      switch (type) {
        case 'add':
          friendMutation.mutate({
            friend: data,
            method: 'POST',
            callback: () => {
              ToastNotify('Заявка отправлена!')
              setOpenFriendBlock(true)
            },
          })
          break

        case 'accept':
          friendMutation.mutate({
            friend: data,
            method: 'POST',
            callback: () => {
              ToastNotify('Заявка принята!')
              setOpenFriendBlock(false)
            },
          })
          break
        case 'decline':
          friendMutation.mutate({
            friend: data,
            method: 'DELETE',
            callback: () => {
              ToastNotify('Заявка отклонена!')
              setOpenFriendBlock(false)
            },
          })
          break
        default:
          break
      }
    },

    [data, friendMutation, setOpenFriendBlock],
  )

  useEffect(() => {
    if (friendRelation === 'incoming_request' || relationship === 'incoming_request') {
      setOpenFriendBlock(true)
    }
  }, [friendRelation, relationship, setOpenFriendBlock])

  if (user && user.nickname === data.nickname) {
    return <></>
  }

  switch (friendRelation ? friendRelation : relationship) {
    case 'friends':
      return (
        <div className={s.friend_container}>
          <NavLink to={`${pathname}#delete_friend`} className={s.modal_open}>
            <div className={s.icon}>
              <img src="/images/icons/my-friend-icon.svg" alt="в друзьях" />
            </div>
          </NavLink>
        </div>
      )

    case 'outcoming_request':
      return (
        <div className={s.friend_container}>
          <div className={s.icon}>
            <img src="/images/icons/add-friend-icon.svg" alt="добавить друга" />
            <img src="/images/icons/pipka.svg" alt="" className={s.pipka} />
          </div>
          <div className={s.friend_req}>
            <div className={s.buttons}>
              <button
                type="button"
                className={`${s.white_button} ${s.btn} ${s.one_button}`}
                onClick={(e: any) => {
                  setOpenFriendBlock(false)
                  action('decline', e)
                }}
              >
                Отменить заявку в друзья
              </button>
            </div>
          </div>
        </div>
      )

    case 'incoming_request':
      return (
        <div className={s.friend_container}>
          <div className={s.icon}>
            <img src="/images/icons/add-friend-icon.svg" alt="кто-то добавляется ко мне" />
            <img src="/images/icons/pipka.svg" alt="" className={s.pipka} />
          </div>
          <div className={s.friend_req}>
            <div className={s.buttons}>
              <button
                type="button"
                className={`${s.red_button} ${s.btn}`}
                onClick={(e: any) => {
                  // setOpenFriendBlock(false)
                  action('accept', e)
                }}
              >
                Принять
              </button>
              <button
                type="button"
                className={`${s.white_button} ${s.btn}`}
                onClick={(e: any) => {
                  // setOpenFriendBlock(false)
                  action('decline', e)
                }}
              >
                Отклонить
              </button>
            </div>
          </div>
        </div>
      )

    default:
      return (
        <div className={s.friend_container}>
          <div className={s.icon}>
            <img
              src="/images/icons/add-friend-icon.svg"
              alt="Добавить в друзья"
              onClick={(e: any) => {
                if (!user) {
                  ToastNotify('Вы не авторизованы!')
                } else {
                  action('add', e)
                }
              }}
            />
          </div>
        </div>
      )
  }
}

export default React.memo(FriendsHeaderButton)
