import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import apiClient from 'application/data/apiClient'
import axios from 'axios'
import cn from 'classnames'
import { List } from 'framework7-react'
import Loader from 'application/presentation/common/Loader'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { useAppSelector } from 'application/domain/store/hooks'
import { User } from 'application/domain/entity/user/User'

import s from './InputWithSearchUser.module.scss'
import 'react-phone-input-2/lib/style.css'
import UserItem from './UserItem'

type Props = {
  containerClass?: any
  setUserNickname?: Function
  setIsNewUser?: Function
  className?: any
  withoutNewUser?: boolean
}

let ajaxRequest: any

const InputWithSearchUser = ({
  containerClass,
  setUserNickname,
  setIsNewUser,
  className,
  withoutNewUser,
}: Props) => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const [value, setValue] = useState<any>('')
  const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false)
  const [searchItems, setSearchItems] = useState<any>()
  const [selectedUser, setSelectedUser] = useState<User>()
  const [showResults, setShowResults] = useState<boolean>()
  const [isPhone, setIsPhone] = useState<boolean>(false)
  const inputRef = useRef<any>()

  const changeSearch = useCallback(
    (val: string) => {
      setSearchIsLoading(true)

      if (ajaxRequest) {
        ajaxRequest.cancel()
      }
      ajaxRequest = axios.CancelToken.source()
      apiClient({
        url: `/api/v1/${user ? user.nickname : ''}/friend?query=${val}`,
        method: 'get',
        cancelToken: ajaxRequest.token,
      })
        .then((response) => {
          let searchArr: any = []
          if (response.data.local && response.data.local.length) {
            searchArr = searchArr.concat(response.data.local)
          }
          if (response.data.global && response.data.global.length) {
            searchArr = searchArr.concat(response.data.global)
          }
          setShowResults(true)
          setSearchItems(searchArr)
          setSearchIsLoading(false)
        })
        .catch((err) => {
          setShowResults(false)
          if (!axios.isCancel(err)) {
            setSearchIsLoading(false)
            ToastNotify('Произошла ошибка')
          }
        })
    },
    [user],
  )

  const selectUser = useCallback(
    (friend: User) => {
      setIsPhone(false)
      setTimeout(() => {
        setSelectedUser(friend)
        if (setUserNickname) {
          setUserNickname(friend.nickname)
        }
        setValue(friend.nickname ? friend.nickname : '')
        setShowResults(false)
      }, 300)
    },
    [setUserNickname],
  )

  useEffect(() => {
    if (value) {
      if ((value.startsWith('+7') || value.startsWith('8')) && value.length !== 0) {
        if (value.startsWith('8')) {
          setValue(value.replace('8', '+7'))
        }
        setIsPhone(true)
      } else {
        setIsPhone(false)
      }
      if (value && (!selectedUser || value !== selectedUser.nickname)) {
        setShowResults(true)
        changeSearch(value)
      } else {
        setShowResults(false)
      }
      if (!selectedUser || value !== selectedUser.nickname) {
        setShowResults(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  useLayoutEffect(() => {
    setTimeout(() => {
      if (inputRef && inputRef.current) {
        if (isPhone) {
          inputRef.current.value = value
        } else {
          setValue('')
        }
        inputRef.current.focus()
      }
    }, 300)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhone])

  useEffect(() => {
    if (value) {
      const selectedName = selectedUser ? selectedUser.nickname : undefined
      if (value !== selectedName) {
        //@ts-ignore
        setSelectedUser({})
        if (setUserNickname) {
          setUserNickname('')
        }
        changeSearch(value)
      }
    } else {
      setShowResults(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const isNewUser = useMemo(() => {
    return !searchIsLoading &&
      (!searchItems || !searchItems.length) &&
      isPhone &&
      value &&
      value.length === 16
      ? true
      : false
  }, [searchIsLoading, searchItems, isPhone, value])

  useEffect(() => {
    if (isNewUser) {
      if (setUserNickname) {
        setUserNickname(value)
      }
      if (setIsNewUser) {
        setIsNewUser(true)
      }
    } else {
      if (setIsNewUser) {
        setIsNewUser(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewUser])

  return (
    <div className={cn(s.container, className ? className : '')}>
      <div className={cn(s.inner_container, containerClass ? containerClass : '')}>
        <div className={cn(s.title)}>Введите имя или номер получателя</div>
        <div className={cn(s.input_container)}>
          {isPhone ? (
            <div className={s.flag}>
              <img src="/images/icons/flags/rus.svg" alt="" />
            </div>
          ) : (
            <div className={s.user_img}>
              <img src="/images/icons/user-icon.svg" alt="user" />
            </div>
          )}
          <input
            type="text"
            className={cn(s.input)}
            ref={inputRef}
            value={value}
            onChange={(e: any) => {
              setValue(e.target.value)
            }}
          />
        </div>
        {isNewUser ? (
          <img src="/images/icons/user-icon.svg" alt="user" className={cn(s.selected_user_img)} />
        ) : null}
        {selectedUser && selectedUser.id ? (
          <div className={cn(s.selected_user_block)}>
            <img src={selectedUser.photo} alt="user" />
          </div>
        ) : null}
        {searchIsLoading ? (
          <Loader size={16} containerClassName={s.loader} />
        ) : value && showResults ? (
          <List className={s.user_container}>
            {searchItems.map((item: any) => {
              return <UserItem item={item} selectUser={selectUser} key={item.id} />
            })}
          </List>
        ) : null}
      </div>
      {!withoutNewUser && isNewUser ? (
        <div className={s.empty_txt}>
          Пользователь не зарегистрирован в сервисе, идея подарка будет доступна к просмотру всем
          участникам, кроме владельца, по его номеру телефона{' '}
        </div>
      ) : null}
    </div>
  )
}

export default React.memo(InputWithSearchUser)
