import React, { useCallback, useEffect, useState } from 'react'
import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import Loader from 'application/presentation/common/Loader'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { User } from 'application/domain/entity/user/User'
import { useAppSelector } from 'application/domain/store/hooks'
import { FriendActionMutation } from 'application/domain/useCase/friends/friendsActions'

import s from './FriendsActionButton.module.scss'

type Props = {
  data: User
  isMyProfile?: boolean
  withoutMarginAndPadding?: boolean
  isSearch?: boolean
  btn_className?: any
  containerClassName?: string
  type?: string
}

const FriendsActionButton = ({
  data,
  withoutMarginAndPadding,
  isMyProfile,
  isSearch,
  btn_className,
  containerClassName,
  type: friendRelation,
}: Props) => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))

  const friendMutation = FriendActionMutation(
    'profile',
    // params && params.nickname ? params.nickname : '',
  )
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false)
  const [relationship, setRelationship] = useState<any>(null)

  useEffect(() => {
    if (data) {
      setRelationship(data.relationship)
    }
  }, [data])

  const action = useCallback(
    (type: string, e: any) => {
      e.preventDefault()
      if (isSearch) {
        setIsRequestLoading(true)
        apiClient({
          method: type === 'decline' ? 'DELETE' : 'POST',
          url: `/api/v1/${data.nickname}/friend`,
        })
          .then((response: any) => {
            setRelationship(response.data.relationship)
            setIsRequestLoading(false)
            // toast.show(type === 'decline' ? 'Заявка отклонена!' : 'Заявка отправлена!')
            queryClient.invalidateQueries(['profile'])
            queryClient.invalidateQueries(['friendsRequest'])
            queryClient.invalidateQueries(['likes'])
            queryClient.invalidateQueries(['feed'])
          })
          .catch((error) => {
            setIsRequestLoading(false)
          })
      } else {
        switch (type) {
          case 'add':
            friendMutation.mutate({
              friend: data,
              method: 'POST',
              callback: () => {
                ToastNotify('Заявка отправлена!')
              },
            })
            break

          case 'accept':
            friendMutation.mutate({
              friend: data,
              method: 'POST',
              callback: () => {
                ToastNotify('Заявка принята!')
              },
            })
            break
          case 'decline':
            friendMutation.mutate({
              friend: data,
              method: 'DELETE',
              callback: () => {
                // ToastNotify('Заявка отклонена!')
              },
            })
            break
          default:
            break
        }
      }
    },
    [data, isSearch, friendMutation],
  )

  if (user && user.nickname === data.nickname) {
    return (
      <div
        className={`${s.btn_container} ${withoutMarginAndPadding ? s.without_margin : ''} ${
          containerClassName || ''
        }`}
      >
        <div className={s.btn_content}>
          <div
            className={`${s.btn} ${s.white_btn} ${btn_className || ''}`}
            // onPress={() => navigation.push('settings_page')}
          >
            <div className={`${s.btn_txt} ${s.white_btn_txt}`}>
              {isSearch ? 'Это вы' : 'Редактировать'}
            </div>
          </div>
        </div>
      </div>
    )
  }

  switch (friendRelation ? friendRelation : isSearch ? relationship : data.relationship) {
    case 'friends':
      return (
        <div
          className={`${s.btn_container} ${withoutMarginAndPadding ? s.without_margin : ''} ${
            containerClassName || ''
          }`}
        >
          <div className={s.btn_content}>
            <div
              className={`${s.btn} ${s.white_btn} ${btn_className || ''}`}
              // onPress={() => {
              //   openMW.openConfirmDeleteMW(true, data, isMyProfile)
              // }}
            >
              <div className={s.icon_img}>
                {/* <img src="/images/icons/more-gray-accept.svg" alt="" /> */}
              </div>
              <div className={`${s.btn_txt} ${s.white_btn_txt}`}>У вас в друзьях</div>
            </div>
          </div>
        </div>
      )

    case 'outcoming_request':
      return (
        <div
          className={`${s.btn_container} ${withoutMarginAndPadding ? s.without_margin : ''} ${
            containerClassName || ''
          }`}
        >
          <div className={s.btn_content}>
            {/* <div
              className={`${s.btn} ${s.white_btn} ${btn_className || ''}`}
              onClick={(e: any) => action('decline', e)}
              // onPress={() => action('decline')}
              // disabled={isRequestLoading}
            >
              {isRequestLoading ? (
                <Loader size={10} />
              ) : (
                <div className={`${s.btn_txt} ${s.white_btn_txt}`}>Отменить</div>
              )}
            </div> */}
          </div>
        </div>
      )

    case 'incoming_request':
      return (
        <div
          className={`${s.btn_container} ${withoutMarginAndPadding ? s.without_margin : ''} ${
            containerClassName || ''
          }`}
        >
          <div className={s.btn_content}>
            <div
              className={`${s.btn} ${s.red_btn} ${btn_className || {}}`}
              onClick={(e: any) => action('accept', e)}
              // onPress={() => action('accept')}
              // disabled={isRequestLoading}
            >
              {isRequestLoading ? (
                <Loader size={10} />
              ) : (
                <div className={`${s.btn_txt} ${s.red_btn_txt}`}>Принять</div>
              )}
            </div>
          </div>
          {friendRelation ? (
            <div className={s.btn_content}>
              <div
                className={`${s.btn} ${s.white_btn} ${btn_className || ''}`}
                onClick={(e: any) => action('decline', e)}
                // onPress={() => action('decline')}
                // disabled={isRequestLoading}
              >
                {isRequestLoading ? (
                  <Loader size={10} />
                ) : (
                  <div className={`${s.btn_txt} ${s.white_btn_txt}`}>Отклонить</div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )

    default:
      return (
        <div
          className={`${s.btn_container} ${withoutMarginAndPadding ? s.without_margin : ''} ${
            containerClassName || ''
          }`}
        >
          <div className={s.btn_content}>
            <div
              className={`${s.btn} ${s.red_btn} ${btn_className || {}}`}
              onClick={(e: any) => {
                if (!user) {
                  e.stopPropagation()
                  e.preventDefault()
                  ToastNotify('Вы не авторизованы!')
                } else {
                  action('add', e)
                }
              }}
              // onPress={() => action('add')}
              // disabled={isRequestLoading}
            >
              {isRequestLoading ? (
                <Loader size={10} />
              ) : (
                <>
                  <div className={s.icon_img}>
                    <img src="/images/icons/add-to-friend.svg" alt="" />
                  </div>
                  <div className={`${s.btn_txt} ${s.red_btn_txt}`}>В друзья</div>
                </>
              )}
            </div>
          </div>
        </div>
      )
  }
}

export default React.memo(FriendsActionButton)
