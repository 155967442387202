import React, { useCallback, useContext, useState } from 'react'
import InputPhone from 'application/presentation/common/InputComponents/InputPhone'
import InputPassword from 'application/presentation/common/InputComponents/InputPassword'
import { useDispatch } from 'react-redux'
import { AuthContext } from 'application/presentation/app/AuthContext'
import apiClient from 'application/data/apiClient'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { setUser, setUserToken } from 'application/domain/store/userReducer'

import { Button } from '../ActionComponents'
import { InputCode } from '../InputComponents'

import s from './AuthForm.module.scss'

type Props = {
  containerClassName?: string
}

const AuthForm = ({ containerClassName }: Props) => {
  const dispatch = useDispatch()
  const authContext = useContext(AuthContext)
  const [isRegistered, setIsRegistered] = useState<boolean>(true)
  const [isRegistration, setIsRegistration] = useState<boolean>(false)
  const [phoneComplited, setPhoneComplited] = useState<boolean>(false)
  const [phoneCheckIsLoading, setPhoneCheckIsLoading] = useState<boolean>(true)
  const [phone, setPhone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loginMsg, setLoginMsg] = useState<string>('')

  const auth = useCallback(
    (phoneNumber: string, passwordVal?: string) => {
      authContext.login(
        { phone: `+${phoneNumber.replace(/[\s-+]/g, '')}`, password: passwordVal || '' },
        () => {
          setIsLoading(true)
        },
        async (response: any) => {
          setIsLoading(false)
          if (response.title === 'sent' || response.code || response.success) {
            if (response.title === 'sent') {
              localStorage.setItem('last_sms_date', new Date().toString())
              setIsRegistration(true)
            }
            if (response.code) {
              setLoginMsg(response.detail)
            }
            if (response.success && response.success.user) {
              const userData = JSON.stringify(response.success.user)
              const userToken = response.success.user.api_token
              localStorage.setItem('userToken', userToken)
              localStorage.setItem('userData', userData)
              // @ts-ignore
              apiClient.defaults.headers.Authorization = `Bearer ${userToken}`
              setUser(response.success.user)(dispatch)
              setUserToken(response.success.user.api_token)(dispatch)
              ToastNotify('Авторизация прошла успешно!')
            }
          } else {
            ToastNotify('Произошла ошибка, попробуйте позже')
          }
        },
      )
    },
    [authContext, dispatch, setIsRegistration],
  )

  return (
    <div className={containerClassName || ''}>
      <InputPhone
        setIsRegistered={setIsRegistered}
        setPhoneComplited={setPhoneComplited}
        setPhoneCheckIsLoading={setPhoneCheckIsLoading}
        onChange={setPhone}
        className={s.input_phone}
      />
      {isRegistration ? (
        <InputCode phone={phone} />
      ) : (
        <>
          {isRegistered && phoneComplited && !phoneCheckIsLoading && (
            <>
              <InputPassword
                onChange={(val: string) => {
                  setPassword(val)
                  setLoginMsg('')
                }}
              />
              {loginMsg && <div className={s.msg}>{loginMsg}</div>}
              <Button
                title="Войти"
                color="red"
                isLoading={isLoading}
                containerClassName={s.btn_container}
                className={s.btn}
                onClick={() => auth(phone, password)}
              />
            </>
          )}
          {!isRegistered && phoneComplited && !phoneCheckIsLoading && (
            <>
              <Button
                title="Отправить смс"
                color="red"
                onClick={() => auth(phone)}
                containerClassName={s.btn_container}
                className={s.btn}
                isLoading={isLoading}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}

export default React.memo(AuthForm)
