import React, { useContext, useEffect } from 'react'
import Header, { HeaderMobileTitle } from 'application/presentation/common/Header'
import { HeaderFooterContext } from 'application/presentation/app/HeaderFooter'
import ContentLayout from 'application/presentation/common/ContentLayout'

import s from './CashbackPage.module.scss'

const CashbackPage = () => {
  const FooterContext = useContext(HeaderFooterContext)

  useEffect(() => {
    FooterContext?.showFooter(false)
    return () => FooterContext?.showFooter(true)
  }, [FooterContext])
  return (
    <>
      <Header forceShow />
      <HeaderMobileTitle.Source>КЭШБЕК</HeaderMobileTitle.Source>
      <ContentLayout>
        <div className={`${s.cashback}`}>
          <div className={s.container}>
            <div className={s.cash_img}>
              <img src="/images/cashback_img.png" alt="" />
            </div>
            <div className={s.cashback_info}>
              <div className={s.title}>Что такое кешбек?</div>
              <div className={s.subtitle}>
                Кешбек - это 1% от суммы товара, который поступит вам в виде доната на желание при
                его добавлении в свой список
              </div>
              <div className={s.stage}>
                <div className={s.stage_title}>Как это работает? Все просто:</div>
                <div className={s.stage_item}>
                  <div className={s.left}>
                    <div className={s.img_block}>
                      <img src="/images/icons/cash1.svg" alt="" />
                    </div>
                  </div>
                  <div className={s.right}>
                    <div className={s.text}>
                      Вы добавляете товар с особой отметкой кешбека себе в желание
                    </div>
                  </div>
                </div>
                <div className={s.stage_item}>
                  <div className={s.left}>
                    <div className={s.img_block}>
                      <img src="/images/icons/cash2.svg" alt="" />
                    </div>
                  </div>
                  <div className={s.right}>
                    <div className={s.text}>
                      Обязателным условием является добавить описание к своему желанию. Например:
                      почему вы хотите именно это желание, чем он вам понравился и т.д.
                    </div>
                  </div>
                </div>
                <div className={s.stage_item}>
                  <div className={s.left}>
                    <div className={s.img_block}>
                      <img src="/images/icons/cash3.svg" alt="" />
                    </div>
                  </div>
                  <div className={s.right}>
                    <div className={s.text}>
                      Делитесь этим желанием в своем аккаунте в Instagram, это может быть как
                      отдельный пост так же и stories
                    </div>
                  </div>
                </div>
                <div className={s.stage_item}>
                  <div className={s.left}>
                    <div className={s.img_block}>
                      <img src="/images/icons/cash4.svg" alt="" />
                    </div>
                  </div>
                  <div className={s.right}>
                    <div className={s.text}>
                      Отмечаете наш аккаунт в публикации, за что получаете дополнительный кешбек до
                      1%
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.bottom_text}>
              Желаний может быть бессчетное количество. Просто добавляйте желание в свой список.
              делитесь им, уведомите нас об этом и получайте начисления.
            </div>
          </div>
        </div>
      </ContentLayout>
    </>
  )
}

export default React.memo(CashbackPage)
