import { fetchIdeas } from 'application/data/api/wish_ideas'
import { WishIdeaCategory } from 'application/domain/entity/wish/WishCategory'
import { useQuery } from '@tanstack/react-query'

type IdeasResponseData = {
  quantity: number
  items: Array<WishIdeaCategory>
}

export const useWishIdeas = (nickname?: string) => {
  const query = useQuery<IdeasResponseData>(['ideas', nickname], () => fetchIdeas(nickname || ''), {
    enabled: nickname ? true : false,
    refetchInterval: 10000,
  })
  return query
}
