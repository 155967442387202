import apiClient from 'application/data/apiClient'
import Loader from 'application/presentation/common/Loader'
import React, { useRef, useLayoutEffect, useMemo, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import cn from 'classnames'
import { useLocation } from 'react-router-dom'

import s from './ThreeDsFrame.module.scss'

const fetchPayment = async (id: number) => {
  const { data } = await apiClient.post(`/api/v1/payment/status/${id}`)

  return data
}

type Props = {
  ThreeDsData: any
  onComplete: Function
  containerClassName?: string
  dark?: boolean
}
const ThreeDsFrame = ({ ThreeDsData, onComplete, containerClassName, dark }: Props) => {
  const { search } = useLocation()
  const formEl = useRef<any>()
  const [stage, setStage] = useState<number>(1)
  const searchParams = new URLSearchParams(search)
  const pid = searchParams.get('payment_id')
  const paymentId = useMemo(() => {
    return ThreeDsData && ThreeDsData.payment ? ThreeDsData.payment.id : pid || 0
  }, [ThreeDsData, pid])
  const { data } = useQuery(['payment', paymentId], () => fetchPayment(paymentId), {
    refetchInterval: 5000,
    enabled: paymentId !== 0,
  })

  // const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const isSafari = true

  useEffect(() => {
    if (pid) {
      setStage(2)
    }
  }, [pid])

  useLayoutEffect(() => {
    if (ThreeDsData && ThreeDsData.Model && formEl.current) {
      localStorage.setItem('payment_prev_url', window.location.origin + window.location.pathname)
      if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
        // $('#iframe-form').removeAttr('target')
      }
      formEl!.current!.submit()
      setStage(2)
    }
  }, [ThreeDsData])

  useEffect(() => {
    if (data) {
      if (data.status !== 'created') {
        onComplete(data.status === 'paid' ? 'success' : 'error', paymentId)
      }
    }
  }, [data, onComplete, paymentId])

  return (
    <div className={cn(s.container, containerClassName || '')}>
      {stage === 1 ? (
        ThreeDsData && ThreeDsData.Model ? (
          <div>
            <form
              ref={formEl}
              id="iframe-form"
              method="POST"
              // target="payment-iframe"
              // target="_blank"
              action={ThreeDsData.Model.AcsUrl}
            >
              <input
                type="text"
                name="PaReq"
                id="PaReq"
                hidden
                value={ThreeDsData.Model.PaReq}
                readOnly
              />
              <input
                type="text"
                name="MD"
                id="TransactionId"
                hidden
                value={ThreeDsData.Model.TransactionId}
                readOnly
              />
              <input
                type="text"
                name="TermUrl"
                id="TermUrl"
                value={`${apiClient.defaults.baseURL}/api/v1/payment/app_cloud3ds${
                  isSafari ? `?url=${window.location.origin + window.location.pathname}` : ''
                }`}
                hidden
                readOnly
              />
              <button type="submit" style={{ display: 'none' }} id="pay_btn">
                Оплатить
              </button>
            </form>
          </div>
        ) : (
          <Loader color={dark ? '#ffffff' : '#ff3e6c'} />
        )
      ) : stage === 2 ? (
        <div>
          <Loader color={dark ? '#ffffff' : '#ff3e6c'} />
          <div className={cn(s.wait_txt, dark ? s.dark : '')}>Ожидаем оплаты</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(ThreeDsFrame)
