import React from 'react'
import { NavLink } from 'react-router-dom'

import s from './ResultItem.module.scss'

type Props = {
  item: any
  type: 'category' | 'product'
  isIdeaFriendNickname: string | boolean
}

const ResultItem = ({ item, type, isIdeaFriendNickname }: Props) => {
  return (
    <NavLink
      className={s.item}
      to={`/shop/category/${
        type === 'category'
          ? item.slug
          : `product/${item.id}${
              isIdeaFriendNickname ? `?idea=true&idea_friend=${isIdeaFriendNickname}` : ''
            }`
      }`}
    >
      <div className={s.img_block}>
        <img src={item.photo} alt="" className={s.img} />
        {/* <Image source={{ uri: item.photo }} style={s.img} resizeMode="contain" /> */}
      </div>
      <div className={s.center}>
        <div className={s.name}>{item.name}</div>
        {type === 'product' ? (
          <div className={s.bold}>{item.price} руб</div>
        ) : (
          <div className={s.gray}>Категория</div>
        )}
      </div>
    </NavLink>
  )
}

export default React.memo(ResultItem)
