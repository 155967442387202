import { likeWish } from 'application/data/api/wish'
import queryClient from 'application/data/apiClient/queryClient'
import { useMutation } from '@tanstack/react-query'

export const WishLikeMutation = (wish_id?: string | number, user_nickname?: string) => {
  const mutation = useMutation(() => likeWish(wish_id), {
    onMutate: async () => {
      await queryClient.cancelQueries(['wish'])
      await queryClient.cancelQueries(['ideas'])
      await queryClient.cancelQueries(['profile'])
      await queryClient.cancelQueries(['myWishlist'])
      await queryClient.cancelQueries(['feed'])
      await queryClient.cancelQueries(['stories'])
      const previousData = queryClient.getQueryData<any>(['wish', wish_id])
      const profilePrevData = queryClient.getQueryData<any>(['profile', user_nickname])
      const profileIdeasPrevData = queryClient.getQueryData<any>(['ideas', user_nickname])
      const myWishPrevData = queryClient.getQueryData<any>(['myWishlist'])
      const feedPrevData = queryClient.getQueryData<any>(['feed'])
      const storiesPrevData = queryClient.getQueryData<any>(['stories'])

      if (previousData) {
        queryClient.setQueryData(['wish', wish_id], {
          ...previousData,
          0: {
            ...previousData[0],
            likes_count: !previousData[0].liked
              ? previousData[0].likes_count + 1
              : previousData[0].likes_count - 1,
            liked: !previousData[0].liked,
          },
        })
      }
      if (user_nickname) {
        if (profilePrevData) {
          queryClient.setQueryData(['profile', user_nickname], {
            ...profilePrevData,
            wish: {
              ...profilePrevData.wish,
              items: profilePrevData.wish.items.map((item: any) => {
                item.wish = item.wish.map((item2: any) => {
                  if (item2.id === wish_id) {
                    item2.liked = !item2.liked
                    item2.likes_count =
                      item2.liked === true ? item2.likes_count + 1 : item2.likes_count - 1
                  }
                  return item2
                })
                return item
              }),
            },
          })
        }
        if (profileIdeasPrevData) {
          queryClient.setQueryData(['ideas', user_nickname], {
            ...profileIdeasPrevData,
            items: profileIdeasPrevData.items.map((item: any) => {
              return {
                ...item,
                wish: item.wish.map((item2: any) => {
                  if (item2.id === wish_id) {
                    item2.liked = !item2.liked
                    item2.likes_count =
                      item2.liked === true ? item2.likes_count + 1 : item2.likes_count - 1
                  }
                  return item2
                }),
              }
            }),
          })
        }
      }
      if (myWishPrevData) {
        queryClient.setQueryData(['myWishlist'], {
          ...myWishPrevData,
          items: myWishPrevData.items.map((item: any) => {
            return {
              ...item,
              wish: item.wish.map((item2: any) => {
                if (item2.id === wish_id) {
                  item2.liked = !item2.liked
                  item2.likes_count =
                    item2.liked === true ? item2.likes_count + 1 : item2.likes_count - 1
                }
                return item2
              }),
            }
          }),
        })
      }
      if (feedPrevData) {
        queryClient.setQueryData(['feed'], {
          ...feedPrevData,
          pages: feedPrevData.pages.map((page: any) => {
            return {
              ...page,
              data: page.data.map((item: any) => {
                if (item.event_type === 'wish' && item.id === wish_id) {
                  item.liked = !item.liked
                  item.likes_count =
                    item.liked === true ? item.likes_count + 1 : item.likes_count - 1
                }
                return { ...item }
              }),
            }
          }),
        })
      }
      if (storiesPrevData) {
        queryClient.setQueryData(['stories'], {
          ...storiesPrevData,
          pages: storiesPrevData.pages.map((page: any) => {
            return {
              ...page,
              stories: {
                ...page.stories,
                data: page.stories.data.map((item: any) => {
                  if (item.wish[0] && item.wish[0].id === wish_id) {
                    item.wish[0].liked = !item.wish[0].liked
                    item.wish[0].likes_count =
                      item.wish[0].liked === true
                        ? item.wish[0].likes_count + 1
                        : item.wish[0].likes_count - 1
                  }
                  return { ...item }
                }),
              },
            }
          }),
        })
      }
      return { previousData, profilePrevData, myWishPrevData, feedPrevData, storiesPrevData }
    },
    onError: () => {},
    onSettled: () => {
      queryClient.invalidateQueries(['wish'])
      queryClient.invalidateQueries(['profile'])
      queryClient.invalidateQueries(['myWishlist'])
      queryClient.invalidateQueries(['feed'])
      queryClient.invalidateQueries(['stories'])
    },
  })
  return mutation
}
