import { Payment } from 'application/domain/entity/payment/Payment'
import BottomSheet from 'application/presentation/common/ModalWindows/BottomSheet'
import React, { useEffect, useState } from 'react'

import UserDonate from './UserDonate'

type Props = {
  donate: Payment
  type: 'in' | 'out'
  opened: boolean
  onClosed: () => void
}

const UserDonateBottomSheet = ({ donate, type, opened, onClosed }: Props): React.ReactElement => {
  const [sheetOpened, setSheetOpened] = useState(opened)
  const handleSheetClosed = () => {
    setSheetOpened(false)

    if (opened) {
      onClosed()
    }
  }

  useEffect(() => {
    setSheetOpened(opened)
  }, [opened])

  return (
    <BottomSheet opened={sheetOpened} onSheetClosed={handleSheetClosed}>
      <UserDonate donate={donate} type={type} />
    </BottomSheet>
  )
}

export default React.memo(UserDonateBottomSheet)
