import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import apiClient from 'application/data/apiClient'
import ToastNotify from 'application/presentation/app/ToastNotify'
import queryClient from 'application/data/apiClient/queryClient'
import { InputCode, InputLabelHorizontal } from 'application/presentation/common/InputComponents'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import ContentLayout from 'application/presentation/common/ContentLayout'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import { MetaDescriptionSettings, MetaTitleSettings } from 'application/presentation/app/MetaTags'
import { useAppSelector } from 'application/domain/store/hooks'
import { setUser } from 'application/domain/store/userReducer'

import s from './SettingsPage.module.scss'
import SettingsLoaderPage from './components/SettingsLoaderPage'

const SettingsPage = () => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))

  const [name, setName] = useState<string>('')
  const [nickname, setNickname] = useState<string>('')
  const [email, setEmail] = useState<any>('')
  const [emailStatus, setEmailStatus] = useState<any>('')
  const [birthdate, setBirthdate] = useState<any>('')
  const [about, setAbout] = useState<string>('')
  const [link, setLink] = useState<any>('')
  const [phone, setPhone] = useState<string>('')
  const [photo, setPhoto] = useState<string>('')
  const [photoFile, setPhotoFile] = useState<any>('')
  const [nicknameAlreadyTaken, setNicknameAlreadyTaken] = useState<boolean>(false)
  const [phoneStatus, setPhoneStatus] = useState<string>('')
  const [hidePhoneInfo, setHidePhoneInfo] = useState<boolean>(false)
  const [saveIsLoad, setSaveIsLoad] = useState<boolean>(false)

  useEffect(() => {
    if (user) {
      setName(user.name)
      setNickname(user.nickname)
      setEmail(user.email)
      setBirthdate(user.birthdate ? moment(user.birthdate).format('DD.MM.YYYY') : '')
      setAbout(user.about ? user.about : '')
      setLink(user.link)
      setPhone(user.phone)
      setPhoto(user.photo)
    }
  }, [user])

  const changePhoto = useCallback((e: any) => {
    const photoPreview = URL.createObjectURL(e.target.files[0])
    setPhoto(photoPreview)
    setPhotoFile(e.target.files[0])
  }, [])

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      const status = params.get('soc_auth_status')
      if (status) {
        ToastNotify(
          status === 'error' ? 'Этот аккаунт уже привязан к Wishmen' : 'Аккаунт успешно привязан!',
        )
      }
    }
  }, [search])

  useEffect(() => {
    if (phone && parseInt(phone!, 10) && phone.length > 10) {
      setHidePhoneInfo(false)
      setPhoneStatus('')
    } else {
      setHidePhoneInfo(true)
    }
  }, [phone])

  useEffect(() => {
    if (email !== user?.confirmed_email) {
      setEmailStatus('')
    }
  }, [email, user])

  const stats = useMemo(() => {
    return {
      phoneStatus:
        phoneStatus === 'trySend'
          ? 'Пробуем отправить смс'
          : phoneStatus === 'smsSended'
          ? 'Сообщение отправлено'
          : phoneStatus === 'phoneTaken'
          ? 'Номер уже занят'
          : phoneStatus,
    }
  }, [phoneStatus])

  const saveChanges = () => {
    setSaveIsLoad(true)
    const formData = new FormData()
    formData.append('name', name)
    formData.append('nickname', nickname)
    if (birthdate) {
      const formatedBirth = birthdate.split('.')
      const newBirth = `${formatedBirth[2]}-${formatedBirth[1]}-${formatedBirth[0]}`
      formData.append('birthdate', newBirth)
    }
    formData.append('about', about)
    formData.append('link', link)
    formData.append('phone', `+${parseInt(phone!, 10)}`)

    if (photoFile) {
      formData.append('photo', photoFile)
    }

    apiClient({
      url: '/api/v1/user/edit',
      method: 'post',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        setUser(response.data.success)(dispatch)
        setSaveIsLoad(false)
        queryClient.invalidateQueries(['profile'])
        ToastNotify('Изменения сохранены!')
      })
      .catch((error) => {
        if (error.response.data.error.phone) {
          setPhoneStatus('phoneTaken')
        } else {
          setPhoneStatus('')
        }
        if (error.response.data.error.nickname) {
          setNicknameAlreadyTaken(true)
        } else {
          setNicknameAlreadyTaken(false)
        }
        setSaveIsLoad(false)
        queryClient.invalidateQueries(['profile'])
      })
  }

  const editPhone = () => {
    setPhoneStatus('trySend')
    const data = {
      phone: `+${parseInt(phone!, 10)}`,
    }
    apiClient({
      url: '/api/v1/user/edit',
      method: 'post',
      data,
    })
      .then(() => {
        apiClient({
          url: '/api/v1/user/confirm_phone',
          method: 'post',
          data: {
            phone: data.phone,
          },
        })
          .then(() => {
            localStorage.setItem('last_sms_date', new Date().toString())
            queryClient.invalidateQueries(['profile'])
            setPhoneStatus('smsSended')
          })
          .catch((error) => {
            setPhoneStatus(error.response.data.errors.detail)
          })
      })
      .catch((error) => {
        if (error.response.data.error.phone) {
          setPhoneStatus('phoneTaken')
        } else {
          setPhoneStatus('')
        }
      })
  }

  const editEmail = useCallback(() => {
    const data = {
      email,
    }
    setEmailStatus('trySave')
    apiClient({
      url: '/api/v1/user/edit',
      method: 'post',
      data,
    })
      .then((response) => {
        setEmailStatus('trySend')
        apiClient
          .get(`/api/v1/user/verify_email?email=${email}`)
          .then(() => {
            ToastNotify('Сообщение отправлено на email. Перейдите по ссылке для подтверждения.')
            setUser(response.data.success)(dispatch)
            setEmailStatus('sended')
            queryClient.invalidateQueries(['profile'])
          })
          .catch(() => {
            setEmailStatus('')
          })
      })
      .catch((error) => {
        if (error.response.data.error.email) {
          setEmailStatus('emailTaken')
        } else {
          setEmailStatus('error')
        }
      })
  }, [dispatch, email])

  const emailStatusComponent = () => {
    const emailConfirmed = user?.confirmed_email === email
    const emailConfirm = user?.confirmed_email !== email
    switch (emailStatus) {
      case 'trySend':
        return <div className={s.confirmed}>Отправляем сообщение</div>
      case 'error':
        return <div className={s.confirmed}>Сообщение не отправлено</div>
      case 'sended':
        return <div className={s.confirmed}>Сообщение отправлено</div>
      case 'emailTaken':
        return <div className={`${s.red_msg} ${s.confirmed}`}>Этот email уже занят</div>
      default:
        if (emailConfirmed) {
          return (
            <div className={s.confirmed}>
              <img className={s.more_gray_accept} src="/images/icons/more-gray-accept.svg" alt="" />
              Почта подтверждена
            </div>
          )
        } else if (emailConfirm) {
          return (
            <div
              className={s.confirm_phone}
              onClick={() => {
                editEmail()
              }}
            >
              Подтвердить почту
            </div>
          )
        }
        return <></>
    }
  }

  const removeSocial = useCallback(
    (provider: string) => {
      apiClient
        .delete(`/api/v1/social/auth/${provider}`)
        .then(({ data }: any) => {
          ToastNotify('Аккаунт отвязан!')
          setUser(data)(dispatch)
        })
        .catch(() => {})
    },
    [dispatch],
  )

  if (!user) {
    return (
      <ContentLayout>
        <HeaderMobileTitle.Source>Настройки аккаунта</HeaderMobileTitle.Source>
        <SettingsLoaderPage />
      </ContentLayout>
    )
  }

  return (
    <ContentLayout>
      <MetaTitleSettings />
      <MetaDescriptionSettings />
      <HeaderMobileTitle.Source>Настройки аккаунта</HeaderMobileTitle.Source>
      <div className={s.wishlist_block}>
        <div className={s.settings_content}>
          <div className={`${s.separator} ${s.settings}`} />

          <label htmlFor="load-userimg" className={`${s.settings_item} ${s.photo}`}>
            <div className={s.user_img}>
              <div className={s.shadow_box}>
                <img src="/images/icons/camera-icon.svg" className={s.camera_icon} alt="" />
              </div>
              <img loading="lazy" src={photo} className={s.inner_img} alt="" />
            </div>
            <div className={s.item_text}>Изменить фото</div>
            <div className={s.arrow}>
              <img src="/images/icons/black-right-arrow.svg" alt="" />
            </div>
            <input
              type="file"
              id="load-userimg"
              name="photo"
              onChange={(e: any) => {
                changePhoto(e)
              }}
              hidden
            />
          </label>

          <InputLabelHorizontal
            title="Введите имя"
            value={name}
            placeholder="Имя"
            onChange={setName}
          />
          <InputLabelHorizontal
            title="Логин"
            value={nickname}
            placeholder="Введите логин"
            onChange={setNickname}
            subtitle="Ссылка на ваш профиль wish.men/"
            componentType="nickname"
          />
          {nicknameAlreadyTaken ? <div className={s.msg}>Этот никнейм уже занят</div> : ''}
          <InputLabelHorizontal
            title="Дата рождения"
            value={birthdate}
            onChange={setBirthdate}
            subtitle="Отображается день и месяц"
            componentType="date"
          />
          <InputLabelHorizontal
            title="О себе"
            placeholder="Введите комментарий"
            value={about}
            onChange={setAbout}
            componentType="comment"
          />
          <InputLabelHorizontal
            title="Сайт"
            placeholder="Введите сайт"
            value={link}
            onChange={setLink}
            componentType="link"
          />

          <div className={s.settings_title}>Контактная информация</div>
          <div className={`${s.separator} ${s.settings}`} />
          <div className={s.input_wrapper}>
            <InputLabelHorizontal
              title="Телефон"
              placeholder="Введите номер"
              value={phone || ''}
              onChange={setPhone}
              componentType="phone"
              hidePadding={hidePhoneInfo}
            />
            <div className={s.subtext}>
              {!hidePhoneInfo ? (
                parseInt(user.phone_confirmed!, 10) === parseInt(phone, 10) ? (
                  <div className={s.confirmed}>
                    <img
                      src="/images/icons/more-gray-accept.svg"
                      className={s.more_gray_accept}
                      alt=""
                    />
                    Номер подтвержден
                  </div>
                ) : !phoneStatus ? (
                  <div className={s.confirm_phone} onClick={editPhone}>
                    Подтвердить номер
                  </div>
                ) : (
                  <div className={s.confirmed}>{stats.phoneStatus}</div>
                )
              ) : (
                ''
              )}
            </div>
          </div>
          {phoneStatus === 'smsSended' && (
            <InputCode
              phone={phone}
              onComplete={(data: any) => {
                if (!data.errors) {
                  setPhoneStatus('')
                  ToastNotify('Телефон подтвержден!')
                }
              }}
              authAfterDispatch
            />
          )}
          <div className={s.input_wrapper}>
            <InputLabelHorizontal
              title="Email"
              placeholder="Введите почту"
              value={email}
              onChange={setEmail}
              componentType="email"
            />
            <div className={s.subtext}>{emailStatusComponent()}</div>
          </div>

          {user ? (
            <>
              <div className={s.settings_title}>Привязанные соц.сети</div>
              <div className={`${s.separator} ${s.settings}`} />
              <a
                className={s.soc_item}
                href="/api/v1/social/auth/vkontakte/redirect"
                onClick={(e: any) => {
                  if (user.vk_id) {
                    e.preventDefault()
                    removeSocial('vkontakte')
                  }
                }}
              >
                <div className={s.soc_icon}>
                  <img src="/images/icons/authIcons/vk.svg" alt="" />
                </div>
                <div className={s.soc_info}>
                  <div className={s.soc_title}>Vkontakte</div>
                  <div className={s.soc_subtitle}>
                    Нажмите, чтобы {user.vk_id ? 'отвязать' : 'привязать'} аккаунт
                  </div>
                </div>
                <div className={s.soc_stat_icon}>
                  {user.vk_id ? <img src="/images/icons/red_accept.svg" alt="" /> : ''}
                </div>
              </a>
              <a
                className={s.soc_item}
                href="/api/v1/social/auth/google/redirect"
                onClick={(e: any) => {
                  if (user.google_id) {
                    e.preventDefault()
                    removeSocial('google')
                  }
                }}
              >
                <div className={s.soc_icon}>
                  <img src="/images/icons/authIcons/google.svg" alt="" />
                </div>
                <div className={s.soc_info}>
                  <div className={s.soc_title}>Google</div>
                  <div className={s.soc_subtitle}>
                    Нажмите, чтобы {user.google_id ? 'отвязать' : 'привязать'} аккаунт
                  </div>
                </div>
                <div className={s.soc_stat_icon}>
                  {user.google_id ? <img src="/images/icons/red_accept.svg" alt="" /> : ''}
                </div>
              </a>
              <a
                className={s.soc_item}
                href="/api/v1/social/auth/facebook/redirect"
                onClick={(e: any) => {
                  if (user.facebook_id) {
                    e.preventDefault()
                    removeSocial('facebook')
                  }
                }}
              >
                <div className={s.soc_icon}>
                  <img src="/images/icons/authIcons/fb.svg" alt="" />
                </div>
                <div className={s.soc_info}>
                  <div className={s.soc_title}>Facebook</div>
                  <div className={s.soc_subtitle}>
                    Нажмите, чтобы {user.facebook_id ? 'отвязать' : 'привязать'} аккаунт
                  </div>
                </div>
                <div className={s.soc_stat_icon}>
                  {user.facebook_id ? <img src="/images/icons/red_accept.svg" alt="" /> : ''}
                </div>
              </a>
            </>
          ) : (
            <></>
          )}

          <button
            type="button"
            className={`${s.save_button_block} ${saveIsLoad ? s.loading : ''} save_button_block`}
            onClick={saveChanges}
          >
            {saveIsLoad ? (
              <span className={`${s.loader} ${s.show}`}>
                <img loading="lazy" src="/images/loader.gif" alt="loader" />
              </span>
            ) : (
              'Сохранить'
            )}
          </button>
        </div>
      </div>
    </ContentLayout>
  )
}

export default React.memo(SettingsPage)
