import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import Swiper from 'react-id-swiper'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import Loader from 'application/presentation/common/Loader'
import ProductItem from 'application/presentation/common/ProductItem'
import { usePartnersList } from 'application/domain/useCase/partners/getPartners'

import s from './PartnerList.module.scss'

const PartnerList = () => {
  const { t } = useTranslation()
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = usePartnersList()

  const partnersList = useMemo(() => {
    return data && data.pages ? data.pages.flatMap((page: any) => page.data) : []
  }, [data])

  return (
    <div>
      <div className={s.header_container}>
        <div className={s.left}>
          <NavLink to="/shop" className={s.img_block}>
            <img loading="lazy" src="/images/icons/back-icon.svg" alt="" />
          </NavLink>
        </div>
        <div className={s.center}>
          <div className={s.name}>ПАРТНЕРЫ</div>
        </div>
        <div className={s.right} />
      </div>
      <div className={s.container}>
        {isLoading ? (
          <Loader />
        ) : (
          <InfiniteScroll
            className={s.prod_list_shop}
            dataLength={partnersList.length}
            next={fetchNextPage}
            style={{ overflow: 'visible', display: 'flex' }}
            hasMore={hasNextPage || false}
            loader={isFetchingNextPage ? <Loader /> : <></>}
            scrollableTarget="page_content"
          >
            {partnersList.map((item) => {
              return (
                <>
                  <NavLink to={`/shop/partner/${item.id}`} className={s.category_title}>
                    <div className={s.title_black}>{item.name}</div>
                    <div className={s.all}>{t('general.all_text')}</div>
                  </NavLink>
                  <div className={s.flat_list}>
                    {item.products.map((product: any) => {
                      return (
                        <ProductItem
                          key={`product_${product.id}`}
                          item={product}
                          categSlug={item.categories_id}
                        />
                      )
                    })}
                  </div>
                  <Swiper slidesPerView="auto" containerClass={s.slider}></Swiper>
                </>
              )
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export default React.memo(PartnerList)
