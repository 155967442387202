import React from 'react'
import { Meta } from 'react-head'

type Props = {
  url?: string
  title?: string
  description?: string
  image?: string
}

export const MetaOG = ({ url, title, description, image }: Props): React.ReactElement => {
  return (
    <>
      {/* Google / Search Engine Tags */}
      <Meta
        itemProp="name"
        content={`${
          title || 'Wishmen — единый список желаний. Создай вишлист подарков и собирай донаты'
        }`}
      />
      <Meta
        itemProp="description"
        content={`${
          description ||
          'Wishmen — сервис, который исполняет желания. Создай вишлист подарков, делись с друзьями, собирай донаты и исполняй мечты.'
        }`}
      />
      <Meta
        itemProp="image"
        content={`${image || 'https://wish.men/images/icons/favicons/logo-wishmen-fav.svg'}`}
      />

      {/* Facebook Meta Tags */}
      <Meta property="og:url" content={`${url || window.location.origin}`} />
      <Meta property="og:type" content="website" />
      <Meta
        property="og:title"
        content={`${
          title || 'Wishmen — единый список желаний. Создай вишлист подарков и собирай донаты'
        }`}
      />
      <Meta
        property="og:description"
        content={`${
          description ||
          'Wishmen — сервис, который исполняет желания. Создай вишлист подарков, делись с друзьями, собирай донаты и исполняй мечты.'
        }`}
      />
      <Meta
        property="og:image"
        content={`${image || 'https://wish.men/images/icons/favicons/logo-wishmen-fav.svg'}`}
      />

      {/* Twitter Meta Tags */}
      <Meta name="twitter:card" content="summary_large_image" />
      <Meta
        name="twitter:title"
        content={`${
          title || 'Wishmen — единый список желаний. Создай вишлист подарков и собирай донаты'
        }`}
      />
      <Meta
        name="twitter:description"
        content={`${
          description ||
          'Wishmen — сервис, который исполняет желания. Создай вишлист подарков, делись с друзьями, собирай донаты и исполняй мечты.'
        }`}
      />
      <Meta
        name="twitter:image"
        content={`${image || 'https://wish.men/images/icons/favicons/logo-wishmen-fav.svg'}`}
      />
    </>
  )
}

export default MetaOG
