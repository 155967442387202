import {
  fetchOutcomePayments,
  fetchPayment,
  fetchPaymentByOrderId,
  fetchPayments,
  fetchPaymentStatus,
  fetchPayMethods,
} from 'application/data/api/payments'
import {
  OutcomePaymentsResponse,
  PaymentsResponse,
} from 'application/domain/response/response_types'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

export const usePayments = () => {
  const query = useInfiniteQuery<PaymentsResponse>(
    ['payments', 'outcome'],
    ({ pageParam = '/api/v1/payment' }) => fetchPayments(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.next_page_url,
    },
  )
  return query
}

export const useOutcomePayments = () => {
  const query = useInfiniteQuery<OutcomePaymentsResponse>(
    ['payments', 'outcome'],
    ({ pageParam = '/api/v1/payment?outcome=true' }) => fetchOutcomePayments(pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.next_page_url,
    },
  )
  return query
}

export const usePayment = (payment_id?: number, sberPayOrderId?: string) => {
  const query = useQuery(
    [sberPayOrderId ? 'sber_payment' : 'payment', sberPayOrderId ? sberPayOrderId : payment_id],
    () => (sberPayOrderId ? fetchPaymentByOrderId(sberPayOrderId) : fetchPayment(payment_id || 0)),
    {
      enabled: sberPayOrderId || payment_id ? true : false,
    },
  )
  return query
}

export const usePaymentStatus = (id: number) => {
  const query = useQuery(['payment_status', id], () => fetchPaymentStatus(id), {
    enabled: id !== 0 ? true : false,
  })
  return query
}

export const getPayMethods = async () => fetchPayMethods()

export const usePayMethods = () => {
  const query = useQuery(['payMethods'], fetchPayMethods)
  return query
}
