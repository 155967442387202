const selectorData = [
  {
    label: 'Услуги',
    value: 1,
    data: [
      {
        label: 'Разработка сайтов',
        value: 2,
      },
      { label: 'Продажа конфеток', value: 3, data: [] },
    ],
  },
  {
    label: 'Товары',
    value: 4,
    data: [
      {
        label: 'Торговля автомобилями',
        value: 5,
        data: [],
      },
    ],
  },
  {
    label: 'Мойка машин',
    value: 6,
  },
]

export default selectorData
