/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-redeclare */
import { useEffect } from 'react'
// @ts-ignore
import anime from 'animejs/lib/anime.es.js'

import s from './AuthPage.module.scss'

// const anime = require('animejs')

type Props = {
  setRedirectToPage: Function
  username: string
}

let tl: any

const Animation = ({ username, setRedirectToPage }: Props) => {
  useEffect(() => {
    const height = $(window).height()
    const self = this
    tl = anime.timeline({
      easing: 'easeInOutQuad',
      duration: 750,
      autoplay: false,
      begin() {
        // $('.login-back').css('display', 'block')
        $('.login-section').css('background', 'none')
      },
      complete() {
        setTimeout(() => setRedirectToPage(true), 1000)
      },
    })

    tl.add({
      targets: 'body',
      scrollTop: 0,
      duration: 100,
    })
      .add({
        targets: '.login-back',
        zIndex: 0,
        duration: 1,
      })
      .add({
        targets: '.colorful-logo',
        translateY: '150%',
        opacity: [1, 0],
      })
      .add(
        {
          targets: '.login-section .auth-block .input-block',
          opacity: [1, 0],
          duration: 1,
        },
        '-=750',
      )
      .add(
        {
          targets: '.social',
          opacity: [1, 0],
          duration: 1,
        },
        '-=750',
      )
      .add(
        {
          targets: '.login-section .auth-block .bottom-info',
          // translateY: '150%',
          opacity: [1, 0],
          duration: 1,
        },
        '-=750',
      )
      .add(
        {
          targets: '.logo-white',
          translateY: '150%',
          opacity: [0, 1],
        },
        '-=750',
      )
      .add(
        {
          targets: '.title',
          translateY: '270%',
          opacity: [1, 0],
          duration: 1,
        },
        '-=750',
      )
      .add(
        {
          targets: '.subtitle',
          translateY: '270%',
          opacity: [1, 0],
          duration: 1,
        },
        '-=750',
      )
      .add(
        {
          targets: '.welcome',
          translateY: ['-300%', '0'],
        },
        '-=750',
      )
      .add(
        {
          targets: '.login-back',
          opacity: [0, 1],
        },
        '-=900',
      )
      .add(
        {
          targets: '.login-back .background',
          // width: `${height}px`,
          // // width: '100%',
          // height: '100%',
          translateX: ['-50%', '-50%'],
          translateY: ['-50%', '-50%'],
          scale: [0, 1],
        },
        '-=800',
      )
      .add(
        {
          targets: '.login-back .background img',
          borderRadius: ['1000px', '0'],
        },
        '-=800',
      )
    $('.page-content').scrollTop(0)
    $('html').css('overflow', 'hidden')
    $('body').css('overflow', 'hidden')
    $('.login-back').css('overflow', 'hidden')
    tl.play()
  }, [setRedirectToPage])

  return (
    <div className={`${s.login_back} login-back`}>
      <div className={`${s.background} background`}>
        <img className="back-img" src="/images/login-back.svg" alt="" />
      </div>

      <div className={`${s.welcome} welcome`}>
        <div className={`${s.user} user`}>
          Добро пожаловать,
          <br />
          <span id="welcome-user-name">{username}</span>
        </div>
        <div className={s.sub_welcome}>Для вас есть что-то новенькое:)</div>
      </div>
    </div>
  )
}

export default Animation
