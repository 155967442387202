import React from 'react'
import { useNavigate } from 'react-router-dom'

import s from './TopBlockProducts.module.scss'

type Props = {
  total: number
  categoryName: string
}

const TopBlockProducts = ({ total, categoryName }: Props) => {
  const navigate = useNavigate()
  return (
    <div className={s.container}>
      <div className={s.txt}>
        В категории <b>{categoryName || ''}</b> найдено {total} результат(ов)
      </div>
      <div className={s.everywhere} onClick={() => navigate(-1)}>
        <img src="/images/icons/mini-red-scope.svg" alt="" />
        <div className={s.red_txt}>Искать везде</div>
      </div>
    </div>
  )
}

export default React.memo(TopBlockProducts)
