import React from 'react'

import s from './AddWishButton.module.scss'

type Props = {
  type: string
  onClick: Function
}

const AddWishButton = ({ type, onClick }: Props) => {
  return (
    <div className={s.button_container}>
      <button
        type="button"
        className={`${s.add_button_page} ${
          type === 'fixed' ? s.fixed : type === 'block' ? s.block : ''
        }`}
        onClick={() => onClick()}
      >
        <div className={s.text}>Добавить желание</div>
      </button>
    </div>
  )
}

export default React.memo(AddWishButton)
