import React from 'react'
import { NavLink } from 'react-router-dom'

import s from './ElevenBlock.module.scss'

const ElevenBlock = () => {
  return (
    <div className={s.container}>
      <div className={s.inner_content}>
        <div className={s.title}>Время пришло.</div>
        <div className={s.subtitle}>Создай свой список прямо сейчас. Это бесплатно.</div>
        <NavLink to="/login">Создать список</NavLink>
      </div>
    </div>
  )
}

export default React.memo(ElevenBlock)
