import apiClient from '../apiClient'

export const fetchFriendsRequest = async () => {
  const { data } = await apiClient.get('/api/v1/user/friend/request')
  return data
}

export const friendActionRequest = async (nickname: string, method: 'POST' | 'GET' | 'DELETE') => {
  const { data } = await apiClient({
    url: `/api/v1/${nickname}/friend`,
    method,
  })
  return data
}
