import React, { useCallback, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { Product } from 'application/domain/entity/product/Product'
import { ExpressAddWish } from 'application/domain/useCase/product/addProduct'
import parseNumber from 'application/presentation/utils/parseNumber'

import { Button } from '../ActionComponents'
import Loader from '../Loader'

import s from './ProductItem.module.scss'

type Props = {
  item: Product
  categSlug?: string
  adaptive?: boolean
  containerClassName?: any
  isIdea?: boolean
  isLastItem?: boolean
  isHorizontal?: boolean
}

const ProductItem = ({
  item,
  adaptive,
  categSlug,
  containerClassName,
  isIdea,
  isLastItem,
  isHorizontal,
}: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [addIsLoading, setAddIsLoading] = useState<boolean>(false)
  const addWish = useCallback(() => {
    ExpressAddWish({
      product: item,
      isIdea,
      user_nickname: '',
      isNewUser: false,
      onStart: () => setAddIsLoading(true),
      onError: () => setAddIsLoading(false),
      onSuccess: () => {
        setAddIsLoading(false)
        ToastNotify(`Желание добавлено`)
      },
    })
  }, [item, isIdea])

  return (
    <div className={`${s.product_wrapper} ${containerClassName || ''}`}>
      <NavLink
        className={`${s.product_card} ${adaptive ? s.adaptive : ''} ${
          isLastItem ? s.lastitem : ''
        } ${isHorizontal ? s.horizontal : ''}`}
        to={`/shop/category/${categSlug || item.categories_id}/${item.id}`}
      >
        <div
          className={`${s.prod_img_block} ${adaptive ? s.adaptive_img_block : ''} ${
            isHorizontal ? s.horizontal_img_block : ''
          }`}
        >
          {item.photo ? (
            <img
              src={
                item.photo
                  ? Array.isArray(item.photo)
                    ? item.photo[0]
                    : item.photo
                  : 'https://cdn.wish.men/icons/product-without-photo.png'
              }
              className={`${s.prod_img} ${adaptive ? s.adaptive_img : ''}`}
              alt=""
              loading="lazy"
            />
          ) : null}

          {item.partner && item.price && item.partner.has_cashback ? (
            <div className={s.cacheback}>
              <img src="/images/icons/cacheback.svg" alt="" width={14} />
              <div className={s.cacheback_txt}>
                {parseNumber(
                  Math.floor(
                    (item.price / 100) *
                      parseInt(item.partner.commission === '0' ? '1' : item.partner.commission, 10),
                  ),
                )}{' '}
                р
              </div>
            </div>
          ) : null}
        </div>
        <div className={`${s.product_info} ${isHorizontal ? s.horizontal_info : ''}`}>
          <div className={s.product_name}>{item.name}</div>
          <div className={`${s.product_price} ${isHorizontal ? s.horizontal_price : ''}`}>
            {parseNumber(item.full_price)} руб
          </div>
          <div className={s.partner_name}>
            {item.partner && item.partner.name ? item.partner.name : 'Wishmen'}
          </div>
          {isHorizontal && <div className={s.for_btn_container} />}
        </div>
      </NavLink>
      {isHorizontal ? (
        <Button
          title="В желания"
          color="red"
          isLoading={addIsLoading}
          containerClassName={s.red_btn_container}
          className={s.red_btn}
          onClick={() => addWish()}
          iconUrl="/images/icons/white-add-plus.svg"
          iconSize={{ width: '1.4rem', height: '1.4rem' }}
        />
      ) : (
        <div className={s.add_container} onClick={() => addWish()}>
          <div className={s.add_icon}>
            {addIsLoading ? (
              <Loader size={8} color="#ffffff" />
            ) : (
              <img src="/images/icons/white-add-plus.svg" alt="" width={14} />
            )}
          </div>
        </div>
      )}
      <div className={`${s.top_block} ${isHorizontal ? s.top_block_horizontal : ''}`}>
        <div className={s.top_labels}>
          {item.wish_top && (
            <div className={`${s.top_item} ${s.top_wish}`}>
              <div className={s.top_txt}>Часто хотят</div>
            </div>
          )}
          {item.idea_top && (
            <div className={`${s.top_item} ${s.top_idea}`}>
              <div className={s.top_txt}>Топ идея подарка</div>
            </div>
          )}
        </div>
        <div
          className={s.more_btn}
          onClick={() => navigate(`${pathname}?product_id=${item.id}#more`)}
        >
          <svg
            width="23"
            height="6"
            viewBox="0 0 23 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.375 2.72656C0.375 1.97656 0.59375 1.35156 1.03125 0.851562C1.48438 0.351562 2.15625 0.101562 3.04688 0.101562C3.9375 0.101562 4.60938 0.351562 5.0625 0.851562C5.53125 1.35156 5.76562 1.97656 5.76562 2.72656C5.76562 3.44531 5.53125 4.04688 5.0625 4.53125C4.60938 5.01562 3.9375 5.25781 3.04688 5.25781C2.15625 5.25781 1.48438 5.01562 1.03125 4.53125C0.59375 4.04688 0.375 3.44531 0.375 2.72656ZM8.95125 2.72656C8.95125 1.97656 9.17 1.35156 9.6075 0.851562C10.0606 0.351562 10.7325 0.101562 11.6231 0.101562C12.5138 0.101562 13.1856 0.351562 13.6388 0.851562C14.1075 1.35156 14.3419 1.97656 14.3419 2.72656C14.3419 3.44531 14.1075 4.04688 13.6388 4.53125C13.1856 5.01562 12.5138 5.25781 11.6231 5.25781C10.7325 5.25781 10.0606 5.01562 9.6075 4.53125C9.17 4.04688 8.95125 3.44531 8.95125 2.72656ZM17.5275 2.72656C17.5275 1.97656 17.7463 1.35156 18.1838 0.851562C18.6369 0.351562 19.3088 0.101562 20.1994 0.101562C21.09 0.101562 21.7619 0.351562 22.215 0.851562C22.6838 1.35156 22.9181 1.97656 22.9181 2.72656C22.9181 3.44531 22.6838 4.04688 22.215 4.53125C21.7619 5.01562 21.09 5.25781 20.1994 5.25781C19.3088 5.25781 18.6369 5.01562 18.1838 4.53125C17.7463 4.04688 17.5275 3.44531 17.5275 2.72656Z"
              fill="#CECECE"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ProductItem)
