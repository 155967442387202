/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import ProductItem from 'application/presentation/common/ProductItem'
import Loader from 'application/presentation/common/Loader'
import { Button } from 'application/presentation/common/ActionComponents'
import { isArray } from 'lodash'
import parseNumber from 'application/presentation/utils/parseNumber'
import { Product } from 'application/domain/entity/product/Product'
import { ShopCategory } from 'application/domain/entity/shop/ShopCategory'

import s from './ProductPage.module.scss'
// import { useRandomProducts } from './useShop'
SwiperCore.use([Pagination])

type Props = {
  product: Product
  category: ShopCategory
  random: any
  randomState: {
    isLoading: boolean
    fetchNextPage: Function
    isFetchingNextPage: boolean
  }
  isIdeaFriendNickname?: string | boolean
}

const ProductContent = ({
  product,
  category,
  random,
  randomState,
  isIdeaFriendNickname,
}: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [desc, setDesc] = useState(true)
  const [showFixedBtn, setShowFixedBtn] = useState(false)

  const openAddWishMW = useCallback(() => {
    navigate(`${location.pathname}/add`)
  }, [location.pathname, navigate])

  const openIdeaWishMW = useCallback(() => {
    navigate(
      `${location.pathname}/add?idea=true${
        isIdeaFriendNickname ? `&idea_friend=${isIdeaFriendNickname}` : ''
      }`,
    )
  }, [isIdeaFriendNickname, location.pathname, navigate])

  const scrollListener = useCallback(
    (scrollElem: any) => {
      if (scrollElem.scrollTop > 540) {
        if (showFixedBtn !== true) {
          setShowFixedBtn(true)
        }
      } else if (showFixedBtn !== false) {
        setShowFixedBtn(false)
      }
    },
    [showFixedBtn],
  )

  useEffect(() => {
    const scrollElem = document.getElementById('page_content')
    scrollElem?.addEventListener('scroll', () => scrollListener(scrollElem))
    return () => scrollElem?.removeEventListener('scroll', () => scrollListener(scrollElem))
  }, [scrollListener])

  return (
    <>
      <div className={`${s.product_container}`}>
        <div className={s.product_slider}>
          <NavLink className={s.category} to={`/shop/category/${category.slug}`}>
            {category.name}
          </NavLink>
          <div className={s.product_name}>{product.name}</div>
          <Swiper spaceBetween={25} slidesPerView={1} className={s.swiper_container} pagination>
            {isArray(product.photo) ? (
              product.photo.map((item: any, i: number) => {
                return (
                  <SwiperSlide key={i} className={s.slide}>
                    <img className={s.slide_content} src={item || 'photo'} alt="" />
                  </SwiperSlide>
                )
              })
            ) : (
              <></>
            )}
          </Swiper>
          {isIdeaFriendNickname ? (
            <>
              <Button
                title="Добавить в идеи"
                onClick={openIdeaWishMW}
                color="red"
                iconUrl="/images/icons/mobile-plus.svg"
                containerClassName={s.add_button_container}
                iconSize={{ width: '1.8rem', height: '1.8rem' }}
                className={s.add_button}
              />
              <Button
                title="Добавить в идеи"
                onClick={openIdeaWishMW}
                color="red"
                iconUrl="/images/icons/mobile-plus.svg"
                containerClassName={`${s.add_button_container} ${s.fixed} ${
                  showFixedBtn ? s.show : ''
                }`}
                iconSize={{ width: '1.8rem', height: '1.8rem' }}
                className={s.add_button}
              />
            </>
          ) : (
            <>
              <Button
                title="Добавить в желания"
                onClick={openAddWishMW}
                color="red"
                iconUrl="/images/icons/mobile-plus.svg"
                containerClassName={s.add_button_container}
                iconSize={{ width: '1.8rem', height: '1.8rem' }}
                className={s.add_button}
              />
              <Button
                title="Добавить в желания"
                onClick={openAddWishMW}
                color="red"
                iconUrl="/images/icons/mobile-plus.svg"
                containerClassName={`${s.add_button_container} ${s.fixed} ${
                  showFixedBtn ? s.show : ''
                }`}
                iconSize={{ width: '1.8rem', height: '1.8rem' }}
                className={s.add_button}
              />
            </>
          )}

          <div className={s.price_block}>
            <div className={s.left}>
              <div className={s.price_name}>Стоимость</div>
              <div className={s.price}>{product.full_price} руб</div>
            </div>
            <div className={s.right}>
              {product.partner && product.partner.has_cashback ? (
                <>
                  <div className={s.cashback_name}>Кэшбек на желание</div>
                  <div className={s.cashback}>
                    {product.partner.has_cashback ? (
                      <span>
                        {product.partner.commission === '0'
                          ? Math.floor((product.full_price / 100) * 1)
                          : Math.floor(
                              (product.full_price / 100) * Number(product.partner.commission),
                            )}{' '}
                        руб
                      </span>
                    ) : (
                      <></>
                    )}
                    <div
                      className={s.img_block}
                      onClick={() => {
                        navigate(`${location.pathname}/cashback`)
                      }}
                    >
                      <img src="/images/icons/question-icon.svg" alt="?" />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={s.cashback_name}>Примерно потребуется</div>
                  <div className={s.cashback}>
                    {parseNumber(Math.trunc(product.full_price / 300))} донатов
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {isIdeaFriendNickname ? (
          <></>
        ) : (
          <div className={s.idea_gift}>
            <div
              className={`${s.block} ${s.idea}`}
              onClick={() => {
                navigate(`${location.pathname}/add?idea=true`)
              }}
            >
              <div className={s.img_block}>
                <img src="/images/icons/lamp-icon.svg" alt="" />
              </div>
              <div className={s.text}>
                <span>Добавить,</span> как идею <br /> подарка другу
              </div>
            </div>
            {/* <div className={`${s.block} ${s.gift}`}>
            <div className={s.img_block}>
              <img src="/images/icons/gift-icon.svg" alt="" />
            </div>
            <div className={s.text}>
              <span>Отправить другу</span>
              <br /> как сюрприз
            </div>
          </div> */}
            {/* <div className={s.margin} /> */}
          </div>
        )}
        <div className={s.desc_container}>
          <div className={s.title}>Описание</div>
          {product && product.desc ? (
            <>
              <div
                className={`${desc ? s.text : `${s.text} ${s.text_show}`}`}
                dangerouslySetInnerHTML={{ __html: product.desc }}
              />
              <span onClick={() => (desc ? setDesc(false) : setDesc(true))} className={s.more}>
                {desc ? 'еще' : 'скрыть'}
              </span>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={s.wish_container}>
          <div className={s.left}>
            <div className={s.img_block}>
              <img src="/images/icons/add-button.svg" alt="" />
            </div>
            {product.qty_wishes > 0 ? (
              <div className={s.text}>
                <div className={s.top}>{product.qty_wishes} раз</div>
                <div className={s.bottom}>Добавили в желание</div>
              </div>
            ) : (
              <div className={s.text}>
                <div className={s.top}>Вы можете</div>
                <div className={s.bottom}>добавить первым</div>
              </div>
            )}
          </div>
          <div className={s.right}>
            <div className={s.img_block}>
              <img src="/images/icons/check-red-icon.svg" alt="" />
            </div>
            <div className={s.text}>
              <div className={s.top}>Вы можете</div>
              <div className={s.bottom}>исполнить первым</div>
            </div>
          </div>
        </div>
        {/* <div className={s.character_container}>
          <div className={s.title}>Общие характеристики</div>
          <div className={s.inner_content}>
            <div className={s.left}>
              <div className={s.name}>Двигатель</div>
            </div>
            <div className={s.right}>
              <div className={s.info}>2.5л, бензин</div>
            </div>
          </div>
        </div> */}
        {/* <div className={s.delivery_container}>
        <div className={s.left}>
          <div className={s.delivery_title}>Информация о доставке</div>
          <div className={s.city_block}>
            <div className={s.img_block}>
              <img src="/images/icons/location-icon.svg" alt="" />
            </div>
            <div className={s.city}>Чебоксары</div>
          </div>
          <div className={s.cur}>
            Пункт выдачи <span>- бесплатно</span>
          </div>
          <div className={s.cur}>
            Курьер Яндекс Go <span>- от 40 рублей</span>
          </div>
        </div>
        <div className={s.right}>
          <div className={s.img_block}>
            <img src="/images/icons/black-right-trigger.svg" alt="" />
          </div>
        </div>
      </div> */}

        {/* <div className={s.wish_users}>
          <div className={s.title}>Это желание в списке у других</div>
          <div className={s.wish_block}>
            <div className={s.inner_content}>
              <div className={s.img_block}>
                <img src="" alt="user" />
              </div>
              <div className={s.user}>
                <div className={s.name}>Иван Иванов</div>
                <div className={s.collected}>Собрал 3% из 4555 руб</div>
                <div className={s.comment}>
                  Давно хотел такую классную штуку, ребята, всем спасибо за то, что поддерживаете
                  меня
                </div>
              </div>
              <div className={s.circle}>
                <img src="" alt="bar" />
              </div>
            </div>
          </div>
          <div className={s.button_more}>
            <button>Показать еще</button>
          </div>
        </div> */}
        {product.partner ? (
          <>
            <NavLink to={`/shop/partner/${product.partner.id}`} className={`${s.dealer_info}`}>
              <div className={s.left_block}>
                <div className={s.title}>Поставщик</div>
                <div className={s.name}>{product.partner.name}</div>
              </div>
              <div className={s.right_block}>
                <div className={s.img_block}>
                  <img src={product.partner.photo || ''} alt="" />
                </div>
              </div>
            </NavLink>
            <NavLink to={`/shop/partner/${product.partner.id}`} className={s.partner_info}>
              <div className={s.title}>Товары поставщика</div>
              <div className={s.img_block}>
                <img src="/images/icons/black-right-trigger.svg" alt="" />
              </div>
            </NavLink>
          </>
        ) : (
          <></>
        )}

        <NavLink to={`/shop/category/${product.categories_id}`} className={s.categ_info}>
          <div className={s.title}>Товары этой категории</div>
          <div className={s.img_block}>
            <img src="/images/icons/black-right-trigger.svg" alt="" />
          </div>
        </NavLink>

        <div className={s.more_products}>
          <div className={s.title}>Возможно, вам понравится</div>
          <div className={s.random_block}>
            {randomState.isLoading ? (
              <div className="pagination_loader">
                <Loader size={16} />
              </div>
            ) : (
              <InfiniteScroll
                className={s.prod_list}
                dataLength={random.length}
                next={() => randomState.fetchNextPage()}
                style={{ overflow: 'visible', display: 'flex' }}
                hasMore
                loader={
                  <div className="pagination_loader">
                    <Loader size={16} />
                  </div>
                }
                scrollableTarget="page_content"
              >
                {random.map((item: any, i: number) => {
                  return (
                    <div className={s.row} key={`random_${i}`}>
                      {item[0] ? <ProductItem item={item[0]} adaptive /> : <></>}
                      {item[1] ? <ProductItem item={item[1]} adaptive isLastItem /> : <></>}
                    </div>
                  )
                })}
              </InfiniteScroll>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(ProductContent)
