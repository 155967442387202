import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.apiUrl || 'https://api.wish.men',
  withCredentials: false,
  headers: {
    'accept-encoding': '*',
    // Authorization: `Bearer ${getCookie('token')}`,
  },
})

// apiClient.interceptors.response.use(
//   response => response,
//   error => {
//     // if (error && (error.indexOf('Network Error') !== -1 || error.indexOf('timeout') !== -1)) {
//     //   Toast.show('Ошибка сети', Toast.SHORT)
//     // }
//     return Promise.reject(error.response)
//   },
// )

export default apiClient
