import { PageContent, Sheet } from 'framework7-react'
import React, { useCallback } from 'react'

import stylebs from '../MW.module.scss'

import s from './SelectorMW.module.scss'

type Props = {
  opened: boolean
  setOpened: Function
  value: number
  data: any
  title: string
  onChange?: Function
}

const SelectorMW = ({ data, title, onChange, value, opened, setOpened }: Props) => {
  const closeBS = useCallback(() => setOpened(false), [setOpened])

  return (
    <div>
      <Sheet
        className={`${stylebs.sheet_modal} ${stylebs.auto_height}`}
        swipeToClose
        backdrop
        opened={opened}
        onSheetClosed={() => closeBS()}
      >
        <div className={stylebs.top_close_button} onClick={() => closeBS()}>
          <div />
        </div>
        <PageContent>
          <div className={s.inner_content}>
            <div>
              <div className={s.title}>{title}</div>
            </div>
            <div className={s.select_container}>
              {data ? (
                data.map((item: any) => {
                  return (
                    <div
                      key={item.id}
                      className={s.pay_item}
                      onClick={() => {
                        // openMW.openPaySelectorMW(false)
                        closeBS()
                        if (onChange) {
                          onChange(item.id)
                        }
                      }}
                    >
                      <div className={`${s.item_inner} ${value === item.id ? s.selected : ''}`}>
                        <div className={s.item_txt}>{item.name}</div>
                        {value === item.id && (
                          <div className={s.selected_icon}>
                            <svg
                              width="61"
                              height="43"
                              viewBox="0 0 61 43"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M20.9469 39.5887C21.0096 39.6761 21.0802 39.7596 21.1588 39.8381C21.9398 40.6191 23.2062 40.6191 23.9872 39.8381L57.4111 6.41421C58.1921 5.63317 58.1921 4.36683 57.4111 3.58579C56.63 2.80474 55.3637 2.80474 54.5827 3.58579L22.5914 35.577L6.4142 19.4C5.63315 18.619 4.36682 18.619 3.58577 19.4001C2.80473 20.1811 2.80474 21.4475 3.5858 22.2285L20.9192 39.5616C20.9283 39.5707 20.9376 39.5798 20.9469 39.5887Z"
                                fill="#FF3E6C"
                              />
                              <path
                                d="M20.9469 39.5887L22.9774 38.1304L22.8447 37.9455L22.6807 37.7877L20.9469 39.5887ZM23.9872 39.8381L25.755 41.6059L23.9872 39.8381ZM57.4111 6.41421L55.6433 4.64645L57.4111 6.41421ZM57.4111 3.58579L55.6433 5.35355L57.4111 3.58579ZM54.5827 3.58579L56.3504 5.35355V5.35355L54.5827 3.58579ZM22.5914 35.577L20.8237 37.3448L22.5915 39.1125L24.3592 37.3448L22.5914 35.577ZM6.4142 19.4L8.18195 17.6323L8.18195 17.6323L6.4142 19.4ZM3.58577 19.4001L1.81799 17.6323L1.81799 17.6323L3.58577 19.4001ZM3.5858 22.2285L1.81805 23.9963H1.81805L3.5858 22.2285ZM20.9192 39.5616L19.1514 41.3293L19.1516 41.3295L20.9192 39.5616ZM22.9266 38.0703C22.9442 38.088 22.9614 38.1081 22.9774 38.1304L18.9163 41.0471C19.0578 41.2441 19.2162 41.4311 19.391 41.6059L22.9266 38.0703ZM22.2194 38.0703C22.4147 37.8751 22.7313 37.8751 22.9266 38.0703L19.391 41.6059C21.1484 43.3632 23.9976 43.3632 25.755 41.6059L22.2194 38.0703ZM55.6433 4.64645L22.2194 38.0703L25.755 41.6059L59.1789 8.18198L55.6433 4.64645ZM55.6433 5.35355C55.4481 5.15829 55.4481 4.84171 55.6433 4.64645L59.1789 8.18198C60.9362 6.42462 60.9362 3.57538 59.1789 1.81802L55.6433 5.35355ZM56.3504 5.35355C56.1552 5.54882 55.8386 5.54882 55.6433 5.35355L59.1789 1.81802C57.4215 0.0606585 54.5723 0.0606618 52.8149 1.81802L56.3504 5.35355ZM24.3592 37.3448L56.3504 5.35355L52.8149 1.81802L20.8237 33.8092L24.3592 37.3448ZM4.64645 21.1678L20.8237 37.3448L24.3592 33.8092L8.18195 17.6323L4.64645 21.1678ZM5.35356 21.1678C5.1583 21.3631 4.84171 21.3631 4.64645 21.1678L8.18195 17.6323C6.42458 15.8749 3.57534 15.8749 1.81799 17.6323L5.35356 21.1678ZM5.35355 20.4607C5.54881 20.656 5.54882 20.9726 5.35356 21.1678L1.81799 17.6323C0.0606475 19.3897 0.0606728 22.2389 1.81805 23.9963L5.35355 20.4607ZM22.6869 37.7938L5.35355 20.4607L1.81805 23.9963L19.1514 41.3293L22.6869 37.7938ZM22.6807 37.7877C22.683 37.7899 22.685 37.7919 22.6867 37.7936L19.1516 41.3295C19.1717 41.3496 19.1921 41.3697 19.213 41.3898L22.6807 37.7877Z"
                                fill="#FF3E6C"
                              />
                            </svg>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })
              ) : (
                <></>
              )}
            </div>
          </div>
        </PageContent>
      </Sheet>
    </div>
  )
}

export default React.memo(SelectorMW)
