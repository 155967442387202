/* eslint-disable no-fallthrough */
import { Time } from 'application/presentation/common/uiComponents'
import React from 'react'
import { NavLink } from 'react-router-dom'

import s from './NotificationItem.module.scss'

const parseType = (data: any, user: any) => {
  let link = '/'
  let image = `/images/icons/notification-icons/${data.data.type.replace(/ /g, '-')}.svg`
  let elemClass = 'not_user'
  switch (data.data.type) {
    // @ts-ignore
    case 'friend request new':
      elemClass = 'friend_accepted'
      image = data.image
    case 'friend request accepted':
      image = data.image
      link = `/${data.data.user_nickname}`
      break

    case 'wish created':
      image = data.image
      link = `/${user.nickname}/${data.data.wish_id}`
      break
    case 'idea_results':
      image = data.image
      link = `/${data.data.user_nickname}/${data.data.wish_id}`
      break
    case 'wish new donate':
      image = data.image
      elemClass = ''
      link = `/${user.nickname}/${data.data.wish_id}/comments`
      // $func = "event.preventDefault();event.stopImmediatePropagation();location.href='/".@$item['data']['user_nickname']."'";
      break

    case 'friend birthday':
      elemClass = ''
      image = data.image
      link = `/${data.data.user_nickname}`
      break
    case 'comment new like':
    case 'wish new like':
      elemClass = ''
      image = data.image
      link = `/${user.nickname}/${data.data.wish_id}`
      break

    case 'wish new comment':
      elemClass = ''
      image = data.image
      link = `/${user.nickname}/${data.data.wish_id}/comments`
      break

    case 'test_results':
      elemClass = ''
      image = data.image
      link = `/main/whatwantfriend?compilation=${data.data.compilation_slug}`
      break

    case 'idea delivery':
      elemClass = ''
      image = data.image
      link = `/${user.nickname}/${data.data.wish_id}`
      break

    case 'wish cost increased':
    case 'wish cost decreased':
    case 'wish ready to order':
    case 'wish fulfilled':
      image = data.image
      link = `/${user.nickname}/${data.data.wish_id}`
      break

    case 'order accepted':
    case 'order new comment':
    case 'order delivery ready':
    case 'order delivered':
    case 'order cancelled':
    case 'order completed':
      image = data.image
      link = `/orders${data.data.order_id ? `/${data.data.order_id}` : '/'}`
      break

    default:
      break
  }

  return {
    link,
    image,
    elemClass,
  }
}
type Props = {
  data: any
  user: any
}
const NotificationItem = (props: Props) => {
  const parsedData = parseType(props.data, props.user)

  return (
    <NavLink
      className={`${s.history_item} link external`}
      to={parsedData.link}
      data-id={props.data.id}
    >
      <div
        className={`${s.left_half} ${parsedData.elemClass === 'not_user' ? s.not_user : ''} ${
          parsedData.elemClass === 'friend_accepted' ? s.friend_accepted : ''
        }`}
      >
        <img
          className={`${parsedData.elemClass === 'not_user' ? s.not_user : ''} ${
            parsedData.elemClass === 'friend_accepted' ? s.friend_accepted : ''
          }`}
          loading="lazy"
          alt=""
          src={parsedData.image}
        />
      </div>
      <div className={s.center}>
        <div className={s.title}>
          <div className={s.user_name}>
            {props.data.data.type === 'order accepted'
              ? `Заказ №${props.data.data.order_id} передан на доставку`
              : props.data.title}
          </div>
        </div>
        <div className={s.message}>
          {props.data.data.type === 'wish new comment' ? props.data.data.comment : props.data.body}
        </div>
      </div>
      <div className={s.right_half}>
        {props.data.data.wish_image ? (
          <div className={s.img_block}>
            <img loading="lazy" src={props.data.data.wish_image} alt="" />
          </div>
        ) : (
          ''
        )}
        <div className={s.title}>
          <div className={s.time}>
            <Time time={props.data.created_at} />
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export default React.memo(NotificationItem)
