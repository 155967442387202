import apiClient from '../apiClient'

export const fetchBalance = async ({ pageParam = '/api/v1/user/balance' }) => {
  const { data } = await apiClient.get(pageParam)
  return data
}

export const fetchReferalBalance = async ({ pageParam = '/api/v1/user/balance?referral=1' }) => {
  const { data } = await apiClient.get(pageParam)
  return data
}
