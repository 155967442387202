import ToastNotify from 'application/presentation/app/ToastNotify'
import Loader from 'application/presentation/common/Loader'
import React, { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { ExpressAddWish } from 'application/domain/useCase/product/addProduct'
import parseNumber from 'application/presentation/utils/parseNumber'

import s from '../ArticleItem.module.scss'

type Props = {
  data: any
  isActive?: boolean
  paused?: boolean
}

const ProductStory = ({ data, isActive, paused }: Props) => {
  const [addIsLoading, setAddIsLoading] = useState<boolean>(false)
  const addWish = useCallback(
    () =>
      ExpressAddWish({
        product: data,
        isIdea: false,
        isNewUser: false,
        user_nickname: '',
        onStart: () => setAddIsLoading(true),
        onSuccess: () => {
          ToastNotify('Желание добавлено!')
          setAddIsLoading(false)
        },
        onError: () => {},
      }),
    [data],
  )
  return (
    <div className={s.product_article}>
      <div className={cn(s.top_shadow, paused ? s.hidden : '')}></div>
      <img src={data.photo[0]} alt="" />
      <div className={cn(s.bottom_shadow, paused ? s.hidden : '')}></div>
      <div className={cn(s.bottom, paused ? s.hidden : '')}>
        <NavLink
          className={s.product_preview}
          to={`/shop/category/${data.categories_id}/${data.id}`}
        >
          <div className={s.prod_img}>
            <img src={data.photo[0]} alt="" />
          </div>
          <div className={s.prod_info}>
            <div className={s.prod_title}>{data.name}</div>
            <div className={s.prod_price}>{parseNumber(data.price)} руб</div>
          </div>
        </NavLink>
        <img src="/images/icons/white_up_trigger.svg" alt="" className={s.trigger} />
        <NavLink className={s.see_more} to={`/shop/category/${data.categories_id}/${data.id}`}>
          Подробнее
        </NavLink>
        <button className={s.plus_icon} type="button" onClick={() => addWish()}>
          {addIsLoading ? (
            <Loader size={12} color="#ffffff" containerClassName={s.loader} />
          ) : (
            <img src="/images/icons/thin_plus_icon.svg" alt="" />
          )}
        </button>
      </div>
    </div>
  )
}

export default React.memo(ProductStory)
