import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { User } from 'application/domain/entity/user/User'
import { useAppSelector } from 'application/domain/store/hooks'
import { useFriendsRequest } from 'application/domain/useCase/friends/getFriendsRequest'

import s from './Footer.module.scss'

type Props = {
  user?: User
  showFooter: boolean
}

export const FooterAuth = ({ user, showFooter }: Props) => {
  const { data } = useFriendsRequest(user ? user.nickname : '')
  const { newNotificationsCount } = useAppSelector(({ userReducer }) => ({
    newNotificationsCount: userReducer.newNotificationsCount,
  }))
  const requestCount = useMemo(() => {
    return data && data.incoming_request && data.incoming_request.length
      ? data.incoming_request.length
      : 0
  }, [data])
  return (
    <div className={`${s.footer_container} ${!showFooter ? s.hide_footer : ''}`}>
      <div>
        <NavLink to="/shop" className={({ isActive }) => `${s.navLink} ${isActive && s.active}`}>
          <div className={`${s.icon} ${s.market}`}>
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={s.not_active}
            >
              <path
                d="M19.7944 17.7057C18.0755 17.7057 16.6772 19.1041 16.6772 20.823C16.6772 22.5418 18.0756 23.9402 19.7944 23.9402C21.5132 23.9402 22.9116 22.5418 22.9116 20.823C22.9116 19.1041 21.5132 17.7057 19.7944 17.7057ZM19.7944 22.0698C19.1067 22.0698 18.5475 21.5106 18.5475 20.823C18.5475 20.1353 19.1067 19.5761 19.7944 19.5761C20.482 19.5761 21.0413 20.1353 21.0413 20.823C21.0413 21.5106 20.482 22.0698 19.7944 22.0698Z"
                fill="black"
              />
              <path
                d="M24.8013 5.65799C24.6242 5.43137 24.3527 5.29919 24.065 5.29919H5.77249L4.93084 1.77769C4.83015 1.35689 4.45388 1.05981 4.02121 1.05981H0.935162C0.418655 1.05977 0 1.47842 0 1.99493C0 2.51143 0.418655 2.93009 0.935162 2.93009H3.28308L6.32235 15.6471C6.42304 16.0683 6.79931 16.365 7.23198 16.365H21.7894C22.2193 16.365 22.594 16.072 22.6972 15.6549L24.9728 6.45906C25.0416 6.17982 24.9783 5.8846 24.8013 5.65799ZM21.0578 14.4947H7.97011L6.21947 7.16952H22.8701L21.0578 14.4947Z"
                fill="black"
              />
              <path
                d="M8.47887 17.7057C6.76001 17.7057 5.36166 19.1041 5.36166 20.823C5.36166 22.5418 6.76006 23.9402 8.47887 23.9402C10.1977 23.9402 11.5961 22.5418 11.5961 20.823C11.5961 19.1041 10.1977 17.7057 8.47887 17.7057ZM8.47887 22.0698C7.79122 22.0698 7.23199 21.5106 7.23199 20.823C7.23199 20.1353 7.79122 19.5761 8.47887 19.5761C9.16652 19.5761 9.72575 20.1353 9.72575 20.823C9.72575 21.5106 9.16652 22.0698 8.47887 22.0698Z"
                fill="black"
              />
            </svg>

            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={s.active}
            >
              <g clipPath="url(#clip0)">
                <path
                  d="M19.8 17.7073C18.0851 17.7073 16.6819 19.1104 16.6819 20.8254C16.6819 22.5404 18.0851 23.9435 19.8 23.9435C21.515 23.9435 22.9182 22.5404 22.9182 20.8254C22.9182 19.1104 21.515 17.7073 19.8 17.7073Z"
                  fill="black"
                />
                <path
                  d="M24.8202 5.67136C24.6331 5.4531 24.3837 5.29721 24.0718 5.29721H5.76851L4.92663 1.77373C4.8331 1.33721 4.45889 1.05658 4.02237 1.05658H0.935407C0.405365 1.05652 0 1.46188 0 1.99199C0 2.52209 0.405365 2.92739 0.935407 2.92739H3.27401L6.29861 15.6493C6.39213 16.0858 6.76634 16.3664 7.20286 16.3664H21.7645C22.201 16.3664 22.5752 16.0858 22.6687 15.6493L24.9761 6.45088C25.0385 6.17025 24.9761 5.88962 24.8202 5.67136Z"
                  fill="black"
                />
                <path
                  d="M8.48125 17.7073C6.76627 17.7073 5.36313 19.1104 5.36313 20.8254C5.36313 22.5404 6.76627 23.9435 8.48125 23.9435C10.1962 23.9435 11.5994 22.5404 11.5994 20.8254C11.5994 19.1104 10.1962 17.7073 8.48125 17.7073Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="25" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={s.title}>Каталог</div>
        </NavLink>
      </div>
      <div>
        <NavLink
          to={`/${user?.nickname}`}
          className={({ isActive }) => `${s.navLink} ${isActive && s.active}`}
        >
          <div className={`${s.icon} ${s.wishlist}`}>
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={s.not_active}
            >
              <path
                d="M24.5402 8.89951C24.2545 8.57884 23.864 8.37027 23.4386 8.31105V8.31016L16.7944 7.39234L14.1652 1.10974C13.7813 0.190139 12.7245 -0.244105 11.805 0.139815C11.3666 0.322856 11.0181 0.67136 10.8351 1.10974L8.20581 7.39234L1.56162 8.31016C0.571717 8.44795 -0.119067 9.36221 0.0187237 10.3521C0.0779412 10.7775 0.28651 11.168 0.607185 11.4537L5.61311 15.9177L4.58454 22.9225C4.47312 23.6338 4.79981 24.3425 5.41306 24.7197C6.01769 25.1063 6.79542 25.0919 7.38524 24.6831L12.5001 21.2047L17.6149 24.6866C18.4402 25.2468 19.5634 25.0318 20.1237 24.2065C20.3782 23.8314 20.4825 23.3744 20.4157 22.9261L19.3871 15.9185L24.3931 11.4545C25.1393 10.7897 25.2052 9.64569 24.5402 8.89951ZM17.839 14.9097C17.6151 15.1098 17.5067 15.409 17.5506 15.7061L18.622 23.2056L13.0045 19.3862C12.7017 19.1804 12.3039 19.1804 12.001 19.3862L6.3505 23.1823L7.44953 15.7025C7.49222 15.4066 7.38393 15.109 7.16113 14.9097L1.80435 10.0815L8.95388 9.0949C9.26691 9.05174 9.53376 8.84631 9.65559 8.55477L12.5107 1.78561C12.514 1.78959 12.5164 1.79414 12.5179 1.799L15.3436 8.55122C15.4655 8.84275 15.7323 9.04818 16.0453 9.09134L23.2038 10.1216L17.839 14.9097Z"
                fill="black"
              />
            </svg>

            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={s.active}
            >
              <path
                d="M24.9183 9.57347C24.7177 8.89661 24.1372 8.40148 23.4371 8.3102L16.793 7.39239L14.1638 1.10987C13.7799 0.190281 12.7232 -0.243958 11.8036 0.139905C11.3653 0.322944 11.0167 0.671444 10.8337 1.10982L8.2045 7.39234L1.56039 8.31015C0.570449 8.44799 -0.120326 9.36219 0.017515 10.3521C0.0767318 10.7775 0.285298 11.1679 0.605969 11.4537L5.61183 15.9176L4.58333 22.9224C4.4719 23.6337 4.79859 24.3424 5.41184 24.7196C6.01646 25.1062 6.79418 25.0918 7.38399 24.683L12.4988 21.2047L17.6135 24.6865C18.4389 25.2467 19.562 25.0317 20.1222 24.2064C20.3768 23.8313 20.481 23.3743 20.4142 22.926L19.3857 15.9185L24.3916 11.4546C24.9231 10.9865 25.1295 10.2495 24.9183 9.57347Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={s.title}>Желания</div>
        </NavLink>
      </div>
      <div>
        <NavLink
          to={`/${user?.nickname}/friends`}
          className={({ isActive }) =>
            `${s.navLink} ${s.notification_link} ${isActive && s.active}`
          }
        >
          <div className={`${s.icon} ${s.friends}`}>
            <svg
              width="25"
              height="23"
              viewBox="0 0 25 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={s.not_active}
            >
              <path
                d="M20.1691 10.1713C21.6593 9.19993 22.6465 7.51898 22.6465 5.61125C22.6465 2.61195 20.2065 0.171875 17.2071 0.171875C15.1995 0.171875 13.4424 1.26536 12.5 2.88794C11.5576 1.26536 9.80072 0.171875 7.79305 0.171875C4.79374 0.171875 2.35348 2.61195 2.35348 5.61125C2.35348 7.51898 3.34072 9.19993 4.83093 10.1713C1.99871 11.3396 0 14.13 0 17.379V20.1246C0 21.6101 1.2085 22.8184 2.69375 22.8184H22.3063C23.7917 22.8184 25 21.6101 25 20.1248V17.379C25 14.13 23.0013 11.3396 20.1691 10.1713ZM17.2071 1.63672C19.3987 1.63672 21.1817 3.41971 21.1817 5.61125C21.1817 7.80299 19.3987 9.58598 17.2071 9.58598C15.0154 9.58598 13.2324 7.80299 13.2324 5.61125C13.2324 3.41971 15.0154 1.63672 17.2071 1.63672ZM12.4973 11.1704C11.9656 10.7664 11.3808 10.4294 10.7552 10.1713C11.4706 9.70499 12.0701 9.075 12.5 8.33495C12.9297 9.07462 13.5288 9.70423 14.2437 10.1706C13.624 10.4254 13.0369 10.7607 12.4973 11.1704ZM3.81832 5.61125C3.81832 3.41971 5.60131 1.63672 7.79305 1.63672C9.98459 1.63672 11.7676 3.41971 11.7676 5.61125C11.7676 7.80299 9.98459 9.58598 7.79305 9.58598C5.60131 9.58598 3.81832 7.80299 3.81832 5.61125ZM12.1077 21.3536H2.69375C2.01607 21.3536 1.46484 20.8023 1.46484 20.1248V17.379C1.46484 13.8895 4.30374 11.0508 7.79305 11.0508C11.2823 11.0508 14.1212 13.8897 14.1212 17.379V20.1248C14.1212 20.8023 13.5698 21.3536 12.8923 21.3536H12.1077ZM23.5352 20.1248C23.5352 20.8023 22.9839 21.3536 22.3063 21.3536H15.2882C15.4781 20.9849 15.5861 20.5673 15.5861 20.1248V17.379C15.5861 15.3843 14.8323 13.5624 13.5954 12.1826C14.6502 11.4487 15.9073 11.0508 17.2071 11.0508C20.6964 11.0508 23.5352 13.8895 23.5352 17.379V20.1248Z"
                fill="black"
              />
            </svg>
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={s.active}
            >
              <path
                d="M21.5923 11.9404C20.3989 12.924 18.8707 13.5157 17.2071 13.5157C16.6313 13.5157 16.0717 13.4448 15.5367 13.3114C16.4934 14.7683 17.0509 16.5097 17.0509 18.3791V21.1247C17.0509 22.1516 16.6759 23.0921 16.0568 23.8184H22.3064C23.7917 23.8184 25 22.6101 25 21.1247V18.3791C25 15.7069 23.6478 13.3448 21.5923 11.9404Z"
                fill="black"
              />
              <path
                d="M7.79305 12.0508C10.7924 12.0508 13.2324 9.61075 13.2324 6.61144C13.2324 3.61195 10.7924 1.17188 7.79305 1.17188C4.79374 1.17188 2.35367 3.61195 2.35367 6.61144C2.35367 9.61075 4.79374 12.0508 7.79305 12.0508Z"
                fill="black"
              />
              <path
                d="M17.2069 12.0508C20.2062 12.0508 22.6465 9.61075 22.6465 6.61144C22.6465 3.61195 20.2062 1.17188 17.2069 1.17188C15.756 1.17188 14.4361 1.74294 13.4597 2.672C14.2393 3.79009 14.6972 5.14812 14.6972 6.61144C14.6972 8.07457 14.2393 9.4326 13.4597 10.5507C14.4361 11.4798 15.756 12.0508 17.2069 12.0508Z"
                fill="black"
              />
              <path
                d="M12.1782 11.9404C10.985 12.924 9.45683 13.5157 7.79305 13.5157C6.12926 13.5157 4.6011 12.9242 3.40767 11.9404C1.35212 13.3448 0 15.7069 0 18.3791V21.1247C0 22.6101 1.20831 23.8184 2.69375 23.8184H12.8922C14.3776 23.8184 15.5859 22.6101 15.5859 21.1247V18.3791C15.5859 15.7069 14.234 13.3448 12.1782 11.9404Z"
                fill="black"
              />
            </svg>

            {requestCount ? (
              <span className={s.notification_count}>
                <span>{requestCount}</span>
              </span>
            ) : (
              ''
            )}
          </div>
          <div className={s.title}>Друзья</div>
        </NavLink>
      </div>
    </div>
  )
}

export default React.memo(FooterAuth)
