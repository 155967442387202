import { useInfiniteQuery } from '@tanstack/react-query'
import apiClient from 'application/data/apiClient'
import { Payment } from 'application/domain/entity/payment/Payment'
import Loader from 'application/presentation/common/Loader'
import React, { useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import UserDonatesEmpty from './UserDonatesEmpty'
import UserDonatesItem from './UserDonatesItem'
import UserDonateSpinner from './UserDonateSpinner'

type DonatesResponse = {
  current_page: number
  data: ReadonlyArray<Payment>
  first_page_url: string
  from: number
  last_page: number
  last_page_url: string
  next_page_url: string | null
  path: string
  per_page: number
  prev_page_url: string | null
  to: number
  total: number
}

const fetchDonatesOut = async ({
  pageParam = '/api/v1/payment?outcome=true',
}): Promise<DonatesResponse> => {
  const { data } = await apiClient.get(pageParam)

  return data
}
const UserDonatesOut = (): React.ReactElement => {
  const {
    data: donatesData,
    hasNextPage,
    fetchNextPage,
    isLoading,
  } = useInfiniteQuery(['donatesOut'], fetchDonatesOut, {
    getNextPageParam: (lastPage) => lastPage.next_page_url,
  })

  const data = useMemo(() => {
    return donatesData ? donatesData.pages?.flatMap((page: any) => page.data) : []
  }, [donatesData])

  if (isLoading) {
    return <UserDonateSpinner />
  }

  return data.length ? (
    <InfiniteScroll
      dataLength={data.length}
      next={fetchNextPage}
      style={{ display: 'flex', flexDirection: 'column' }}
      hasMore={hasNextPage || false}
      loader={<Loader color="#ff3e6c" size={32} />}
      scrollableTarget="page_content"
    >
      {data.map((donate: any) =>
        donate && donate.type !== 'down' ? (
          <UserDonatesItem key={donate.id} donate={donate} type="out" />
        ) : (
          <></>
        ),
      )}
    </InfiniteScroll>
  ) : (
    <UserDonatesEmpty type="out" />
  )
}

export default React.memo(UserDonatesOut)
