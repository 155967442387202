import React from 'react'
import { Title } from 'react-head'

type Props = {
  children?: string
}

export const MetaTitle = ({ title }: { title: string }) => {
  return <Title>{title}</Title>
}

export const MetaTitleProduct = ({ children }: Props): React.ReactElement => {
  return <Title>{children} в подарок на Wishmen</Title>
}

export const MetaTitleUser = ({ children }: Props): React.ReactElement => {
  return <Title>{children} — список желаний на Wishmen</Title>
}

export const MetaTitleWish = ({ children }: Props): React.ReactElement => {
  return <Title>{children} — Wishmen</Title>
}

export const MetaTitleFriends = ({ children }: Props): React.ReactElement => {
  return <Title>{children} — друзья на Wishmen</Title>
}

export const MetaTitleMain = (): React.ReactElement => {
  return <Title>Главная страница Wishmen</Title>
}

export const MetaTitleCatalog = (): React.ReactElement => {
  return <Title>Каталог подарков Wishmen</Title>
}

export const MetaTitleCollections = (): React.ReactElement => {
  return <Title>Подборки и идеи для подарков Wishmen</Title>
}

export const MetaTitleFeed = (): React.ReactElement => {
  return <Title>Моя лента</Title>
}

export const MetaTitleNotifications = (): React.ReactElement => {
  return <Title>Мои уведомления</Title>
}

export const MetaTitleSettings = (): React.ReactElement => {
  return <Title>Мои настройки</Title>
}

export const MetaTitleOrder = (): React.ReactElement => {
  return <Title>Мои заказы</Title>
}

export const MetaTitlePartnersInfo = (): React.ReactElement => {
  return <Title>Партнеры Wishmen</Title>
}

export const MetaTitleSearch = (): React.ReactElement => {
  return <Title>Поиск Wishmen</Title>
}

export const MetaTitleGuaranty = (): React.ReactElement => {
  return <Title>Политика гарантий</Title>
}

export const MetaTitleArticle = ({ children }: Props): React.ReactElement => {
  return <Title>{children}</Title>
}

export const MetaTitleContacts = (): React.ReactElement => {
  return <Title>Контакты</Title>
}

export const MetaTitleFaq = (): React.ReactElement => {
  return <Title>Вопросы и ответы</Title>
}

export const MetaTitleBalance = (): React.ReactElement => {
  return <Title>Мой баланс</Title>
}

export const MetaTitleReferral = (): React.ReactElement => {
  return <Title>Партнерская программа Wishmen</Title>
}

export const MetaTitleSanta = (): React.ReactElement => {
  return <Title>Тайный Cанта 2022, играй по всей России!</Title>
}
