import React, { useContext } from 'react'
import cn from 'classnames'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Moment from 'react-moment'
import _find from 'lodash/find'
import { Order, OrderStatusList } from 'application/domain/entity/order/Order'

import s from './UserOrdersItem.module.scss'
import { statusColorMap, statusTextMap } from './userOrderConstants'
import UserOrderBottomSheet from './UserOrderBottomSheet'

type Props = {
  order: Order
  t: any
}

const UserOrdersItem = ({ order, t }: Props): React.ReactElement => {
  const status = _find(OrderStatusList, (item) => item.status === order.status)
  const navigate = useNavigate()
  const params = useParams<{ id?: string }>()
  const { pathname } = useLocation()
  const bottomSheetOpened = typeof params.id === 'string' && parseInt(params.id, 10) === order.id
  const isCompleted = pathname.indexOf('/orders/completed') !== -1

  const handleBottomSheetClose = () => {
    navigate(-1)
    // if (isPreviousLocationWithinApp()) {
    //   navigate(-1)
    // } else {
    //   navigate(pathname.replace(/\/$/g, '').split('/').slice(0, -1).join('/'))
    // }
  }

  const body = (
    <>
      <span className={s.details}>
        <span className={s.title}>
          {t('orders_page.order')} #{order.id} {t('orders_page.from')}{' '}
          <Moment format="DD MMMM YYYY" locale="ru" date={order.created_at} />
        </span>
        <span className={cn(s.status, status?.color)}>{status?.title || ''}</span>
        <span className={s.deliveryDay}>
          {t('orders_page.delivery_date')}:{' '}
          <Moment format="DD MMMM YYYY" locale="ru" date={order.delivery_date} />
        </span>
      </span>
      <span className={s.photo}>
        {order.photo && <img src={order.photo} alt={`Превью заказа #${order.id}`} />}
      </span>
    </>
  )

  return (
    <>
      <Link className={s.item} to={`/orders/${isCompleted ? 'completed/' : ''}${order.id}`}>
        {body}
      </Link>
      <UserOrderBottomSheet
        orderId={order.id}
        opened={bottomSheetOpened}
        onClose={handleBottomSheetClose}
      />
    </>
  )
}

export default React.memo(UserOrdersItem)
