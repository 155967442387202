import React from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Time } from 'application/presentation/common/uiComponents'
import { Payment } from 'application/domain/entity/payment/Payment'

import s from './UserDonatesItemView.module.scss'

type Props = {
  donate: Payment
  type: 'in' | 'out'
  short?: boolean
}

const UserDonatesItemView = ({ donate, type, short = false }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const isOut = type !== 'in'
  let amountClassName: string | undefined
  if (donate.amount >= 1000) {
    // TODO: добавить другие варианты
    amountClassName = s.donateAmount_red
  }

  return (
    <span className={cn({ [s.item]: true, [s.viewMobile]: true })}>
      {donate.user && (
        <span className={s.userpic}>
          <img src={donate.user.photo} alt={donate.user.name} />
        </span>
      )}
      <span className={s.donate}>
        {!short ? (
          <span className={s.donateTitle}>
            {isOut
              ? `${t('donates_page.you_send')} ${
                  donate.wish && donate.wish.user
                    ? donate.wish.user.name
                    : t('donates_page.undefined')
                }`
              : `${t('donates_page.from')} ${donate.user?.name ?? t('donates_page.undefined')}`}
          </span>
        ) : (
          <>
            <span className={s.donateUsername}>
              {isOut
                ? donate.wish && donate.wish.user
                  ? donate.wish.user.name
                  : t('donates_page.undefined')
                : donate.user?.name}
              {/* {donate.user?.name ?? t('donates_page.undefined')} */}
            </span>
            {isOut ? (
              donate.wish && donate.wish.user ? (
                <span className={s.donateUserTag}>@{donate.wish.user.nickname}</span>
              ) : (
                ''
              )
            ) : donate.user ? (
              <span className={s.donateUserTag}>@{donate.user.nickname}</span>
            ) : (
              ''
            )}
          </>
        )}
        {donate.comment && <span className={s.donateComment}>{donate.comment}</span>}
        <span className={cn(s.donateAmount, amountClassName)}>
          {isOut ? '-' : '+'} {donate.amount} {t('general.chars.rub')}
        </span>
        <span className={s.donateType}>
          {isOut ? t('donates_page.out_to_wish') : t('donates_page.in_to_wish')}
        </span>
        {!short && (
          <span className={s.createdAt}>
            <Time time={donate.created_at} />
          </span>
        )}
      </span>
      {donate.wish && (
        <span className={s.preview}>
          <img src={donate.wish.photo} alt={donate.wish.name} />
        </span>
      )}
    </span>
  )
}

export default React.memo(UserDonatesItemView)
