import React, { useMemo } from 'react'
import i18next from 'i18next'
import { useLocation } from 'react-router-dom'
import { Button } from 'application/presentation/common/ActionComponents'
import ToastNotify from 'application/presentation/app/ToastNotify'

import s from './AddWishPage.module.scss'

type Props = {
  resultData: any
  isIdea: boolean
}

const ResultStage = ({ resultData, isIdea }: Props) => {
  const { pathname } = useLocation()
  const ideaUrl = useMemo(() => {
    return `${window.location.origin}/${
      resultData && resultData.target_user ? resultData && resultData.target_user.nickname : ''
    }/ideas/${resultData.id}`
  }, [resultData])
  return (
    <div className={s.add_result}>
      <div className={s.result}>
        <div className={s.success_img}>
          <img loading="lazy" src="/images/icons/red-big-accept.svg" alt="" />
        </div>
        {isIdea ? (
          <div className={s.success_title}>
            Поздравляем, ваша идея{' '}
            {`для ${
              resultData && resultData.target_user
                ? resultData.target_user.name
                  ? resultData.target_user.name
                  : resultData.target_user.nickname
                  ? resultData.target_user.nickname
                  : ''
                : ''
            }`}{' '}
            {'\n'} успешно добавлена!
          </div>
        ) : (
          <div
            className={s.success_title}
            dangerouslySetInnerHTML={{
              __html: i18next.t('edit_wish_mw.wish_added.title'),
            }}
          />
        )}
        {isIdea ? (
          <>
            <div className={s.success_subtitle}>
              Отправьте ссылку на идею вашим друзьям. Вместе вы соберете сумму на подарок намного
              быстрее!
            </div>
            <div className={s.clipboard_container}>
              <div
                className={s.input_wrapper}
                onClick={() => {
                  navigator.clipboard.writeText(ideaUrl)
                  ToastNotify('Ссылка скопирована')
                }}
              >
                <div className={s.input_copy}>{ideaUrl}</div>
                <img src="/images/icons/red-copy-icon.svg" alt="" className={s.img} />
              </div>
            </div>
          </>
        ) : (
          <div className={s.success_subtitle}>
            {i18next.t('edit_wish_mw.wish_added.wish')}{' '}
            <span className={s.wish_name}>
              {resultData && resultData.name ? resultData.name : ''}
            </span>{' '}
            {i18next.t('edit_wish_mw.wish_added.added')}
            <br />
            {i18next.t('edit_wish_mw.wish_added.in_category')}{' '}
            <span className={s.categ_name}>
              {resultData && resultData.category ? resultData.category.label : ''}
            </span>
          </div>
        )}

        <Button
          title="Поделиться"
          color="white"
          isLink
          to={`${pathname}?sharetype=${isIdea ? 'idea' : 'wish'}&nickname=${
            resultData && resultData.target_user ? resultData.target_user.nickname : ''
          }&wish_id=${resultData ? resultData.id : 0}#share`}
          onClick={() => {}}
          containerClassName={s.btn_container}
          className={s.result_btn}
        />
        <Button
          title={isIdea ? 'Перейти к идеям' : i18next.t('general.open_wishlist') || ''}
          color="red"
          isLink
          containerClassName={s.btn_container}
          className={s.result_btn}
          to={
            isIdea
              ? `/${
                  resultData && resultData.target_user ? resultData.target_user.nickname : ''
                }/ideas`
              : '/wishlist'
          }
        />
      </div>
    </div>
  )
}

export default React.memo(ResultStage)
