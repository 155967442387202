import React from 'react'
import { ArticleLayout } from 'application/presentation/common/ArticleLayout'
import { Menu, MenuItem } from 'application/presentation/common/Menu'

import { HeaderMobileTitle } from '../Header'

const AboutMenu = (): React.ReactElement => {
  return (
    <>
      <HeaderMobileTitle.Source>Документация</HeaderMobileTitle.Source>
      <ArticleLayout>
        <Menu>
          <MenuItem to="/about">О проекте</MenuItem>
          <MenuItem to="/for_partners">Для партнеров</MenuItem>
          <MenuItem to="/faq">Вопросы и ответы</MenuItem>
          <MenuItem to="/contacts">Контакты</MenuItem>
        </Menu>
      </ArticleLayout>
    </>
  )
}

export default React.memo(AboutMenu)
