import { fetchOrder, fetchOrdersList } from 'application/data/api/order'
import { OrderResponse, OrdersListResponse } from 'application/domain/response/response_types'
import { useQuery } from '@tanstack/react-query'

export const getOrder = async (id: number) => fetchOrder(id)

export const useOrder = (id: number, config?: any) => {
  const query = useQuery<OrderResponse>(['order', id], () => fetchOrder(id), {
    enabled: id ? true : false,
    ...config,
  })
  return { ...query, data: query.data ? query.data[0] : undefined }
}

export const getOrdersList = async () => fetchOrdersList()

export const useOrdersList = (token?: string, config?: any) => {
  const query = useQuery<OrdersListResponse>(['orders', token], fetchOrdersList, {
    enabled: token ? true : false,
    ...config,
  })
  return query
}
