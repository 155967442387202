import React, { useMemo } from 'react'

import s from './TopBlock.module.scss'

type Props = {
  data: any
  doSearchInCategory: Function
}

const TopBlock = ({ data, doSearchInCategory }: Props) => {
  const totalProducts = useMemo(() => {
    let products = 0
    data.map((item: any) => {
      products += item.total
      return true
    })
    return products
  }, [data])
  const categLength = useMemo(() => {
    return data.length ? data.length : 0
  }, [data])
  return (
    <div className={s.container}>
      <div className={s.info}>
        Найдено {totalProducts} результатов <b>в {categLength} категориях</b>
      </div>
      <div className={s.list}>
        {data && data.length ? (
          data.map((item: any) => (
            <div
              className={s.categ_item}
              key={`category_${item.id}`}
              onClick={() => doSearchInCategory(item.id, item.name)}
            >
              <div>{item.name}</div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
      <div className={s.separator} />
    </div>
  )
}

export default React.memo(TopBlock)
