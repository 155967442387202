import { HeaderMobileTitle } from 'application/presentation/common/Header'
import Loader from 'application/presentation/common/Loader'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { HeaderFooterContext } from 'application/presentation/app/HeaderFooter'
import { SendCommentField } from 'application/presentation/common/ActionComponents'
import { PageContent } from 'framework7-react'
import { useAppSelector } from 'application/domain/store/hooks'
import { useParams } from 'react-router-dom'
import useWish from 'application/domain/useCase/wish/getWish'
import { useComments } from 'application/domain/useCase/comments/getComments'
import { LikeCommentMutation } from 'application/domain/useCase/comments/commentsActions'

import CommentItem from './CommentItem'
import s from './CommentsPage.module.scss'

const emojiMap = ['⚡', '❤️', '😍', '⚡', '❤️', '😍']

const CommentsPage = () => {
  const inputRef = useRef<any>(null)
  const [commentText, setCommentText] = useState<string>('')
  const [commentPlayText, setCommentPlayText] = useState<string>('')
  const FooterContext = useContext(HeaderFooterContext)
  const { user } = useAppSelector(({ userReducer }) => ({ user: userReducer.user }))
  const params = useParams<{ wishid?: any }>()
  const wishid = params && params.wishid ? params.wishid : 0
  const commentsRequest = useComments(wishid ? wishid : 0)

  const { data: wishData } = useWish(wishid ? wishid : 0)

  const likeCommentMut = LikeCommentMutation(wishid)
  const { isLoading, data: commentsData, fetchNextPage, hasNextPage } = commentsRequest

  useEffect(() => {
    FooterContext?.showFooter(false)
    return () => FooterContext?.showFooter(true)
  }, [FooterContext])

  const comments = useMemo(() => {
    return commentsData && commentsData.pages
      ? commentsData.pages.flatMap((page: any) => page.data)
      : []
  }, [commentsData])

  const likeComment = useCallback(
    (comment_id: string, type: string, like: boolean) => {
      likeCommentMut.mutate({ comment_id, type, like })
    },
    [likeCommentMut],
  )

  const answerComment = useCallback(
    (nickname: string, user_id: number) => {
      if (nickname) {
        const reg = /@\S+/
        setCommentText(
          `@[${nickname}](0) ${commentText.replace(reg, '').replace(/ +/g, ' ').trim()}`,
        )
        setCommentPlayText(
          `@${user_id.toString()} ${commentPlayText.replace(reg, '').replace(/ +/g, ' ').trim()}`,
        )
        if (inputRef && inputRef.current) inputRef.current.focus()
      }
    },
    [commentPlayText, commentText, inputRef],
  )

  const addEmoji = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (code: any, inputRef?: any) => {
      const text = commentText + code
      setCommentText(text)
      setCommentPlayText(`${commentPlayText}${code}`)
      if (inputRef && inputRef.current) inputRef.current.focus()
    },
    [commentPlayText, commentText, setCommentPlayText, setCommentText],
  )

  const mentionedUserData = useMemo(() => {
    let mentUser: any = []
    if (comments && comments.length && wishData && wishData.user) {
      mentUser.push({
        id: wishData.user?.id,
        name: wishData.user!.nickname,
        photo: wishData.user!.photo,
      })
      comments.map((item: any) => {
        if (item && item.user) {
          const find = mentUser.filter((item2: any) => {
            return item2.id === item.user.id
          })
          if (!find.length) {
            mentUser.push({
              id: item.user.id,
              name: item.user.nickname,
              photo: item.user.photo,
            })
          }
        }
      })
    }
    return mentUser && mentUser.length ? mentUser : []
  }, [comments, wishData])

  return (
    <PageContent className={s.container}>
      <HeaderMobileTitle.Source>Комментарии</HeaderMobileTitle.Source>
      <div className={s.title}>Комментарии и истории</div>
      <div className={s.comments_list}>
        {isLoading ? (
          <Loader size={16} />
        ) : !comments || comments.length === 0 ? (
          <div className={s.empty_list}>У желания нет комментариев/донатов</div>
        ) : (
          <InfiniteScroll
            dataLength={comments.length}
            next={fetchNextPage}
            style={{ display: 'flex', flexDirection: 'column', paddingBottom: '2rem' }}
            hasMore={!!hasNextPage}
            loader={
              <div className="pagination_loader">
                <img loading="lazy" src="/images/loader.gif" alt="" />
              </div>
            }
            scrollableTarget="comments_list"
          >
            {comments.map((item: any, i: number) =>
              item ? (
                <CommentItem
                  item={item}
                  key={item.id ? `comment${item.id}` : `comment_${i}`}
                  likeComment={likeComment}
                  answerComment={answerComment}
                  //@ts-ignore
                  user={user}
                />
              ) : (
                <></>
              ),
            )}
          </InfiniteScroll>
        )}
      </div>
      <div className={s.input_comment}>
        <div className={s.emoji_block}>
          {emojiMap.map((item: string, i: number) => (
            <div
              className={s.emoji_item}
              data-code={`${item}`}
              onClick={() => {
                addEmoji(item)
              }}
              key={`emoji_${i}`}
            >
              {item}
            </div>
          ))}
        </div>
        <SendCommentField
          mentionedUserData={mentionedUserData}
          mentionsRef={inputRef}
          wish_id={wishid}
          {...{ commentText, commentPlayText, setCommentPlayText, setCommentText }}
        />
      </div>
    </PageContent>
  )
}

export default React.memo(CommentsPage)
