export type Order = {
  id: number
  status: OrderStatus
  created_at: string
  comment: string | null
  delivery_date: string
  photo: string
  unpaid_amount: number
  address: string
  amocrm_id: number | null
  amount: number
  delivery_amount: number
  method: DeliveryMethod
  phone: string
  recipient: string
  updated_at: string
  url_amocrm: string | null
  visible: boolean
  wish_id: number
  yandex_id: number | null
  user_comment: string | null
  apps_id: any
  wish_paid_status: boolean
  wish_amount: number
  apps_paid_status: boolean
  apps_amount: number
  delivery_paid_status: boolean
}

export type DeliveryMethod = {
  address: string
  aggregator: string | null
  city: string
  delivery_date: number
  enabled: boolean
  from: string
  id: number
  link: string
  name: string
  partner_id: number | null
  price: number
  to: string
  type: string
  work_days: string
}

export type OrderList = Array<Order>

export type OrderStatus =
  | 'preliminary_order'
  | 'payment_required'
  | 'new'
  | 'in_processing'
  | 'delivery'
  | 'on_point_delivery'
  | 'processed'
  | 'confirmation'
  | 'started_delivery'
  | 'in_delivery'
  | 'error'
  | 'cancelled'
  | 'processed'
  | 'delivered'

export type OrderMethod = {
  address: string
  from: string
  id: number
  name: string
  to: string
  type: string
  work_days: string
  price: number
  delivery_date: number
  link: string
  partner_id: number | null
}

export type OrderFull = Order & {
  recipient: string
  phone: string
  wish_id: number
  updated_at: string
  address: string
  method: OrderMethod
  amount: number
  delivery_amount: number
}

export const OrderStatusList = [
  {
    id: 1,
    title: 'Желание собрано на 100%',
    info: 'Пока не изменилась цена, оформите желание. Подскажите нам, куда вам доставить его?',
    status: 'preliminary_order',
    stage: 1,
    icon: require('images/emojiIcons/congrat.png'),
    color: '#31B2FF',
  },
  {
    id: 2,
    title: 'Ожидаем оплату доставки заказа',
    info: '',
    status: 'payment_required',
    stage: 2,
    icon: require('images/emojiIcons/clock.png'),
    color: '#31B2FF',
  },
  {
    id: 3,
    title: 'Заказ создан и ожидает обработки',
    info: 'Ваш заказ успешно оформлен и находится в очереди. Как только он будет принят в обработку, статус заказа изменится',
    status: 'new',
    stage: 2,
    icon: require('images/emojiIcons/clock.png'),
    color: '#31B2FF',
  },
  {
    id: 4,
    title: 'Заказ принят к обработке',
    info: 'Напишите нам в поддержку, если возникли вопросы',
    status: 'in_processing',
    stage: 3,
    icon: require('images/emojiIcons/light.png'),
    color: '#31B2FF',
  },
  {
    id: 5,
    title: 'Товар заказан, выполняется доставка в город назначения',
    info: 'Доставка в среднем занимает от 5 до 7 дней',
    status: 'delivery',
    stage: 3,
    icon: require('images/emojiIcons/car.png'),
    color: '#AA11FF',
  },
  {
    id: 6,
    title: 'В пункте cамовывоза',
    info: 'Ваш заказ находится: г. Чебоксары, ул. Советская, 72 до 10.01.2022',
    status: 'on_point_delivery',
    stage: 4,
    icon: require('images/emojiIcons/gift.png'),
    color: '#AA11FF',
  },
  {
    id: 7,
    title: 'Желание исполнено ✌️',
    info: '',
    status: 'processed',
    stage: 5,
    icon: require('images/emojiIcons/correct.png'),
    color: '#AA11FF',
  },
  {
    id: 8,
    title: 'Ожидает подтверждения начала заказа',
    info: 'Подтвердите заказ и мы начнем обработку',
    status: 'confirmation',
    stage: 2,
    icon: require('images/emojiIcons/clock.png'),
    color: '#31B2FF',
  },
  {
    id: 9,
    title: 'Статус "Клиент готов к создания заявки" в агрегаторе на доставку',
    info: '',
    status: 'started_delivery',
    stage: 4,
    icon: require('images/emojiIcons/light.png'),
    color: '#AA11FF',
  },
  {
    id: 10,
    title: 'Статус для создания заявки в агрегаторе на доставку (Яндекс)',
    info: '',
    status: 'in_delivery',
    stage: 4,
    icon: require('images/emojiIcons/light.png'),
    color: '#AA11FF',
  },
  {
    id: 11,
    title: 'Ошибка в заказе',
    info: '',
    status: 'error',
    stage: 0,
    icon: require('images/emojiIcons/wrong.png'),
    color: '#31B2FF',
  },
  {
    id: 12,
    title: 'Заказ отменен',
    info: '',
    status: 'cancelled',
    stage: 0,
    icon: require('images/emojiIcons/error.png'),
    color: '#747474',
  },
  {
    id: 13,
    title: 'Желание исполнено ✌️',
    info: '',
    status: 'delivered',
    stage: 5,
    icon: require('images/emojiIcons/correct.png'),
    color: '#AA11FF',
  },
]
