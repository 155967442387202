/* eslint-disable no-plusplus */
/* eslint-disable no-new-object */
import apiClient from 'application/data/apiClient'
// @ts-ignore
import { Sortable, Plugins } from '@shopify/draggable'

const DragAndDrop = () => {
  const containerSelector = '.list'
  const containers = document.querySelectorAll(containerSelector)

  if (containers.length === 0) {
    return false
  }

  const sortable = new Sortable(containers, {
    draggable: '.wish-cart',
    mirror: {
      constrainDimensions: true,
      xAxis: false,
    },
    delay: 500,
    swapAnimation: {
      duration: 200,
      easingFunction: 'ease-in-out',
      horizontal: false,
    },
    plugins: [Plugins.SwapAnimation],
  })
  // Сортировка
  sortable.on('sortable:stop', () => {
    let j = 0
    const priorityArray: any[] = []
    let c = 1

    $('.list').each((i, item) => {
      c = 1
      $(item)
        .find('.wish-cart')
        .each((_: number, item2: any) => {
          if (!$(item2).hasClass('draggable--original') && !$(item2).hasClass('draggable-mirror')) {
            priorityArray[j] = {
              id: $(item2).find('.item').attr('data-id'),
              order: c,
              category: $(item2).closest('.mycategory_section').attr('data-id'),
            }
            c++
            j++
          }
        })
    })

    let result = new Object()
    if ($('.main_wish_container')) {
      priorityArray.push({
        id: $('.main_wish_container').attr('data-id'),
        order: 0,
        category: $('.main_wish_container').attr('data-category'),
      })
    }
    result = { orders: priorityArray }
    apiClient({
      method: 'POST',
      data: result,
      url: '/api/v1/wish/order',
    }).then((response) => {
      //   console.log(response)
    })
  })
  return true
}

export default DragAndDrop
