/* eslint-disable react/no-array-index-key */
import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import ContentLayout from 'application/presentation/common/ContentLayout'
import Loader from 'application/presentation/common/Loader'
import React, { useEffect, useMemo } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useSelector } from 'react-redux'
import nProgress from 'nprogress'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import {
  MetaDescriptionNotifications,
  MetaOG,
  MetaTitleNotifications,
} from 'application/presentation/app/MetaTags'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'application/domain/store/hooks'
import { useNotifications } from 'application/domain/useCase/notifications/getNotifications'

import s from './NotificationsPage.module.scss'
import NotificationItem from './NotificationItem'

const NotificationsPage = () => {
  const { t } = useTranslation()
  const { user, token } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
    token: userReducer.token,
  }))
  const { data, isLoading, hasNextPage, fetchNextPage } = useNotifications(token || '')

  const notifications = useMemo(() => {
    return data && data.pages ? data.pages.flatMap((page: any) => page.data) : []
  }, [data])

  useEffect(() => {
    apiClient.post('/api/v1/notification/all').then(() => {
      queryClient.invalidateQueries(['newNotifications'])
    })
  }, [])

  if (isLoading) {
    nProgress.start()
    return (
      <>
        <HeaderMobileTitle.Source>Уведомления</HeaderMobileTitle.Source>
        <MetaTitleNotifications />
        <MetaDescriptionNotifications />
        <MetaOG
          title={t('notifications_page.meta.title') || ''}
          description={t('notifications_page.meta.description') || ''}
        />
      </>
    )
  }
  nProgress.done()

  return (
    <ContentLayout>
      <HeaderMobileTitle.Source>Уведомления</HeaderMobileTitle.Source>
      <MetaTitleNotifications />
      <MetaDescriptionNotifications />
      <MetaOG
        title={t('notifications_page.meta.title') || ''}
        description={t('notifications_page.meta.description') || ''}
      />
      <InfiniteScroll
        className={s.history_content_block}
        dataLength={notifications.length}
        next={() => fetchNextPage()}
        style={{ display: 'flex', flexDirection: 'column' }}
        hasMore={!!hasNextPage}
        loader={
          <div className="pagination_loader">
            <Loader size={16} />
          </div>
        }
        scrollableTarget="page_content"
      >
        {notifications.map((item: any, i: number) => {
          return <NotificationItem key={`notification_${i}`} data={item} user={user} />
        })}
      </InfiniteScroll>
    </ContentLayout>
  )
}

export default React.memo(NotificationsPage)
