import { fetchProduct } from 'application/data/api/product'
import { Product } from 'application/domain/entity/product/Product'
import { ShopCategory } from 'application/domain/entity/shop/ShopCategory'
import { useQuery } from '@tanstack/react-query'

export const getProduct = async (prod_id: number) => {
  const data = await fetchProduct(prod_id)
  return data
}

export const useProduct = (prod_id: number, config?: any) => {
  const conf = config || {
    enabled: prod_id ? true : false,
    retry: false,
    refetchInterval: 10000,
  }
  const query = useQuery<{ 0: Product; category: ShopCategory }>(
    ['product', prod_id],
    () => fetchProduct(prod_id),
    conf,
  )

  return query
}
