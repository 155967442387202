import React from 'react'
import ContentLayout from 'application/presentation/common/ContentLayout'
import { HeaderMobileTitle } from 'application/presentation/common/Header'

import s from './BonusPage.module.scss'

const BonusPage = () => {
  return (
    <ContentLayout>
      <HeaderMobileTitle.Source>О бонусах</HeaderMobileTitle.Source>
      <div className={s.container}>
        <div className={s.item}>
          <div className={s.left}>
            <div className={s.procent}>0,5%</div>
            <div className={s.title}>реферальное вознагрождение</div>
          </div>
          <div className={s.right}>
            В случае если вы не знаете адрес получателя и клиент не сможет забрать товар в доступном
            городе, он может удалить этот подарок и выбрать подходящий, доступный в его городе.
          </div>
        </div>
        <div className={s.item}>
          <div className={s.left}>
            <div className={s.procent}>1%</div>
            <div className={s.title}>от суммы желания за поделиться в соцсетях</div>
          </div>
          <div className={s.right}>
            В случае если вы не знаете адрес получателя и клиент не сможет забрать товар в доступном
            городе, он может удалить этот подарок и выбрать подходящий, доступный в его городе.
          </div>
        </div>
        <div className={s.item}>
          <div className={s.left}>
            <div className={s.procent}>1%</div>
            <div className={s.title}>от суммы желания за добавление описания</div>
          </div>
          <div className={s.right}>
            В случае если вы не знаете адрес получателя и клиент не сможет забрать товар в доступном
            городе, он может удалить этот подарок и выбрать подходящий, доступный в его городе.
          </div>
        </div>
        <div className={s.item}>
          <div className={s.left}>
            <div className={s.procent}>1%</div>
            <div className={s.title}>от суммы исполненной твоей идеи для друга</div>
          </div>
          <div className={s.right}>
            В случае если вы не знаете адрес получателя и клиент не сможет забрать товар в доступном
            городе, он может удалить этот подарок и выбрать подходящий, доступный в его городе.
          </div>
        </div>
        <div className={s.item}>
          <div className={s.left}>
            <div className={s.procent}>1%</div>
            <div className={s.title}>от суммы доната для друга</div>
          </div>
          <div className={s.right}>
            В случае если вы не знаете адрес получателя и клиент не сможет забрать товар в доступном
            городе, он может удалить этот подарок и выбрать подходящий, доступный в его городе.
          </div>
        </div>
      </div>
    </ContentLayout>
  )
}

export default React.memo(BonusPage)
