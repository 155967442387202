import { useMutation } from '@tanstack/react-query'
import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import { RootState } from 'application/domain/store/reduxStore'
import _concat from 'lodash/concat'
import { useSelector } from 'react-redux'

type AddCategoryType = {
  name: string
  permission: {
    users: any
    type: 'deny' | 'allow'
  }
}

const addCategoryRequest = async (dat: any) => {
  const { data } = await apiClient({
    url: `/api/v1/wish/category/add`,
    method: 'POST',
    data: dat,
  })
  return data
}

export const AddCategoryMutation = (callback?: () => void) => {
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const mutation = useMutation(
    ({ name, permission }: AddCategoryType) => addCategoryRequest({ name, permission }),
    {
      onMutate: async ({ name, permission }) => {
        queryClient.cancelQueries(['profile'])
        const profilePrevData = queryClient.getQueryData<any>([
          'profile',
          user ? user.nickname : '',
        ])
        if (profilePrevData) {
          queryClient.setQueryData(['profile', user ? user.nickname : ''], {
            ...profilePrevData,
            wish: {
              ...profilePrevData.wish,
              items: _concat(profilePrevData.wish.items, {
                id: -1,
                name,
                permission,
                wish: [],
              }),
            },
          })
        }
        if (callback) {
          callback()
        }
        return { profilePrevData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(['profile', user ? user.nickname : ''], context.profilePrevData)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['profile'])
      },
    },
  )
  return mutation
}
