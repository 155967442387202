import React from 'react'
import cn from 'classnames'
import Moment from 'react-moment'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import _find from 'lodash/find'
import { OrderFull, OrderStatusList } from 'application/domain/entity/order/Order'

import s from './UserOrder.module.scss'

type Props = {
  order?: OrderFull
}

const UserOrder = ({ order }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const handleChatClick = () => {
    if (typeof window.jivo_api !== 'undefined') {
      window.jivo_api.open()
    }
  }

  // TODO: добавить вариантов под разные статусы
  const statusDetail = order ? (
    <div className={s.orderDeliveryDate}>
      {order.status === 'delivered'
        ? t('orders_page.issue_gift_date')
        : t('orders_page.planned_delivery_date')}{' '}
      <Moment format="DD MMMM YYYY" locale="ru" date={order.delivery_date} />
    </div>
  ) : null
  const status = order ? _find(OrderStatusList, (item) => item.status === order.status) : undefined

  return (
    <div className={cn({ [s.container]: true, [s.viewMobile]: true })}>
      <div className={s.title}>
        {order ? `${t('orders_page.order')} №${order.id}` : <Skeleton />}
      </div>
      <div className={s.content}>
        {order ? (
          <div className={cn(s.block, s.block_details)}>
            <div className={s.orderDetails}>
              <div className={s.orderDate}>
                {t('orders_page.from')}{' '}
                <Moment format="DD MMMM YYYY" locale="ru" date={order.created_at} />
              </div>
              <div className={cn(s.orderStatus, status && s[status.color])}>
                {status ? status.title : ''}
              </div>
              {statusDetail}
            </div>
            <div className={s.orderPhoto}>
              {order.photo && <img src={order.photo} alt={`Превью заказа #${order.id}`} />}
            </div>
          </div>
        ) : (
          <div className={cn(s.block, s.block_details)}>
            <div className={s.orderDetails}>
              <Skeleton count={3} />
            </div>
            <div className={s.orderPhoto}>
              <Skeleton height={80} width={80} />
            </div>
          </div>
        )}
        {order ? (
          <div className={cn(s.block, s.block_address)}>
            <div className={s.blockTitle}>{t('orders_page.delivery_adress')}</div>
            <div className={s.blockContent}>{order.address}</div>
            {order.method.id === 1 &&
              order.method.from &&
              order.method.to &&
              order.method.work_days && (
                <>
                  <div className={s.workTime}>
                    {t('orders_page.work_days')}: {order.method.work_days}
                  </div>
                  <div className={s.workTime}>
                    {t('orders_page.work_time')}:{' '}
                    {`${order.method.from.slice(0, -3)} - ${order.method.to.slice(0, -3)}`}
                  </div>
                </>
              )}
          </div>
        ) : (
          <div className={s.block}>
            <div className={s.blockTitle}>
              <Skeleton />
            </div>
            <div className={s.blockContent}>
              <Skeleton width="70%" />
            </div>
          </div>
        )}
        {order ? (
          <div className={cn(s.block, s.block_recipient)}>
            <div className={s.blockTitle}>{t('orders_page.recipient_info')}</div>
            <div className={s.blockContent}>
              <div>{order.recipient}</div>
              <div>
                {t('general.phone')}{' '}
                <input type="text" className="user_phone" value={order.phone} readOnly />
              </div>
            </div>
          </div>
        ) : (
          <div className={s.block}>
            <div className={s.blockTitle}>
              <Skeleton />
            </div>
            <div className={s.blockContent}>
              <Skeleton count={2} width="70%" />
            </div>
          </div>
        )}
        {order ? (
          <div className={cn(s.block, s.block_payment)}>
            <div className={s.blockTitle}>Оплата</div>
            <div className={s.blockContent}>
              <div>
                {t('orders_page.product_cost')}: <span>{order.amount}</span>{' '}
                {t('general.chars.rub')}
              </div>
              {order.method.id !== 1 && (
                <div>
                  {t('orders_page.delivery_cost')}: <span>{order.delivery_amount}</span>{' '}
                  {t('general.chars.rub')}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={cn(s.block, s.block_last)}>
            <div className={s.blockTitle}>
              <Skeleton />
            </div>
            <div className={s.blockContent}>
              <Skeleton count={2} width="70%" />
            </div>
          </div>
        )}
      </div>

      <div className={s.questionButton}>
        {order && (
          <button type="button" onClick={handleChatClick}>
            <img src="/images/icons/black-chat.svg" alt="icon" />
            <div>{t('general.ask_a_question')}</div>
          </button>
        )}
      </div>
    </div>
  )
}

export default React.memo(UserOrder)
