/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-useless-escape */
import React, { useEffect, useMemo, useState } from 'react'
import _clone from 'lodash/clone'
import nprogress from 'nprogress'
import ContentLayout from 'application/presentation/common/ContentLayout'
import { HeaderMobileRightButton, HeaderMobileTitle } from 'application/presentation/common/Header'
import Loader from 'application/presentation/common/Loader'
import { Button } from 'application/presentation/common/ActionComponents'
import {
  MetaDescriptionFriends,
  MetaOG,
  MetaTitleFriends,
} from 'application/presentation/app/MetaTags'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'application/domain/store/hooks'
import { useFriendsRequest } from 'application/domain/useCase/friends/getFriendsRequest'
import { useUser } from 'application/domain/useCase/user/getUser'
import { friendSearch } from 'application/presentation/utils/SearchFunc'
import { User } from 'application/domain/entity/user/User'

import s from './UserFriendsPage.module.scss'
import FriendItem from './components/FriendItem'
import ActionsBlock from './components/ActionsBlock'
import SearchField from './components/SearchField'
import UserFriendsBday from './UserFriendsBday'

const phoneRegExp =
  /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/

const UserFriendsPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const params = useParams<{ nickname?: string }>()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false)
  const [searchResult, setSearchResult] = useState<any>()
  const [searchVal, setSearchVal] = useState<string>('')

  const { data: requestData } = useFriendsRequest(user ? user.nickname : '')
  const requestCount = useMemo(() => {
    return requestData && requestData.incoming_request && requestData.incoming_request.length
      ? requestData.incoming_request.length
      : 0
  }, [requestData])

  const user_nickname = useMemo(() => {
    return params && params.nickname ? params.nickname : user ? user.nickname : ''
  }, [params, user])

  const { data, isLoading, isFetching, refetch } = useUser(user_nickname)

  const isMyProfile = !!(
    !params ||
    !params.nickname ||
    (params && user && params.nickname === user.nickname)
  )

  const friends = useMemo(() => {
    if (data && data.friends && data.friends.items) {
      let dat = _clone(data.friends.items)
      dat = dat.sort((a: any, b: any) => {
        if (a.name && b.name) {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
        }
        if (a.nickname && b.nickname) {
          if (a.nickname < b.nickname) {
            return -1
          }
          if (a.nickname > b.nickname) {
            return 1
          }
        }
        return 0
      })
      return dat
    }
    return undefined
  }, [data])

  useEffect(() => {
    if (searchVal) {
      friendSearch({
        val: searchVal,
        user_nickname,
        onStart: () => setSearchIsLoading(true),
        onSuccess: (result: any) => {
          setSearchResult(result.local.concat(result.global))
          setSearchIsLoading(false)
        },
      })
    }
  }, [searchVal, user_nickname])

  if (isLoading) {
    nprogress.start()
    return <></>
  }
  nprogress.done()

  return (
    <ContentLayout>
      <HeaderMobileTitle.Source>
        {isMyProfile ? 'Мои друзья' : `${user_nickname} - друзья`}
      </HeaderMobileTitle.Source>
      <HeaderMobileRightButton.Source>
        {isMyProfile ? (
          <div
            className={s.calendar}
            onClick={() => navigate(`/${user?.nickname}/friends/calendar`)}
          >
            <img src="/images/icons/calendar-red.svg" alt="" />
          </div>
        ) : (
          <></>
        )}
      </HeaderMobileRightButton.Source>
      <MetaTitleFriends>{user_nickname}</MetaTitleFriends>
      <MetaDescriptionFriends nickname={user_nickname}>{user_nickname}</MetaDescriptionFriends>
      <MetaOG
        title={`${user_nickname} ${t('general.friends_page.meta.title')}`}
        description={`${user_nickname} ${t('general.friends_page.meta.description')}`}
      />
      <div className={s.friendlist}>
        <SearchField onChange={setSearchVal} searchVal={searchVal} />
        {searchVal && searchVal.length ? (
          <>
            {searchIsLoading ? (
              <Loader size={16} />
            ) : searchResult && searchResult.length ? (
              <>
                {searchResult.map((item: any) => (
                  <FriendItem item={item} key={`friend_${item.id}`} isMyProfile />
                ))}
              </>
            ) : (
              <div className={s.empty_container}>
                <div className={s.empty}>
                  К сожалению, <b>{searchVal}</b>
                  {'\n'}не найден в друзьях
                </div>
                {phoneRegExp.test(searchVal) ? (
                  <>
                    {/* {searchResult && searchResult.not_registered && searchResult.not_registered.length ? (
                        <FriendItem
                            item={searchResult.not_registered[0]}
                            isMyProfile={true}
                            isSearch={true}
                            isNotRegistered={true}
                            searchVal={searchVal}
                        />
                        ) : (
                        <FriendItem
                            item={{
                                photo: 'https://cdn.wish.men/user_male_default.png',
                            }}
                            isMyProfile={true}
                            isSearch={true}
                            isNotRegistered={true}
                            searchVal={searchVal}
                        />
                        )} */}
                  </>
                ) : (
                  <div className={s.empty_container}>
                    <div className={s.empty_subtitle}>
                      Пригласите друга отправив личное сообщение по вашей реферальной ссылке и
                      получайте бонусы.{'\n'}
                      <div className={s.empty_red}>Подробнее</div>
                    </div>
                    <Button
                      color="red"
                      title="Пригласить"
                      onClick={() =>
                        navigate(
                          `${pathname}?sharetype=profile&nickname=${
                            user ? user.nickname : ''
                          }#share`,
                        )
                      }
                      className={s.empty_btn}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {isMyProfile && <ActionsBlock requestCount={requestCount} user={user} />}
            {friends && friends.length ? <UserFriendsBday /> : <></>}
            {friends && friends.length ? (
              <>
                <div className={s.friendlist_title}>Все друзья</div>
                <div className={s.friends_container}>
                  {friends.map((item: User) => {
                    return (
                      <FriendItem item={item} key={`friend_${item.id}`} isMyProfile={isMyProfile} />
                    )
                  })}
                </div>
              </>
            ) : (
              <div className={s.empty_title}>Пользователь еще не добавил друзей</div>
            )}
          </>
        )}
      </div>
    </ContentLayout>
  )
}

export default React.memo(UserFriendsPage)
