import React, { useLayoutEffect, useRef } from 'react'
import { Sheet, PageContent } from 'framework7-react'
import cn from 'classnames'

import s from './MW.module.scss'

type Props = {
  opened: boolean
  onSheetOpened?: Function
  onSheetClosed?: Function
  autoHeight?: boolean
  onCloseBtnClick?: Function
  children: React.ReactNode
}

const BottomSheet = ({
  opened,
  onSheetClosed,
  onSheetOpened,
  children,
  autoHeight,
  onCloseBtnClick,
  ...rest
}: Props) => {
  const sheetRef = useRef<any>()
  const handleSheetOpen = (instance: any | undefined) => {
    if (typeof instance !== 'undefined') {
      sheetRef.current = instance
      if (onSheetOpened) onSheetOpened()
    }
  }

  useLayoutEffect(
    () => () => {
      if (typeof sheetRef.current !== 'undefined') {
        sheetRef.current.close(sheetRef.current.el)
      }
    },
    [],
  )

  return (
    <div>
      <Sheet
        className={cn(s.sheet_modal, autoHeight ? s.auto_height : '')}
        swipeToClose
        backdrop
        onSheetOpen={handleSheetOpen}
        // onSheetOpened={() => {
        //   if (onSheetOpened) {
        //     onSheetOpened()
        //   }
        // }}
        opened={opened}
        onSheetClosed={() => {
          if (onSheetClosed) {
            onSheetClosed()
          }
        }}
        {...rest}
      >
        <div
          className={`${s.top_close_button}`}
          onClick={() => {
            if (onCloseBtnClick) {
              onCloseBtnClick()
            }
          }}
        >
          <div />
        </div>
        <PageContent className={s.wishBS_page_content}>
          <div className={`${s.inner_content} ${s.wish}`}>{children}</div>
        </PageContent>
      </Sheet>
    </div>
  )
}

export default React.memo(BottomSheet)
