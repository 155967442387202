import React, { useEffect, useMemo } from 'react'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'application/domain/store/hooks'
import { WishCategoryWithWishes } from 'application/domain/entity/wish/WishCategory'

import CategoryMoreMW from '../ModalWindows/CategoryMoreMW'
import EditCategMW from '../ModalWindows/EditCategMW'
import WishMoreMW from '../ModalWindows/WishMoreMW'
import { DeleteWishMW } from '../ModalWindows'

import MainWishItem from './components/MainWishItem'
import DragAndDrop from './DragAndDrop'
import WishlistCategory from './WishlistCategory'

type Props = {
  data?: Array<WishCategoryWithWishes>
}
const WishlistContent = ({ data }: Props) => {
  const params = useParams<{ nickname?: string }>()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))

  const isMyProfile = useMemo(() => {
    if (params) {
      return !!((user && params.nickname === user.nickname) || !params.nickname)
    }
    return true
  }, [params, user])

  const completed = useMemo(() => {
    if (data) {
      let completedCat: any = {
        id: -2,
        name: 'Исполненые желания',
        wish: [],
        permission: {
          type: 'allow',
        },
        type: 'completed_wish',
      }
      data.map((item) => {
        if (item.wish) {
          item.wish.map((w: any) => {
            if (w.status === 'delivered') {
              completedCat.wish.push(w)
            }
          })
        }
      })
      return completedCat
    }
    return null
  }, [data])

  const categData = useMemo(() => {
    if (data) {
      // if (params && params.category) {
      //   return data.filter((item: any) => item.id === params.category)
      // } else {
      let clone = _.cloneDeep(data)
      clone = clone.map((item: any) => {
        return { ...item, wish: _.filter(item.wish, (w) => w.status !== 'delivered') }
      })
      if (completed) {
        clone.push(completed)
      }
      return clone
      // }
    } else {
      return []
    }
  }, [data, completed])

  const mainWish = useMemo(() => {
    let elem: any
    if (data) {
      data.map((item: any) => {
        item.wish.map((item2: any) => {
          if (item2.is_main) {
            elem = item2
          }
        })
      })
    }
    if (elem) {
      elem.user_nickname = params && params.nickname ? params.nickname : ''
    }
    return elem
  }, [data, params])

  useEffect(() => {
    DragAndDrop()
  }, [])

  return (
    <div>
      {mainWish ? <MainWishItem data={mainWish} /> : <></>}
      {categData.map((item) => (
        <WishlistCategory data={item} isMyProfile={isMyProfile} key={`category_${item.id}`} />
      ))}
      {/* {categData.map((item: any) => (
        <WishlistCategory data={item} isMyProfile={isMyProfile} key={`category_${item.id}`} />
      ))}
      {deliveredData.length > 0 && (
        <WishDeliveredCategory data={deliveredData} isMyProfile={isMyProfile} />
      )} */}
      <CategoryMoreMW />
      <EditCategMW />
      <WishMoreMW />
      <DeleteWishMW isWishList />
    </div>
  )
}

export default React.memo(WishlistContent)
