import { fetchComments } from 'application/data/api/comment'
import { CommentList } from 'application/domain/entity/comment/Comment'
import { Pagination } from 'application/domain/entity/Pagination'
import { useInfiniteQuery } from '@tanstack/react-query'

export const useComments = (wish_id: number) => {
  const query = useInfiniteQuery<Pagination<CommentList>>(
    ['comments', wish_id],
    ({ pageParam = `/api/v1/wish/${wish_id}/donates?per=20` }) => fetchComments(wish_id, pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page_url
      },
      enabled: wish_id ? true : false,
      refetchInterval: 10000,
    },
  )

  return query
}
