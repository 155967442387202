/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import ProgressBar from 'react-customizable-progressbar'
import { Wish } from 'application/domain/entity/wish/Wish'
import wishStatus from 'application/presentation/utils/wishStatus'

import s from './MainWishItem.module.scss'

type Props = {
  data: Wish
}

const MainWishItem = ({ data }: Props) => {
  // const likeMutation = WishLikeMutation(item.id, user_nickname)
  const { pathname } = useLocation()
  const { circleProcent, color, wish_status } = wishStatus(data)
  return (
    <NavLink
      to={`${pathname}/${data.id}`}
      className={`${s.main_wish_container} main_wish_container`}
      id={`wishcart_${data.id}`}
      data-id={data.id}
      data-category={data.category ? data.category.id : 0}
    >
      <div className={s.wish_background}>
        <div className={s.main_wish}>
          <div className={s.fire_icon}>
            <img src="/images/icons/main-wish-icon.svg" alt="" />
          </div>
          <div className={s.wish}>
            <div className={s.left}>
              <img src={data.photo} alt="" />
            </div>
            <div className={s.right}>
              <div className={s.name}>{data.name}</div>
              <div className={s.status}>
                <div className={s.progress_container}>
                  <ProgressBar
                    className="circle_progress_mini"
                    progress={circleProcent}
                    radius={100}
                    strokeWidth={20}
                    trackStrokeWidth={20}
                    initialAnimation
                    transition="1s ease-in-out"
                    strokeColor={color}
                  />
                  {data.status === 'delivered' && (
                    <img loading="lazy" src="/images/icons/GradientComplete.svg" alt="" />
                  )}
                </div>
                <div className={s.status_text}>{wish_status}</div>
              </div>
            </div>
          </div>
          <div className={s.bottom_container}>
            <div className={s.donatesum_variants}>
              {data.status === 'opened' && (
                <div className={`${s.donate_button_block}`}>
                  <NavLink
                    to={`${pathname}/${data.id}/donate`}
                    className={s.do_donate}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                  >
                    <img src="/images/icons/rub-in-red-circle.svg" alt="" />
                    <span>Поддержать</span>
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export default React.memo(MainWishItem)
