import React from 'react'

import s from './FilterProductPage.module.scss'

type Props = {
  filterVariables: any
  changeStage: Function
  changeValues: Function
}

export const FilterItemVariables = ({ filterVariables, changeValues }: Props) => {
  const [active, setActive] = React.useState(filterVariables.value)
  const [activeName, setActiveName] = React.useState(filterVariables.valueName)

  return (
    <>
      <div className={s.filter_variables_content}>
        {filterVariables.items &&
          filterVariables.items.map((item: any, i: number) => {
            return (
              <div
                key={i}
                className={`${s.item} ${
                  !item.params && !active
                    ? s.active
                    : item.params && item.params === active
                    ? s.active
                    : item.value && item.value === active
                    ? s.active
                    : ``
                }`}
                onClick={() => {
                  setActive(item.params ? item.params : item.value)
                  setActiveName(item.name)
                }}
              >
                {item.name}
              </div>
            )
          })}
      </div>
      <button
        type="button"
        className={s.accept_button}
        onClick={() => {
          changeValues(filterVariables.id, active, activeName)
        }}
      >
        Применить
      </button>
    </>
  )
}

export default React.memo(FilterItemVariables)
