/* eslint-disable @typescript-eslint/naming-convention */
import { User } from 'application/domain/entity/user/User'
import { useAppSelector } from 'application/domain/store/hooks'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { SearchField } from 'application/presentation/common/ActionComponents'
import ContentLayout from 'application/presentation/common/ContentLayout'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import Loader from 'application/presentation/common/Loader'
import { friendSearch } from 'application/presentation/utils/SearchFunc'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import FriendItem from '../UserFriendsPage/components/FriendItem'

import s from './AddToFriendsPage.module.scss'

const AddToFriendsPage = () => {
  const params = useParams<{ nickname?: string }>()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const user_nickname = useMemo(() => (params && params.nickname ? params.nickname : ''), [params])
  const [searchVal, setSearchVal] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchResult, setSearchResult] = useState<any>()
  const searchRef = useRef<any>(null)

  useEffect(() => {
    if (searchVal) {
      friendSearch({
        val: searchVal,
        user_nickname,
        onStart: () => setIsLoading(true),
        onSuccess: ({ global, local }: any) => {
          let arr: any = []
          if (local && local.length) {
            arr = arr.concat(local)
          }
          if (global && global.length) {
            arr = arr.concat(global)
          }
          setSearchResult(arr)
          setIsLoading(false)
        },
        onError: () => {
          ToastNotify('Ошибка выполнения запроса')
          setIsLoading(false)
        },
      })
    }
  }, [searchVal, user_nickname])

  return (
    <ContentLayout>
      <HeaderMobileTitle.Source>Добавить друга</HeaderMobileTitle.Source>
      <SearchField value={searchVal} onChange={setSearchVal} inputRef={searchRef} />

      <div className={s.container}>
        <div className={s.items_container}>
          <div
            className={s.item}
            onClick={() => {
              if (searchRef && searchRef.current) searchRef.current.focus()
            }}
          >
            <div className={s.icon}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.8779 18.6996L14.0681 12.8897C15.17 11.5293 15.8334 9.79975 15.8334 7.9167C15.8334 3.55145 12.2819 0 7.91666 0C3.55142 0 0 3.55145 0 7.9167C0 12.2819 3.55146 15.8334 7.9167 15.8334C9.79975 15.8334 11.5293 15.17 12.8897 14.0681L18.6996 19.878C18.8624 20.0407 19.1261 20.0407 19.2889 19.878L19.878 19.2888C20.0407 19.1261 20.0407 18.8623 19.8779 18.6996ZM7.9167 14.1667C4.47025 14.1667 1.66668 11.3632 1.66668 7.9167C1.66668 4.47025 4.47025 1.66668 7.9167 1.66668C11.3632 1.66668 14.1667 4.47025 14.1667 7.9167C14.1667 11.3632 11.3632 14.1667 7.9167 14.1667Z"
                  fill="#747474"
                />
              </svg>
            </div>
            <div className={s.item_name}>Найти по имени или номеру телефона</div>
          </div>
          <div
            className={s.item}
            onClick={
              () =>
                navigate(
                  `${pathname}?sharetype=profile&nickname=${user ? user.nickname : ''}#share`,
                )
              // onShare({
              //   type: 'profile',
              //   msg: user ? user.nickname : '',
              //   url: `https://wish.men/${user ? user.nickname : ''}`,
              // })
            }
          >
            <div className={`${s.icon} ${s.share_icon}`}>
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.8469 6.00841C16.8469 7.06806 15.988 7.92709 14.9283 7.92709C13.8687 7.92709 13.0098 7.06806 13.0098 6.00841C13.0098 4.94887 13.8687 4.08984 14.9283 4.08984C15.988 4.08984 16.8469 4.94887 16.8469 6.00841Z"
                  fill="#747474"
                />
                <path
                  d="M14.9277 8.36973C13.6254 8.36973 12.5664 7.31062 12.5664 6.0083C12.5664 4.70608 13.6254 3.64697 14.9277 3.64697C16.2301 3.64697 17.2891 4.70608 17.2891 6.0083C17.2891 7.31062 16.2301 8.36973 14.9277 8.36973ZM14.9277 4.53248C14.1137 4.53248 13.4519 5.19488 13.4519 6.0083C13.4519 6.82181 14.1137 7.48422 14.9277 7.48422C15.7418 7.48422 16.4035 6.82181 16.4035 6.0083C16.4035 5.19488 15.7418 4.53248 14.9277 4.53248Z"
                  fill="#747474"
                />
                <path
                  d="M16.8469 15.4538C16.8469 16.5134 15.988 17.3724 14.9283 17.3724C13.8687 17.3724 13.0098 16.5134 13.0098 15.4538C13.0098 14.3942 13.8687 13.5352 14.9283 13.5352C15.988 13.5352 16.8469 14.3942 16.8469 15.4538Z"
                  fill="#747474"
                />
                <path
                  d="M14.9277 17.8155C13.6254 17.8155 12.5664 16.7564 12.5664 15.4542C12.5664 14.1519 13.6254 13.0928 14.9277 13.0928C16.2301 13.0928 17.2891 14.1519 17.2891 15.4542C17.2891 16.7564 16.2301 17.8155 14.9277 17.8155ZM14.9277 13.9783C14.1137 13.9783 13.4519 14.6407 13.4519 15.4542C13.4519 16.2676 14.1137 16.93 14.9277 16.93C15.7418 16.93 16.4035 16.2676 16.4035 15.4542C16.4035 14.6407 15.7418 13.9783 14.9277 13.9783Z"
                  fill="#747474"
                />
                <path
                  d="M8.58334 10.7311C8.58334 11.7907 7.72431 12.6496 6.66466 12.6496C5.60512 12.6496 4.74609 11.7907 4.74609 10.7311C4.74609 9.67142 5.60512 8.8125 6.66466 8.8125C7.72431 8.8125 8.58334 9.67142 8.58334 10.7311Z"
                  fill="#747474"
                />
                <path
                  d="M6.66406 13.0928C5.36184 13.0928 4.30273 12.0338 4.30273 10.7314C4.30273 9.42912 5.36184 8.37012 6.66406 8.37012C7.96638 8.37012 9.02549 9.42912 9.02549 10.7314C9.02549 12.0338 7.96638 13.0928 6.66406 13.0928ZM6.66406 9.25563C5.85 9.25563 5.18824 9.91792 5.18824 10.7314C5.18824 11.545 5.85 12.2073 6.66406 12.2073C7.47822 12.2073 8.13998 11.545 8.13998 10.7314C8.13998 9.91792 7.47822 9.25563 6.66406 9.25563Z"
                  fill="#747474"
                />
                <path
                  d="M8.05773 10.4479C7.85224 10.4479 7.6527 10.341 7.54406 10.1498C7.38289 9.86698 7.48212 9.50627 7.7649 9.34445L13.2426 6.22161C13.5254 6.05925 13.8861 6.15848 14.0479 6.44233C14.2091 6.72511 14.1098 7.08582 13.827 7.24764L8.34926 10.3705C8.25716 10.423 8.15685 10.4479 8.05773 10.4479Z"
                  fill="#747474"
                />
                <path
                  d="M13.5342 15.3179C13.435 15.3179 13.3347 15.293 13.2426 15.2405L7.76482 12.1176C7.48204 11.9565 7.38292 11.5958 7.54409 11.3123C7.70461 11.029 8.06586 10.9292 8.34928 11.0916L13.8271 14.2145C14.1098 14.3756 14.209 14.7363 14.0478 15.0198C13.9386 15.211 13.7391 15.3179 13.5342 15.3179Z"
                  fill="#747474"
                />
              </svg>
            </div>
            <div className={s.item_name}>Пригласить по ссылке</div>
          </div>
        </div>
        <div className={s.search_container}>
          {isLoading ? (
            <Loader size={16} color="#ff3e6c" />
          ) : searchResult && searchResult.length ? (
            <div>
              {searchResult.map((item: User) =>
                item.relationship !== 'friends' ? (
                  <FriendItem item={item} key={`friend_${item.id}`} isMyProfile />
                ) : (
                  <></>
                ),
              )}
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </ContentLayout>
  )
}

export default React.memo(AddToFriendsPage)
