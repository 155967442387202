import React, { useState } from 'react'
import ReactModal from 'react-modal'

import s from './VideoBlock.module.scss'

const VideoBlock = () => {
  const [showModal, setShowModal] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false)
  }

  return (
    <div className={s.container}>
      <div className={s.video}>
        <img
          className={s.video_media}
          src="/images/mainPage/video-preview.png"
          alt="video"
          onClick={() => setShowModal(true)}
        />
        <div className={s.video_info}>
          <div className={s.video_title}>Что такое Wishmen?</div>
          <div className={s.video_time}>
            <img src="/images/icons/play-button.svg" alt="play" className={s.play_btn} />
          </div>
        </div>
      </div>
      {/* @ts-ignore */}
      <ReactModal
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            transition: 'none',
          },
        }}
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        className={s.modal_video_mobile}
      >
        <div className={s.modal_video_content_mobile} onClick={() => handleCloseModal()}>
          <iframe
            loading="lazy"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/DjkQN-IV-u4"
            title="Что такое Wishmen?"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          <button type="button" className={s.modal_close} onClick={() => handleCloseModal()}>
            <img src="/images/icons/gray-close.svg" alt="close" />
          </button>
        </div>
      </ReactModal>
    </div>
  )
}

export default React.memo(VideoBlock)
