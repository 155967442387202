import Loader from 'application/presentation/common/Loader'
import React from 'react'

import s from './UserDonateSpinner.module.scss'

const UserDonateSpinner = (): React.ReactElement => {
  return (
    <div className={s.spinner}>
      <Loader />
    </div>
  )
}

export default React.memo(UserDonateSpinner)
