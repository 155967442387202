import React, { useCallback } from 'react'
import { Sheet, PageContent } from 'framework7-react'

import stylebs from '../MW.module.scss'

import s from './PhotoSelectMW.module.scss'

type Props = {
  setPhoto: Function
  setPhotoFile: Function
  opened: boolean
  setOpened: Function
  setOpenedWishPhoto: Function
}

const PhotoSelectMW = ({
  setPhoto,
  setPhotoFile,
  opened,
  setOpened,
  setOpenedWishPhoto,
}: Props) => {
  const selectedFile = useCallback(
    (e: any) => {
      const photoPreview = URL.createObjectURL(e.target.files[0])
      setPhoto(photoPreview)
      setPhotoFile(e.target.files[0])
      setOpened(false)
      setOpenedWishPhoto(true)
    },
    [setOpened, setOpenedWishPhoto, setPhoto, setPhotoFile],
  )

  return (
    <Sheet
      className={`${stylebs.sheet_modal} ${stylebs.auto_height}`}
      swipeToClose
      backdrop
      opened={opened}
      onSheetClosed={() => {
        setOpened(false)
      }}
    >
      <div className={stylebs.top_close_button}>
        <div />
      </div>
      <PageContent>
        <div className={s.inner_content}>
          <div className={s.photo_wrapper}>
            <label className={s.camera_btn}>
              <div className={s.img_block}>
                <img src="/images/icons/camera.svg" alt="" />
              </div>
              <div className={s.text}>
                Сделать <br /> снимок
              </div>
              <input
                type="file"
                accept="image/*"
                // @ts-ignore
                capture="camera"
                onChange={(e: any) => selectedFile(e)}
              />
            </label>
            <label className={s.gallery_btn}>
              <div className={s.img_block}>
                <img src="/images/icons/gallery.svg" alt="" />
              </div>
              <div className={s.text}>
                Загрузить с <br /> устройства
              </div>
              <input
                type="file"
                name="pic"
                accept="image/*"
                onChange={(e: any) => selectedFile(e)}
              />
            </label>
          </div>
        </div>
      </PageContent>
    </Sheet>
  )
}

export default React.memo(PhotoSelectMW)
