import React, { useEffect } from 'react'
import apiClient from 'application/data/apiClient'
import ContentLayout from 'application/presentation/common/ContentLayout'
import { HeaderMobileRightButton, HeaderMobileTitle } from 'application/presentation/common/Header'
import BurgerMenu from 'application/presentation/common/BurgerMenu/BurgerMenu'
import { useQueries } from '@tanstack/react-query'
import { useAppSelector } from 'application/domain/store/hooks'

import BalanceAndRef from './components/BalanceAndRef'
import ProfileOther from './components/ProfileOther'
import ProfileAbout from './components/ProfileAbout'
import LoaderPage from './components/LoaderPage'

// Подгрузка данных
const fetchBalance = async ({ pageParam = '/api/v1/user/balance' }) => {
  const { data } = await apiClient.get(pageParam)
  return data
}

// Подгрузка данных
const fetchReferal = async ({ pageParam = '/api/v1/user/balance?referral=1' }) => {
  const { data } = await apiClient.get(pageParam)
  return data
}
const fetchPayments = async () => {
  const { data } = await apiClient.get('/api/v1/payment')
  return data
}
const fetchOrders = async () => {
  const { data } = await apiClient.get('/api/v1/order')
  return data
}

const Menu = () => {
  const { user, notificationsCount } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
    notificationsCount: userReducer.newNotificationsCount,
  }))

  const results: any = useQueries({
    queries: [
      { queryKey: ['balance'], queryFn: fetchBalance, enabled: !!user, refetchInterval: 15000 },
      { queryKey: ['referal'], queryFn: fetchReferal, enabled: !!user, refetchInterval: 15000 },
      { queryKey: ['payments'], queryFn: fetchPayments, enabled: !!user, refetchInterval: 15000 },
      { queryKey: ['orders'], queryFn: fetchOrders, enabled: !!user, refetchInterval: 15000 },
    ],
  })

  if (
    results[0].isLoading ||
    results[1].isLoading ||
    results[2].isLoading ||
    results[3].isLoading
  ) {
    // nprogress.start()
    return (
      <ContentLayout>
        <HeaderMobileTitle.Source>Меню</HeaderMobileTitle.Source>
        <HeaderMobileRightButton.Source>
          <BurgerMenu opened />
        </HeaderMobileRightButton.Source>
        <LoaderPage />
      </ContentLayout>
    )
  }

  // nprogress.done()
  return user ? (
    <ContentLayout>
      <HeaderMobileTitle.Source>Меню</HeaderMobileTitle.Source>
      <HeaderMobileRightButton.Source>
        <BurgerMenu opened />
      </HeaderMobileRightButton.Source>

      <ProfileOther
        notificationsCount={notificationsCount}
        // @ts-ignore
        user={user}
      />

      <BalanceAndRef balance={results[0].data} referal={results[1].data} />

      {/* <SwipeKnowWish user={user} /> */}

      <ProfileAbout />
    </ContentLayout>
  ) : (
    <></>
  )
}
export default React.memo(Menu)
