import React, { useEffect, useRef, useState } from 'react'

import s from './MultipleSelector.module.scss'
import SelectItem from './SelectItem'

type Props = {
  data: Array<SelectedValType>
  onChange?: Function
  containerClassName?: string
  value: SelectedValType
  title: string
  wrong?: boolean
}

type SelectedValType = {
  value: number
  label: string
  data?: Array<SelectedValType>
}

const useOutsideClick = (ref: any, setOpened: Function) => {
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpened(false)
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [ref, setOpened])
}

const MultipleSelector = ({ data, onChange, value, containerClassName, title, wrong }: Props) => {
  const [opened, setOpened] = useState<boolean>(false)
  const wrapperRef = useRef<any>()
  useOutsideClick(wrapperRef, setOpened)
  return (
    <div
      className={`${s.container} ${containerClassName || ''} ${wrong ? s.wrong : ''}`}
      ref={wrapperRef}
    >
      <div className={s.selected_container} onClick={() => setOpened(!opened)}>
        <div className={s.title}>{title}</div>
        <div className={s.value}>{value ? (value.label ? value.label : value.value) : ''}</div>
        <div className={s.trigger} />
      </div>
      <div className={`${s.list} ${opened ? s.opened : ''}`}>
        <div className={s.list_container}>
          {data && data.length ? (
            data.map((item: SelectedValType, i: number) => (
              <SelectItem
                key={`select_item_${i}`}
                item={item}
                onClick={(val: SelectedValType) => {
                  if (onChange) onChange(val)
                }}
                closeSelector={() => setOpened(false)}
                selected={value}
              />
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(MultipleSelector)
