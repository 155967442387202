import _ from 'lodash'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import FilterContent from './FilterContent'
import FilterItemVariables from './FilterItemVariables'
import s from './FilterProductPage.module.scss'

const FilterProductPage = () => {
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [stage, setStage] = useState<number>(1)
  const [selectedFilter, setSelectedFilter] = useState<number>(0)

  const sortParams = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const order = params.get('order')
    const desc = params.get('desc')
    return order ? `order=${order}${desc ? '&desc=1' : ''}` : null
  }, [location])

  const sortArray = useMemo(
    () => [
      {
        name: t('shop_category_page.filter.price'),
        params: 'order=price',
        value: false,
      },
      {
        name: t('shop_category_page.filter.price_desc'),
        params: 'order=price&desc=1',
        value: false,
      },
      {
        name: t('shop_category_page.filter.name'),
        params: 'order=name',
        value: false,
      },
      {
        name: t('shop_category_page.filter.name_desc'),
        params: 'order=name&desc=1',
        value: false,
      },
    ],
    [t],
  )

  const filterList = useMemo(() => {
    const selected = sortParams ? _.find(sortArray, { params: sortParams }) : null
    return [
      {
        id: -1,
        name: 'Сортировать',
        valueName: selected ? selected.name : 'Без сортировки',
        value: selected ? selected.params : 0,
        items: [
          {
            name: 'Без сортировки',
            params: '',
            value: false,
          },
          ...sortArray,
        ],
      },
    ]
  }, [sortArray, sortParams])

  const changeStage = useCallback((localType: string, id: number) => {
    if (localType === 'back') setStage(1)
    if (localType === 'sort') {
      setStage(2)
      setSelectedFilter(id)
    }
  }, [])

  const changeValues = useCallback(
    (filterId: number, value: any, valueName: any) => {
      filterList.map((item: any) => {
        if (item.id === filterId) {
          item.valueName = valueName
          item.value = value
        }
        return item
      })
      setStage(1)
    },
    [filterList],
  )

  return (
    <div className={s.filter_container}>
      {stage === 1 ? (
        <div className={s.header_container}>
          <div className={s.left}>
            <div className={s.img_block} onClick={() => navigate(-1)}>
              <img loading="lazy" src="/images/icons/back-icon.svg" alt="" />
            </div>
          </div>
          <div className={s.center}>Фильтры</div>
          <div className={s.right} />
        </div>
      ) : (
        <div className={s.header_container}>
          <div className={s.left}>
            <div className={s.img_block} onClick={() => setStage(1)}>
              <img loading="lazy" src="/images/icons/back-icon.svg" alt="" />
            </div>
          </div>
          <div className={s.center}>Сортировка</div>
          <div className={s.right} />
        </div>
      )}
      <div className={`${s.inner_content} ${s.stage}`}>
        {stage === 1 && <FilterContent changeStage={changeStage} filterList={filterList} />}

        {stage === 2 &&
          filterList.map((item: { id: number }) => {
            return selectedFilter === item.id ? (
              <FilterItemVariables
                changeStage={changeStage}
                changeValues={changeValues}
                filterVariables={item}
              />
            ) : (
              true
            )
          })}
      </div>
    </div>
  )
}

export default React.memo(FilterProductPage)
