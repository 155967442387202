import apiClient from '../apiClient'

export const fetchNotifications = async (pageParam: string) => {
  const { data } = await apiClient.get(pageParam)
  return data
}

export const fetchNotificationsByType = async (type?: string) => {
  const { data } = await apiClient.get<any>(`/api/v1/notification/${type}`)
  return data
}
