import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import s from './ShareButton.module.scss'

type Props = {
  wishId?: number
  nickname?: string
  productId?: number
  shareType: 'category' | 'wish' | 'product' | 'idea' | 'ideas' | 'article' | 'profile' | 'santa'
}

const ShareButton = ({ wishId, nickname, productId, shareType }: Props) => {
  const { pathname } = useLocation()
  return (
    <NavLink
      className={s.share_btn}
      type="button"
      to={`${pathname}?sharetype=${shareType}&${wishId ? `wish_id=${wishId}&` : ''}${
        nickname ? `nickname=${nickname}&` : ''
      }${productId ? `product_id=${productId}` : ''}#share`}
    >
      <svg
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0726 1.17357C10.89 1.00375 10.616 0.953204 10.3781 1.04568C10.1402 1.13816 9.98497 1.3555 9.98497 1.59637V4.77841C6.54036 4.90653 4.81544 6.24178 4.55187 6.46378C2.814 7.7974 1.62743 9.65584 1.19645 11.7188C0.980962 12.7156 0.942348 13.7387 1.0826 14.7471L1.0831 14.7506C1.08435 14.7595 1.08584 14.7685 1.08734 14.7774L1.22286 15.5058C1.26845 15.7511 1.47248 15.9442 1.73431 15.9899C1.99638 16.0355 2.26095 15.924 2.39797 15.7103L2.80404 15.0774C4.05164 13.1377 5.60641 11.8518 7.42574 11.2557C8.24635 10.9843 9.11578 10.8653 9.98497 10.9049V14.2006C9.98497 14.442 10.1407 14.6598 10.3793 14.7518C10.6182 14.844 10.8928 14.7923 11.0749 14.6211L17.8144 8.28728C18.0627 8.05387 18.0618 7.67626 17.8121 7.44402L11.0726 1.17357ZM11.2607 12.7554V10.3684C11.2607 10.0758 11.0335 9.82636 10.7244 9.77977C10.1788 9.69754 8.72367 9.56686 7.00398 10.1304C5.16945 10.7314 3.58229 11.914 2.27515 13.6518C2.27141 13.0808 2.32921 12.5108 2.44779 11.9506C2.83542 10.118 3.81995 8.5787 5.37373 7.376C5.38743 7.36551 5.40038 7.35457 5.41309 7.34292C5.45768 7.30425 7.07747 5.93685 10.6177 5.96155H10.6225C10.9747 5.96155 11.2605 5.69436 11.2607 5.36497V3.03108L16.4606 7.86891L11.2607 12.7554Z"
          fill="white"
          stroke="white"
          strokeWidth="0.5"
        />
      </svg>
    </NavLink>
  )
}

export default React.memo(ShareButton)
