import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import { Product } from 'application/domain/entity/product/Product'

type Props = {
  product: Product
  isIdea: boolean | undefined
  isNewUser: boolean
  user_nickname: string
  onStart: Function
  onSuccess: Function
  onError: Function
}

export const ExpressAddWish = ({
  product,
  isIdea,
  isNewUser,
  user_nickname,
  onStart,
  onSuccess,
  onError,
}: Props) => {
  onStart()

  let data: any = {
    name: product.name,
    public_comment: '',
    product_id: product.id,
  }
  if (isIdea) {
    data.type = 'idea'
    data.idea_delivery = 'birthday'
    data.nickname = user_nickname
    if (isNewUser) {
      data.new_user = true
    }
  } else {
    data.type = 'wish'
    data.category = 0
    data.is_public = 1
  }
  apiClient({
    method: 'POST',
    url: '/api/v1/wish/add',
    data,
  })
    .then(async (response: any) => {
      //   console.log(response.data)
      queryClient.invalidateQueries(['profile'])
      queryClient.invalidateQueries(['ideas'])
      queryClient.invalidateQueries(['myWishlist'])
      onSuccess(response.data)
    })
    .catch((error) => {
      console.log(error)
      onError()
    })
}
