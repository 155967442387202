import React from 'react'
import { NavLink } from 'react-router-dom'
import ProgressBar from 'react-customizable-progressbar'
import { Wish } from 'application/domain/entity/wish/Wish'
import wishStatus from 'application/presentation/utils/wishStatus'
import wordDeclination from 'application/presentation/utils/wordDeclination'

import s from '../WishPage.module.scss'

import WishOrderStatus from './WishOrderStatus'

type Props = {
  wishData: Wish
  isIdea: boolean
}
const TopBlock = ({ wishData, isIdea }: Props): React.ReactElement => {
  const { circleProcent, color } = wishStatus(wishData)

  return (
    <div className={`${s.headline} ${s.wish}`}>
      <div className={`${s.flex_block}`}>
        <NavLink to={`/${wishData.user.nickname}`} className={`${s.from_block}`}>
          {isIdea ? 'Идея' : 'Желание'} от @
          <span className={s.user_name}>{wishData.user!.nickname}</span>
        </NavLink>
      </div>
      <div className={`${s.wish_title} wish-name`}>
        <h1 className={`${s.wish_name} ${s.wishname_for_share}`}>
          {wishData.name}
          <span className={s.star}>
            <img loading="lazy" src="/images/icons/purple-star-mini.svg" alt="" />
          </span>
        </h1>

        <NavLink to="#" className={`${s.categ_name} link external`}>
          {wishData.category ? wishData.category.name : ''}
        </NavLink>
      </div>
      <div className={s.wish_circle_block}>
        <div className={s.wish_circle} id="wish-circle" data-percent="0">
          <ProgressBar
            className={s.circle_progress}
            progress={circleProcent}
            radius={143}
            strokeWidth={15}
            trackStrokeWidth={15}
            initialAnimation
            transition="1s ease-in-out"
            strokeColor={color}
          />
          <div
            className={`${s.circle_content} ${wishData.status === 'delivered' ? s.delivered : {}}`}
          >
            {wishData && wishData.status === 'delivered' && (
              <div className={s.delivered_arrow}>
                <img src="/images/icons/more-black-accept.svg" alt="" />
              </div>
            )}
            <div className={`${s.img_block} img-block`}>
              <img
                loading="lazy"
                src={wishData.photo}
                alt={wishData.product ? wishData.product.name : wishData.name}
                onError={(e: any) => {
                  e.target.onerror = null
                  e.target.src = 'https://cdn.wish.men/icons/product-without-photo.png'
                }}
              />
            </div>

            {wishData &&
            wishData.status !== '' &&
            wishData &&
            wishData.status !== 'without_price' ? (
              <div className={`${s.collected_block} collected-block`}>
                {wishData.full_price === 0 ? (
                  <div className={s.collected}>Бесплатно</div>
                ) : (
                  <>
                    {wishData.status === 'delivered' ? (
                      <></>
                    ) : (
                      <>
                        <div className={s.collected}>{wishData.amount_collected} руб</div>
                        <div className={s.total}>из {wishData.full_price} руб</div>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {wishData ? (
        <>
          {wishData.status !== 'opened' && <WishOrderStatus wishData={wishData} />}
          {wishData.status === 'delivered' && (
            <div className={s.delivered_block}>
              <div className={s.delivered_title}>Желание исполнено ✌️</div>
              <div className={s.delivered_subtitle}>
                {wishData.donars_quantity}{' '}
                <>{wordDeclination(wishData.donars_quantity, ['друг', 'друга', 'друзей'])}</>{' '}
                <>{wordDeclination(wishData.donars_quantity, ['помог', 'помогли', 'помогли'])}</>{' '}
                исполнить желание
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(TopBlock)
