import { Payment } from 'application/domain/entity/payment/Payment'
import React from 'react'

import UserDonateBottomSheet from './UserDonateBottomSheet'

type Props = {
  donate: Payment
  type: 'in' | 'out'
  opened: boolean
  onClosed: () => void
}

const UserDonateOverlay = ({
  donate,
  opened,
  type,
  onClosed,
}: Props): React.ReactElement | null => {
  return <UserDonateBottomSheet donate={donate} opened={opened} type={type} onClosed={onClosed} />
}

export default React.memo(UserDonateOverlay)
