import Moment from 'moment'

// Проверка даты создания записи
const checkDate = (type: string, someDate: number) => {
  if (type === 'today') {
    if (Moment().isSame(Moment.unix(someDate), 'day')) {
      return true
    }
    return false
  }
  if (type === 'yesterday') {
    if (Moment().add(-1, 'days').isSame(Moment.unix(someDate), 'day')) {
      return true
    }
    return false
  }
  if (type === 'otherday') {
    if (Moment().add(-2, 'days').isAfter(Moment.unix(someDate), 'day')) {
      return true
    }
    return false
  }
}

export default checkDate
