import Loader from 'application/presentation/common/Loader'
import React from 'react'

import s from './PayButtons.module.scss'

type Props = {
  selected_id: number
  isLoading?: boolean
  bankOnClick: Function
  apayOnClick: Function
  balanceOnClick: Function
  gpayOnClick: Function
  tinkoffOnClick: Function
  blockBalance?: boolean
  disabled?: boolean
}

const PayButtons = (props: Props) => {
  switch (props.selected_id) {
    case 1:
      return (
        <div
          className={`${s.donate_button_block} ${
            props.isLoading || props.disabled ? s.disabled : ''
          } ${s.bank}`}
        >
          <button
            type="button"
            onClick={() => {
              props.bankOnClick()
            }}
          >
            Оплатить с карты
          </button>
        </div>
      )

    case 2:
      return (
        <div
          className={`${s.donate_button_block} ${s.apple_pay} ${
            props.isLoading || props.disabled ? s.disabled : ''
          }`}
        >
          <button
            type="button"
            className={s.apple_pay_checkout}
            onClick={() => {
              props.apayOnClick()
            }}
          >
            {props.isLoading ? (
              <Loader color="#ffffff" size={12} />
            ) : (
              <span className={s.text}>
                Оплатить с <img src="/images/icons/applepay.svg" alt="" />
              </span>
            )}
          </button>
        </div>
      )
    case 3:
      return (
        <div
          className={`${s.donate_button_block} ${s.google_pay} ${
            props.isLoading || props.disabled ? s.disabled : ''
          }`}
        >
          <button
            type="button"
            className={s.gpay_checkout}
            onClick={() => {
              props.gpayOnClick()
            }}
          >
            {props.isLoading ? (
              <Loader color="#ffffff" size={12} />
            ) : (
              <span className={s.text}>
                <img src="/images/icons/gpay-logo.svg" alt="" />
              </span>
            )}
          </button>
        </div>
      )
    case 4:
      return (
        <div
          className={`${s.donate_button_block} ${
            props.isLoading || props.disabled ? s.disabled : ''
          } ${s.bank} ${props.blockBalance ? 'disabled' : ''}`}
        >
          <button
            type="button"
            onClick={() => {
              props.balanceOnClick()
            }}
          >
            {props.isLoading ? (
              <Loader color="#ffffff" size={12} />
            ) : (
              <span className={s.text}>Оплатить с баланса</span>
            )}
          </button>
        </div>
      )
    case 5:
      return (
        <div className={`${s.donate_button_block} ${s.bank}`}>
          <button
            type="button"
            onClick={() => {
              props.tinkoffOnClick()
            }}
          >
            Оформить кредит
          </button>
        </div>
      )
    default:
      return <></>
  }
}

export default React.memo(PayButtons)
