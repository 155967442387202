import { useProduct } from 'application/domain/useCase/product/getProduct'
import Loader from 'application/presentation/common/Loader'
import React, { useCallback, useMemo } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import BottomSheet from '../BottomSheet'
import s from '../WishMoreMW/WishMoreMW.module.scss'

const ProductMoreMW = () => {
  const navigate = useNavigate()
  const { search, hash } = useLocation()
  const productId = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const id = searchParams.get('product_id')
    return id ? parseInt(id, 10) : 0
  }, [search])

  const { data: prodData, isLoading: productIsLoading } = useProduct(productId)
  const opened = useMemo(() => {
    return productId && hash.indexOf('more') !== -1 ? true : false
  }, [productId, hash])

  const closeBS = useCallback(() => {
    if (hash.indexOf('#more') !== -1) {
      navigate(-1)
    }
  }, [hash, navigate])

  return (
    <BottomSheet opened={opened} autoHeight onSheetClosed={() => closeBS()}>
      {productIsLoading ? (
        <Loader />
      ) : opened && prodData ? (
        <div className={s.settings_content}>
          <NavLink
            className={s.item}
            to={`/shop/category/${prodData.category.id}/${prodData[0].id}/add`}
          >
            <div className={s.title}>Добавить в желание</div>
            <img loading="lazy" src="/images/icons/add-wish-plus.svg" alt="" />
          </NavLink>
          {/* <div className={s.item} onClick={(ev) => openAddIdeaMW(ev)}>
                  <div className={s.title}>Предложить идею другу</div>
                  <img loading="lazy" src="/images/icons/lamp-icon-black.svg" alt="" />
                </div> */}
          <NavLink className={s.item} to={`?sharetype=product&product_id=${prodData[0].id}#share`}>
            <div className={s.title}>Поделиться</div>
            <img loading="lazy" src="/images/icons/share-icon-bs.svg" alt="" />
          </NavLink>
        </div>
      ) : (
        <></>
      )}
    </BottomSheet>
  )
}

export default React.memo(ProductMoreMW)
