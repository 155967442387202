/* eslint-disable react/display-name */
import React, { useMemo } from 'react'
import _ from 'lodash'
import { NavLink } from 'react-router-dom'
import { ShopCategory } from 'application/domain/entity/shop/ShopCategory'
import splitByTwo from 'application/presentation/utils/splitByTwo'

import s from '../ShopContent.module.scss'

type Props = {
  data: any
}

type ItemProps = {
  item: ShopCategory
}

const CategoryItem = React.memo(({ item }: ItemProps) => {
  return (
    <NavLink
      className={s.top_categ_item}
      to={`${item.id === 0 ? '/shop/categories#categories-0' : `/shop/category/${item.slug}`}`}
    >
      <div className={s.top_categ_item_image_block}>
        {item.id === 0 || item.photo_mini ? (
          <img
            src={item.id === 0 ? '/images/scope.png' : item.photo_mini ? item.photo_mini : ''}
            alt=""
            className={s.top_categ_item_image}
          />
        ) : (
          <></>
        )}
      </div>
      <div className={s.top_categ_item_txt}>
        <span>{item.id === 0 ? 'Все' : item.name}</span>
      </div>
    </NavLink>
  )
})

const TopCategories = ({ data }: Props) => {
  const categData = useMemo(() => {
    const clone = _.clone(data)
    clone.unshift({
      id: 0,
    })
    return splitByTwo(data && clone ? clone : [])
  }, [data])
  return (
    <div className={s.container}>
      <div className={s.title_container}>
        <div className={s.red_title}>Топ категорий</div>
        <NavLink to="/shop/categories#categories-0">
          <div className={s.black_title}>Все</div>
        </NavLink>
      </div>
      <div className={s.flat_list}>
        {categData.map((item: any, index: number) => (
          <div className={s.top_categ_column} key={`top_categ_${index}`}>
            {item[0] ? <CategoryItem item={item[0]} /> : <></>}
            {item[1] ? <CategoryItem item={item[1]} /> : <></>}
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(TopCategories)
