import apiClient from '../apiClient'

export const fetchSearch = async (pageParam?: string) => {
  const { data } = await apiClient.get(pageParam!)
  return data
}

export const fetchHistory = async () => {
  const { data } = await apiClient.get('/api/v1/shop/search/history')
  return data
}

export const fetchTopRequests = async () => {
  const { data } = await apiClient.get('/api/v1/shop/search/top')
  return data
}

export const deleteHistoryRequest = async (id: string) => {
  const { data } = await apiClient({
    method: 'POST',
    url: '/api/v1/shop/search/history/delete',
    data: {
      id,
    },
  })
  return data
}
