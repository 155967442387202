/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/display-name */
import { ListItem, SwipeoutActions, SwipeoutButton } from 'framework7-react'
import React, { useCallback } from 'react'
import ProgressBar from 'react-customizable-progressbar'
import { useLocation, useNavigate } from 'react-router-dom'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { useDispatch } from 'react-redux'
import { Wish } from 'application/domain/entity/wish/Wish'
import wishStatus from 'application/presentation/utils/wishStatus'
import { HideWishMutation } from 'application/domain/useCase/wish/editWish'
import { setWishMoreMwData } from 'application/domain/store/modalReducer'

import s from './WishItem.module.scss'

type Props = {
  data: Wish
  isMyProfile?: boolean
  isIdea?: boolean
}

const SwipeActionBlock = React.memo(({ data, hideWish, pathname }: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const moreBtnClickHandler = useCallback(() => {
    setWishMoreMwData({
      wish_id: data.id,
      is_public: data.is_public,
    })(dispatch)
    navigate(`${pathname}#more_mw`)
  }, [data.id, data.is_public, dispatch, navigate, pathname])

  return (
    <SwipeoutActions right>
      <SwipeoutButton
        close
        className={`${s.wishmenu__item} ${s.del} ${'swipeout-close'}`}
        onClick={() => navigate(`${pathname}?wish_id=${data.id}#delete_wish`)}
      >
        <div className={s.icon}>
          <img loading="lazy" src="/images/icons/white-trash.svg" alt="" />
        </div>
        <div className={s.title}>Удалить</div>
      </SwipeoutButton>
      <SwipeoutButton
        close
        className={`${s.wishmenu__item} ${s.hide}`}
        onClick={() => {
          hideWish.mutate({ wish_id: data.id, is_public: data.is_public })
        }}
      >
        <div className={s.icon}>
          <img loading="lazy" src="/images/icons/show-white-icon.svg" alt="" />
        </div>
        <div className={s.title}>
          {data.is_public ? 'Скрыть' : 'Показать'}
          <br />
          желание
        </div>
      </SwipeoutButton>
      <SwipeoutButton
        close
        className={`${s.wishmenu__item} ${s.edit}`}
        onClick={() => navigate(`${pathname}/${data.id}/edit`)}
      >
        <div className={s.icon}>
          <img loading="lazy" src="/images/icons/pencil.svg" alt="" />
        </div>
        <div className={s.title}>Редактировать</div>
      </SwipeoutButton>
      <SwipeoutButton
        close
        className={`${s.wishmenu__item} ${s.more}`}
        onClick={moreBtnClickHandler}
      >
        <div className={s.icon}>
          <img loading="lazy" src="/images/icons/3points-black.svg" alt="" />
        </div>
        <div className={s.title}>Еще</div>
      </SwipeoutButton>
    </SwipeoutActions>
  )
})

const WishItem = ({ data, isMyProfile, isIdea }: Props) => {
  const { circleProcent, color, wish_status } = wishStatus(data)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const hideWish = HideWishMutation(() =>
    ToastNotify(`Желание ${data.is_public ? 'отображено' : 'скрыто'}`),
  )
  return (
    <div
      className={`${s.wish_item} ${isIdea ? s.idea_item : ''} wish-cart`}
      id={`wishcart_${data.id}`}
    >
      <ListItem key={data.id} swipeout className={`${s.wish_list_item}`}>
        <div className={`${s.inner_content} item`} slot="inner-start" data-id={data.id}>
          <div
            className={`${s.wish_cart} ${isIdea ? s.idea_cart : ''}`}
            onClick={() => navigate(`${pathname}/${data.id}`)}
          >
            <div className={s.wish_img}>
              <img loading="lazy" src={data.photo} alt="" />
            </div>

            <div className={s.text}>
              {isIdea && <div className={s.idea_name}>Идея от @{data.idea.idea_user.nickname}</div>}
              <div className={s.cart_name}>
                {!data.is_public && (
                  <img loading="lazy" src="/images/icons/hide-icon-gray.svg" alt="" />
                )}
                {data.name}
              </div>
              <div className={s.cart_status}>
                <div className={s.circlestat}>
                  <div className={s.progress}>
                    <ProgressBar
                      className="circle_progress_mini"
                      progress={circleProcent}
                      radius={100}
                      strokeWidth={20}
                      trackStrokeWidth={20}
                      initialAnimation
                      transition="1s ease-in-out"
                      strokeColor={color}
                    />
                    {data.status === 'delivered' && (
                      <img loading="lazy" src="/images/icons/GradientComplete.svg" alt="" />
                    )}
                  </div>
                </div>
                <div className={s.status_text}>{wish_status}</div>
              </div>
            </div>
            {isIdea ? (
              <></>
            ) : (
              <>
                <div className={s.arrow}>
                  <img src="/images/icons/back-icon.svg" alt="" />
                </div>
              </>
            )}
          </div>
        </div>
        {isMyProfile && <SwipeActionBlock data={data} hideWish={hideWish} pathname={pathname} />}
      </ListItem>
    </div>
  )
}

export default React.memo(WishItem)
