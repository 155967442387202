/* eslint-disable @typescript-eslint/naming-convention */
import { Wish } from 'application/domain/entity/wish/Wish'
import { WishLikeMutation } from 'application/domain/useCase/wish/likeWish'
import wishStatus from 'application/presentation/utils/wishStatus'
import React from 'react'
import ProgressBar from 'react-customizable-progressbar'
import { NavLink, useLocation } from 'react-router-dom'

import s from './IdeaItem.module.scss'

type Props = {
  item: Wish
  isMainIdea?: boolean
}

const IdeaItem = ({ item, isMainIdea }: Props) => {
  const { wish_status, circleProcent, color } = wishStatus(item)
  const location = useLocation()
  const likeMutation = WishLikeMutation(
    item ? item.id : 0,
    item && item.user ? item.user.nickname : '',
  )
  return (
    <NavLink
      to={`${location.pathname}/${item.id}`}
      id={`ideacart_${item.id}`}
      data-id={item.id}
      className={s.main_wish_container}
    >
      <div className={`${s.wish_background} ${isMainIdea ? s.main : ''}`}>
        <div className={s.main_wish_mobile}>
          <div className={s.idea_container}>
            <ProgressBar
              className="circle_progress_mini"
              progress={circleProcent}
              radius={100}
              strokeWidth={20}
              trackStrokeWidth={20}
              initialAnimation
              transition="1s ease-in-out"
              strokeColor={color}
            />
            <div className={s.idea_info}>
              <div className={s.name}>{item.product?.name}</div>
              <div className={s.status}>{wish_status}</div>
            </div>
            <div className={s.img_block}>
              <img src={item.photo} alt="" />
            </div>
          </div>
          <div className={s.idea_user}>
            <div className={s.top_container}>
              <div className={s.img_block}>
                <img src={item.idea.idea_user?.photo} alt="" />
              </div>
              <div className={s.nickname}>
                {item.idea.idea_user?.name} <span>предложил идею</span>
              </div>
            </div>
            <div
              className={
                item.public_comment === '' || item.public_comment === null
                  ? `${s.comment} ${s.comment_without}`
                  : s.comment
              }
            >
              {item.public_comment}
            </div>
          </div>
          <div className={s.bottom_container}>
            <div className={`${s.donate_button_block}`}>
              <NavLink
                to={`${location.pathname}/${item.id}/donate`}
                className={s.do_donate}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <img src="/images/icons/rub-in-red-circle.svg" alt="" />
                <span>Поддержать</span>
              </NavLink>
            </div>
            <div className={s.stat_block}>
              <div
                className={s.likes}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  likeMutation.mutate()
                }}
              >
                {item?.liked ? (
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.4134 1.83508C17.3781 0.712189 15.9575 0.09375 14.413 0.09375C13.2585 0.09375 12.2012 0.45874 11.2704 1.1785C10.8008 1.54181 10.3752 1.9863 10 2.5051C9.62494 1.98645 9.19922 1.54181 8.7294 1.1785C7.79877 0.45874 6.74149 0.09375 5.58701 0.09375C4.04251 0.09375 2.62177 0.712189 1.58646 1.83508C0.563507 2.94485 0 4.46097 0 6.10434C0 7.79578 0.630341 9.34409 1.98364 10.9771C3.19427 12.4378 4.93423 13.9207 6.94916 15.6377C7.63718 16.2241 8.41705 16.8888 9.22684 17.5968C9.44077 17.7842 9.71527 17.8873 10 17.8873C10.2846 17.8873 10.5592 17.7842 10.7729 17.5971C11.5826 16.8889 12.363 16.224 13.0513 15.6373C15.0659 13.9205 16.8059 12.4378 18.0165 10.9769C19.3698 9.34409 20 7.79578 20 6.10419C20 4.46097 19.4365 2.94485 18.4134 1.83508Z"
                      fill="#FF3E6C"
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5408 1.90111C17.5543 0.781811 16.1859 0.165405 14.6875 0.165405C12.5802 0.165405 11.246 1.424 10.4979 2.47986C10.3038 2.75384 10.1386 3.02857 10 3.28708C9.86137 3.02857 9.69629 2.75384 9.50215 2.47986C8.75402 1.424 7.41984 0.165405 5.3125 0.165405C3.81414 0.165405 2.44566 0.781851 1.45918 1.90115C0.518242 2.96888 0 4.39888 0 5.92771C0 7.59189 0.649727 9.13974 2.04473 10.7988C3.29148 12.2816 5.08512 13.8101 7.16211 15.5799C7.93605 16.2395 8.73641 16.9215 9.58848 17.667L9.61406 17.6894C9.72453 17.7861 9.86227 17.8345 10 17.8345C10.1377 17.8345 10.2755 17.7861 10.3859 17.6894L10.4115 17.667C11.2636 16.9215 12.0639 16.2395 12.838 15.5799C14.9149 13.8101 16.7085 12.2817 17.9553 10.7988C19.3503 9.1397 20 7.59189 20 5.92771C20 4.39888 19.4818 2.96888 18.5408 1.90111ZM12.0779 14.688C11.4107 15.2565 10.724 15.8416 10 16.471C9.27602 15.8417 8.58934 15.2565 7.92203 14.6879C3.85723 11.2241 1.17188 8.93579 1.17188 5.92771C1.17188 4.68435 1.58613 3.52951 2.33836 2.67595C3.09922 1.81271 4.15543 1.33728 5.3125 1.33728C6.9191 1.33728 7.95766 2.32705 8.54594 3.15736C9.07363 3.90205 9.34898 4.65275 9.44289 4.94091C9.52145 5.18212 9.74633 5.34537 10 5.34537C10.2537 5.34537 10.4786 5.18212 10.5571 4.94091C10.651 4.65275 10.9264 3.90205 11.4541 3.15732C12.0423 2.32705 13.0809 1.33728 14.6875 1.33728C15.8446 1.33728 16.9008 1.81271 17.6616 2.67595C18.4139 3.52951 18.8281 4.68435 18.8281 5.92771C18.8281 8.93579 16.1428 11.2241 12.0779 14.688Z"
                      fill="black"
                    />
                  </svg>
                )}
                <span>{item?.likes_count}</span>
              </div>
              <NavLink
                to={`${location.pathname}/${item.id}/comments`}
                className={s.comments}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <img src="/images/icons/comment-icon.svg" alt="" />
                <span>
                  {(item.comments_count ? item.comments_count : 0) +
                    (item.donates_quantity ? item.donates_quantity : 0)}
                </span>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  )
}

export default React.memo(IdeaItem)
