/* eslint-disable @typescript-eslint/naming-convention */
import { OrderStatusList } from 'application/domain/entity/order/Order'
import { Wish } from 'application/domain/entity/wish/Wish'
import _ from 'lodash'

const wishStatus = (wish?: Wish | null, isNotOpened?: boolean) => {
  if (wish) {
    let procent = 0
    let circleProcent = 0
    if (wish.full_price! > 0 && wish.amount_collected > 0) {
      procent = Math.floor((wish.amount_collected / wish.full_price!) * 100)
      procent = procent >= 100 ? 100 : procent
      circleProcent = procent < 1 ? 1 : procent
    } else if (wish.status === 'without_price' || wish.status === '') {
      procent = 0
      circleProcent = 0
    } else {
      procent = 0
      circleProcent = 1
    }
    if (wish.status === 'full' || wish.status === 'order') {
      procent = 100
      circleProcent = 100
    }

    let color = ''
    let wish_status = ''
    let order_status = ''
    if (wish.wish_order) {
      let findedStatus = _.find(OrderStatusList, { status: wish.wish_order.status })
      if (findedStatus) {
        // @ts-ignore
        color = findedStatus.color
        wish_status = findedStatus.title
        order_status = findedStatus.status
      } else {
        color = '#ff3e6c'
        wish_status = ''
        order_status = ''
      }
    } else {
      switch (wish.status) {
        case 'order':
        case 'transferred':
          color = '#9A3FD0'
          break

        case 'delivered':
        case 'order-delivery':
          color = '#1DCBFF'
          break

        default:
          if (procent === 100) {
            // path.setAttribute("stroke", "url(
            color = '#6372FF'
          } else {
            color = '#FF3E6C'
          }
      }
      switch (wish.status) {
        case 'full':
        case 'over':
          wish_status = 'Средства собраны. Оформите желание.'
          break
        case 'order':
        case 'transferred':
          wish_status = 'Заказ оформлен'
          break
        case 'delivered':
        case 'order-delivery':
          wish_status = 'Желание исполнено'
          break
        case 'opened':
          wish_status =
            wish.full_price === 0 ? 'Бесплатно' : `${procent}% из ${wish.full_price} рублей`
          break
        case 'without_price':
          wish_status = 'Желание без сбора средств'
          break
        default:
          break
      }
    }

    if (wish.product_id === -1) {
      wish_status = 'Желание без сбора средств'
    }
    if (wish.product_id === 99999999) {
      wish_status = 'Желание парсится с Ozon'
    }

    if (isNotOpened) {
      color = '#ff3e6c'
      circleProcent = 100
    }
    return {
      procent,
      circleProcent,
      wish_status,
      color,
      order_status,
    }
  }
  return {
    procent: 0,
    circleProcent: 0,
    wish_status: '',
    color: '',
    order_status: '',
  }
}

export default wishStatus
