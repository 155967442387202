/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from 'application/presentation/common/Loader'
import { useAppSelector } from 'application/domain/store/hooks'
import { useBalanceInfinite } from 'application/domain/useCase/balance/getBalance'
import checkDate from 'application/presentation/utils/checkDate'
import { Balance } from 'application/domain/entity/balance/Balance'

import TopBlock from './TopBlock'
import s from './BalanceAndRefPage.module.scss'
import BalanceItem from './BalanceItem'
import SwipeBalanceCard from './SwipeBalanceCard'

let contentSwiper: any = null

type Props = {
  t: any
}

const BalancePage = ({ t }: Props) => {
  const { user, token } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
    token: userReducer.token,
  }))
  const [close, setClose] = useState<boolean>(true)
  const { isLoading, data, fetchNextPage, hasNextPage } = useBalanceInfinite(token || '')

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isIn = !pathname.startsWith('/balance/out')

  const allData = useMemo(() => {
    return data && data.pages ? data.pages.flatMap((page: any) => page.transactions.data) : []
  }, [data])

  const totalSumData = useMemo(() => {
    return data && data.pages
      ? {
          getLast7: data.pages[0].getLast7,
          getLast30: data.pages[0].getLast30,
          total: data.pages[0].total,
        }
      : {
          getLast7: 0,
          getLast30: 0,
          total: 0,
        }
  }, [data])

  const todayArr = useMemo(() => {
    return allData.filter((item: Balance) => {
      return checkDate('today', item.created_at)
    })
  }, [allData])

  const yesterdayArr = useMemo(() => {
    return allData.filter((item: Balance) => {
      return checkDate('yesterday', item.created_at)
    })
  }, [allData])

  const otherdayArr = useMemo(() => {
    return allData.filter((item: Balance) => {
      return checkDate('otherday', item.created_at)
    })
  }, [allData])

  useEffect(() => {
    if (contentSwiper && !contentSwiper.destroyed) {
      if (isIn) {
        contentSwiper.slideTo(0)
      } else {
        contentSwiper.slideTo(1)
      }
    }
  }, [isIn])

  useEffect(() => {
    if (contentSwiper && !contentSwiper.destroyed) {
      contentSwiper.updateAutoHeight(0)
    }
  }, [todayArr, yesterdayArr, otherdayArr])

  const fakeData = [
    {
      id: 1,
      title: 'Куда можно потратить баланс?',
      subtitle:
        'Баланс можно потратить оплатив свое или чужое желание, а так же на благотворительность',
      img: '/images/icons/up-arrow.svg',
    },
    {
      id: 2,
      title: 'Как пополняется баланс?',
      subtitle:
        'Когда вы удаляете старое желание, накопленные средства с этого желания возвращаются к вам на баланс',
      img: '/images/icons/down-arrow.svg',
    },
  ]

  if (isLoading) {
    return (
      <>
        <div className={s.content}>
          <HeaderMobileTitle.Source>{t('balance_page.balance')}</HeaderMobileTitle.Source>
          <Loader />
        </div>
      </>
    )
  }
  return (
    <>
      {/* <MetaDescriptionBalance />
      <MetaTitleBalance /> */}
      {/* <MetaOG
        title="Мой баланс"
        description="Узнайте общую сумму баланса и узнайте куда ее можно потратить"
      /> */}
      <div className={s.content}>
        <HeaderMobileTitle.Source>{t('balance_page.balance')}</HeaderMobileTitle.Source>
        <TopBlock
          type="balance"
          totalSum={totalSumData.total}
          getLast7={totalSumData.getLast7}
          getLast30={totalSumData.getLast30}
          t={t}
        />

        {!close ? (
          <></>
        ) : (
          <div className={s.balance_info}>
            <div className={s.balance_info_title}>
              О балансе
              <div className={s.close_btn} onClick={() => setClose(false)}>
                <img src="/images/icons/gray_cancel.svg" alt="" />
              </div>
            </div>
            <Swiper
              spaceBetween={20}
              slidesPerView="auto"
              className={`${s.swiper_container} ${s.profile_swiper}`}
            >
              {fakeData.map((item: any) => {
                return (
                  <SwiperSlide className={s.swiper_slide} key={item.id}>
                    <SwipeBalanceCard title={item.title} subtitle={item.subtitle} img={item.img} />
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
        )}
        <div className={s.cart_nav_block}>
          <div className={s.cart_nav}>
            <NavLink to="/balance/in" className={`${s.right} ${isIn ? s.active : ''}`} replace>
              {/* {t('balance_page.sum_out')} */}
              Отправлено
            </NavLink>
            <NavLink to="/balance/out" className={`${s.left} ${!isIn ? s.active : ''}`} replace>
              {/* {t('balance_page.sum_in')} */}
              Получено
            </NavLink>
          </div>
        </div>
        <div className={s.red_title}>
          <div className={s.text}>{t('balance_page.history')}</div>
        </div>
        <div className={s.content_block}>
          <Swiper
            slidesPerView="auto"
            className={`${s.swiper_container}`}
            autoHeight
            onInit={(swiper) => {
              contentSwiper = swiper
            }}
            onSlideChange={() => {
              if (contentSwiper.activeIndex === 0) {
                navigate('/balance/in', { replace: true })
              } else {
                navigate('/balance/out', { replace: true })
              }
            }}
          >
            <SwiperSlide>
              <InfiniteScroll
                className={s.history_content_block}
                dataLength={allData.length}
                next={fetchNextPage}
                style={{ display: 'flex', flexDirection: 'column' }}
                hasMore={!!hasNextPage}
                loader={<Loader />}
                scrollableTarget="page_content"
              >
                {allData.length === 0 ? (
                  <div className={s.empty_history}>{t('balance_page.empty')}</div>
                ) : (
                  <>
                    {todayArr.length ? (
                      <div className={s.history_items_block}>
                        <div className={s.title}>Сегодня</div>
                        <div className={s.items_container}>
                          {todayArr.map((item: Balance) =>
                            item.type && item.type === 'down' ? (
                              <BalanceItem data={item} key={item.id} t={t} />
                            ) : (
                              <></>
                            ),
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {yesterdayArr.length ? (
                      <div className={s.history_items_block}>
                        <div className={s.title}>Вчера</div>
                        <div className={s.items_container}>
                          {yesterdayArr.map((item: Balance) =>
                            item.type && item.type === 'down' ? (
                              <BalanceItem data={item} key={item.id} t={t} />
                            ) : (
                              <></>
                            ),
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {otherdayArr.length ? (
                      <div className={s.history_items_block}>
                        {todayArr.length || yesterdayArr.length ? (
                          <div className={s.title}>Ранее</div>
                        ) : (
                          ''
                        )}
                        <div className={s.items_container}>
                          {otherdayArr.map((item: Balance) =>
                            item.type && item.type === 'down' ? (
                              <BalanceItem data={item} key={item.id} t={t} />
                            ) : (
                              <></>
                            ),
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </InfiniteScroll>
            </SwiperSlide>
            <SwiperSlide>
              <InfiniteScroll
                className={s.history_content_block}
                dataLength={allData.length}
                next={fetchNextPage}
                hasMore={!!hasNextPage}
                style={{ display: 'flex', flexDirection: 'column' }}
                loader={<Loader />}
                scrollableTarget="page_content"
              >
                {allData.length === 0 ? (
                  <div className={s.empty_history}>{t('balance_page.empty')}</div>
                ) : (
                  <>
                    {todayArr.length ? (
                      <div className={s.history_items_block}>
                        <div className={s.title}>Сегодня</div>
                        <div className={s.items_container}>
                          {todayArr.map((item: Balance) =>
                            item.type === 'refund' ||
                            item.type === 'change' ||
                            item.type === 'cashback' ? (
                              <BalanceItem data={item} key={item.id} t={t} />
                            ) : (
                              <></>
                            ),
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {yesterdayArr.length ? (
                      <div className={s.history_items_block}>
                        <div className={s.title}>Вчера</div>
                        <div className={s.items_container}>
                          {yesterdayArr.map((item: Balance) =>
                            item.type === 'refund' ||
                            item.type === 'change' ||
                            item.type === 'cashback' ? (
                              <BalanceItem data={item} key={item.id} t={t} />
                            ) : (
                              <></>
                            ),
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    {otherdayArr.length ? (
                      <div className={s.history_items_block}>
                        {todayArr.length || yesterdayArr.length ? (
                          <div className={s.title}>Ранее</div>
                        ) : (
                          ''
                        )}
                        <div className={s.items_container}>
                          {otherdayArr.map((item: Balance) =>
                            item.type === 'refund' ||
                            item.type === 'change' ||
                            item.type === 'cashback' ? (
                              <BalanceItem data={item} key={item.id} t={t} />
                            ) : (
                              <></>
                            ),
                          )}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </InfiniteScroll>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default React.memo(BalancePage)
