import React from 'react'

import s from './BalanceAndRefPage.module.scss'

type Props = {
  title: string
  subtitle: string
  img: string
}

const SwipeBalanceCard = ({ title, subtitle, img }: Props) => {
  return (
    <div onClick={() => {}}>
      <div className={`${s.slide_content} `}>
        <div className={s.top_icon}>
          <div className={s.icon}>
            <img src={img} alt="" />
          </div>
        </div>
        <div className={s.title}>{title}</div>
        <div className={s.subtitle}>{subtitle}</div>
      </div>
    </div>
  )
}

export default React.memo(SwipeBalanceCard)
