import React, { useCallback, useRef, useState } from 'react'
import cn from 'classnames'
import InputMask from 'react-input-mask'
import { Button } from 'application/presentation/common/ActionComponents'
import { CardDataType } from 'application/domain/entity/payment/Payment'
import rus_to_latin from 'application/presentation/utils/rusToLatin'

import s from './CardForm.module.scss'

type Props = {
  // cardData: CardDataType
  // setCardData: Function
  containerClassName?: string
  dark?: boolean
  errorMsg?: string
  payIsLoading: boolean
  payProcess: Function
}

const CardForm = ({ containerClassName, dark, errorMsg, payProcess, payIsLoading }: Props) => {
  const [cardMask, setCardMask] = useState<string>('9999 9999 9999 9999')
  const monthInput = useRef<any>()
  const yearInput = useRef<any>(null)
  const cvvInput = useRef<any>()
  const cardInput = useRef<any>()
  const [cardData, setCardData] = useState<CardDataType>({
    cardNumber: '',
    cardCvv: '',
    cardExtMonth: '',
    cardExtYear: '',
    cardName: '',
  })
  const { cardNumber, cardExtMonth, cardExtYear, cardCvv, cardName } = cardData
  const cardChangeHandler = useCallback(
    (val: string, fieldKey: string) => {
      setCardData((prev: CardDataType) => {
        if (fieldKey === 'cardNumber') {
          if (/^3[47]/.test(val)) {
            setCardMask('9999 999999 99999')
          } else {
            setCardMask('9999 9999 9999 9999')
          }
        }
        if (fieldKey === 'cardExtMonth' && val.length === 2) {
          yearInput.current.getInputDOMNode().focus()
        }
        if (fieldKey === 'cardExtYear' && val.length === 2) {
          cvvInput.current.focus()
        }
        return {
          cardNumber: fieldKey === 'cardNumber' ? val : prev.cardNumber,
          cardExtMonth: fieldKey === 'cardExtMonth' ? val : prev.cardExtMonth,
          cardExtYear: fieldKey === 'cardExtYear' ? val : prev.cardExtYear,
          cardCvv: fieldKey === 'cardCvv' ? val : prev.cardCvv,
          cardName: fieldKey === 'cardName' ? val : prev.cardName,
        }
      })
    },
    [setCardData, yearInput, cvvInput],
  )

  return (
    <div className={s.payment_section}>
      <form id="paymentForm" className={s.payment_form} autoComplete="off">
        <div className={cn(s.first_block, dark ? s.dark : '')}>
          <div className={s.payment_item}>
            <div className={s.item_name}>Введите номер карты</div>
            <div className={s.input_item}>
              {/* <div className={`${s.bankCard_img} ${cardType || ''}`} /> */}
              <InputMask
                maskPlaceholder={null}
                mask={cardMask}
                data-cp="cardNumber"
                id="cardNumber"
                value={cardNumber}
                onChange={(e: any) => cardChangeHandler(e.target.value, 'cardNumber')}
                // onBlur={() => {
                //   this.checkField('cardNumber')
                // }}
                inputMode="numeric"
                maskChar=""
                alwaysShowMask={false}
                placeholder="XXXX XXXX XXXX XXXX"
                ref={cardInput}
              />
            </div>
          </div>
          <div className={`${s.payment_item} ${s.flex}`}>
            <div className={s.left}>
              <div className={s.item_name}>Имя владельца карты</div>
              <div className={s.input_item}>
                <input
                  type="text"
                  data-cp="name"
                  id="cardName"
                  placeholder="NAME"
                  onChange={(e: any) => cardChangeHandler(rus_to_latin(e.target.value), 'cardName')}
                  value={cardName}
                />
              </div>
            </div>
            <div className={s.right}>
              <div className={s.item_name}>Срок действия:</div>
              <div className={s.card_date}>
                <div className={`${s.input_item}`}>
                  <InputMask
                    inputRef={monthInput}
                    type="text"
                    data-cp="expDateMonth"
                    id="expDateMonth"
                    placeholder="MM"
                    inputMode="numeric"
                    mask="99"
                    maskChar=""
                    maskPlaceholder={null}
                    onChange={(e: any) => cardChangeHandler(e.target.value, 'cardExtMonth')}
                    value={cardExtMonth}
                    // onBlur={() => {
                    //   this.checkField('month')
                    // }}
                  />
                </div>
                <div className={`${s.input_item}`}>
                  <InputMask
                    ref={yearInput}
                    // inputRef={yearInput}
                    type="text"
                    data-cp="expDateYear"
                    id="expDateYear"
                    placeholder="YY"
                    inputMode="numeric"
                    mask="99"
                    maskChar=""
                    maskPlaceholder={null}
                    onChange={(e: any) => cardChangeHandler(e.target.value, 'cardExtYear')}
                    value={cardExtYear}
                  />
                </div>
              </div>
            </div>
          </div>
          {errorMsg ? <div className={s.CardHolderMessage}>{errorMsg}</div> : ''}
        </div>
        <div className={cn(s.second_block, dark ? s.dark : '')}>
          <div className={s.payment_item}>
            <div className={s.item_name}>CVV код:</div>
            <div className={`${s.input_item} ${s.cvv}`}>
              <input
                ref={cvvInput}
                type="password"
                pattern="[0-9]*"
                inputMode="numeric"
                data-cp="cvv"
                id="cvv"
                placeholder="***"
                maxLength={3}
                autoComplete="false"
                value={cardCvv}
                onChange={(e: any) => cardChangeHandler(e.target.value, 'cardCvv')}
              />
            </div>
          </div>
        </div>
      </form>
      <Button
        title="Оплатить"
        onClick={() => payProcess('bank')}
        isLoading={payIsLoading}
        color="red"
        containerClassName={s.red_btn_container}
        className={s.red_btn}
      />
    </div>
  )
}

export default React.memo(CardForm)
