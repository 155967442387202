import {
  fetchMainShop,
  fetchRandomShop,
  fetchShop,
  fetchShopCategory,
  fetchShopParentCategories,
  fetchShopSubCategory,
} from 'application/data/api/shop'
import queryClient from 'application/data/apiClient/queryClient'
import { Pagination } from 'application/domain/entity/Pagination'
import { ProductList } from 'application/domain/entity/product/Product'
import { ShopData, ShopMainData } from 'application/domain/entity/shop/Shop'
import {
  ShopCategory,
  ShopCategoryRequestType,
  ShopSubCategory,
} from 'application/domain/entity/shop/ShopCategory'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

export const getShop = async () => fetchShop()

export const useShop = (config?: any) => {
  const query = useQuery<ShopData>(['shop'], fetchShop, config)
  return query
}

export const getMainShop = async () => fetchMainShop()

export const useMainShop = () => {
  const prevData = queryClient.getQueryData<ShopMainData>(['shop'])
  const query = useQuery<ShopMainData>(['shop_main'], fetchMainShop, {
    enabled: prevData ? false : true,
  })

  if (prevData) {
    return {
      ...query,
      data: prevData,
    }
  }
  return query
}

export const useShopLastAddedProducts = (config?: any) => {
  const query = useInfiniteQuery<ShopCategoryRequestType>(
    ['category', 'something-other'],
    ({ pageParam = '/api/v1/shop/category/something-other' }) => fetchShopCategory(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.products.next_page_url
      },
      ...config,
    },
  )
  return query
}

export const getShopParentCategories = async () => fetchShopParentCategories()

export const useShopParentCategories = (config?: any) => {
  const query = useQuery<Pagination<ShopCategory>>(
    ['parentCategories'],
    fetchShopParentCategories,
    config,
  )

  return query
}

export const useShopRandomProducts = () => {
  const query = useInfiniteQuery<ProductList>(
    ['randomCategories'],
    ({ pageParam = '/api/v1/shop/random' }) => fetchRandomShop(pageParam),
    {
      getNextPageParam: () => '/api/v1/shop/random',
    },
  )

  return query
}

export const getShopSubCategory = async (id: number) => {
  const data = await fetchShopSubCategory(id)
  return data
}

export const useShopSubCategory = (id: number | string, config?: any) => {
  const query = useQuery<ShopSubCategory>(
    ['shop_sub_category', id],
    () => fetchShopSubCategory(id),
    {
      enabled: id ? true : false,
      ...config,
    },
  )
  return query
}

type UseShopCategoryProps = {
  partner_id?: number
  categ_id?: number
  categ_slug?: string
  requestParams?: string
}

export const getShopCategory = async ({
  partner_id,
  categ_id,
  categ_slug,
  requestParams,
}: UseShopCategoryProps) => {
  const data = fetchShopCategory(
    categ_id
      ? `/api/v1/shop/category/${categ_id}${
          requestParams
            ? `?${partner_id ? `partner=${partner_id}&` : ''}${requestParams}`
            : `${partner_id ? `?partner=${partner_id}` : ''}`
        }`
      : `/api/v1/shop/category/${categ_slug}${requestParams ? `?${requestParams}` : ''}`,
  )
  return data
}

export const useShopCategory = (
  { partner_id, categ_id, categ_slug, requestParams }: UseShopCategoryProps,
  config?: any,
) => {
  const query = useInfiniteQuery<ShopCategoryRequestType>(
    [
      `category${partner_id ? `_partner_${partner_id}` : ''}`,
      categ_id ? categ_id : categ_slug,
      requestParams ? requestParams : '',
    ],
    ({
      pageParam = categ_id
        ? `/api/v1/shop/category/${categ_id}${
            requestParams
              ? `?${partner_id ? `partner=${partner_id}&` : ''}${requestParams}`
              : `${partner_id ? `?partner=${partner_id}` : ''}`
          }`
        : `/api/v1/shop/category/${categ_slug}${requestParams ? `?${requestParams}` : ''}`,
    }) => fetchShopCategory(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.products.next_page_url
      },
      ...config,
    },
  )
  return query
}
