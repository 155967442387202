import React, { useState } from 'react'
import Modal from 'react-modal'

import s from './FiveBlock.module.scss'

Modal.setAppElement('#root')

const ModalWindow = ({ isOpen, setIsOpen }: any) => {
  return (
    <>
      {/* @ts-ignore */}
      <Modal
        isOpen={isOpen === 1 || isOpen === 2 || isOpen === 3}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }}
        closeTimeoutMS={500}
        className={`${s.modal_window_desk} ${s.full_open}`}
      >
        <div className={s.modal_content}>
          <div className={s.modal_inner_content}>
            <div className={s.logo}>
              <img src="/images/icons/logo-white.svg" alt="" />
            </div>
            <div className={s.modal_close_button} onClick={() => setIsOpen(0)}>
              <img src="/images/icons/close-icon-white.svg" alt="" />
            </div>
            <div className={s.modal_container}>
              <video className={s.video} preload="auto" autoPlay loop>
                {isOpen === 1 ? <source src="/videos/curan.mp4" type="video/mp4" /> : <></>}
                {isOpen === 2 ? <source src="/videos/sergey.mp4" type="video/mp4" /> : <></>}
                {isOpen === 3 ? <source src="/videos/anna.mp4" type="video/mp4" /> : <></>}
              </video>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const FiveBlock = () => {
  const [isOpen, setIsOpen] = useState(0)

  return (
    <div className={s.container}>
      <ModalWindow isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className={s.inner_content}>
        <div className={s.title}>О проекте в цифрах</div>
        <div className={s.wish_stat_mobile}>
          <div className={s.card}>
            <div className={s.inner}>
              <div className={s.img_container}>
                <img src="/images/mainPage/main-five-check.svg" alt="" />
              </div>
              <div className={s.counter}>93</div>
              <div className={s.subline}>Желаний исполнено</div>
            </div>
          </div>
          <div className={s.card}>
            <div className={s.inner}>
              <div className={s.img_container}>
                <img src="/images/mainPage/main-five-speed.svg" alt="" />
              </div>
              <div className={s.counter}>907</div>
              <div className={s.subline}>Донатов сделано</div>
            </div>
          </div>
          <div className={s.card}>
            <div className={s.inner}>
              <div className={s.img_container}>
                <img src="/images/mainPage/main-five-rub.svg" alt="" />
              </div>
              <div className={s.counter}>1371053</div>
              <div className={s.subline}>Общая сумма донатов</div>
            </div>
          </div>
        </div>
        <div className={s.wish_completed}>
          <div className={s.inner_content_wish}>
            <div className={s.title_wish}>Они уже осуществили свою мечту</div>
            <div className={s.review_block}>
              <div className={s.item}>
                <div className={s.img_block}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      setIsOpen(1)
                    }}
                  >
                    <img src="/images/mainPage/curan.png" loading="lazy" alt="" />
                  </a>
                </div>
                <div className={s.text}>Видео-распаковка подарков от Ани Курановой</div>
              </div>
              <div className={s.item}>
                <div className={s.img_block}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      setIsOpen(2)
                    }}
                  >
                    <img src="/images/mainPage/sergey.png" loading="lazy" alt="" />
                  </a>
                </div>
                <div className={s.text}>Дорогой подарок для Сергея от друзей</div>
              </div>
              <div className={s.item}>
                <div className={s.img_block}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                      setIsOpen(3)
                    }}
                  >
                    <img src="/images/mainPage/anna.png" loading="lazy" alt="" />
                  </a>
                </div>
                <div className={s.text}>Анна Плотникова о своем желании и его осуществлении </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(FiveBlock)
