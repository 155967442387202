import React from 'react'
import cn from 'classnames'

import s from './DeliveryMethodsWrapper.module.scss'

type Props = {
  data: any
  value: number
  onChange: Function
}

const DeliveryMethodsWrapper = ({ data, value, onChange }: Props) => {
  return (
    <div className={s.container}>
      <div className={s.headline}>Cпособ получения заказа</div>
      <div className={s.title}>
        Пункты выдачи в <span className={s.red}>г.Чебоксары</span>
      </div>

      {data ? (
        data.map((item: any) => {
          return (
            <div
              className={cn(s.item, item.id === value ? s.selected : '')}
              onClick={() => onChange(item.id)}
              key={`delivery_method_${item.id}`}
            >
              <div className={s.name}>{item.name}</div>
              <div className={s.address}>{item.address}</div>
              <div className={s.days}>
                {item.work_days ? item.work_days : ''} {item.from ? item.from.slice(0, -3) : ''} -{' '}
                {item.to ? item.to.slice(0, -3) : ''}
              </div>
              {item.id === value ? (
                <img src="/images/icons/red_accept.svg" alt="" className={s.selected_icon} />
              ) : (
                <></>
              )}
            </div>
          )
        })
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(DeliveryMethodsWrapper)
