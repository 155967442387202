import React from 'react'
import cn from 'classnames'

import s from './Menu.module.scss'

type Props = {
  children?: React.ReactNode
}

const Menu = ({ children }: Props): React.ReactElement => {
  return <div className={cn({ [s.menu]: true, [s.menu_mobile]: true })}>{children}</div>
}

export default Menu
