import React from 'react'
import { NavLink } from 'react-router-dom'

import s from './Footer.module.scss'

type Props = {
  showFooter: boolean
}

export const FooterMobileNotAuth = ({ showFooter }: Props) => {
  return (
    <div className={`${s.footer_container} ${!showFooter ? s.hide_footer : ''}`}>
      <div>
        <NavLink to="/home" className={({ isActive }) => `${s.navLink} ${isActive && s.active}`}>
          <div className={s.icon}>
            {/* <img loading="lazy" src='/images/icons/compas.svg'></img> */}
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M23.2141 9.77659L12.7975 0.227959C12.4581 -0.082539 11.9361 -0.0749706 11.6062 0.245049L1.76822 9.79363C1.59981 9.95711 1.50459 10.182 1.50459 10.4166V24.1319C1.50459 24.6113 1.89317 24.9999 2.37266 24.9999H9.60645C10.0859 24.9999 10.4745 24.6113 10.4745 24.1319V17.7661H14.5254V24.1319C14.5254 24.6113 14.914 24.9999 15.3935 24.9999H22.6273C23.1067 24.9999 23.4954 24.6116 23.4954 24.1319V10.4166C23.4954 10.1733 23.3935 9.94124 23.2141 9.77659ZM21.7592 23.2638H16.2616V16.8981C16.2616 16.4187 15.873 16.0301 15.3935 16.0301H9.6065C9.12706 16.0301 8.73843 16.4187 8.73843 16.8981V23.2638H3.24073V10.7838L12.2277 2.06131L21.7593 10.7986V23.2638H21.7592Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="25" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={s.title}>Главная</div>
        </NavLink>
      </div>
      <div>
        <NavLink to="/login" className={({ isActive }) => `${s.navLink} ${isActive && s.active}`}>
          <div className={s.icon}>
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M18.4834 13.3152H2.81066C2.36318 13.3152 2 12.95 2 12.5C2 12.05 2.36318 11.6848 2.81066 11.6848H18.4834C18.9309 11.6848 19.2941 12.05 19.2941 12.5C19.2941 12.95 18.9309 13.3152 18.4834 13.3152Z"
                  fill="black"
                />
                <path
                  d="M14.1599 17.663C13.9523 17.663 13.7449 17.5837 13.587 17.4239C13.2703 17.1055 13.2703 16.5892 13.587 16.2706L17.3377 12.499L13.587 8.72722C13.2703 8.40878 13.2703 7.8923 13.587 7.57386C13.9038 7.25541 14.4172 7.25541 14.7339 7.57386L19.0574 11.9218C19.374 12.2403 19.374 12.7565 19.0574 13.075L14.7339 17.4227C14.575 17.5837 14.3676 17.663 14.1599 17.663Z"
                  fill="black"
                />
                <path
                  d="M12.9706 24.4566C8.05141 24.4566 3.70855 21.4805 1.90454 16.8738C1.74027 16.4565 1.94551 15.9826 2.36173 15.8174C2.77676 15.6544 3.24918 15.8576 3.41345 16.2783C4.97105 20.2555 8.72275 22.8261 12.9706 22.8261C18.6322 22.8261 23.2391 18.1934 23.2391 12.5C23.2391 6.8066 18.6322 2.17383 12.9706 2.17383C8.72275 2.17383 4.97105 4.74447 3.41345 8.72164C3.248 9.14239 2.77676 9.34559 2.36173 9.18259C1.94551 9.0174 1.74027 8.54351 1.90454 8.12615C3.70855 3.51946 8.05141 0.543396 12.9706 0.543396C19.5262 0.543396 24.8604 5.90759 24.8604 12.5C24.8604 19.0924 19.5262 24.4566 12.9706 24.4566Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="24.8603" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={s.title}>Войти</div>
        </NavLink>
      </div>
      <div>
        <NavLink to="/shop" className={({ isActive }) => `${s.navLink} ${isActive && s.active}`}>
          <div className={s.icon}>
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M19.8 17.7073C18.085 17.7073 16.6819 19.1104 16.6819 20.8254C16.6819 22.5404 18.085 23.9435 19.8 23.9435C21.515 23.9435 22.9181 22.5404 22.9181 20.8254C22.9181 19.1104 21.515 17.7073 19.8 17.7073Z"
                  fill="black"
                />
                <path
                  d="M24.8202 5.67136C24.6331 5.4531 24.3837 5.29721 24.0718 5.29721H5.76851L4.92663 1.77373C4.8331 1.33721 4.45889 1.05658 4.02237 1.05658H0.935407C0.405365 1.05652 0 1.46188 0 1.99199C0 2.52209 0.405365 2.92739 0.935407 2.92739H3.27401L6.29861 15.6493C6.39213 16.0858 6.76634 16.3664 7.20286 16.3664H21.7645C22.201 16.3664 22.5752 16.0858 22.6687 15.6493L24.9761 6.45088C25.0385 6.17025 24.9761 5.88962 24.8202 5.67136Z"
                  fill="black"
                />
                <path
                  d="M8.48126 17.7073C6.76628 17.7073 5.36314 19.1104 5.36314 20.8254C5.36314 22.5404 6.76628 23.9435 8.48126 23.9435C10.1962 23.9435 11.5994 22.5404 11.5994 20.8254C11.5994 19.1104 10.1962 17.7073 8.48126 17.7073Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="25" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={s.title}>Каталог</div>
        </NavLink>
      </div>
      <div>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            `${s.navLink} ${s.notification_link} ${isActive && s.active}`
          }
        >
          <div className={s.icon}>
            {/* <img loading="lazy" src='/images/icons/alert-icon.svg'></img> */}
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 0C5.6075 0 0 5.60758 0 12.5001C0 19.3926 5.6075 25 12.5 25C19.3925 25 25 19.3926 25 12.5001C25 5.60758 19.3925 0 12.5 0ZM12.5 23C6.86061 23 2 18.1393 2 12.5C2 6.86068 6.86061 2 12.5 2C18.1394 2 23 6.86068 23 12.5C23 18.1393 18.1393 23 12.5 23Z"
                fill="black"
              />
              <path
                d="M12.4998 5.30304C11.6645 5.30304 10.985 5.98304 10.985 6.81887C10.985 7.65395 11.6645 8.33334 12.4998 8.33334C13.3352 8.33334 14.0147 7.65395 14.0147 6.81887C14.0147 5.98304 13.3352 5.30304 12.4998 5.30304Z"
                fill="black"
              />
              <path
                d="M12.5 10.606C11.8724 10.606 11.3636 11.1148 11.3636 11.7424V18.5606C11.3636 19.1882 11.8724 19.697 12.5 19.697C13.1276 19.697 13.6364 19.1882 13.6364 18.5606V11.7424C13.6364 11.1148 13.1276 10.606 12.5 10.606Z"
                fill="black"
              />
            </svg>
          </div>
          <div className={s.title}>О сервисе</div>
        </NavLink>
      </div>
      <div>
        <NavLink to="/login" className={({ isActive }) => `${s.navLink} ${isActive && s.active}`}>
          <div className={s.icon}>
            {/* <img loading="lazy" src='/images/icons/3points.svg'></img> */}
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g>
                <path
                  d="M21.9478 16.0886C20.2606 16.0886 18.8885 14.7166 18.8885 13.0294C18.8885 11.3421 20.2606 9.97009 21.9478 9.97009C23.6351 9.97009 25.0071 11.3421 25.0071 13.0294C25.0071 14.7166 23.635 16.0886 21.9478 16.0886ZM21.9478 11.4997C21.1046 11.4997 20.4182 12.1861 20.4182 13.0294C20.4182 13.8726 21.1046 14.559 21.9478 14.559C22.7911 14.559 23.4775 13.8726 23.4775 13.0294C23.4775 12.1861 22.7911 11.4997 21.9478 11.4997Z"
                  fill="black"
                />
                <path
                  d="M12.5035 16.0886C10.8163 16.0886 9.44427 14.7166 9.44427 13.0294C9.44427 11.3421 10.8163 9.97009 12.5035 9.97009C14.1908 9.97009 15.5628 11.3421 15.5628 13.0294C15.5628 14.7166 14.1908 16.0886 12.5035 16.0886ZM12.5035 11.4997C11.6603 11.4997 10.9739 12.1861 10.9739 13.0294C10.9739 13.8726 11.6603 14.559 12.5035 14.559C13.3468 14.559 14.0332 13.8726 14.0332 13.0294C14.0332 12.1861 13.3468 11.4997 12.5035 11.4997Z"
                  fill="black"
                />
                <path
                  d="M3.05926 16.0886C1.37205 16.0886 0 14.7166 0 13.0294C0 11.3421 1.37205 9.97009 3.05926 9.97009C4.74651 9.97009 6.11852 11.3421 6.11852 13.0294C6.11856 14.7166 4.74651 16.0886 3.05926 16.0886ZM3.05926 11.4997C2.21601 11.4997 1.52961 12.1861 1.52961 13.0294C1.52961 13.8726 2.21601 14.559 3.05926 14.559C3.90251 14.559 4.58891 13.8726 4.58891 13.0294C4.58891 12.1861 3.90251 11.4997 3.05926 11.4997Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="25" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={s.title}>Меню</div>
        </NavLink>
      </div>
    </div>
  )
}

export default React.memo(FooterMobileNotAuth)
