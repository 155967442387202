import React from 'react'
import { useNavigate } from 'react-router-dom'

import ResultItem from './components/ResultItem'
// import HistoryContainer from './components/HistoryContainer'
// import ProductItem from './components/ProductItem'
// import ResultItem from './components/ResultItem'
import s from './SearchMW.module.scss'

type Props = {
  data: any
  setSearchVal: Function
  //   historyData: any
  //   isHistoryArray: boolean
  searchVal: string
  doSearch: Function
  isIdeaFriendNickname: string | boolean
}

const SearchResults = ({
  data,
  setSearchVal,
  searchVal,
  doSearch,
  isIdeaFriendNickname,
}: Props) => {
  const navigate = useNavigate()
  const { predict, category, products } = data
  return (
    <div>
      {predict && predict.length ? (
        <div className={s.history_container}>
          <div className={s.predict_container}>
            {predict.map((item: string) => {
              return (
                <div
                  className={s.search_item_predict}
                  key={item}
                  onClick={() => setSearchVal(item)}
                >
                  <div className={s.search_item_predict_txt}>{item}</div>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
      {category && category.length ? (
        <div className={s.history_container}>
          {category.map((item: any) => (
            <ResultItem
              item={item}
              key={item.id}
              type="category"
              isIdeaFriendNickname={isIdeaFriendNickname}
            />
          ))}
        </div>
      ) : (
        <></>
      )}
      {products && products.length ? (
        <div className={s.history_container}>
          {products.map((item: any) => (
            <ResultItem
              item={item}
              key={item.id}
              type="product"
              isIdeaFriendNickname={isIdeaFriendNickname}
            />
          ))}
        </div>
      ) : (
        <></>
      )}
      <div className={s.see_all} onClick={() => doSearch()}>
        <img src="/images/icons/scope_gray.svg" alt="" />
        <div className={s.see_all_center}>
          <div className={s.red_txt}>Смотреть все результаты</div>
          <div className={s.gray_txt}>Искать по всем категориям</div>
        </div>
      </div>
      <div
        className={s.card}
        onClick={() => navigate('/shop/category/slug/0/add?without_price=true')}
        // onPress={() => {
        // //   navigation.push('wishNav', {
        // //     screen: 'add_wish_page',
        // //     params: { type: 'wish', wish_id: 0, without_price: true, wish_name: searchVal },
        // //   })
        // }}
      >
        <div className={s.left}>
          <div className={s.card_title}>Добавить в список без сбора донатов</div>
          <div className={s.card_val}>{searchVal}</div>
        </div>
        <div className={s.card_icon}>
          <img src="/images/icons/add-button.svg" alt="" />
          {/* <SvgXml xml={addSvg} width={26} height={26} /> */}
        </div>
      </div>
    </div>
  )
}

export default React.memo(SearchResults)
