/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import parseComment from 'application/presentation/pages/CommentsPage/parseComment'
import { SendCommentField } from 'application/presentation/common/ActionComponents'
import _ from 'lodash'
import { AuthUser } from 'application/domain/entity/user/User'
import { Wish } from 'application/domain/entity/wish/Wish'
import { useComments } from 'application/domain/useCase/comments/getComments'

import s from '../WishPage.module.scss'

type Props = {
  user: AuthUser
  wishData: Wish
  totalComments: number
  firstComment: any
  likeWish: Function
}

const sumVariants = [
  { id: 1, value: 150, emoji: '☕️' },
  { id: 2, value: 300, emoji: '🍔️' },
  { id: 3, value: 450, emoji: '🤑' },
  { id: 4, value: 600, emoji: '💰' },
  {
    id: 5,
    value: '100%',
    emoji: '✔️',
  },
]

const Content = ({ wishData, user, totalComments, firstComment, likeWish }: Props) => {
  const navigate = useNavigate()
  const params = useParams<{ wishid?: any }>()
  const inputRef = useRef<any>(null)
  const { search, pathname } = useLocation()
  const [commentText, setCommentText] = useState<string>('')
  const [commentPlayText, setCommentPlayText] = useState<string>('')

  const likesCount = useMemo(() => {
    return firstComment && firstComment.likes ? firstComment.likes.length : 0
  }, [firstComment])

  const wish_id = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const id = searchParams.get('wish_id')
    return params && params.wishid ? parseInt(params.wishid, 10) : id ? parseInt(id) : 0
  }, [search, params])

  const { data: commentsData } = useComments(wish_id)

  const comments = useMemo(() => {
    return commentsData ? commentsData.pages.flatMap((page: any) => page.data) : []
  }, [commentsData])

  const liked = useMemo(() => {
    let isLiked = false
    if (firstComment) {
      firstComment.likes.map((likeItem: any) => {
        if (user && likeItem.user!.id === user.id) {
          isLiked = true
        }
        return true
      })
    }
    return isLiked
  }, [firstComment, user])

  const mentionedUserData = useMemo(() => {
    const mentionedData: any = []
    if (wishData && wishData.user) {
      mentionedData.push({
        id: wishData.user?.id,
        display: wishData.user!.nickname,
        photo: wishData.user!.photo,
      })
      comments.map((item: any) => {
        if (!_.find(mentionedData, (u: any) => u.id === item.user.id)) {
          mentionedData.push({
            id: item.user.id,
            display: item.user.nickname,
            photo: item.user.photo,
          })
        }
      })
    }
    return mentionedData
  }, [comments, wishData])

  return (
    <>
      <div className={s.wish_information_container}>
        {wishData.public_comment ? (
          wishData.product && wishData.product.id !== 153793 ? (
            <div className={`${s.comment_block} comment-block`}>
              <div className={s.comment_text}>
                <span className={s.user_name}>
                  {wishData.user!.name ? wishData.user.name : wishData.user.nickname}
                </span>
                <span className={s.txt}>{wishData.public_comment}</span>
              </div>
            </div>
          ) : !wishData.product ? (
            <div className={`${s.comment_block} comment-block`}>
              <div className={s.comment_text}>
                <span className={s.user_name}>
                  {wishData.user!.name ? wishData.user.name : wishData.user.nickname}
                </span>
                <span className={s.txt}>{wishData.public_comment}</span>
              </div>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {wishData && wishData.status === 'opened' ? (
          <>
            <button
              type="button"
              className={s.donate_btn}
              onClick={() => {
                navigate(`${pathname}/donate`)
              }}
            >
              <span className={s.img_donate}>
                <img src="/images/icons/rub_icon.svg" alt="" />
              </span>{' '}
              <span className={s.text_donate}>Помочь исполнить</span>
            </button>

            <div className={s.selector_sum}>
              {sumVariants.map((item) => {
                return (
                  <button
                    type="button"
                    key={`sum_${item.value}`}
                    className={s.btn}
                    data-val={item.value}
                    onClick={() => {
                      navigate(
                        `${pathname}/donate?sum=${
                          item.value === '100%' ? wishData?.available_amount : item.value
                        }`,
                      )
                    }}
                  >
                    <span>{item.emoji}</span> {item.value} {item.value !== '100%' ? 'руб' : ''}
                  </button>
                )
              })}
            </div>
          </>
        ) : (
          ''
        )}

        <div className={s.stat_block}>
          <div
            className={`${s.likes} ${wishData.liked ? '' : s.unliked}`}
            onClick={() => likeWish()}
          >
            <img
              alt=""
              src={
                wishData.liked ? '/images/icons/red-hearth.svg' : '/images/icons/hearth-icon.svg'
              }
            />
            <span>{wishData.likes_count}</span>
          </div>
          <div
            className={s.comments}
            onClick={() => {
              navigate(`${pathname}/comments`)
            }}
          >
            <img src="/images/icons/comment-icon.svg" alt="" />
            <span>{totalComments}</span>
          </div>
          {wishData.likes && wishData.likes.length ? (
            <div
              className={s.liked_users}
              onClick={() => {
                // dispatch(setLikesMwStatus(true, 'wish', wishData.id))
              }}
            >
              <div className={s.text}>Нравится</div>
              <div className={s.users}>
                {wishData.likes[0] && (
                  <img
                    className={`${s.photo} ${s.photo1}`}
                    src={wishData.likes[0].user!.photo ? wishData.likes[0].user!.photo : ''}
                    alt=""
                  />
                )}
                {wishData.likes[1] && (
                  <img
                    className={`${s.photo} ${s.photo2}`}
                    src={wishData.likes[1].user!.photo ? wishData.likes[1].user!.photo : ''}
                    alt=""
                  />
                )}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>

        <div className={s.wish_history_comment}>
          <div
            className={s.title}
            onClick={() => {
              navigate(`${pathname}/comments`)
            }}
          >
            Комментарии
          </div>
          {firstComment && firstComment.user ? (
            <div
              className={s.history_item_preview}
              onClick={() => {
                navigate(`${pathname}/comments`)
              }}
            >
              <div className={`${s.comment_item} ${s.first}`}>
                <div className={s.user_img}>
                  <img src={firstComment ? firstComment.user.photo : ''} alt="" />
                </div>
                <div className={s.center}>
                  <div className={s.text}>
                    <div className={s.comment_text}>
                      <span>
                        {firstComment.user.name
                          ? firstComment.user.name
                          : firstComment.user.nickname}
                      </span>{' '}
                      {parseComment(
                        firstComment.text
                          ? firstComment.text
                          : firstComment.comment
                          ? firstComment.comment
                          : '',
                        firstComment.mentioned_user ? firstComment.mentioned_user : [],
                      )}
                    </div>
                  </div>
                  {firstComment.amount ? (
                    <div className={s.sum}>+ {firstComment.amount} руб</div>
                  ) : (
                    ''
                  )}
                </div>
                <div className={s.right}>
                  <div className={s.likes}>
                    {liked ? (
                      <img src="/images/icons/red-hearth.svg" alt="" />
                    ) : (
                      <img src="/images/icons/hearth-icon.svg" alt="" />
                    )}
                    <span>{likesCount}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <SendCommentField
              mentionedUserData={mentionedUserData}
              wish_id={wish_id}
              commentText={commentText}
              setCommentText={setCommentText}
              commentPlayText={commentPlayText}
              setCommentPlayText={setCommentPlayText}
              mentionsRef={inputRef}
            />
          )}
          {totalComments > 0 && (
            <div
              className={s.show_all}
              onClick={() => {
                navigate(`${pathname}/comments`)
              }}
            >
              Смотреть всю историю желания ({totalComments})
            </div>
          )}
        </div>
        {wishData.product ? (
          <NavLink
            className={`${s.flex_link} ${s.prod_link} link external`}
            to={`/shop/category/${wishData.product.categories_id}/${wishData.product.id}`}
          >
            <div>
              Подробнее о <span>{wishData.product.name}</span>
            </div>
            <div className={s.arrow}>
              <img src="/images/icons/black-right-trigger.svg" alt="" />
            </div>
          </NavLink>
        ) : (
          ''
        )}

        {wishData.product && wishData.product.partner ? (
          <div className={s.provider}>
            <div className={s.left}>
              <div className={s.title}>Поставщик</div>
              <div className={s.subtitle}>{wishData.product.partner.name}</div>
            </div>
            <div className={s.right}>
              <img
                src={wishData.product.partner.photo ? wishData.product.partner.photo : ''}
                alt=""
              />
            </div>
          </div>
        ) : (
          ''
        )}

        {wishData.product && wishData.product.partner ? (
          <NavLink
            to={`/shop/brands/${wishData.product.partner.id}`}
            className={`${s.flex_link} ${s.partner_link} link external`}
          >
            <div>Товары поставщика</div>
            <div className={s.arrow}>
              <img src="/images/icons/black-right-trigger.svg" alt="" />
            </div>
          </NavLink>
        ) : (
          ''
        )}

        <div className={s.create_date}>
          Желание создано {moment.unix(parseInt(wishData.created_at, 10)).format('DD.MM.YYYY')} в{' '}
          {moment.unix(parseInt(wishData.created_at, 10)).format('HH:mm')}
        </div>
      </div>
    </>
  )
}

export default React.memo(Content)
