/* eslint-disable react/display-name */
import React from 'react'
import { Route, NavLink, Routes } from 'react-router-dom'
import { useAppSelector } from 'application/domain/store/hooks'
import { RootState } from 'application/domain/store/reduxStore'

import { FooterAuth } from './FooterAuth'
import FooterNotAuth from './FooterNotAuth'
import s from './Footer.module.scss'

type Props = {
  showFooter: boolean
}

const FooterComponent = React.memo(() => {
  return (
    <section className={s.footer}>
      <div className={s.first_row}>
        <div className={s.logo}>
          <img loading="lazy" src="/images/icons/newlogo-gray.svg" alt="Логотип" />
        </div>
        <div className={s.social_links}>
          <a
            href="https://vk.com/wishmen_ru"
            className={`${s.link_item} link external`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <svg
              width="612"
              height="402"
              viewBox="0 0 612 402"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M401.277 174.642H347.09V25.736C347.09 11.9372 336.54 0.75 323.498 0.75H257.543C249.661 0.75 243.291 7.51715 243.291 15.8503V35.6434C243.291 43.9766 249.661 50.7437 257.543 50.7437H299.906V349.541C237.5 340.523 176.226 296.782 129.685 235.462C83.0762 174.053 51.2684 95.0425 47.9066 15.7091C47.5746 7.78613 41.1992 1.59921 33.6775 1.59921H14.9949C6.75479 1.59921 0.390511 8.94534 0.765802 17.6246C4.78575 115.616 45.7674 211.514 105.587 282.916C165.404 354.315 244.086 401.25 323.52 401.25C336.562 401.25 347.112 390.041 347.112 376.264V224.68H401.299C486.838 224.68 557.204 294.94 563.749 383.925C564.324 391.731 570.476 397.723 577.891 397.723H596.617C604.921 397.723 611.486 390.203 610.889 381.451C605.56 302.318 561.094 234.488 497.931 199.672C561.094 164.833 605.56 97.0035 610.889 17.8704C611.486 9.11915 604.921 1.59921 596.617 1.59921H577.891C570.498 1.59921 564.324 7.61226 563.749 15.3964C557.204 104.381 486.838 174.642 401.277 174.642Z"
                fill="#AEAEAE"
                stroke="#AEAEAE"
                strokeWidth="0.5"
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/wishmen_ru/"
            className={`${s.link_item} link external`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <svg
              width="402"
              height="402"
              viewBox="0 0 402 402"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M93.3143 401.25H308.686C359.738 401.25 401.25 359.738 401.25 308.7V93.3143C401.25 42.2763 359.738 0.75 308.7 0.75H93.3143C42.2762 0.75 0.75 42.2762 0.75 93.3143V308.686C0.75 359.738 42.2762 401.25 93.3143 401.25ZM32.0214 93.3143C32.0214 59.5095 59.5237 32.0214 93.3143 32.0214H308.7C342.49 32.0214 369.993 59.5238 369.993 93.3143V308.7C369.993 342.49 342.49 369.993 308.7 369.993H93.3143C59.5095 369.993 32.0214 342.491 32.0214 308.7V93.3143Z"
                fill="#AEAEAE"
                stroke="#AEAEAE"
                strokeWidth="0.5"
              />
              <path
                d="M111.748 200.998C111.748 250.208 151.788 290.248 200.998 290.248C250.207 290.248 290.248 250.208 290.248 200.998C290.248 151.789 250.207 111.748 200.998 111.748C151.788 111.748 111.748 151.789 111.748 200.998ZM143.019 200.998C143.019 169.022 169.021 143.019 200.998 143.019C232.974 143.019 258.976 169.022 258.976 200.998C258.976 232.974 232.974 258.977 200.998 258.977C169.021 258.977 143.019 232.974 143.019 200.998Z"
                fill="#AEAEAE"
                stroke="#AEAEAE"
                strokeWidth="0.5"
              />
              <path
                d="M301.858 123.459C314.738 123.459 325.18 113.018 325.18 100.138C325.18 87.2578 314.738 76.8164 301.858 76.8164C288.978 76.8164 278.537 87.2578 278.537 100.138C278.537 113.018 288.978 123.459 301.858 123.459Z"
                fill="#AEAEAE"
                stroke="#AEAEAE"
                strokeWidth="0.5"
              />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/wishmen.ru/"
            className={`${s.link_item} link external`}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <svg
              width="402"
              height="402"
              viewBox="-110 1 511 511.99996"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#AEAEAE"
                strokeWidth="0.5"
                stroke="#AEAEAE"
                d="m180 512h-81.992188c-13.695312 0-24.835937-11.140625-24.835937-24.835938v-184.9375h-47.835937c-13.695313 0-24.835938-11.144531-24.835938-24.835937v-79.246094c0-13.695312 11.140625-24.835937 24.835938-24.835937h47.835937v-39.683594c0-39.347656 12.355469-72.824219 35.726563-96.804688 23.476562-24.089843 56.285156-36.820312 94.878906-36.820312l62.53125.101562c13.671875.023438 24.792968 11.164063 24.792968 24.835938v73.578125c0 13.695313-11.136718 24.835937-24.828124 24.835937l-42.101563.015626c-12.839844 0-16.109375 2.574218-16.808594 3.363281-1.152343 1.308593-2.523437 5.007812-2.523437 15.222656v31.351563h58.269531c4.386719 0 8.636719 1.082031 12.289063 3.121093 7.878906 4.402344 12.777343 12.726563 12.777343 21.722657l-.03125 79.246093c0 13.6875-11.140625 24.828125-24.835937 24.828125h-58.46875v184.941406c0 13.695313-11.144532 24.835938-24.839844 24.835938zm-76.8125-30.015625h71.632812v-193.195313c0-9.144531 7.441407-16.582031 16.582032-16.582031h66.726562l.027344-68.882812h-66.757812c-9.140626 0-16.578126-7.4375-16.578126-16.582031v-44.789063c0-11.726563 1.191407-25.0625 10.042969-35.085937 10.695313-12.117188 27.550781-13.515626 39.300781-13.515626l36.921876-.015624v-63.226563l-57.332032-.09375c-62.023437 0-100.566406 39.703125-100.566406 103.609375v53.117188c0 9.140624-7.4375 16.582031-16.578125 16.582031h-56.09375v68.882812h56.09375c9.140625 0 16.578125 7.4375 16.578125 16.582031zm163.0625-451.867187h.003906zm0 0"
              />
            </svg>
          </a>
        </div>
      </div>
      <div className={s.second_row}>
        <div>
          <NavLink to="/faq" className="link external">
            О сервисе
          </NavLink>
          <NavLink to="/contacts" className="link external">
            Контакты
          </NavLink>
        </div>
        <div>
          <NavLink to="/documentation/oferta" className="link external">
            Пользовательское соглашение
          </NavLink>
        </div>
      </div>
      <div className={s.third_row}>
        <div>©2020 Wish.men - единый список желаний.</div>
        <div>Все права защищены</div>
        <br />
        <div>ООО «Исполнение желаний»</div>
        <div>ООО «Ispolneniye zhelaniy»</div>
        <div>ИНН 2130213331 ОГРН 1192130009488</div>
      </div>
    </section>
  )
})

const Footer = ({ showFooter }: Props) => {
  const { user } = useAppSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))

  return (
    <Routes>
      <Route path={'/login'} />
      <Route path={'/recovery'} />
      <Route path={'/registration'} />
      <Route path="/about" element={<FooterComponent />} />
      <Route path="/for_partners" element={<FooterComponent />} />
      <Route path="/home" element={<FooterComponent />} />
      <Route path="/" element={<FooterComponent />} />
      <Route
        path="*"
        element={
          <section className={`${s.footer_section} mobile_foot_menu`}>
            {user ? (
              <FooterAuth user={user} showFooter={showFooter} />
            ) : (
              <FooterNotAuth showFooter={showFooter} />
            )}
          </section>
        }
      ></Route>
    </Routes>
  )
}

export default React.memo(Footer)
