import BottomSheet from 'application/presentation/common/ModalWindows/BottomSheet'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'

import s from './BalanceItemBS.module.scss'

type Props = {
  data: any
  opened: boolean
  onClose: () => void
  t: any
}

const BalanceItemBS = (props: Props) => {
  const { opened, onClose, data, t } = props
  const [sheetOpened, setSheetOpened] = useState(opened)
  const handleSheetClosed = () => {
    setSheetOpened(false)

    if (opened) {
      onClose()
    }
  }

  useEffect(() => {
    setSheetOpened(opened)
  }, [opened])

  const userName = data.recipient
    ? data.recipient.name
      ? data.recipient.name
      : data.recipient.nickname
      ? data.recipient.nickname
      : t('general.undefined')
    : t('general.undefined')
  const userTeg = data.recipient ? data.recipient.nickname : ''

  return (
    <BottomSheet opened={sheetOpened} onSheetClosed={handleSheetClosed} autoHeight>
      <div className={s.content}>
        <div className={s.headline}>{t('balance_page.donate_detail')}</div>
        <div className={s.top_block}>
          <div className={s.left}>
            <div className={s.title}>
              {data.type === 'refund'
                ? t('balance_page.refund_sum')
                : data.type === 'down'
                ? 'Сумма списания'
                : t('balance_page.reward_sum')}
            </div>
            <div className={s.sum}>
              {data.type === 'down' ? `-` : `+`} {data.amount} {t('general.chars.rub')}
            </div>
            <div className={s.type_block}>
              <div className={s.rub_wrapper}>
                <img src="/images/icons/white_rubble.svg" alt="Р" />
              </div>
              <div className={s.text}>{t('balance_page.balance')}</div>
            </div>
          </div>
          <div className={s.right}>
            <div className={`${s.icon_wrapper} ${data.type === 'refund' ? s.red : s.fiol}`}>
              <img
                src={
                  data.type === 'refund'
                    ? '/images/icons/white_rub_reverse.svg'
                    : '/images/icons/share-icon-app.svg'
                }
                alt=""
              />
            </div>
            <div className={s.text}>
              {data.type === 'refund' ? t('balance_page.refund') : t('balance_page.referral')}
            </div>
            <div className={s.time}>
              <Moment unix format="DD.MM.YYYY HH:mm">
                {data.created_at}
              </Moment>
            </div>
          </div>
        </div>
        <div className={s.subtitle}>
          {data.type === 'refund' ? t('balance_page.refund') : t('balance_page.reward_for')}
        </div>
        <div className={s.bottom_block}>
          <div className={s.left}>
            <img
              src={data.recipient && data.recipient.photo ? data.recipient.photo : 'photo'}
              alt=""
            />
          </div>
          <div className={s.center}>
            <div className={s.name}>{userName}</div>
            <div className={s.teg}>@{userTeg}</div>
            <div className={`${s.sum} ${data.type === 'refund' ? s.red : s.fiol}`}>
              + {data.amount} {t('general.chars.rub')}
            </div>
            <div className={s.type}>
              {data.type === 'refund' ? t('balance_page.refund') : t('balance_page.referral')}
            </div>
          </div>
          <div className={s.right}>
            {data.sender && data.sender.photo ? <img src={data.sender.photo} alt="" /> : ''}
          </div>
        </div>
      </div>
    </BottomSheet>
  )
}

export default React.memo(BalanceItemBS)
