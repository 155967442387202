import React, { useMemo } from 'react'
import {
  MetaDescriptionCatalog,
  MetaTitleCatalog,
  MetaOG,
} from 'application/presentation/app/MetaTags'
import nprogress from 'nprogress'
import ContentLayout from 'application/presentation/common/ContentLayout'
import { useTranslation } from 'react-i18next'
import {
  useShopLastAddedProducts,
  useShopParentCategories,
} from 'application/domain/useCase/shop/getShop'
import splitByTwo from 'application/presentation/utils/splitByTwo'

import ShopContent from './ShopContent'

const ShopPage = (): React.ReactElement | null => {
  const { t } = useTranslation()
  const { data: categData, isLoading: categIsLoading } = useShopParentCategories()

  const {
    data: productsData,
    isLoading: prodIsLoading,
    fetchNextPage,
    hasNextPage,
  } = useShopLastAddedProducts()
  const parsedData = useMemo(() => {
    let arr: any = []
    if (categData && productsData) {
      const products = productsData.pages.length
        ? productsData.pages.flatMap((page: any) => page.products.data)
        : []
      arr.push(categData.data)
      arr = arr.concat(splitByTwo(products))
    }
    return arr
  }, [categData, productsData])

  if (prodIsLoading || categIsLoading) {
    nprogress.start()
    return <div className="page_loader" />
  }

  if (parsedData && parsedData.length) {
    nprogress.done()
    return (
      <ContentLayout>
        <MetaTitleCatalog />
        <MetaDescriptionCatalog />
        <MetaOG
          title={t('shop_page.meta.title') || ''}
          description={t('shop_page.meta.description') || ''}
        />
        <ShopContent data={parsedData} fetchNextPage={fetchNextPage} hasNextPage={hasNextPage} />
      </ContentLayout>
    )
  }

  return <></>
}

export default React.memo(ShopPage)
