import { useQuery } from '@tanstack/react-query'
import { fetchWish, fetchWishlist } from 'application/data/api/wish'
import { WishlistResponse, WishResponse } from 'application/domain/response/response_types'
import { useAppSelector } from 'application/domain/store/hooks'

export const getWish = async (
  wish_id: number | string,
  isShortLink?: boolean,
): Promise<WishResponse> => fetchWish(wish_id, isShortLink)

export const useWish = (wish_id: number | string, isShortLink?: boolean, config?: any) => {
  const query = useQuery<WishResponse>(['wish', wish_id], () => fetchWish(wish_id, isShortLink), {
    enabled: wish_id ? true : false,
    retry: false,
    refetchInterval: 10000,
    ...config,
  })

  return {
    ...query,
    data: query.data && query.data[0] ? query.data[0] : null,
  }
}

export const useWishlist = () => {
  const { token } = useAppSelector(({ userReducer }) => ({
    token: userReducer.token,
  }))
  const query = useQuery<WishlistResponse>(['myWishlist', token], fetchWishlist, {
    enabled: token ? true : false,
    refetchInterval: 10000,
  })
  return query
}

export default useWish
