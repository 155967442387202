/* eslint-disable @typescript-eslint/naming-convention */
import queryClient from 'application/data/apiClient/queryClient'
import { PayButtons, PaySelector } from 'application/presentation/common/ActionComponents'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import {
  InputCheckbox,
  InputLabel,
  InputPhone,
} from 'application/presentation/common/InputComponents'
import Loader from 'application/presentation/common/Loader'
import bankPay from 'application/presentation/common/Payments/payFunctions/BankPayment'
import apayBtnClick from 'application/presentation/common/Payments/payFunctions/PaymentApplePay'
import balanceBtnClick from 'application/presentation/common/Payments/payFunctions/PaymentBalance'
import gpayBtnClick from 'application/presentation/common/Payments/payFunctions/PaymentGpay'
import { CardForm, ErrorMsg, OffertaInfo } from 'application/presentation/common/uiComponents'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import cn from 'classnames'
import ThreeDsFrame from 'application/presentation/common/Payments/3dsStage/ThreeDsFrame'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { HeaderFooterContext } from 'application/presentation/app/HeaderFooter'
import ProgressBar from 'react-customizable-progressbar'
import { useAppSelector } from 'application/domain/store/hooks'
import useWish from 'application/domain/useCase/wish/getWish'
import wishStatus from 'application/presentation/utils/wishStatus'
import CheckDonateSum from 'application/presentation/utils/CheckDonateSum'
import { PaymentMethodType, PaymentProps } from 'application/domain/entity/payment/Payment'

import PayResultContent from './PayResultContent'
import s from './DonatePage.module.scss'

const sumVariants = [
  { id: 1, value: 150, emoji: '☕️' },
  { id: 2, value: 300, emoji: '🍔️' },
  { id: 3, value: 450, emoji: '🤑' },
  { id: 4, value: 600, emoji: '💰' },
  {
    id: 5,
    value: '100%',
    emoji: '✔️',
  },
]

const DonatePage = () => {
  const navigate = useNavigate()
  const { search, pathname, hash } = useLocation()
  const params = useParams<{ wishid?: string; nickname?: string }>()
  const { t } = useTranslation()
  const { user, selectedPayMethod } = useAppSelector(({ userReducer, payReducer }) => ({
    user: userReducer.user,
    selectedPayMethod: payReducer.selectedPayMethod,
  }))

  const [comment, setComment] = useState<string>('')
  const [isAnonym, setIsAnonym] = useState<boolean>(false)
  const [donateSum, setDonateSum] = useState<number | string>(100)
  const [stage, setStage] = useState<number>(1)
  const [threeDsData, setThreeDsData] = useState<any>()
  const [payment_id, setPaymentId] = useState<number>(0)
  const [payOrderId, setPayOrderId] = useState<string>('')
  const [payIsLoading, setPayIsLoading] = useState<boolean>(false)
  const [sumError, setSumError] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [phoneCompleted, setPhoneCompleted] = useState<boolean>(false)

  const headerColor = useContext(HeaderFooterContext)

  useEffect(() => {
    headerColor?.headerBgColor(true)
    return () => headerColor?.headerBgColor(false)
  }, [headerColor])

  const wishid = useMemo(() => {
    return params && params.wishid ? parseInt(params.wishid, 10) : 0
  }, [params])
  const { data: wishData, isLoading: wishIsLoading } = useWish(wishid)

  const { circleProcent, color, wish_status } = wishStatus(wishData)

  useEffect(() => {
    const searchParams = new URLSearchParams(search)
    const id = searchParams.get('payment_id')
    const sum = searchParams.get('sum')
    const orderId = searchParams.get('orderId')
    if (id) {
      setPaymentId(parseInt(id, 10))
      setStage(4)
    }
    if (orderId) {
      setPayOrderId(orderId)
      setStage(4)
    }
    if (sum) {
      setDonateSum(parseInt(sum, 10))
    } else {
      setDonateSum(100)
    }
  }, [search])

  const onPayStart = useCallback(() => {
    console.log('payStart')
    setPayIsLoading(true)
  }, [])
  const onPaySuccess = useCallback(
    (status: string, response?: any) => {
      setPayIsLoading(false)
      queryClient.invalidateQueries(['profile'])
      queryClient.invalidateQueries(['wish'])
      if (status === 'success') {
        setPaymentId(response)
        setStage(4)
      } else {
        setThreeDsData(response)
        setStage(6)
      }
    },
    [setStage, setThreeDsData],
  )
  const onPayError = useCallback(() => {
    console.log('payError')
    setPayIsLoading(false)
    if (stage === 3) {
      ToastNotify('Произошла ошибка')
    } else {
      setStage(4)
    }
  }, [setStage, stage])

  const payProcess = useCallback(
    (type: PaymentMethodType) => {
      if (!user && !phoneCompleted) {
        return ToastNotify('Введите номер телефона!')
      }
      const payData = {
        auth_user: user,
        phone: phone ? `+${phone}` : null,
        amount: donateSum,
        wish_id: wishid,
        is_anonym: isAnonym,
        type: 'wish',
        comment,
      }
      const paymentFuncData: PaymentProps = {
        //@ts-ignore
        data: payData,
        onStart: onPayStart,
        onSuccess: onPaySuccess,
        onError: onPayError,
      }
      if (type === 'bank') bankPay(paymentFuncData)
      if (type === 'apay') apayBtnClick(paymentFuncData)
      if (type === 'gpay') gpayBtnClick(paymentFuncData)
      if (type === 'balance') balanceBtnClick(paymentFuncData)
    },
    [
      comment,
      donateSum,
      wishid,
      user,
      onPayError,
      onPaySuccess,
      onPayStart,
      isAnonym,
      phone,
      phoneCompleted,
    ],
  )

  useEffect(() => {
    if (wishData) {
      setSumError(CheckDonateSum(donateSum, wishData.available_amount))
    }
  }, [donateSum, wishData])

  const closeStage = useCallback(() => {
    if (stage === 4) {
      navigate(`/${params.nickname}`)
      navigate(`/${params.nickname}/${params.wishid}`)
      // navigate(-1)
    } else {
      navigate(-1)
    }
    // if (pathname.indexOf('/order') !== -1 || pathname.indexOf('/donate') !== -1) {
    //   navigate(pathname.replace('/order', '').replace('/donate', ''))
    // } else {
    //   navigate(pathname.replace(/\/$/g, '').split('/').slice(0, -1).join('/'))
    // }
  }, [navigate, params, stage])

  if (wishIsLoading) {
    return (
      <div className={s.container}>
        <div className={s.content}>
          <HeaderMobileTitle.Source>Донат</HeaderMobileTitle.Source>
          <Loader />
        </div>
      </div>
    )
  }
  if (!wishData) {
    return (
      <>
        <HeaderMobileTitle.Source>Донат</HeaderMobileTitle.Source>
        <div>Желание не найдено</div>
      </>
    )
  }

  return stage === 3 ? (
    <div className={s.content}>
      <HeaderMobileTitle.Source>Оплата с карты</HeaderMobileTitle.Source>
      <CardForm payProcess={payProcess} payIsLoading={payIsLoading} />
    </div>
  ) : stage === 4 ? (
    <PayResultContent
      payment_id={payment_id}
      payOrderId={payOrderId}
      setStage={setStage}
      closeStage={closeStage}
    />
  ) : stage === 6 ? (
    <ThreeDsFrame ThreeDsData={threeDsData} onComplete={onPaySuccess} />
  ) : (
    <div className={s.container}>
      <div className={s.content}>
        <HeaderMobileTitle.Source>
          Отправка доната для @
          {wishData.user.nickname ? wishData.user.nickname : wishData.user.name}
        </HeaderMobileTitle.Source>
        <div className={s.wish_container}>
          <div className={s.wish_wrapper}>
            <div className={s.left}>
              <img src={wishData.photo} alt="" />
            </div>
            <div className={s.right}>
              <div className={s.title}>{wishData.name}</div>
              <div className={s.cart_status}>
                <div className={s.circlestat}>
                  <div className={s.progress}>
                    <ProgressBar
                      className="circle_progress_mini"
                      progress={circleProcent}
                      radius={100}
                      strokeWidth={20}
                      trackStrokeWidth={20}
                      initialAnimation
                      transition="1s ease-in-out"
                      strokeColor={color}
                    />
                    {wishData.status === 'delivered' && (
                      <img loading="lazy" src="/images/icons/GradientComplete.svg" alt="" />
                    )}
                  </div>
                </div>
                <div className={s.status_text}>{wish_status}</div>
              </div>
              {/* <div className={s.arrow}>
                <img src="/images/icons/back-icon.svg" alt="" />
              </div> */}
            </div>
          </div>
        </div>
        {!user && (
          <InputPhone
            onChange={setPhone}
            className={s.input_phone}
            setPhoneComplited={setPhoneCompleted}
          />
        )}
        <InputLabel
          value={comment}
          onChange={setComment}
          title="Введите комментарий"
          className={s.comment_container}
        />
        <InputCheckbox
          value={isAnonym}
          onChange={setIsAnonym}
          title="Скрыть отправителя"
          containerClassName={s.checkbox_container}
        />
      </div>
      <div className={s.selector_sum}>
        {sumVariants.map((item) => {
          return (
            <button
              key={`sum_${item.value}`}
              className={cn({
                [s.btn]: true,
                [s.active]:
                  (item.value === '100%' && donateSum === wishData.available_amount) ||
                  donateSum === item.value
                    ? true
                    : false,
              })}
              type="button"
              onClick={() => {
                setDonateSum(item.value === '100%' ? wishData.available_amount : item.value)
              }}
            >
              <span>{item.emoji}</span> {item.value} {item.value !== '100%' ? 'руб' : ''}
            </button>
          )
        })}
      </div>

      <div className={s.pay_container}>
        <PaySelector withoutTitle />

        <label className={cn(s.input_block__donatesum, sumError ? s.error : '')}>
          <div className={s.donatesum_content}>
            <input
              type="number"
              name="donatesum"
              onChange={(e: any) => {
                setSumError('')
                setDonateSum(parseInt(e.target.value, 10))
              }}
              value={donateSum}
            />
            <span>{t('general.chars.rub')}</span>
          </div>
        </label>
      </div>
      {sumError ? <ErrorMsg text={sumError} containerClassName={s.err} /> : <></>}

      <OffertaInfo />
      <PayButtons
        selected_id={selectedPayMethod}
        bankOnClick={() => payProcess('bank')}
        apayOnClick={() => payProcess('apay')}
        gpayOnClick={() => payProcess('gpay')}
        balanceOnClick={() => payProcess('balance')}
        tinkoffOnClick={() => {}}
      />
    </div>
  )
}

export default React.memo(DonatePage)
