import { Payment } from 'application/domain/entity/payment/Payment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'

import s from './UserDonate.module.scss'
import UserDonatesItemView from './UserDonatesItemView'

type Props = {
  donate: Payment
  type: 'in' | 'out'
}

const UserDonate = ({ donate, type }: Props): React.ReactElement => {
  const { t } = useTranslation()
  const isOut = type === 'out'

  return (
    <div className={s.viewMobile}>
      <div className={s.detailsContainer}>
        <div className={s.details}>
          <div className={s.detailsAmountContainer}>
            <div className={s.detailsAmountLabel}>{t('donates_page.donate_sum')}</div>
            <div className={s.detailsAmount}>
              {isOut ? '-' : '+'} {donate.amount} {t('general.chars.rub')}
            </div>
            <div className={s.detailsPaymentSystem}>
              <img src="/images/icons/circle-ruble.svg" alt="Счёт" /> {t('donates_page.balance')}
            </div>
          </div>
          <div className={s.detailsTypeContainer}>
            <div className={s.detailsTypeIcon}>
              <img src="/images/icons/rubles.svg" alt="Рубли" />
            </div>
            <div className={s.detailsType}>
              {isOut ? t('donates_page.out_to_wish') : t('donates_page.in_to_wish')}
            </div>
            <div className={s.detailsDate}>
              <Moment format="DD.MM.YYYY hh:mm" date={donate.created_at} unix />
            </div>
          </div>
        </div>
      </div>
      <div className={s.senderContainer}>
        <div className={s.senderContainerLabel}>
          {isOut ? t('donates_page.receiver') : t('donates_page.sender')}
        </div>
        <div className={s.sender}>
          <UserDonatesItemView donate={donate} type={type} short />
        </div>
      </div>
    </div>
  )
}

export default React.memo(UserDonate)
