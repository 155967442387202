import React, { useState, useMemo, useContext } from 'react'
import apiClient from 'application/data/apiClient'
import { useQuery } from '@tanstack/react-query'
import WishPreviewCard from 'application/presentation/common/WishPreviewCard'
import { NavLink } from 'react-router-dom'

import s from './SixBlock.module.scss'

const fetchId554 = async () => {
  const { data } = await apiClient.get(`/api/v1/id554`)

  return data
}

const fetchEroshchenko = async () => {
  const { data } = await apiClient.get(`/api/v1/eroshchenko`)

  return data
}

const fetchId391 = async () => {
  const { data } = await apiClient.get(`/api/v1/id391`)

  return data
}
const fetchPaveltheodor = async () => {
  const { data } = await apiClient.get(`/api/v1/paveltheodor`)

  return data
}
const fetchVsezbs = async () => {
  const { data } = await apiClient.get(`/api/v1/vsezbs`)

  return data
}

type Props = {
  data: any
  user: any
}

const WishContent = ({ data, user }: Props) => {
  const wishData = useMemo(() => {
    const arr: any = []
    let c = 0
    if (data && data.items) {
      data.items.map((categ: any) => {
        if (categ.wish) {
          categ.wish.map((item: any) => {
            if (c < 5) {
              arr.push(item)
              c += 1
            }
          })
        }
      })
    }
    return arr
  }, [data])

  return (
    <div className={s.wishlist}>
      {wishData.map((item: any) => {
        return (
          <NavLink
            to={`/${user.nickname}/${item.id}`}
            className={s.link_cart}
            key={`wish_content_${item.id}`}
          >
            <WishPreviewCard
              wish={item}
              type="wish"
              className={s.main}
              classTextBlock={s.text_over}
              // classCircle={!isMobile ? s.circle_stat : ''}
              // classWishName={!isMobile ? s.wish_name : ''}
              // classPrice={!isMobile ? s.price : ''}
              // classImg={!isMobile ? s.wish_img : ''}
              // classSvg={!isMobile ? s.svg_img : ''}
            />
          </NavLink>
        )
      })}
    </div>
  )
}

const SixBlock = () => {
  const { data: id554, isLoading: isLoad1 } = useQuery(['id554'], fetchId554)
  const { data: eroshchenko, isLoading: isLoad2 } = useQuery(['eroshchenko'], fetchEroshchenko)
  const { data: id391, isLoading: isLoad3 } = useQuery(['id391'], fetchId391)
  const { data: paveltheodor, isLoading: isLoad4 } = useQuery(['paveltheodor'], fetchPaveltheodor)
  const { data: vsezbs, isLoading: isLoad5 } = useQuery(['vsezbs'], fetchVsezbs)
  const [active, setActive] = useState<number>(0)

  const data = useMemo(() => {
    const arr: any = []
    if (id554) arr.push(id554)
    if (eroshchenko) arr.push(eroshchenko)
    if (id391) arr.push(id391)
    if (paveltheodor) arr.push(paveltheodor)
    if (vsezbs) arr.push(vsezbs)
    return arr
  }, [id554, eroshchenko, id391, paveltheodor, vsezbs])

  // const openWish = (e: any) => setActive(+e.target.dataset.index)
  let arr: any[] = []

  if (!isLoad1 && !isLoad2 && !isLoad3 && !isLoad4 && !isLoad5) {
    arr = [id554, eroshchenko, id391, paveltheodor, vsezbs]
  }

  return (
    <div className={s.container}>
      <div className={s.inner_content}>
        <div className={s.wish_info}>
          <div className={s.left}>Их желания исполняются прямо сейчас!</div>
          <div className={s.right}>
            Сервис, в котором вы можете опубликовать свои желания с любимых маркетплейсов, услуг
            лучших салонов. Вы получите поддержку ваших идей от друзей и подписчиков в виде
            переводов до 400 000 рублей. Оформляйте доставку и получайте на руки ваши подарки от
            друзей со всего света. Безопасно. Быстро. Прозрачно.
          </div>
        </div>
        <div className={s.users}>
          {!isLoad1 && !isLoad2 && !isLoad3 && !isLoad4 && !isLoad5 ? (
            arr.map((item, i: number) => {
              return (
                <>
                  <img
                    src={item.photo ? item.photo : '/images/user_default.png'}
                    alt=""
                    onClick={() => setActive(i)}
                    className={`${s.user_pic} ${i === active ? s.user_pic_active : ''}`}
                    data-index={i}
                  />
                </>
              )
            })
          ) : (
            <></>
          )}
        </div>
        <div className={s.title}>
          {!isLoad1 && !isLoad2 && !isLoad3 && !isLoad4 && !isLoad5 ? (
            arr.map((item, i: number) => {
              if (i === active) {
                return `Желания ${item.name} `
              }
              return false
            })
          ) : (
            <></>
          )}
        </div>

        {!isLoad1 && !isLoad2 && !isLoad3 && !isLoad4 && !isLoad5 ? (
          arr.map((item, i: number) => {
            if (i === active) {
              return (
                <NavLink to={`/${item.nickname}`} className={s.nickname_link} key={`item_${i}`}>
                  @{item.nickname}
                </NavLink>
              )
            }
            return false
          })
        ) : (
          <></>
        )}

        {data && data.length ? <WishContent data={data[active].wish} user={data[active]} /> : <></>}
      </div>
    </div>
  )
}

export default React.memo(SixBlock)
