/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import apiClient from 'application/data/apiClient'
import { PaymentDataProps, PaymentProps } from 'application/domain/entity/payment/Payment'

// import ToastNotify from '../ToastNotify'

export const apayBtnClick = ({ data, onStart, onSuccess, onError }: PaymentProps) => {
  const { auth_user } = data
  if (auth_user === null) {
    // ToastNotify('Вы не авторизованы!', 'error')
    onError('not_auth')
    return false
  }

  onStart()

  applePay(data, onSuccess, onError)
}

const applePay = (params: PaymentDataProps, onSuccess: Function, onError: Function) => {
  // обработчик кнопки
  const request = {
    // requiredShippingContactFields: ['email'], //Раскомментируйте, если вам нужен e-mail. Также можно запросить postalAddress, phone, name.
    countryCode: 'RU',
    currencyCode: 'RUB',
    supportedNetworks: ['visa', 'masterCard'],
    merchantCapabilities: ['supports3DS'],
    // Назначение платежа указывайте только латиницей!
    total: { label: 'Wish.men', amount: params.amount }, // назначение платежа и сумма
  }
  const session = new ApplePaySession(1, request)

  // обработчик события для создания merchant session.
  session.onvalidatemerchant = (event: any) => {
    const data = {
      url: event.validationURL,
    }

    // отправьте запрос на ваш сервер, а далее запросите API CloudPayments
    // для запуска сессии
    apiClient({
      method: 'POST',
      url: '/api/v1/payment/cloudapple',
      data,
    }).then((result) => {
      console.log(result.data)
      session.completeMerchantValidation(result.data.Model)
    })
  }

  // обработчик события авторизации платежа
  session.onpaymentauthorized = (event: any) => {
    // localStorage.setItem('payment_prev_url', location.href)

    // var email = event.payment.shippingContact.emailAddress; //если был запрошен адрес e-mail
    // var phone = event.payment.shippingContact.phoneNumber; //если был запрошен телефон
    // все варианты смотрите на сайте https://developer.apple.com/reference/applepayjs/paymentcontact

    if (params.type === 'wish') {
      const data = {
        crypto: JSON.stringify(event.payment.token),
        amount: params.amount,
        wish: params.wish_id,
        comment: params.comment,
        to_balance: !!params.to_balance,
        type: 'Apple Pay',
      }
      apayment(data, session, onSuccess, onError)
    }
    // else if (params.type === 'buy') {
    //   const dat = {
    //     product_id: params.wish,
    //     comment: params.comment,
    //     type: 'Apple Pay',
    //     with_delivery: params.orderData.with_delivery,
    //     delivery_id: params.orderData.delivery_id,
    //     apps_id: '',
    //     phone: params.orderData.phone,
    //   }
    //   apiClient({
    //     method: 'POST',
    //     url: '/api/v1/buy',
    //     data: dat,
    //   })
    //     .then((response) => {
    //       const data = {
    //         crypto: JSON.stringify(event.payment.token),
    //         amount: response.data.cost,
    //         wish: response.data.wish_id,
    //         comment: params.comment,
    //         type: 'Apple Pay',
    //       }
    //       apayment(data, params, session)
    //     })
    //     .catch((error) => {
    //       alert(error)
    //     })
    // } else {
    //   apiClient({
    //     method: 'POST',
    //     url: '/api/v1/order',
    //     data: params.orderData,
    //   })
    //     .then((response) => {
    //       const data = {
    //         crypto: JSON.stringify(event.payment.token),
    //         amount: params.amount,
    //         order: response.data.id,
    //         comment: params.comment,
    //         type: 'Apple Pay',
    //       }
    //       apayment(data, params, session)
    //     })
    //     .catch((error) => {
    //       alert(error)
    //     })
    // }
  }

  session.oncancel = () => {
    onError('error', 'cancel')
  }

  session.abort = () => {
    onError('error', 'abort')
  }
  // Начало сессии Apple Pay
  session.begin()
}

function apayment(data: any, session: any, onSuccess: Function, onError: Function) {
  // передайте полученный токен на бэкэнд сервера, и оттуда выполните
  // запрос  оплаты по криптограмме https://developers.cloudpayments.ru/#oplata-po-kriptogramme,
  // используя этот токен в параметре CardCryptogramPacket
  /// /console.log("Что отправляем в бэк : \n"+data);
  apiClient({ method: 'POST', url: '/api/v1/payment', data }).then((result) => {
    let status
    if (result.data.success) {
      status = ApplePaySession.STATUS_SUCCESS
      session.completePayment(status)
      console.log(data)
      onSuccess('success', result.data.success.id)
      // if (params.callback) params.callback('paySuccess', result.data.success.id, data)
    } else {
      status = ApplePaySession.STATUS_FAILURE
      session.completePayment(status)
      onError('error', result.data.Model.CardHolderMessage)

      // if (params.callback) params.callback('error', result.data.Model.CardHolderMessage)
      //   location.href = '/payment/result/failed'
      //   postForm('/payment/result/failed', { CardHolderMessage: result.Model.CardHolderMessage })
    }
  })
}

export default apayBtnClick
