/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import apiClient from 'application/data/apiClient'
import { PaymentDataProps, PaymentProps } from 'application/domain/entity/payment/Payment'

export const balanceBtnClick = ({ data, onStart, onSuccess, onError }: PaymentProps) => {
  const { auth_user } = data
  if (auth_user === null) {
    onError('not_auth')
    return false
  }

  onStart()

  balancePay(data, onSuccess, onError)
}

const balancePay = (data: PaymentDataProps, onSuccess: Function, onError: Function) => {
  const { type, amount, wish_id, comment, to_balance, his_santa_id, your_santa_id, is_anonym } =
    data
  const sendData = {
    amount,
    comment,
    type: 'balance',
    to_balance: !!to_balance,
    is_anonym: !!is_anonym,
  }
  if (type === 'order') {
    // @ts-ignore
    sendData.order = wish_id
  } else {
    // @ts-ignore
    sendData.wish = wish_id
  }
  if (your_santa_id || his_santa_id) {
    // @ts-ignore
    sendData.your_santa_id = your_santa_id
    // @ts-ignore
    sendData.his_santa_id = his_santa_id
  }
  if (type === 'wish') {
    apiClient({
      method: 'POST',
      url: '/api/v1/payment/balance',
      data: sendData,
    })
      .then((response) => {
        if (response.data.status === 'Success') {
          onSuccess('success', response.data.id)
        } else {
          onError('error', response.data.msg)
        }
      })
      .catch((error) => {
        console.log(error.response.data)
        onError('error', error.response.data.title)
      })
  }
}

export default balanceBtnClick
