import BtnComponent from 'application/presentation/common/ActionComponents/Button'
import {
  InputLabel,
  MultipleSelector,
  TextareaInput,
} from 'application/presentation/common/InputComponents'
import { CloseButton, OffertaInfo } from 'application/presentation/common/uiComponents'
import React, { useCallback, useMemo, useState } from 'react'
import Modal from 'react-modal'
import { useLocation, useNavigate } from 'react-router-dom'
import apiClient from 'application/data/apiClient'
import _ from 'lodash'
import ToastNotify from 'application/presentation/app/ToastNotify'

import selectorData from './SelectorValues'
import s from './BePartnerMW.module.scss'

const BePartnerMW = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const opened = useMemo(() => {
    return location.hash.indexOf('bepartner') !== -1
  }, [location])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [compName, setCompName] = useState<string>('')
  const [activity, setActivity] = useState<{ value: number; label: string }>({
    value: 0,
    label: 'Выберите значение',
  })
  const [site, setSite] = useState<string>('')
  const [fio, setFio] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [fieldsValid, setFieldsValid] = useState<any>({
    compNameValid: true,
    activityValid: true,
    phoneValid: true,
    fioValid: true,
    emailValid: true,
    siteValid: true,
  })

  const handleChange = useCallback(() => {
    setFieldsValid({
      compNameValid: true,
      activityValid: true,
      phoneValid: true,
      fioValid: true,
      emailValid: true,
      siteValid: true,
    })
  }, [])

  const validator = useCallback(
    (fields: any) => {
      const fieldsTmp = _.clone(fieldsValid)
      let isValid = true
      if (fields.compName.length === 0) {
        fieldsTmp.compNameValid = false
        isValid = false
      }
      if (fields.activity.value === 0) {
        fieldsTmp.activityValid = false
        isValid = false
      }
      if (fields.phone.length === 0) {
        fieldsTmp.phoneValid = false
        isValid = false
      }
      if (fields.fio.length === 0) {
        fieldsTmp.fioValid = false
        isValid = false
      }
      if (fields.email.length === 0) {
        fieldsTmp.emailValid = false
        isValid = false
      }
      if (fields.site.length === 0) {
        fieldsTmp.siteValid = false
        isValid = false
      }
      setFieldsValid(fieldsTmp)
      return isValid
    },
    [fieldsValid],
  )

  const send = useCallback(() => {
    if (
      validator({
        compName,
        activity,
        phone,
        fio,
        email,
        site,
      })
    ) {
      setIsLoading(true)
      apiClient
        .post('/api/v1/partner/request', {
          company: compName,
          type: activity.label,
          link: site,
          name: fio,
          phone,
          mail: email,
          comment,
        })
        .then(() => {
          //   console.log(response.data)
          ToastNotify('Заявка отправлена!')
          navigate(-1)
          setIsLoading(false)
        })
        .catch((error) => {
          if (error.response.data) {
            ToastNotify(error.response.data.detail)
          }
          setIsLoading(false)
        })
    }
  }, [compName, activity, site, fio, phone, email, comment, validator, navigate])

  return (
    <>
      {/* @ts-ignore */}
      <Modal
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
          },
        }}
        closeTimeoutMS={500}
        isOpen={opened}
        onAfterClose={() => {
          // changeStage(1)
        }}
        className={s.modal_window_desk}
      >
        <CloseButton containerClassName={s.close_btn} onClick={() => navigate(-1)} />
        <div className={s.content}>
          <div className={s.title}>Стать партнером</div>
          <div className={s.form}>
            <InputLabel
              value={compName}
              onChange={(text: string) => {
                setCompName(text)
                handleChange()
              }}
              name="compName"
              type="text"
              title="Название вашей компании"
              wrong={!fieldsValid.compNameValid}
            />
            {!fieldsValid.compNameValid && (
              <div className={s.msg}>Пожалуйста, заполните название компании</div>
            )}
            <MultipleSelector
              title="Сфера деятельности"
              data={selectorData}
              value={activity}
              onChange={(val: any) => {
                setActivity(val)
                handleChange()
              }}
              wrong={!fieldsValid.activityValid}
            />
            {!fieldsValid.activityValid && (
              <div className={s.msg}>Пожалуйста, выберите сферу деятельности</div>
            )}
            <InputLabel
              value={site}
              onChange={(text: string) => {
                setSite(text)
                handleChange()
              }}
              name="site"
              type="text"
              title="Адрес вашего сайта"
              wrong={!fieldsValid.siteValid}
            />
            {!fieldsValid.siteValid && <div className={s.msg}>Пожалуйста, введите адрес сайта</div>}
            <InputLabel
              value={fio}
              onChange={(text: string) => {
                setFio(text)
                handleChange()
              }}
              name="fio"
              type="text"
              title="Ваше ФИО"
              wrong={!fieldsValid.fioValid}
            />
            {!fieldsValid.fioValid && <div className={s.msg}>Пожалуйста, введите ФИО</div>}
            <InputLabel
              value={phone}
              onChange={(text: string) => {
                setPhone(text)
                handleChange()
              }}
              name="phone"
              type="text"
              title="Ваш телефон"
              wrong={!fieldsValid.phoneValid}
            />
            {!fieldsValid.phoneValid && <div className={s.msg}>Пожалуйста, введите телефон</div>}
            <InputLabel
              value={email}
              onChange={(text: string) => {
                setEmail(text)
                handleChange()
              }}
              name="email"
              type="text"
              title="Ваш e-mail"
              wrong={!fieldsValid.emailValid}
            />
            {!fieldsValid.emailValid && <div className={s.msg}>Пожалуйста, введите email</div>}
            <TextareaInput
              value={comment}
              onChange={(text: string) => {
                setComment(text)
                handleChange()
              }}
              title="Расскажите немного о компании"
              name="comment"
            />
            <OffertaInfo />
            <BtnComponent
              color="red"
              title="Отправить"
              className={s.btn_container}
              onClick={() => send()}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

export default React.memo(BePartnerMW)
