import React from 'react'

import s from './TextareaInput.module.scss'

type Props = {
  name: string
  value: string
  onChange: Function
  title: string
  maxLength?: number
  placeholder?: string
}

const TextareaInput = ({ name, value, onChange, title, maxLength, placeholder }: Props) => {
  return (
    <label className={s.container} htmlFor={name}>
      <div className={s.top_block}>
        <div className={s.title}>{title}</div>
        <div className={s.count}>
          {value ? value.length : 0} / {maxLength || 87}
        </div>
      </div>
      <textarea
        name={name}
        className={`${s.textarea} resizable`}
        placeholder={placeholder || ''}
        maxLength={maxLength || 87}
        value={value || ''}
        onChange={(e: any) => onChange(e.target.value)}
      />
    </label>
  )
}

export default React.memo(TextareaInput)
