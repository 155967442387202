/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo } from 'react'
import ProgressBar from 'react-customizable-progressbar'

import s from './WishPreviewCard.module.scss'

type Props = {
  wish: any
  type: 'product' | 'wish' | 'wish_full' | 'product_preview'
  wishstatus?: string
  className?: string
  classTextBlock?: string
}

const WishPreviewCard = ({ wish, type, className, classTextBlock, wishstatus }: Props) => {
  const { wishCircleProcent, wishColor, status } = useMemo(() => {
    let procent
    let circleProcent
    let color: string
    let wish_status = ''

    if (type === 'product') {
      circleProcent = 1
      wish_status = `${wish.full_price?.toLocaleString('ru')} руб`
      color = '#FF3E6C'
    } else {
      if (wish.full_price! > 0 && wish.amount_collected > 0) {
        procent = Math.floor((wish.amount_collected / wish.full_price!) * 100)
        procent = procent >= 100 ? 100 : procent
        circleProcent = procent < 1 ? 1 : procent
      } else if (wish.status === 'without_price' || wish.status === '') {
        procent = 0
        circleProcent = 0
      } else {
        procent = 0
        circleProcent = 1
      }
      if (wish.status === 'full' || wish.status === 'order') {
        procent = 100
        circleProcent = 100
      }

      // Круглые прогрессбары
      switch (wish.status) {
        case 'order':
        case 'transferred':
          color = '#9A3FD0'
          break

        case 'delivered':
        case 'order-delivery':
          color = '#AF12FF'
          break

        default:
          if (procent === 100) {
            // path.setAttribute("stroke", "url(
            color = '#AF12FF'
          } else {
            color = '#FF3E6C'
          }
      }

      switch (wish.status) {
        case 'full':
        case 'over':
          wish_status = 'Средства собраны. Оформите желание.'
          break
        case 'order':
        case 'transferred':
          wish_status = 'Заказ оформлен'
          break
        case 'delivered':
        case 'order-delivery':
          wish_status = 'Желание исполнено'
          break
        case 'opened':
          wish_status =
            wish.full_price === 0 ? 'Бесплатно' : `${procent}% из ${wish.full_price} рублей`
          break
        default:
          break
      }
    }

    return {
      wishCircleProcent: circleProcent,
      status: wish_status,
      wishColor: color,
    }
  }, [wish, type])

  return (
    <div className={`${s.wish_cart_preview} ${className || ''}`}>
      <div className={`${s.circlestat}`} data-percent="1">
        <ProgressBar
          className={`circle_progress_mini `}
          progress={wishCircleProcent}
          radius={100}
          strokeWidth={20}
          trackStrokeWidth={20}
          initialAnimation
          transition="1s ease-in-out"
          strokeColor={wishColor}
        />
      </div>
      <div className={`${s.text} ${classTextBlock || ''}`}>
        <div className={`${s.cart_name}`}>{wish.name}</div>

        <div className={`${s.cart_status} ${type === 'wish' ? s.gray : ''}`}>
          {' '}
          {type === 'product'
            ? status
              ? status
              : `${wish.full_price} руб`
            : type === 'product_preview'
            ? `${wish.price} руб`
            : wishstatus || status}
        </div>
      </div>
      <div className={`${s.wish_img}`}>
        <img
          src={type === 'product' ? (wish.photo ? wish.photo[0] : '') : wish.photo}
          loading="lazy"
          alt=""
          onError={(e: any) => {
            e.target.onerror = null
            e.target.src = 'https://cdn.wish.men/icons/product-without-photo.png'
          }}
        />
      </div>
    </div>
  )
}

export default React.memo(WishPreviewCard)
