import React from 'react'
import cn from 'classnames'

import s from '../ArticleItem.module.scss'

type Props = {
  data: any
  isActive?: boolean
  paused?: boolean
}

const PreviewStory = ({ data, isActive, paused }: Props) => {
  return (
    <div className={s.preview_article}>
      <div className={cn(s.top_shadow, paused ? s.hidden : '')}></div>
      <img src={data.preview} alt="" />
      <div className={cn(s.bottom_shadow, paused ? s.hidden : '')}></div>
      <div className={cn(s.bottom, paused ? s.hidden : '')}>
        <div className={s.title}>{data.title}</div>
        <div className={s.subtitle}>{data.meta_desc}</div>
      </div>
    </div>
  )
}

export default React.memo(PreviewStory)
