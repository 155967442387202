import { fetchPartnersCategory, fetchPartnersList } from 'application/data/api/partner'
import { PartnersListResponse } from 'application/domain/response/response_types'
import { useInfiniteQuery } from '@tanstack/react-query'

export const getPartnersList = async () => fetchPartnersList('/api/v1/partners_info')

export const usePartnersList = (config?: any) => {
  const query = useInfiniteQuery<PartnersListResponse>(
    ['partners_list'],
    ({ pageParam = '/api/v1/partners_info' }) => fetchPartnersList(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page_url
      },
      ...config,
    },
  )
  return query
}

export const getPartnersCategory = async (id: number) =>
  fetchPartnersCategory(`/api/v1/shop/partner/${id}`)

export const usePartnersCategory = (id: number, config?: any) => {
  const query = useInfiniteQuery(
    ['partners_category', id],
    ({ pageParam = `/api/v1/shop/partner/${id}` }) => fetchPartnersCategory(pageParam),
    {
      enabled: id && id !== 0 ? true : false,
      getNextPageParam: (lastPage) => {
        return lastPage[1].next_page_url
      },
      ...config,
    },
  )
  return query
}
