import { useQuery } from '@tanstack/react-query'
import {
  fetchArticle,
  fetchArticleList,
  fetchCollections,
  fetchGuaranty,
} from 'application/data/api/article'

export const getGuaranty = async () => fetchGuaranty()

export const getCollections = async () => fetchCollections()

export const useCollections = (config?: any) => {
  const query = useQuery(['collections'], fetchCollections, config)

  return query
}

export const getArticleList = async () => fetchArticleList()
export const useArticleList = (config?: any) => {
  const query = useQuery(['articles'], fetchArticleList, config)
  return query
}

export const getArticle = async (id: string) => fetchArticle(id)

export const useArticle = (id: string, config?: any) => {
  const query = useQuery(['article', id], () => fetchArticle(id), {
    enabled: !!id,
    ...config,
  })

  return query
}
