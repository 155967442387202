import React, { useMemo } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Product from 'application/presentation/common/ProductItem'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from 'application/presentation/common/Loader'
import { usePartnersCategory } from 'application/domain/useCase/partners/getPartners'

import s from './PartnersPageMobile.module.scss'

const PartnersPageMobile = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()

  const partnerId = useMemo(() => (params && params.id ? parseInt(params.id, 10) : 0), [params])

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    usePartnersCategory(partnerId)

  const partnerName = useMemo(() => {
    return data && data.pages ? data.pages[0][0].name : ''
  }, [data])

  const partnerPhoto = useMemo(() => {
    return data && data.pages ? data.pages[0][0].photo : ''
  }, [data])

  const partnersCategory = useMemo(() => {
    return data && data.pages ? data.pages.flatMap((page: any) => page[1].data) : []
  }, [data])

  return (
    <div>
      <div className={s.header_container}>
        <div className={s.left}>
          <NavLink to="#" className={s.img_block} onClick={() => navigate(-1)}>
            <img loading="lazy" src="/images/icons/back-icon.svg" alt="" />
          </NavLink>
        </div>
        <div className={s.center}>
          <div className={s.name}>{partnerName}</div>
          <div className={s.logo}>{isLoading ? <></> : <img src={partnerPhoto} alt="" />}</div>
          {isLoading ? null : partnersCategory && partnersCategory.length ? null : (
            <div>
              <div className={s.empty_txt}>Нет данных</div>
            </div>
          )}
        </div>
        <div className={s.right} />
      </div>

      <div className={s.container}>
        {isLoading ? (
          <Loader />
        ) : (
          <InfiniteScroll
            className={s.prod_list_shop}
            dataLength={partnersCategory.length}
            next={fetchNextPage}
            style={{ overflow: 'visible', display: 'flex' }}
            hasMore={hasNextPage ? true : false}
            loader={isFetchingNextPage ? <Loader /> : <></>}
            scrollableTarget="page_content"
          >
            {partnersCategory.map((item) => {
              return (
                <>
                  <NavLink to={`/shop/category/${item.slug}`} className={s.category_title}>
                    <div className={s.title_black}>{item.name}</div>
                    <div className={s.all}>{t('general.all_text')}</div>
                  </NavLink>
                  <div className={s.flat_list}>
                    {item.products.map((product: any) => {
                      return (
                        <Product
                          key={`product_${product.id}`}
                          // i={2}
                          item={product}
                          // slug={item.slug}
                          // border={false}
                        />
                      )
                    })}
                  </div>
                  {/* <Swiper slidesPerView="auto" containerClass={s.slider}>
                    
                  </Swiper> */}
                </>
              )
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export default React.memo(PartnersPageMobile)
