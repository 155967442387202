import React from 'react'
import Skeleton from 'react-loading-skeleton'

import s from './TopBlock.module.scss'

type Props = {
  type: string
  t: any
  totalSum?: number
  getLast7?: number
  getLast30?: number
}

const TopBlock = ({ type, totalSum, getLast7, getLast30, t }: Props) => {
  const isBalance = type === 'balance'
  return (
    <div className={s.top_block}>
      <div className={s.title}>
        <div>{isBalance ? t('balance_page.all_sum') : t('referral_page.earnings')}</div>
        {/* <div className={isBalance ? s.balance : s.referral}>
          {type === 'balance' ? (
            <div className={`${s.icon} ${s.balance}`}>
              <svg
                width="11"
                height="13"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.06455 0.259521H3.41477C2.84786 0.259521 2.38828 0.719098 2.38828 1.28602V5.47348H1.32923C0.762311 5.47348 0.302734 5.93306 0.302734 6.49998C0.302734 7.0669 0.762311 7.52647 1.32923 7.52647H2.38828V8.60185H1.32923C0.762311 8.60185 0.302734 9.06143 0.302734 9.62834C0.302734 10.1953 0.762311 10.6548 1.32923 10.6548H2.38828V11.7139C2.38828 12.2809 2.84786 12.7404 3.41477 12.7404C3.98169 12.7404 4.44127 12.2809 4.44127 11.7139V10.6548H6.54314C7.11006 10.6548 7.56964 10.1953 7.56964 9.62834C7.56964 9.06143 7.11006 8.60185 6.54314 8.60185H4.44127V7.52647H7.06455C9.06803 7.52647 10.698 5.8965 10.698 3.89302C10.698 1.8895 9.06807 0.259521 7.06455 0.259521ZM7.06455 5.47343H4.44127V2.31246H7.06455C7.936 2.31246 8.64501 3.02143 8.64501 3.89292C8.64501 4.76442 7.93605 5.47343 7.06455 5.47343Z"
                  fill="white"
                />
              </svg>
            </div>
          ) : (
            <div className={`${s.icon} ${s.referral}`}>
              <svg
                width="22"
                height="24"
                viewBox="0 0 22 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.1701 3.98469C21.1701 5.77286 19.7206 7.22249 17.9324 7.22249C16.1443 7.22249 14.6948 5.77286 14.6948 3.98469C14.6948 2.19669 16.1443 0.74707 17.9324 0.74707C19.7206 0.74707 21.1701 2.19669 21.1701 3.98469Z"
                  fill="white"
                />
                <path
                  d="M17.932 7.96973C15.7343 7.96973 13.9473 6.18246 13.9473 3.98477C13.9473 1.78727 15.7343 0 17.932 0C20.1297 0 21.9168 1.78727 21.9168 3.98477C21.9168 6.18246 20.1297 7.96973 17.932 7.96973ZM17.932 1.49431C16.5583 1.49431 15.4416 2.61213 15.4416 3.98477C15.4416 5.3576 16.5583 6.47542 17.932 6.47542C19.3058 6.47542 20.4225 5.3576 20.4225 3.98477C20.4225 2.61213 19.3058 1.49431 17.932 1.49431Z"
                  fill="white"
                />
                <path
                  d="M21.1701 19.9243C21.1701 21.7123 19.7206 23.1619 17.9324 23.1619C16.1443 23.1619 14.6948 21.7123 14.6948 19.9243C14.6948 18.1361 16.1443 16.6865 17.9324 16.6865C19.7206 16.6865 21.1701 18.1361 21.1701 19.9243Z"
                  fill="white"
                />
                <path
                  d="M17.932 23.9089C15.7343 23.9089 13.9473 22.1217 13.9473 19.9242C13.9473 17.7265 15.7343 15.9392 17.932 15.9392C20.1297 15.9392 21.9168 17.7265 21.9168 19.9242C21.9168 22.1217 20.1297 23.9089 17.932 23.9089ZM17.932 17.4335C16.5583 17.4335 15.4416 18.5513 15.4416 19.9242C15.4416 21.2968 16.5583 22.4146 17.932 22.4146C19.3058 22.4146 20.4225 21.2968 20.4225 19.9242C20.4225 18.5513 19.3058 17.4335 17.932 17.4335Z"
                  fill="white"
                />
                <path
                  d="M7.22297 11.9544C7.22297 13.7426 5.77335 15.192 3.98518 15.192C2.19718 15.192 0.747559 13.7426 0.747559 11.9544C0.747559 10.1662 2.19718 8.7168 3.98518 8.7168C5.77335 8.7168 7.22297 10.1662 7.22297 11.9544Z"
                  fill="white"
                />
                <path
                  d="M3.98477 15.9393C1.78727 15.9393 0 14.1522 0 11.9545C0 9.75681 1.78727 7.96973 3.98477 7.96973C6.18246 7.96973 7.96973 9.75681 7.96973 11.9545C7.96973 14.1522 6.18246 15.9393 3.98477 15.9393ZM3.98477 9.46404C2.61103 9.46404 1.49431 10.5817 1.49431 11.9545C1.49431 13.3273 2.61103 14.445 3.98477 14.445C5.35869 14.445 6.47542 13.3273 6.47542 11.9545C6.47542 10.5817 5.35869 9.46404 3.98477 9.46404Z"
                  fill="white"
                />
                <path
                  d="M6.33705 11.4763C5.99028 11.4763 5.65355 11.2959 5.47023 10.9732C5.19825 10.496 5.36571 9.88729 5.84289 9.61422L15.0866 4.34436C15.5638 4.07038 16.1725 4.23783 16.4455 4.71684C16.7175 5.19403 16.5501 5.80274 16.0729 6.07581L6.82901 11.3457C6.67359 11.4343 6.50432 11.4763 6.33705 11.4763Z"
                  fill="white"
                />
                <path
                  d="M15.5797 19.6951C15.4123 19.6951 15.243 19.6531 15.0876 19.5645L5.84373 14.2946C5.36655 14.0226 5.19927 13.4139 5.47125 12.9356C5.74213 12.4575 6.35175 12.2892 6.83003 12.5632L16.0739 17.833C16.5511 18.105 16.7183 18.7137 16.4464 19.192C16.2621 19.5147 15.9254 19.6951 15.5797 19.6951Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
        </div> */}
      </div>
      <div className={s.sum}>
        {totalSum?.toFixed(0)} ₽
        {/* {isBalance && totalSum && !isNaN(totalSum) ? (
          <>
            {totalSum.toLocaleString('ru')} {t('general.chars.big_r')}
          </>
        ) : !isBalance && typeof totalSum === 'number' ? (
          <>
            {totalSum.toLocaleString('ru')} {t('general.chars.big_r')}
          </>
        ) : (
          <Skeleton
            style={{
              display: 'inline-block',
              height: '25px',
              width: '40%',
            }}
          />
        )} */}
      </div>
      <div className={s.info}>
        <div className={s.left_half}>
          {/* {t('balance_page.for_week')} <br /> */}
          За неделю {getLast7! ? getLast7.toLocaleString('ru') : '0'} {t('general.chars.rub')}
        </div>
        <div className={s.right_half}>
          {/* {t('balance_page.for_month')} <br /> */}
          За месяц {getLast30! ? getLast30.toLocaleString('ru') : '0'} {t('general.chars.rub')}
        </div>
      </div>
    </div>
  )
}

export default React.memo(TopBlock)
