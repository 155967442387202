import React from 'react'
import Checkbox from 'rc-checkbox'
import cn from 'classnames'

import s from './InputCheckbox.module.scss'

type Props = {
  value: boolean
  onChange: Function
  title: string
  containerClassName?: string
}

const InputCheckbox = ({ value, onChange, title, containerClassName }: Props) => {
  return (
    <div className={cn(s.container, containerClassName || '')}>
      <label>
        <Checkbox checked={value} onChange={(e: any) => onChange(e.target.checked)} />
        <span className={s.text}>{title}</span>
      </label>
    </div>
  )
}

export default React.memo(InputCheckbox)
