import React from 'react'
import moment from 'moment'

type Props = {
  time: number | string
}

moment.updateLocale('ru', {
  relativeTime: {
    future: 'in %s',
    past: '%s г',
    s: '1 сек',
    ss: '%d сек',
    m: '1 мин',
    mm: '%d мин',
    h: '1 ч',
    hh: '%d ч',
    d: '1 дн',
    dd: '%d дн',
    w: '1 нед',
    ww: '%d нед',
    M: '1 мес',
    MM: '%d мес',
    y: '1 г',
    yy: '%d г',
  },
})

const Time = ({ time }: Props) => {
  const datediff = moment.unix(parseInt(time.toString(), 10)).locale('ru').fromNow(true)
  return <span>{datediff}</span>
}

export default React.memo(Time)
