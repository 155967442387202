import React from 'react'
import { Link } from 'react-head'

type Props = {
  children?: string
  nickname?: string
  male?: string
}

export const MetaCanonical = ({ children }: Props): React.ReactElement => {
  return <Link rel="canonical" href={children} />
}
