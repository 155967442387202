import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import { HeaderFooterContext } from 'application/presentation/app/HeaderFooter'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { Button, Selector } from 'application/presentation/common/ActionComponents'
import AuthForm from 'application/presentation/common/AuthForm'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import { Checkbox, InputLabel } from 'application/presentation/common/InputComponents'
import InputWithSearchUser from 'application/presentation/common/InputComponents/InputWithSearchUser'
import Loader from 'application/presentation/common/Loader'
import WishPreviewCard from 'application/presentation/common/WishPreviewCard'
import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useWish, { useWishlist } from 'application/domain/useCase/wish/getWish'
import { useProduct } from 'application/domain/useCase/product/getProduct'
import { useAppSelector } from 'application/domain/store/hooks'
import { MetaTitle } from 'application/presentation/app/MetaTags'

import ResultStage from './ResultStage'
import s from './AddWishPage.module.scss'

const AddWishPage = () => {
  const params = useParams<{
    wishid?: string
    productid?: string
    user_nickname?: string
    isNewUser?: string
  }>()
  const location = useLocation()
  const navigate = useNavigate()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const [userNick, setUserNick] = useState(
    params && params.user_nickname ? params.user_nickname : '',
  )
  const [isNewUser, setIsNewUser] = useState(params && params.isNewUser ? true : false)
  const footerContext = useContext(HeaderFooterContext)
  const withoutPrice = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const res = searchParams.get('without_price')
    return res && res === 'true' ? true : false
  }, [location.search])
  const isEdit = useMemo(() => {
    return location.pathname.indexOf('/edit') !== -1
  }, [location])
  const wishid = useMemo(() => {
    return parseInt(params.wishid ? params.wishid : '0', 10)
  }, [params])
  const productid = useMemo(() => {
    return parseInt(params.productid ? params.productid : '0', 10)
  }, [params])
  const { isLoading: productIsLoading, data: product } = useProduct(productid)
  const { isLoading: wishIsLoading, data: wish } = useWish(wishid)
  const { data: wishlistData } = useWishlist()

  const isIdea = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const idea = searchParams.get('idea')
    return idea ? true : false
  }, [location])

  const isIdeaFriendNickname = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const idea = searchParams.get('idea_friend')
    return idea ? idea : false
  }, [location])

  const productData = useMemo(() => {
    return product || undefined
  }, [product])

  const wishData = useMemo(() => {
    return wish || undefined
  }, [wish])

  const [stage, setStage] = useState<number>(1)
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [comment, setComment] = useState<string>('')
  const [selectedCateg, setSelectedCateg] = useState<number>(0)
  const [isHidden, setIsHidden] = useState<boolean>(false)
  const [isMainWish, setIsMainWish] = useState<boolean>(false)
  const [resData, setResData] = useState<any>()
  const [sendIsFull, setSendIsFull] = useState<boolean>(true)
  const [sendInBD, setSendInBD] = useState<boolean>(false)

  useEffect(() => {
    footerContext?.showFooter(false)
    return () => footerContext?.showFooter(true)
  }, [footerContext])

  const categMap = useMemo(
    () =>
      wishlistData
        ? wishlistData.items.map((item: any) => {
            return { id: item.id, name: item.name }
          })
        : [],
    [wishlistData],
  )

  useEffect(() => {
    setStage(1)
    return () => setStage(1)
  }, [])
  useEffect(() => {
    if (withoutPrice) {
      setName('')
      setComment('')
      setSelectedCateg(0)
    } else {
      if (productData) {
        setName(productData[0].name)
        setComment('')
        if (isIdea) {
          setSendIsFull(true)
          setSendInBD(false)
        } else {
          setSelectedCateg(0)
          setIsHidden(false)
          setIsMainWish(false)
        }
      }
      if (isEdit && wishData) {
        setName(wishData.name)
        setComment(wishData.public_comment || '')
        setSelectedCateg(wishData.category ? wishData.category.id : 0)
        setIsHidden(!wishData.is_public)
        setIsMainWish(!!wishData.is_main)
      }
    }
  }, [productData, wishData, isEdit, withoutPrice, isIdea])

  const addOrEditWish = useCallback(() => {
    if (isIdea && !sendInBD && !sendIsFull) {
      ToastNotify('Не выбран способ дарения')
      return false
    }
    if (isIdea && !userNick && !isIdeaFriendNickname) {
      ToastNotify('Не выбран пользователь')
      return false
    }
    setSaveIsLoading(true)
    const data: any = {
      name,
      public_comment: comment,
    }
    if (!isEdit && !withoutPrice && productData) {
      data.product_id = productData[0].id
    }
    if (isIdea) {
      data.type = 'idea'
      data.idea_delivery = sendInBD ? 'birthday' : 'complete'
      if (isIdeaFriendNickname) {
        data.nickname = isIdeaFriendNickname
      } else {
        data.nickname = userNick
      }
      // if (isNewUser) {
      //   data.new_user = true
      // }
    } else {
      data.type = 'wish'
      data.category = selectedCateg
      data.is_public = isHidden ? 0 : 1
      if (isMainWish) {
        data.is_main = isMainWish
      }
    }
    apiClient({
      method: 'POST',
      url: `/api/v1/wish/${
        isEdit
          ? `${wishData ? wishData.id : 0}/edit`
          : `add${withoutPrice ? '?without_price=true' : ''}`
      }`,
      data,
    })
      .then(async (response: any) => {
        // console.log(response.data)
        queryClient.invalidateQueries(['profile'])
        queryClient.invalidateQueries(['ideas'])
        queryClient.invalidateQueries(['myWishlist'])
        setResData(response.data)
        // if (!isIdea && !isEdit && wishlistData.quantity >= 0) {
        //   if (wishlistData.quantity === 0) {
        //     dispatch(setWantCompleteMwStatus(true, response.data.id))
        //   } else if (wishlistData.quantity === 1) {
        //     const showed = await AsyncStorage.getItem('whoOnlineIsShowed')
        //     if (showed !== 'true') {
        //       await AsyncStorage.setItem('whoOnlineIsShowed', 'true')
        //       setTimeout(() => {
        //         dispatch(setWhoOnlineMw(true))
        //       }, 10000)
        //     }
        //   } else if (!userHaveMainWish && !data.is_main) {
        //     dispatch(setSelectMainWishMW(true))
        //   }
        // }
        setSaveIsLoading(false)
        if (isEdit) {
          ToastNotify('Ваше желание изменено!')
          navigate(-1)
        } else {
          setStage(2)
        }
      })
      .catch((error) => {
        setSaveIsLoading(false)
      })
  }, [
    comment,
    navigate,
    isEdit,
    isHidden,
    isIdea,
    isIdeaFriendNickname,
    isMainWish,
    name,
    productData,
    selectedCateg,
    sendInBD,
    sendIsFull,
    userNick,
    wishData,
    withoutPrice,
  ])

  return (
    <>
      <MetaTitle title={isEdit ? 'Редактирование' : `Добавление ${isIdea ? 'идеи' : 'желания'}`} />
      <HeaderMobileTitle.Source>
        {isEdit ? 'Редактирование' : `Добавление ${isIdea ? 'идеи' : 'желания'}`}
      </HeaderMobileTitle.Source>
      <div className={s.container}>
        {(wishid && wishIsLoading) || (productid && productIsLoading) ? (
          <Loader size={32} />
        ) : stage === 2 ? (
          <ResultStage resultData={resData} isIdea={isIdea} />
        ) : (isEdit && wishData && wishData) || (!isEdit && productData) || withoutPrice ? (
          <>
            <WishPreviewCard
              wish={
                withoutPrice
                  ? { name: name ? name : 'Желание без сбора' }
                  : isEdit
                  ? wishData
                  : productData
              }
              type={withoutPrice || isEdit ? 'wish' : 'product'}
              // wishstatus={withoutPrice ? comment || 'Без сбора' : comment}
              // withoutPrice={withoutPrice}
            />
            <div className={s.separator} />
            <>
              {isIdeaFriendNickname ? (
                <></>
              ) : (
                <>
                  {!isEdit && isIdea ? (
                    <InputWithSearchUser
                      setUserNickname={setUserNick}
                      setIsNewUser={setIsNewUser}
                    />
                  ) : null}
                </>
              )}
            </>
            {!user && <AuthForm containerClassName={s.auth} />}
            <InputLabel
              title={isIdea ? 'Введите название вашей идеи' : 'Введите название вашего желания'}
              value={name}
              onChange={setName}
            />
            <InputLabel
              title={
                isIdea ? 'Для чего идея может понадобиться?' : 'Для чего вам нужно это желание'
              }
              value={comment}
              onChange={setComment}
            />
            {isIdea ? (
              <>
                <Checkbox
                  name="sendIsFull"
                  value={sendIsFull}
                  onChange={(val: boolean) => {
                    setSendIsFull(val)
                    setSendInBD(!val)
                  }}
                  containerClassName={s.checkbox}
                  title="Подарить по мере накопления"
                />
                <Checkbox
                  name="sendInBD"
                  value={sendInBD}
                  onChange={(val: boolean) => {
                    setSendIsFull(!val)
                    setSendInBD(val)
                  }}
                  containerClassName={s.checkbox}
                  title="Подарить на день рождения"
                />
              </>
            ) : (
              <>
                <Selector
                  data={categMap}
                  title="Категория"
                  value={selectedCateg}
                  onChange={setSelectedCateg}
                />
                <Checkbox
                  title="Скрыть желание"
                  name="hide_wish"
                  value={isHidden}
                  onChange={setIsHidden}
                  containerClassName={s.checkbox}
                />
                <Checkbox
                  title="Добавить как главное желание"
                  name="hide_wish"
                  value={isMainWish}
                  onChange={setIsMainWish}
                  containerClassName={s.checkbox}
                />
              </>
            )}

            <Button
              title={isEdit ? 'Сохранить' : 'Добавить'}
              onClick={() => addOrEditWish()}
              isLoading={saveIsLoading}
              color="red"
              containerClassName={s.btn_container}
              className={s.btn}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default React.memo(AddWishPage)
