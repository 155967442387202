import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Sheet, PageContent } from 'framework7-react'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { useAppSelector } from 'application/domain/store/hooks'
import { FriendActionMutation } from 'application/domain/useCase/friends/friendsActions'
import { useUser } from 'application/domain/useCase/user/getUser'

import style from '../../ModalWindows/MW.module.scss'

import s from './FriendsHeaderButton.module.scss'

const DeleteFriendMW = () => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))

  const friendMutation = FriendActionMutation('profile')

  const { hash, pathname } = useLocation()
  const navigate = useNavigate()

  const opened = useMemo(() => {
    return hash.indexOf('#delete_friend') !== -1
  }, [hash])

  const closeMW = useCallback(() => navigate(-1), [navigate])

  const friendNickname = useMemo(() => {
    return pathname ? pathname.replace('/', '') : ''
  }, [pathname])

  const { data: profileData, isLoading: profileIsLoading } = useUser(friendNickname)

  const isMyProfile = useMemo(() => {
    return user && user.nickname && profileData && profileData.nickname === user.nickname
      ? true
      : false
  }, [profileData, user])

  const action = useCallback(
    (type: string, e: any) => {
      e.preventDefault()

      switch (type) {
        case 'decline':
          friendMutation.mutate({
            friend: profileData,
            method: 'DELETE',
            callback: () => {
              ToastNotify('Друг удалён!')
              closeMW()
            },
          })
          break
        default:
          break
      }
    },
    [closeMW, friendMutation, profileData],
  )

  return (
    <Sheet
      className={`${style.sheet_modal} ${style.auto_height}`}
      swipeToClose
      backdrop
      opened={opened}
      onSheetClosed={() => {
        if (location.hash.indexOf('#delete_friend') !== -1) {
          closeMW()
        }
      }}
    >
      <div
        className={`${style.top_close_button} top-close-button`}
        onClick={() => {
          closeMW()
        }}
      >
        <div />
      </div>
      <PageContent className={s.content}>
        <div className={s.item} onClick={(e: any) => action('decline', e)}>
          <div className={s.title}>Удалить из друзей</div>
          <div className={s.icon}>
            <img src="/images/icons/trash.svg" alt="delete" />
          </div>
        </div>
      </PageContent>
    </Sheet>
  )
}

export default React.memo(DeleteFriendMW)
