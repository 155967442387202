/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { EffectCoverflow } from 'swiper'
SwiperCore.use([EffectCoverflow])

import s from './Stories.module.scss'
import StoriesWrapper from './StoriesWrapper'

type DataType = {
  id: number
}

type Props = {
  fetchStory: Function
  data: Array<DataType>
  templates: (data: any) => Array<any> | null
  initialIndex?: number
  //   children: React.ReactElement
}

const Stories = ({ fetchStory, data, templates, initialIndex }: Props) => {
  const [swiperRef, setSwiperRef] = useState<any>()
  const goPrevSlide = useCallback(() => {
    swiperRef.slidePrev()
  }, [swiperRef])

  const goNextSlide = useCallback(() => {
    swiperRef.slideNext()
  }, [swiperRef])
  return (
    <Swiper
      slidesPerView={1}
      className={s.swiper_container}
      effect="coverflow"
      // @ts-ignore
      onSwiper={(sw) => {
        setSwiperRef(sw)
      }}
      onSlideChange={(swiper) => {
        //   setCurrentIndex(swiper.activeIndex)
      }}
      initialSlide={initialIndex}
    >
      {data.map((item: DataType) => {
        return (
          <SwiperSlide key={`item_slide_${item.id}`}>
            {({ isActive, isNext, isPrev }) => {
              return isActive || isNext || isPrev ? (
                <StoriesWrapper
                  key={`story_item_${item.id}`}
                  data={item}
                  fetchStory={fetchStory}
                  templates={templates}
                  goNextSlide={goNextSlide}
                  goPrevSlide={goPrevSlide}
                  isActive={isActive}
                />
              ) : (
                <></>
              )
            }}
          </SwiperSlide>
        )
      })}
      {/* {articles.map((item: any) => {
        return (
          <SwiperSlide>
            {({ isActive, isNext, isPrev }) => {
                    return isActive || isNext || isPrev ? (
                      <ArticleItem
                        item={item}
                        isActive={isActive}
                        onStoryEnd={onStoryEnd}
                        goPrevStory={goPrevStory}
                      />
                    ) : (
                      <></>
                    )
                  }}
          </SwiperSlide>
        )
      })} */}
    </Swiper>
  )
}

export default React.memo(Stories)
