import { useAppSelector } from 'application/domain/store/hooks'
import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import s from '../../../AppView.module.scss'
import PrivateRoute from '../app/PrivateRoute'
import AboutMenu from '../common/AboutMenu'
import AboutPage from '../pages/AboutPage'
import AddToFriendsPage from '../pages/AddToFriendsPage'
import AddWishPage from '../pages/AddWishPage'
import ArticlePage from '../pages/ArticlePage'
import AuthPage from '../pages/AuthPage'
import BalanceAndRefPage from '../pages/BalanceAndRefPage'
import BonusPage from '../pages/BonusPage'
import CashbackPage from '../pages/CashbackPage'
import CommentsPage from '../pages/CommentsPage'
import ContactsPage from '../pages/ContactsPage'
import DonatePage from '../pages/DonatePage'
import FaqPage from '../pages/FaqPage'
import FaqQuestionsPage from '../pages/FaqQuestionsPage'
import FilterProductPage from '../pages/FilterProductPage'
import ForPartnersPage from '../pages/ForPartnersPage'
import MainPage from '../pages/MainPage'
import MenuPage from '../pages/MenuPage'
import NotificationsPage from '../pages/NotificationsPage'
import OrderPage from '../pages/OrderPage'
import PartnerList from '../pages/PartnersPage/PartnerList'
import PartnersPageMobile from '../pages/PartnersPage/PartnersPageMobile'
import ProductPage from '../pages/ProductPage'
import PublicProfilePage from '../pages/PublicProfilePage'
import SearchResultPage from '../pages/SearchResultPage'
import SelectLocationPage from '../pages/SelectLocationPage'
import SettingsPage from '../pages/SettingsPage'
import ShopCategoryPage from '../pages/ShopCategoryPage'
import ShopListPage from '../pages/ShopListPage'
import ShopPage from '../pages/ShopPage'
import UserDonatesPage from '../pages/UserDonatesPage'
import UserFriendsCalendar from '../pages/UserFriendsPage/UserFriendsCalendar'
import UserFriendsPage from '../pages/UserFriendsPage/UserFriendsPage'
import UserFriendsRequestPage from '../pages/UserFriendsPage/UserFriendsRequestPage'
import UserIdeasPage from '../pages/UserIdeasPage'
import UserOrdersPage from '../pages/UserOrdersPage'
import WishPage from '../pages/WishPage'

const MainNavigation = () => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))

  return (
    <div className={s.page_content}>
      <Routes>
        <Route path="/" element={<MainPage />}></Route>
        <Route path="/shop" element={<ShopPage />} />
        <Route path="/shop/category" element={<Navigate to="/shop" replace />} />
        <Route path={'/collections/:category/:article_id'} element={<ArticlePage />} />
        <Route
          path="/notification"
          element={
            <PrivateRoute>
              <NotificationsPage />
            </PrivateRoute>
          }
        />
        <Route path="/balance" element={<BalanceAndRefPage />} />
        <Route path="/balance/:filter" element={<BalanceAndRefPage />} />
        <Route path="/balance/:filter/:id" element={<BalanceAndRefPage />} />

        <Route path="/referral" element={<BalanceAndRefPage />} />
        <Route path="/referral/:info" element={<BalanceAndRefPage />} />
        <Route path="/referral/:info/:id" element={<BalanceAndRefPage />} />
        <Route path="/:nickname/menu" element={<MenuPage />} />
        <Route path="/:nickname/settings" element={<SettingsPage />} />
        <Route path="/about" element={<AboutPage />}></Route>
        <Route path="/documentation" element={<AboutMenu />} />
        <Route path="/for_partners" element={<ForPartnersPage />} />
        <Route path="/faq/:slug" element={<FaqQuestionsPage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="/bonus" element={<BonusPage />} />
        <Route path="/shop/category/:slug/filter" element={<FilterProductPage />} />
        <Route path="/shop/category/:slug/:id" element={<ProductPage />} />
        <Route path="/shop/category/:slug/:id/cashback" element={<CashbackPage />} />
        <Route path="/shop/category/:slug" element={<ShopCategoryPage />} />
        <Route path="/shop/categories" element={<ShopListPage />} />
        <Route path="/shop/results" element={<SearchResultPage />} />
        <Route path="/shop/partner" element={<PartnerList />} />
        <Route path="/shop/partner/:id" element={<PartnersPageMobile />} />
        <Route path="/location" element={<SelectLocationPage />} />

        <Route path="/orders" element={<UserOrdersPage />} />
        <Route path="/orders/completed/:id" element={<UserOrdersPage />} />
        <Route path="/orders/:id" element={<UserOrdersPage />} />
        <Route path="/donates" element={<Navigate to="/donates/in" replace />} />
        <Route
          path="/donates/in/:id"
          element={
            <PrivateRoute>
              <UserDonatesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/donates/out/:id"
          element={
            <PrivateRoute>
              <UserDonatesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/wishlist"
          element={<Navigate to={`/${user ? user.nickname : ''}`} replace />}
        />
        <Route
          path="/:nickname/menu"
          element={
            <PrivateRoute>
              <MenuPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/:nickname/settings"
          element={
            <PrivateRoute>
              <SettingsPage />
            </PrivateRoute>
          }
        />

        <Route path="/:nickname/ideas/:wishid/order" element={<OrderPage />} />
        <Route path="/:nickname/ideas/:wishid/comments" element={<CommentsPage />} />
        <Route path="/:nickname/ideas/:wishid/donate" element={<DonatePage />} />
        <Route path="/:nickname/ideas/:wishid" element={<WishPage />} />
        <Route path="/:nickname/ideas" element={<UserIdeasPage />} />

        <Route path="/:nickname/friends/add" element={<AddToFriendsPage />} />
        <Route path="/:nickname/friends/new" element={<UserFriendsRequestPage />} />
        <Route path="/:nickname/friends/calendar" element={<UserFriendsCalendar />} />
        <Route path="/:nickname/friends" element={<UserFriendsPage />} />
        <Route path="/:nickname" element={<PublicProfilePage />} />

        <Route path="/:nickname/:wishid/comments" element={<CommentsPage />} />
        <Route path="/:nickname/:wishid/donate" element={<DonatePage />} />
        <Route path="/:nickname/:wishid/order" element={<OrderPage />} />

        <Route path="/:nickname/:wishid/edit" element={<AddWishPage />} />
        <Route path="/shop/category/:slug/:productid/add" element={<AddWishPage />} />

        <Route path="/:nickname/:wishid" element={<WishPage />} />
        <Route path="/login" element={<AuthPage />} />
      </Routes>
      {/* <CategoryListMW />
      <CityMW />
      <AddOrEditWishMW />
      <ShareMW /> */}
    </div>
  )
}

export default React.memo(MainNavigation)
