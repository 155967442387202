import axios from 'axios'
import _ from 'lodash'
import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import Cookies from 'js-cookie'
import { setUser } from 'application/domain/store/userReducer'

const getUserCity = (user: any) => {
  if (user) {
    let geo: any
    if (typeof user.geolocation === 'string') {
      geo = JSON.parse(user.geolocation)
    } else {
      geo = user.geolocation
    }
    const cookieCity = Cookies.get('City')
    return geo
      ? geo.selected
        ? geo.selected
        : geo.current
        ? geo.current
        : cookieCity
        ? cookieCity.toString()
        : 'Город не выбран'
      : 'Город не выбран'
  }
}

export const getGeo = (user: any, dispatch: any) => {
  const selected = getUserCity(user)
  if (!selected || !selected.length || selected === 'Город не выбран') {
    navigator.geolocation.getCurrentPosition((position) => {
      if (position) {
        const { coords } = position
        axios
          .get(
            `https://geocode-maps.yandex.ru/1.x?geocode=${coords.longitude},${coords.latitude}&apikey=3fde4a75-e191-441e-aa18-ecc2d80124ce&kind=locality&format=json&results=1`,
          )
          .then(({ data }: any) => {
            if (data) {
              try {
                const city = _.find(
                  data.response.GeoObjectCollection.featureMember[0].GeoObject.metaDataProperty
                    .GeocoderMetaData.Address.Components,
                  { kind: 'locality' },
                )
                console.log(city)
                if (city && city.name) {
                  Cookies.set('City', city.name)
                  apiClient({
                    url: '/api/v1/user/geolocation',
                    method: 'POST',
                    data: {
                      selected: city.name,
                    },
                  })
                    .then((response: any) => {
                      queryClient.cancelQueries(['profile'])
                      Cookies.set('City', city.name)
                      setUser({
                        ...user,
                        geolocation: response.data.success.geolocation,
                      })(dispatch)
                    })
                    .catch((error) => {
                      console.log(error)
                    })
                }
              } catch (e) {
                console.log(e)
              }
            }
          })
      }
    })
  }
}

export default getUserCity
