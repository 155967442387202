import React, { useContext, useEffect, useMemo, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { HeaderMobileRightButton, HeaderMobileTitle } from 'application/presentation/common/Header'
import ShareButton from 'application/presentation/common/ActionComponents/ShareButton'
import WishlistContent from 'application/presentation/common/WishlistContent'
import ContentLayout from 'application/presentation/common/ContentLayout'
import ProfileContent from 'application/presentation/common/ProfileContent/ProfileContent'
import AddWishButton from 'application/presentation/common/AddWishButton'
import BurgerMenu from 'application/presentation/common/BurgerMenu'
import { MetaDescriptionUser, MetaTitleUser } from 'application/presentation/app/MetaTags'
import { HeaderFooterContext } from 'application/presentation/app/HeaderFooter'
import FriendsHeaderButton from 'application/presentation/common/ActionComponents/FriendsHeaderButton'
import nProgress from 'nprogress'
import { useAppSelector } from 'application/domain/store/hooks'
import { useUser } from 'application/domain/useCase/user/getUser'
import { useWishIdeas } from 'application/domain/useCase/wish_ideas/getWishIdeas'

import s from './PublicProfilePage.module.scss'
import ProfileLoaderPage from './components/ProfileLoaderPage'
import IdeasList from './components/IdeasList'

const PublicProfilePage = () => {
  const navigate = useNavigate()
  const params = useParams<{ nickname?: string }>()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))

  const [openFriendBlock, setOpenFriendBlock] = useState<boolean>(false)

  const { data, isLoading } = useUser(params.nickname)

  const headerColor = useContext(HeaderFooterContext)

  const { data: ideasData, isLoading: ideasIsLoading } = useWishIdeas(params.nickname)

  useEffect(() => {
    headerColor?.headerBgColor(true)
    return () => headerColor?.headerBgColor(false)
  }, [headerColor])

  const isMyProfile = useMemo(() => {
    return user && data && user.nickname === data.nickname
  }, [data, user])

  if (isLoading) {
    nProgress.start()
    return (
      <ContentLayout>
        <HeaderMobileTitle.Source>{params.nickname}</HeaderMobileTitle.Source>
        <MetaTitleUser>{params.nickname}</MetaTitleUser>
        <MetaDescriptionUser male="">{params.nickname}</MetaDescriptionUser>
        <ProfileLoaderPage />
      </ContentLayout>
    )
  }
  nProgress.done()

  return (
    <>
      <ContentLayout>
        {data ? (
          <div>
            <HeaderMobileTitle.Source>
              {isMyProfile ? (
                <>
                  {params.nickname}
                  <NavLink to={`/${params.nickname}/settings`} style={{ display: 'inline' }}>
                    <img
                      src="/images/icons/pencil-gray-icon.svg"
                      alt=""
                      style={{
                        marginLeft: '0.5rem',
                        position: 'relative',
                        top: '0.4rem',
                      }}
                    />
                  </NavLink>
                </>
              ) : (
                params.nickname
              )}
            </HeaderMobileTitle.Source>
            <MetaTitleUser>{params.nickname}</MetaTitleUser>
            <MetaDescriptionUser male="">{params.nickname}</MetaDescriptionUser>
            <HeaderMobileRightButton.Source>
              {isMyProfile ? (
                <>
                  <ShareButton shareType="profile" nickname={params.nickname} />
                  <BurgerMenu />
                </>
              ) : (
                <>
                  <FriendsHeaderButton
                    data={data}
                    isMyProfile={isMyProfile || false}
                    setOpenFriendBlock={setOpenFriendBlock}
                    openFriendBlock={openFriendBlock}
                  />
                  <ShareButton shareType="profile" nickname={params.nickname} />
                </>
              )}
            </HeaderMobileRightButton.Source>
            <ProfileContent
              user={data}
              openFriendBlock={openFriendBlock}
              isMyProfile={isMyProfile || false}
            />
            <div className={s.wishlist_container}>
              <div className={s.title}>Список желаний</div>
              {data.wish && data.wish.quantity !== 0 ? (
                <WishlistContent data={data.wish.items} />
              ) : (
                <div className={s.empty_wishlist}>
                  <div className={s.emoji}>🤔</div>
                  {isMyProfile ? (
                    <>
                      <div className={s.text}>У вас еще нет желаний,</div>
                      <div className={s.subline}>добавьте первое</div>
                    </>
                  ) : (
                    <>
                      <div className={s.text}>Пользователь еще не добавил</div>
                      <div className={s.subline}>ни одного желания</div>
                    </>
                  )}
                  {isMyProfile && (
                    <AddWishButton type="block" onClick={() => navigate('#search')} />
                  )}
                </div>
              )}
            </div>

            {isMyProfile && data.wish && data.wish.quantity > 0 && (
              <AddWishButton type="fixed" onClick={() => navigate('#search')} />
            )}
          </div>
        ) : (
          <div>Пользователь не найден</div>
        )}
        {!isMyProfile && !ideasIsLoading ? (
          <IdeasList
            name={data?.name || ''}
            nickname={params.nickname ? params.nickname : data?.nickname || ''}
            ideasData={ideasData}
          />
        ) : (
          <></>
        )}
      </ContentLayout>
    </>
  )
}

export default React.memo(PublicProfilePage)
