import React, { useCallback, useEffect, useState } from 'react'

import GestureController from './components/GestureController'
import ProgressBar from './components/ProgressBar'
import s from './Story.module.scss'

type StoryType = {
  content: any
}

type Props = {
  stories: Array<StoryType>
  goNextSlide: Function
  goPrevSlide: Function
  isActive: boolean
}

const Story = ({ stories, goNextSlide, goPrevSlide, isActive }: Props) => {
  const [index, setIndex] = useState<number>(0)
  const [paused, setPaused] = useState<boolean>(true)
  const [isTouch, setIsTouch] = useState<boolean>(false)
  useEffect(() => {
    if (!isTouch) {
      setPaused(!isActive)
    } else {
      setPaused(true)
    }
  }, [isActive, isTouch])

  const getStoryContent = useCallback(() => {
    let InnerContent = stories[index].content
    // @ts-ignore
    return <InnerContent paused={paused} />
  }, [paused, stories, index])

  return (
    <div className={s.story_contener}>
      <ProgressBar
        elemCount={stories.length}
        currentIndex={index}
        setIndex={setIndex}
        goNextSlide={goNextSlide}
        paused={paused}
      />
      {getStoryContent()}
      {/* {stories[index].content} */}
      <GestureController
        index={index}
        setIndex={setIndex}
        elemCount={stories.length}
        goNextSlide={goNextSlide}
        goPrevSlide={goPrevSlide}
        setPaused={setPaused}
        paused={paused}
        setIsTouch={setIsTouch}
      />
    </div>
  )
}

export default React.memo(Story)
