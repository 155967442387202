import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cn from 'classnames'

import s from './ProgressBar.module.scss'

type Props = {
  setIndex: Function
  elemCount: number
  goNextSlide: Function
  currentIndex: number
  paused: boolean
}

const getProgressStyle = ({ active, count }: any) => {
  switch (active) {
    case 2:
      return { width: '100%' }
    case 1:
      return { transform: `scaleX(${count / 100})` }
    case 0:
      return { width: 0 }
    default:
      return { width: 0 }
  }
}

const ProgressBar = ({ elemCount, currentIndex, goNextSlide, setIndex, paused }: Props) => {
  const [count, setCount] = useState<number>(0)
  const elemWidth = useMemo(() => {
    return (window.innerWidth - 30) / elemCount - 4
  }, [elemCount])

  useEffect(() => {
    setCount(0)
  }, [currentIndex, elemCount, paused])

  let animationFrameId = useRef<any>()

  const incrementCount = useCallback(() => {
    let countCopy = count
    // if (countCopy === 0) storyStartCallback()
    setCount((c: number) => {
      const interval = 5000
      countCopy = c + 100 / ((interval / 1000) * 60)
      return c + 100 / ((interval / 1000) * 60)
    })
    if (countCopy < 100) {
      animationFrameId.current = requestAnimationFrame(incrementCount)
    } else {
      // storyEndCallback();
      //   if (currentId === stories.length - 1) {
      //     allStoriesEndCallback()
      //   }
      cancelAnimationFrame(animationFrameId.current)
      if (currentIndex < elemCount - 1) {
        setIndex(currentIndex + 1)
      } else {
        goNextSlide()
      }
    }
  }, [count, currentIndex, elemCount, goNextSlide, setIndex, animationFrameId])

  useEffect(() => {
    if (!paused) {
      animationFrameId.current = requestAnimationFrame(incrementCount)
    } else {
      cancelAnimationFrame(animationFrameId.current)
    }
    return () => {
      cancelAnimationFrame(animationFrameId.current)
    }
  }, [currentIndex, paused, incrementCount])

  return (
    <div className={cn(s.progress_bar, paused ? s.hidden : '')}>
      {[...Array(elemCount)].map((_, i: number) => (
        <div
          className={s.progress_item}
          style={{ width: `${elemWidth}px` }}
          key={`progress_item_${i}`}
        >
          <div
            className={s.progress_inner}
            style={getProgressStyle({
              active: i === currentIndex ? 1 : i < currentIndex ? 2 : 0,
              count,
            })}
          />
        </div>
      ))}
    </div>
  )
}

export default React.memo(ProgressBar)
