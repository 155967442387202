import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import Backend from 'i18next-http-backend'

import en from './locales/translation.en.json'
import ru from './locales/translation.ru.json'

const resources = {
  en: {
    translation: en,
  },
  ru: {
    translation: ru,
  },
}

i18n
  // .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    // ns: ['ru', 'en'],
    // defaultNS: 'ru',
    // backend: {
    //   loadPath: '/locales/translation.{{lng}}.json',
    // },
    // lng: window.localStorage.getItem('lang'),
    fallbackLng: 'ru',
    saveMissing: true,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
