import React from 'react'
import { NavLink } from 'react-router-dom'

import s from './OffertaInfo.module.scss'

const OffertaInfo = () => {
  return (
    <div className={s.container}>
      Я ознакомился <NavLink to="/documentation/oferta">с правилами и офертой</NavLink>
      <br />
      сервиса и полностью принимаю их
    </div>
  )
}

export default React.memo(OffertaInfo)
