import { editWishRequest } from 'application/data/api/wish'
import queryClient from 'application/data/apiClient/queryClient'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/reduxStore'

export const editWish = (id: number, data: any) => {
  return editWishRequest(id, data)
}

export const HideWishMutation = (callback?: () => void) => {
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const mutation = useMutation(
    ({ wish_id, is_public }: any) => editWishRequest(wish_id, { is_public: !is_public }),
    {
      onMutate: async ({ wish_id, is_public }) => {
        await queryClient.cancelQueries(['myWishlist'])
        const previousData = queryClient.getQueryData<any>(['myWishlist'])
        const wishPrevData = queryClient.getQueryData<any>(['wish', wish_id])
        const profilePrevData = queryClient.getQueryData<any>([
          'profile',
          user ? user.nickname : '',
        ])
        if (previousData) {
          queryClient.setQueryData(['myWishlist'], {
            ...previousData,
            items: previousData.items.map((item: any) => {
              return {
                ...item,
                wish: item.wish.map((item2: any) => {
                  if (item2.id === wish_id) {
                    item2.is_public = is_public === 1 ? 0 : 1
                  }
                  return item2
                }),
              }
            }),
          })
        }
        if (wishPrevData) {
          queryClient.setQueryData(['wish', wish_id], {
            ...wishPrevData,
            0: {
              ...wishPrevData[0],
              is_public: is_public === 1 ? 0 : 1,
            },
          })
        }
        if (profilePrevData) {
          queryClient.setQueryData(['profile', user ? user.nickname : ''], {
            ...profilePrevData,
            wish: {
              ...profilePrevData.wish,
              items: profilePrevData.wish.items.map((item: any) => {
                return {
                  ...item,
                  wish: item.wish.map((item2: any) => {
                    if (item2.id === wish_id) {
                      item2.is_public = is_public === 1 ? 0 : 1
                    }
                    return item2
                  }),
                }
              }),
            },
          })
        }
        if (callback) {
          callback()
        }
        return { previousData, wishPrevData, profilePrevData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(['myWishlist'], context.previousData)
          queryClient.setQueryData(['wish', variables.wish_id], context.wishPrevData)
          queryClient.setQueryData(['profile', user ? user.nickname : ''], context.profilePrevData)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['myWishlist'])
        queryClient.invalidateQueries(['wish'])
        queryClient.invalidateQueries(['profile'])
      },
    },
  )
  return mutation
}

type MainWishType = {
  wish_id: number
  is_main: boolean
}

export const SetMainWishMutation = (callback?: () => void) => {
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const mutation = useMutation(
    ({ wish_id, is_main }: MainWishType) =>
      editWishRequest(wish_id, { is_main: is_main ? true : false }),
    {
      onMutate: async ({ wish_id }) => {
        queryClient.cancelQueries(['profile'])
        queryClient.cancelQueries(['wish'])
        const wishPrevData = queryClient.getQueryData<any>(['wish', wish_id])
        const profilePrevData = queryClient.getQueryData<any>([
          'profile',
          user ? user.nickname : '',
        ])
        if (wishPrevData) {
          queryClient.setQueryData(['wish', wish_id], {
            ...wishPrevData,
            0: {
              ...wishPrevData[0],
              is_main: true,
            },
          })
        }
        if (profilePrevData) {
          queryClient.setQueryData(['profile', user ? user.nickname : ''], {
            ...profilePrevData,
            wish: {
              ...profilePrevData.wish,
              items: profilePrevData.wish.items.map((item: any) => {
                return {
                  ...item,
                  wish: item.wish.map((item2: any) => {
                    item2.is_main = false
                    if (item2.id === wish_id) {
                      item2.is_main = true
                    }
                    return item2
                  }),
                }
              }),
            },
          })
        }
        if (callback) {
          callback()
        }
        return { wishPrevData, profilePrevData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          // queryClient.setQueryData(['myWishlist'], context.previousData)
          queryClient.setQueryData(['wish', variables.wish_id], context.wishPrevData)
          queryClient.setQueryData(['profile', user ? user.nickname : ''], context.profilePrevData)
        }
      },
      onSettled: () => {
        // queryClient.invalidateQueries('myWishlist')
        queryClient.invalidateQueries(['wish'])
        queryClient.invalidateQueries(['profile'])
      },
    },
  )
  return mutation
}

export const OpenWishMutation = (callback?: () => void) => {
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const mutation = useMutation(({ wish_id }: any) => editWishRequest(wish_id, { is_opened: 1 }), {
    onMutate: async ({ wish_id }) => {
      queryClient.cancelQueries(['profile'])
      queryClient.cancelQueries(['wish'])
      const wishPrevData = queryClient.getQueryData<any>(['wish', wish_id])
      const profilePrevData = queryClient.getQueryData<any>(['profile', user ? user.nickname : ''])
      if (wishPrevData) {
        queryClient.setQueryData(['wish', wish_id], {
          ...wishPrevData,
          0: {
            ...wishPrevData[0],
            is_opened: 1,
          },
        })
      }
      if (profilePrevData) {
        queryClient.setQueryData(['profile', user ? user.nickname : ''], {
          ...profilePrevData,
          wish: {
            ...profilePrevData.wish,
            items: profilePrevData.wish.items.map((item: any) => {
              return {
                ...item,
                wish: item.wish.map((item2: any) => {
                  if (item2.id === wish_id) {
                    item2.is_opened = 1
                  }
                  return item2
                }),
              }
            }),
          },
        })
      }
      if (callback) {
        callback()
      }
      return { wishPrevData, profilePrevData }
    },
    onError: (err, variables, context: any) => {
      if (context?.previousData) {
        // queryClient.setQueryData(['myWishlist'], context.previousData)
        queryClient.setQueryData(['wish', variables.wish_id], context.wishPrevData)
        queryClient.setQueryData(['profile', user ? user.nickname : ''], context.profilePrevData)
      }
    },
    onSettled: () => {
      // queryClient.invalidateQueries('myWishlist')
      queryClient.invalidateQueries(['wish'])
      queryClient.invalidateQueries(['profile'])
    },
  })
  return mutation
}
