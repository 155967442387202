import React from 'react'
import { ReactSVG } from 'react-svg'
import cn from 'classnames'

import s from './IconSvg.module.scss'

type Props = {
  src: string
  className?: string
}

const IconSvg = ({ src, className }: Props) => {
  return <ReactSVG src={src} wrapper="span" className={cn(s.container, className || '')} />
}

export default React.memo(IconSvg)
