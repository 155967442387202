import React, { useMemo, useState } from 'react'
import ContentLayout from 'application/presentation/common/ContentLayout'
import Header, {
  HeaderMobileLeftButton,
  HeaderMobileTitle,
} from 'application/presentation/common/Header'
import apiClient from 'application/data/apiClient'
import Loader from 'application/presentation/common/Loader'
import { NavLink, useLocation } from 'react-router-dom'
import _ from 'lodash'
import {
  useShopParentCategories,
  useShopSubCategory,
} from 'application/domain/useCase/shop/getShop'

import s from './ShopListPage.module.scss'

const fetchCategList = async () => {
  const { data } = await apiClient.get(`/api/v1/shop/category?parent=0`)

  return data.data
}

const fetchCategory = async (id: string | number) => {
  const { data } = await apiClient.get(`/api/v1/shop/sub_category/${id}`)

  return data
}

const ShopListPage = () => {
  const [localHistory, setLocalHistory] = useState<any>([])
  const { data, isLoading } = useShopParentCategories()
  const { hash } = useLocation()

  const categId = useMemo(() => {
    const category = Number(hash.replace('#categories-', ''))
    return hash && category !== 0 ? category : 0
  }, [hash])

  const { data: categData, isLoading: categIsLoading } = useShopSubCategory(categId)

  return (
    <ContentLayout>
      <Header forceShow />
      <HeaderMobileTitle.Source>
        {categId !== 0 ? categData?.name : 'Категории'}
      </HeaderMobileTitle.Source>
      {isLoading || categIsLoading ? (
        <Loader />
      ) : categId === 0 && data ? (
        <div className={s.list_container}>
          {/* @ts-ignore */}
          {data.map((item: any) => {
            return (
              <NavLink to={`#categories-${item.id}`} className={s.item} key={item.id}>
                <div className={s.img_block}>
                  <img src={item.photo_mini} alt={item.slug} />
                </div>
                <div className={s.name}>{item.name}</div>
                <div className={s.arrow}>
                  <img src="/images/icons/gray-right-trigger.svg" alt="" />
                </div>
              </NavLink>
            )
          })}
        </div>
      ) : categId !== 0 && categData ? (
        <div className={s.list_container}>
          {categId && categId !== 0 ? (
            <NavLink to={`/shop/category/${categData.slug}`} className={s.item}>
              <div className={s.name}>Все товары</div>
              <div className={s.arrow}>
                <img src="/images/icons/gray-right-trigger.svg" alt="" />
              </div>
            </NavLink>
          ) : (
            <></>
          )}
          {categData.child_category.map((item: any, i: number) => {
            const isLink = item.child_category.length < 1
            return (
              <NavLink
                to={`${isLink ? `/shop/category/${item.slug}` : `#categories-${item.id}`}`}
                className={s.item}
                key={item.id}
              >
                <div className={s.name}>{item.name}</div>
                <div className={s.arrow}>
                  <img src="/images/icons/gray-right-trigger.svg" alt="" />
                </div>
              </NavLink>
            )
          })}
        </div>
      ) : (
        <></>
      )}
    </ContentLayout>
  )
}

export default React.memo(ShopListPage)
