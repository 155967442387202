import React from 'react'

import s from './SevenBlock.module.scss'

const SevenBlock = () => {
  return (
    <div className={s.container}>
      <div className={s.inner_content}>
        <div className={s.img_block}>
          <div className={s.number}>
            <img src="/images/mainPage/number3.png" loading="lazy" alt="" />
          </div>
          <img src="/images/mainPage/wish-app.png" loading="lazy" alt="" />
        </div>
        <div className={s.title}>Твои желания в одно касание</div>
        <div className={s.subtitle}>Уже доступно в вашем магазине предложений</div>
        <div className={s.ios_android}>
          <a
            href="https://apps.apple.com/ru/app/wishmen-cpisok-zelanii/id1569348715"
            className={s.link}
          >
            <div className={s.item}>
              <img src="/images/mainPage/apple-main.png" alt="" />
            </div>
          </a>
          <a href="https://play.google.com/store/apps/details?id=wish.men" className={s.link}>
            <div className={s.item}>
              <img src="/images/mainPage/google-main.png" alt="" />
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SevenBlock)
