import React from 'react'
import { useTranslation } from 'react-i18next'
import ContentLayout from 'application/presentation/common/ContentLayout'
import { useLocation } from 'react-router-dom'

import BalancePage from './BalancePage'
import ReferalPage from './ReferalPage'

const BalanceAndRefPage = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isBalance = pathname.startsWith('/balance')
  return isBalance ? (
    <ContentLayout>
      <BalancePage t={t} />
    </ContentLayout>
  ) : (
    <ContentLayout>
      <ReferalPage t={t} />
    </ContentLayout>
  )
}

export default React.memo(BalanceAndRefPage)
