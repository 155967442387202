import { fetchBalance, fetchReferalBalance } from 'application/data/api/balance'
import { BalanceResponse } from 'application/domain/response/response_types'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'

export const getBalance = async () => fetchBalance({})

export const useBalance = (token: string) => {
  const query = useQuery<BalanceResponse>(['balance', token], fetchBalance, {
    enabled: token ? true : false,
  })
  return query
}

export const useReferralBalance = (token: string) => {
  const query = useQuery<BalanceResponse>(['referral', token], fetchReferalBalance, {
    enabled: token ? true : false,
  })
  return query
}

export const useBalanceInfinite = (token: string) => {
  const query = useInfiniteQuery<BalanceResponse>(
    ['balance_infinite', token],
    ({ pageParam = '/api/v1/user/balance' }) => fetchBalance(pageParam),
    {
      enabled: token ? true : false,
      getNextPageParam: (lastPage) => lastPage.transactions.next_page_url,
    },
  )
  return query
}

export const getReferralBalance = async () =>
  fetchReferalBalance({ pageParam: '/api/v1/user/balance?referral=1' })

export const useReferralBalanceInfinite = (token: string, config?: any) => {
  const query = useInfiniteQuery<BalanceResponse>(
    ['referral_infinite', token],
    ({ pageParam = '/api/v1/user/balance?referral=1' }) => fetchReferalBalance(pageParam),
    {
      enabled: token ? true : false,
      getNextPageParam: (lastPage) => lastPage.transactions.next_page_url,
      ...config,
    },
  )
  return query
}
