import React, { useEffect, useMemo, useState } from 'react'

import Loader from '../Loader'

import Story from './Story'
import s from './StoriesWrapper.module.scss'

type Props = {
  data: {
    id: number
  }
  fetchStory: Function
  templates: (data: any) => Array<any> | null
  goNextSlide: Function
  goPrevSlide: Function
  isActive: boolean
}

const StoriesWrapper = ({
  data,
  fetchStory,
  templates,
  goNextSlide,
  goPrevSlide,
  isActive,
}: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [storyData, setStoryData] = useState<any>(null)
  useEffect(() => {
    fetchStory(data.id)
      .then((response: any) => {
        setStoryData(response)
        setIsLoading(false)
      })
      .catch(() => {
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const parsed = useMemo(() => {
    if (templates) {
      const temp = templates(storyData)
      if (temp && temp.length) {
        return temp.map((item: any) => ({
          content: item,
        }))
      }
    }
    return null
  }, [templates, storyData])

  return isLoading ? (
    <Loader color="#fff" containerClassName={s.loader} />
  ) : parsed ? (
    <Story
      stories={parsed}
      goNextSlide={goNextSlide}
      goPrevSlide={goPrevSlide}
      isActive={isActive}
    />
  ) : (
    <></>
  )
}

export default React.memo(StoriesWrapper)
