import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'

import { PaymentMethod } from '../entity/payment/Payment'

type PaymentMethodState = {
  selectedPayMethod: number
  payMethods: Array<PaymentMethod>
}

const initialState: PaymentMethodState = {
  payMethods: [],
  selectedPayMethod: 1,
}

export const payReducer = createSlice({
  name: 'payReducer',
  initialState,
  reducers: {
    setSelectedPayMethod: (state, { payload: method }: PayloadAction<number>) => {
      state.selectedPayMethod = method
    },
    setPayMethodsAction: (state, { payload: methods }: PayloadAction<Array<PaymentMethod>>) => {
      state.payMethods = methods
    },
  },
})

const { setSelectedPayMethod, setPayMethodsAction } = payReducer.actions

export const selectPayMethod = (method: number) => (dispatch: Dispatch) =>
  dispatch(setSelectedPayMethod(method))

export const setPayMethods = (methods: Array<PaymentMethod>) => (dispatch: Dispatch) =>
  dispatch(setPayMethodsAction(methods))

export default payReducer.reducer
