import React from 'react'

import s from './FaqCategories.module.scss'

type Props = {
  children?: React.ReactNode
}

const FaqCategories = ({ children }: Props): React.ReactElement => {
  return <div className={s.categories}>{children}</div>
}

export default FaqCategories
