import React from 'react'
import cn from 'classnames'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import NavTabs from 'application/presentation/common/NavTabs'
import NavTab from 'application/presentation/common/NavTabs/NavTab'
import { useSwipeable } from 'react-swipeable'
import { Title } from 'react-head'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import s from './UserDonates.module.scss'
import UserDonatesIn from './UserDonatesIn'
import UserDonatesOut from './UserDonatesOut'

const UserDonates = (): React.ReactElement => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isIn = !pathname.startsWith('/donates/out')
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (isIn) {
        navigate('/donates/out')
      }
    },
    onSwipedRight: () => {
      if (!isIn) {
        navigate('/donates/in')
      }
    },
  })

  const sectionTitle = isIn ? t('donates_page.in') : t('donates_page.out')

  let body = !isIn ? <UserDonatesIn /> : <UserDonatesOut />

  return (
    <div className={cn({ [s.container]: true, [s.viewMobile]: true })}>
      <Title>
        {t('donates_page.my_donates')} – {sectionTitle}
      </Title>
      <HeaderMobileTitle.Source>{t('donates_page.my_donates')}</HeaderMobileTitle.Source>
      <NavTabs>
        <NavTab to="/donates/in" replace>
          {t('donates_page.out')}
        </NavTab>
        <NavTab to="/donates/out" replace>
          {t('donates_page.in')}
        </NavTab>
      </NavTabs>
      <div {...swipeHandlers}>{body}</div>
    </div>
  )
}

export default React.memo(UserDonates)
