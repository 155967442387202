import React, { useCallback, useEffect, useRef, useState } from 'react'
import VerificationInput from 'react-verification-input'
import { useDispatch } from 'react-redux'
import apiClient from 'application/data/apiClient'
import { setUser } from 'application/domain/store/userReducer'

import s from './InputCode.module.scss'

type Props = {
  phone: string
  onComplete?: Function
  authAfterDispatch?: boolean
  setToken?: any
  changeStatus?: any
  phone_confirm_last_sms?: any
}

const CELL_COUNT = 4

const InputCode = ({
  phone,
  onComplete,
  authAfterDispatch,
  setToken,
  changeStatus,
  phone_confirm_last_sms,
}: Props) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState<string>('')
  const [timeStarted, setTimeStarted] = useState<boolean>(false)
  const [smsStatus, setSmsStatus] = useState<string>('')
  const [seconds, setSeconds] = useState<number>(60)
  const codeInput = useRef(null)

  const smsTimer = useCallback(() => {
    const curdate: any = new Date()
    const local: any = localStorage.getItem('last_sms_date')
    const lastdate: any = new Date(local)
    if ((curdate - lastdate) / 1000 < 60) {
      const nowsec = Math.round(60 - (curdate - lastdate) / 1000)
      setSeconds(nowsec)
      setTimeStarted(true)
      setTimeout(smsTimer, 1000)
    } else {
      setTimeStarted(false)
    }
  }, [])

  const sendAgain = useCallback(() => {
    apiClient({
      url: '/api/v1/user/confirm_phone',
      method: 'post',
      data: {
        phone: `+${phone.replace(/[\s-+]/g, '')}`,
      },
    })
      .then(() => {
        localStorage.setItem('last_sms_date', new Date().toString())
        smsTimer()
      })
      .catch((error) => {
        console.log(error.response)
      })
  }, [phone, smsTimer])

  useEffect(() => {
    smsTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const checkSmsFunc = useCallback(
    (val: string) => {
      setSmsStatus('')
      if (val && phone && val.length === CELL_COUNT) {
        apiClient({
          url: '/api/v1/user/check_sms',
          method: 'POST',
          data: {
            code: parseInt(val.replace(/[\s-+]/g, ''), 10),
            phone: `+${phone.replace(/[\s-+]/g, '')}`,
          },
        })
          .then(async (response: any) => {
            setSmsStatus('correct')
            if (!authAfterDispatch) {
              setUser(response.data.success)(dispatch)
            } else {
              setUser(response.data.success)(dispatch)
            }
            if (onComplete) {
              onComplete(response.data.success)
            }
            if (setToken) {
              setToken(response.data.success.api_token)
            }
            if (changeStatus) {
              changeStatus('correct')
            }
          })
          .catch((error) => {
            setSmsStatus('wrong')
            if (changeStatus) {
              changeStatus('wrong')
            }
          })
      }
    },
    [authAfterDispatch, changeStatus, dispatch, onComplete, phone, setToken],
  )

  useEffect(() => {
    if (value && phone && value.length === CELL_COUNT) {
      checkSmsFunc(value)
    }
  }, [value, phone, checkSmsFunc])

  return (
    <div className={`${s.sms_code_block} ${s.without_padding}`}>
      <div className={s.title}>Введите код</div>

      <VerificationInput
        ref={codeInput}
        length={CELL_COUNT}
        // removeDefaultStyles
        classNames={{
          container: `${s.sms_code}`,
          character: `${s.number_input} ${
            smsStatus === 'correct' ? s.correct : smsStatus === 'wrong' ? s.wrong : ''
          }`,
          characterInactive: 'character--inactive',
          characterSelected: `${s.selected}`,
        }}
        placeholder=""
        inputProps={{
          id: 'single-factor-code-text-field',
          autocomplete: 'one-time-code',
        }}
        validChars="0-9"
        onChange={(val: any) => {
          setValue(val)
          setSmsStatus('')
        }}
      />
      <div
        className={`${s.sms_message} ${
          smsStatus === 'correct' || smsStatus === 'wrong' ? s.show : ''
        }`}
      >
        {smsStatus === 'correct' ? '' : 'Вы ввели неверный код'}
      </div>
      <div className={s.code_timer}>
        <div
          onClick={sendAgain}
          className={`${s.send_again_password} ${timeStarted ? '' : s.show}`}
        >
          Отправить код ещё раз
        </div>
        <div className={`${s.timer_block} ${timeStarted ? s.show : ''}`}>
          Отправить повторно через
          <span className={s.sms_timer} id="sms_timer">
            {seconds}
          </span>
          сек
        </div>
      </div>
    </div>
  )
}

export default React.memo(InputCode)
