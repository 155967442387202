import React, { useMemo } from 'react'
import moment from 'moment'
import Loader from 'application/presentation/common/Loader'
import { useAppSelector } from 'application/domain/store/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { useUser } from 'application/domain/useCase/user/getUser'
import { User } from 'application/domain/entity/user/User'

import s from './UserFriendsCalendar.module.scss'
import FriendItem from './components/FriendItem'

const UserFriendsBday = () => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const params = useParams<{ nickname?: string }>()

  const navigate = useNavigate()

  const isMyProfile = useMemo(() => {
    return !!(!params || !params.nickname || (params && user && params.nickname === user.nickname))
  }, [params, user])

  const { data, isLoading } = useUser(params ? params.nickname : '')

  const friendsData = useMemo(() => {
    let arr: any = []
    if (data && data.friends && data.friends.quantity) {
      let today = moment()
      let monthsList = moment.months()
      let nowMonth = moment().month()
      let tomorrow = moment().add(1, 'days')

      arr = [
        { title: 'Сегодня', data: [] },
        { title: 'Завтра', data: [] },
      ]
      for (let i = 0; i < 12; i++) {
        arr.push({
          id: nowMonth,
          title: monthsList[nowMonth].charAt(0).toUpperCase() + monthsList[nowMonth].slice(1),
          data: [],
        })
        if (nowMonth < 11) {
          nowMonth++
        } else {
          nowMonth = 0
        }
      }
      data.friends.items.map((item: User) => {
        if (item.birthdate) {
          let isToday =
            moment(item.birthdate).month() === moment(today).month() &&
            moment(item.birthdate).date() === moment(today).date()
          let isTomorrow =
            moment(item.birthdate).month() === moment(tomorrow).month() &&
            moment(item.birthdate).date() === moment(tomorrow).date()
          if (isToday) {
            arr[0].data.push(item)
          } else if (isTomorrow) {
            arr[1].data.push(item)
          } else {
            arr.map((month: any) => {
              if (month.id === moment(item.birthdate).month()) {
                month.data.push(item)
              }
            })
          }
        }
      })
    }
    arr = arr.map((item: any) => {
      return {
        ...item,
        data: item.data.sort((a: any, b: any) => {
          if (moment(a.birthdate).date() > moment(b.birthdate).date()) {
            return 1
          } else {
            return -1
          }
        }),
      }
    })
    return arr
  }, [data])

  const BdayFriendsCount = useMemo(() => {
    return friendsData[0].data.length + friendsData[1].data.length
  }, [friendsData])

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {BdayFriendsCount > 1 && user ? (
        <>
          <div className={s.bday_container}>
            <div className={s.bday_info}>
              <div className={s.text}>Дни рождения</div>
              <div
                className={s.all}
                onClick={() => navigate(`/${params.nickname}/friends/calendar`)}
              >
                Все
              </div>
            </div>
            {friendsData.map((item: any) => {
              if (item.title === 'Сегодня') {
                return (
                  <>
                    {item.data && item.data.length ? (
                      <div className={s.title}>{item.title} празднуют день рождения</div>
                    ) : (
                      <></>
                    )}
                    {item.data.map((friend: User, i: number) => {
                      return (
                        <React.Fragment key={`friend_bday_${friend.nickname}_${i}`}>
                          <FriendItem item={friend} isMyProfile={isMyProfile} bday />
                        </React.Fragment>
                      )
                    })}
                  </>
                )
              }
              if (item.title === 'Завтра') {
                return (
                  <>
                    {item.data && item.data.length ? (
                      <div className={s.title}>{item.title} празднуют день рождения</div>
                    ) : (
                      <></>
                    )}
                    {item.data.map((friend: User, i: number) => {
                      return (
                        <React.Fragment key={`friend_bday_${friend.nickname}_${i}`}>
                          <FriendItem item={friend} isMyProfile={isMyProfile} bday />
                        </React.Fragment>
                      )
                    })}
                  </>
                )
              }
            })}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default React.memo(UserFriendsBday)
