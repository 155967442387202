const parseNumber = (price: any) => {
  return price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : ''
}

// Удаляет из строки все символы, кроме цифр
export const getNumber = (val: string) => {
  return val ? val.replace(/\D/g, '') : ''
}

export default parseNumber
