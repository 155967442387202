/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import apiClient from 'application/data/apiClient'
import { PaymentDataProps, PaymentProps } from 'application/domain/entity/payment/Payment'

// import ToastNotify from '../ToastNotify'

const bankPay = ({ data, onStart, onSuccess, onError }: PaymentProps) => {
  const { auth_user, phone } = data
  if (auth_user === null && !phone) {
    // ToastNotify('Вы не авторизованы!', 'error')
    if (onError) {
      onError()
    }
    return false
  }
  if (onStart) onStart()

  // apiClient
  //   .get('/webapi/get_cloud')
  //   .then((response) => {
  //     console.log(response.data)

  //     const checkout = new cp.Checkout(
  //       // public id из личного кабинета
  //       response.data,
  //       // тег, содержащий поля данных карты
  //       document.getElementById('paymentForm'),
  //     )

  //     createCryptogram(checkout, data, onSuccess, onError)
  //   })
  //   .catch((error) => {})
  createCryptogram(data, onSuccess, onError)
}
const createCryptogram = (
  // checkout: any,
  payData: PaymentDataProps,
  onSuccess: Function,
  onError: Function,
) => {
  // const result = checkout.createCryptogramPacket()
  const { type, amount, wish_id, comment, to_balance, phone, auth_user } = payData
  // if (result.success) {
  const sendData = {
    // crypto: result.packet,
    amount,
    comment,
    type: 'cards-sberbank',
    to_balance: !!to_balance,
    page_view: 'MOBILE',
    return_url: `${window.location.origin}${window.location.pathname}`,
  }
  if (!auth_user) {
    // @ts-ignore
    sendData.phone = phone
  }
  if (type === 'order') {
    // @ts-ignore
    sendData.order = wish_id
  } else {
    // @ts-ignore
    sendData.wish = wish_id
  }
  apiClient({
    method: 'POST',
    url: '/api/v1/payment/sber3ds',
    data: sendData,
  })
    .then((response) => {
      if (response.data.formUrl) {
        sessionStorage.removeItem('appLocationHistory')
        window.location.href = response.data.formUrl
      } else {
        onError('error')
      }
      // if (response.data.success) {
      //   onSuccess('success', response.data.success.id)
      // } else if (response.data.Model) {
      //   if (response.data.Model.ReasonCode) {
      //     onError('error', response.data.Model.CardHolderMessage)
      //   } else {
      //     onSuccess('3ds', response.data)
      //   }
      // } else onError('error')
    })
    .catch((error) => {
      console.log(error.data)
      onError('error')
    })
  // apiClient({
  //   method: 'POST',
  //   url: '/api/v1/payment',
  //   data: {
  //     crypto: result.packet,
  //     amount,
  //     order: wish_id,
  //     comment,
  //     type: 'cloud',
  //   },
  // })
  //   .then((response) => {
  //     console.log(response.data)
  //     if (response.data.success) {
  //       console.log('success')
  //       if (callback) callback('paySuccess', response.data.success.id)
  //     } else if (response.data.Model) {
  //       // $('.ajax-loader').hide()
  //       if (response.data.Model.ReasonCode) {
  //         console.log(response.data.Model)
  //         if (callback) callback('error', response.data.Model.CardHolderMessage)
  //       } else {
  //         console.log('пробуем 3ds')
  //         if (callback) callback('3dsStage', 3, response.data)
  //         //   if (callback) callback()
  //       }
  //     } else if (callback) callback('error')
  //   })
  //   .catch((error) => {
  //     console.log(error.data)
  //     if (callback) callback('error')
  //   })
  // } else {
  //   // найдены ошибки в введённых данных, объект `result.messages` формата:
  //   // { name: "В имени держателя карты слишком много символов", cardNumber: "Неправильный номер карты" }
  //   // где `name`, `cardNumber` соответствуют значениям атрибутов `<input ... data-cp="cardNumber">`
  //   // $('#CardHolderMessage').html('')
  //   console.log(result)
  //   let msg
  //   for (const msgName in result.messages) {
  //     msg = result.messages[msgName]
  //     break
  //   }
  //   onError('cardError', msg)
  // }
}

export default bankPay
