import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Sheet, PageContent } from 'framework7-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'application/presentation/common/ActionComponents'
import { InputCheckbox, InputLabel } from 'application/presentation/common/InputComponents'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { useTranslation } from 'react-i18next'
import Loader from 'application/presentation/common/Loader'
import _ from 'lodash'
import { useAppSelector } from 'application/domain/store/hooks'
import { useUser } from 'application/domain/useCase/user/getUser'
import { EditWishCategMutation } from 'application/domain/useCase/wish_category/editWishCategory'
import { AddCategoryMutation } from 'application/domain/useCase/wish_category/addWishCategory'

import stylebs from '../MW.module.scss'

import sbs from './EditCategMW.module.scss'

const EditCategMW = () => {
  const { t } = useTranslation()
  const { search, hash } = useLocation()
  const navigate = useNavigate()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const { data: profileData, isLoading } = useUser(user ? user.nickname : '')
  const [name, setName] = useState<string>('')
  const [isHidden, setIsHidden] = useState<boolean>(false)

  const closeMW = useCallback(() => navigate(-1), [navigate])

  const editMutation = EditWishCategMutation(user?.nickname, () => {
    ToastNotify('Категория успешно отредактирована!')
    closeMW()
  })

  const addMutation = AddCategoryMutation(() => {
    ToastNotify('Категория добавлена!')
    closeMW()
  })

  const categoryId = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const id = searchParams.get('category_id')
    return id ? parseInt(id, 10) : 0
  }, [search])

  const opened = useMemo(() => {
    return categoryId && (hash.indexOf('add') !== -1 || hash.indexOf('edit') !== -1) ? true : false
  }, [hash, categoryId])

  const type: string = useMemo(() => {
    return hash.replace('#', '')
  }, [hash])

  const wishlist = useMemo(() => {
    return profileData && profileData.wish ? profileData.wish.items : []
  }, [profileData])

  const category = useMemo(() => {
    return categoryId && wishlist && wishlist.length
      ? _.find(wishlist, { id: categoryId })
      : undefined
  }, [wishlist, categoryId])

  useEffect(() => {
    if (opened) {
      setName(category && category.name ? category.name : '')
      setIsHidden(
        category && category.permission && category.permission.type === 'deny' ? true : false,
      )
    }
  }, [category, opened])

  const AddOrEditCateg = useCallback(() => {
    if (type === 'edit') {
      editMutation.mutate({
        id: categoryId,
        name: name,
        permission: {
          type: isHidden ? 'deny' : 'allow',
          users: null,
        },
      })
    } else {
      addMutation.mutate({
        name,
        permission: {
          type: isHidden ? 'deny' : 'allow',
          users: null,
        },
      })
    }
  }, [categoryId, name, isHidden, editMutation, type, addMutation])

  const closeBS = useCallback(() => {
    if (hash.indexOf('#add') !== -1 || hash.indexOf('#edit') !== -1) {
      navigate(-1)
    }
  }, [hash, navigate])

  return (
    <Sheet
      className={`${stylebs.sheet_modal} ${stylebs.auto_height}`}
      swipeToClose
      backdrop
      opened={opened}
      onSheetClosed={closeBS}
    >
      <div className={stylebs.top_close_button}>
        <div />
      </div>
      <PageContent>
        <div className={sbs.inner_content}>
          <div className={sbs.title}>
            {type === 'edit' ? 'Редактирование категории' : 'Добавление категории'}
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className={sbs.edit_content}>
              <InputLabel title="Название" value={name} onChange={setName} type="text" />
              <InputCheckbox title="Скрыть категорию" value={isHidden} onChange={setIsHidden} />
              <Button
                title={type === 'edit' ? t('general.save_text') || '' : t('general.add_text') || ''}
                color="red"
                className={sbs.btn}
                containerClassName={sbs.btn_container}
                onClick={AddOrEditCateg}
              />
            </div>
          )}
        </div>
      </PageContent>
    </Sheet>
  )
}
export default React.memo(EditCategMW)
