import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { VelocityTransitionGroup } from 'velocity-react'
import { useAppSelector } from 'application/domain/store/hooks'
import { selectPayMethod } from 'application/domain/store/payReducer'

import s from './PaySelector.module.scss'

type Props = {
  containerClassName?: string
  withoutTitle?: boolean
  listClassName?: string
  itemClassName?: string
  withoutBalance?: boolean
}

const getItemData = (item_id: number) => {
  let img = ''
  let txt = ''
  switch (item_id) {
    case 1:
      img = 'mc-visa'
      txt = 'С карты'
      break
    case 2:
      img = 'apple-gray'
      break
    case 3:
      img = 'Gpay'
      break
    case 4:
      txt = 'С баланса аккаунта'
      img = 'circle_logo'
      break
    case 5:
      img = 'Tinkoff'
      break
    default:
      break
  }
  return {
    img,
    txt,
  }
}

const PaySelector = ({
  containerClassName,
  withoutTitle,
  listClassName,
  itemClassName,
  withoutBalance,
}: Props) => {
  const dispatch = useDispatch()
  const { selectedPayMethod, payMethods } = useAppSelector(({ payReducer }) => ({
    selectedPayMethod: payReducer.selectedPayMethod,
    payMethods: payReducer.payMethods,
  }))
  const [selectOpen, setSelectOpen] = useState<boolean>(false)

  if (!payMethods) return <></>

  return (
    <div className={cn(s.container, containerClassName || '')}>
      {!withoutTitle && <div className={s.title}>Метод оплаты</div>}

      <div className={s.select_container}>
        {payMethods.map((item: any) => {
          const { img } = getItemData(item.id)

          if (withoutBalance && item.id === 4) {
            return <React.Fragment key={item.id} />
          }

          if (item.id !== 5 && item.id === selectedPayMethod) {
            return (
              <div
                key={item.id}
                className={cn(s.select_item_active, selectOpen ? s.open : '', itemClassName || '')}
                onClick={() => {
                  selectPayMethod(item.id)(dispatch)
                  setSelectOpen(!selectOpen)
                }}
              >
                <div className={s.select_img}>
                  <img src={`/images/icons/${img}.svg`} alt="" />
                </div>
                <div className={s.arrow}>
                  <img src="/images/icons/back-icon.svg" alt="" />
                </div>
              </div>
            )
          }
          return <React.Fragment key={item.id} />
        })}
        <VelocityTransitionGroup
          enter={{ animation: 'slideDown' }}
          leave={{ animation: 'slideUp' }}
          duration={1500}
          // animation={{ opacity: selectOpen ? 1 : 0 }}
          className={s.animation}
        >
          {selectOpen && (
            <div className={s.select_modal}>
              {payMethods.map((item: any) => {
                const { img } = getItemData(item.id)

                if (withoutBalance && item.id === 4) {
                  return <React.Fragment key={item.id} />
                }

                return item.id !== 5 && item.id !== selectedPayMethod ? (
                  <div
                    key={item.id}
                    className={cn(
                      s.select_item,
                      s.select_item_min,
                      item.id === selectedPayMethod ? s.active : '',
                      itemClassName || '',
                    )}
                    onClick={() => {
                      selectPayMethod(item.id)(dispatch)
                      setSelectOpen(!selectOpen)
                    }}
                  >
                    <div className={s.select_img}>
                      <img src={`/images/icons/${img}.svg`} alt="" />
                    </div>
                  </div>
                ) : (
                  <React.Fragment key={item.id} />
                )
              })}
            </div>
          )}
        </VelocityTransitionGroup>
      </div>
    </div>
  )
}

export default React.memo(PaySelector)
