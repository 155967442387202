import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import WishItem from 'application/presentation/common/WishlistContent/components/WishItem'

import s from './IdeasList.module.scss'

type Props = {
  name: string
  nickname: string
  ideasData: any
}

const IdeasList = ({ name, nickname, ideasData }: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const data = useMemo(() => {
    return ideasData && ideasData.items ? ideasData.items : []
  }, [ideasData])
  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <div className={s.title}>
          Идеи для сюрприза
          <br />
          <span>Это невидимый список для</span> {name} <span>с идеями, что можно подарить</span>
        </div>
        <div className={s.list}>
          {data && data.length ? (
            data.map((item: any) => {
              return item.wish
                ? item.wish.map((item2: any) => {
                    return item2 && item2.idea && item2.status === 'opened' ? (
                      <WishItem data={item2} key={`idea${item2.id}`} isIdea />
                    ) : (
                      <React.Fragment key={item2} />
                    )
                  })
                : null
            })
          ) : (
            <div className={s.empty_ideas}>Идей нет</div>
          )}
          {ideasData.quantity < 1 ? <div className={s.empty_ideas}>Идей нет</div> : <></>}
        </div>
        <button
          className={s.search}
          onClick={() => navigate(`${location.pathname}?idea_friend=${nickname}#search`)}
        >
          <div className={s.icon}>
            <img src="/images/icons/plus.svg" alt="" />
          </div>
          <div className={s.btn_text}>Предложить свою идею</div>
        </button>
      </div>
    </div>
  )
}

export default React.memo(IdeasList)
