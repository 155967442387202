/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo } from 'react'
import { Sheet, PageContent } from 'framework7-react'
import i18next from 'i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import Loader from 'application/presentation/common/Loader'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { useAppSelector } from 'application/domain/store/hooks'
import { DeleteWishMutation } from 'application/domain/useCase/wish/deleteWish'
import useWish from 'application/domain/useCase/wish/getWish'

import stylebs from '../MW.module.scss'

import s from './DeleteWishMW.module.scss'

type Props = {
  isWishList?: boolean
}

const DeleteWishMW = ({ isWishList }: Props) => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const { search, hash } = useLocation()
  const navigate = useNavigate()

  const closeMW = useCallback(
    () => (isWishList ? navigate(`/${user?.nickname}`) : navigate(-1)),
    [navigate, isWishList, user?.nickname],
  )
  const deleteMutation = DeleteWishMutation(() => {
    ToastNotify(i18next.t('general.wish_deleted_text'))
    closeMW()
  })

  const wishId = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const id = searchParams.get('wish_id')
    return id ? parseInt(id, 10) : 0
  }, [search])

  const opened = useMemo(() => {
    return wishId && hash.indexOf('delete_wish') !== -1 ? true : false
  }, [wishId, hash])

  const { data: wishData, isLoading: wishIsLoading } = useWish(wishId)

  const data = useMemo(() => {
    if (wishId && wishData) {
      return wishData
    }
    return null
  }, [wishId, wishData])

  const deleteWish = useCallback(
    () =>
      deleteMutation.mutate({
        wish_id: wishId,
        nickname: user ? user.nickname : '',
        isIdea: false,
      }),
    [wishId, user, deleteMutation],
  )

  const wish_status = useMemo(() => {
    return data && data.status ? data.status : ''
  }, [data])

  return (
    <Sheet
      className={`${stylebs.sheet_modal} ${stylebs.auto_height}`}
      swipeToClose
      backdrop
      opened={opened}
      onSheetClosed={closeMW}
    >
      <div className={stylebs.top_close_button}>
        <div />
      </div>
      <PageContent>
        <div className={s.inner_content}>
          {wishIsLoading ? (
            <Loader />
          ) : (
            <div className={s.delete_content}>
              <div className={s.delete_img}>
                <img loading="lazy" src="/images/icons/trash-red-icon.svg" alt="" />
              </div>
              {wish_status === 'order' ||
              wish_status === 'transferred' ||
              wish_status === 'delivered' ? (
                <>
                  <div className={s.title}>{i18next.t('delete_wish_mw.cant_delete_title')}</div>
                  <div className={s.subtitle}>
                    <span>{i18next.t('delete_wish_mw.wish_ordered')}</span>
                  </div>
                  <div className={s.select_buttons_block}>
                    <div className={s.select_now}>
                      <button type="button" className={s.ok_deleteWish} onClick={closeMW}>
                        {i18next.t('delete_wish_mw.back_to_my_wish')}
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={s.title}>{i18next.t('delete_wish_mw.title')}</div>
                  <div className={s.subtitle}>
                    В случае удаления желания, оно пропадет из вашего вишлиста. Вся собранная сумма
                    будет возвращена на ваш баланс
                  </div>
                  <div className={s.select_buttons_block}>
                    <div className={s.select_now}>
                      <button type="button" className={s.ok_deleteWish} onClick={deleteWish}>
                        {i18next.t('general.agree_text')}
                      </button>
                    </div>
                    <div className={s.select_later}>
                      <button className={s.ok_close_button} type="button" onClick={closeMW}>
                        {i18next.t('general.cancel_text')}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </PageContent>
    </Sheet>
  )
}

export default React.memo(DeleteWishMW)
