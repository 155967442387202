import React, { useMemo, useCallback, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { createTeleporter } from 'react-teleporter'
import { useAppSelector } from 'application/domain/store/hooks'

import s from './Header.module.scss'
import MainHeader from './components/MainHeader'

export const HeaderMobileTitle = createTeleporter()
export const HeaderMobileLeftButton = createTeleporter()
export const HeaderMobileRightButton = createTeleporter()

type Props = {
  forceShow?: boolean
  onBackClick?: Function
  headerBgColor?: boolean
}

const Header = ({ forceShow, onBackClick, headerBgColor }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))

  const backClickHandler = useCallback(() => {
    navigate(`/${user ? user.nickname : ''}`)
  }, [navigate, user])

  const isMyProfile = useMemo(() => {
    const nickname = location.pathname.replace('/', '')
    return nickname && user && nickname === user.nickname ? true : false
  }, [location.pathname, user])

  if (!forceShow) {
    if (location.pathname.indexOf('shop') !== -1 || location.pathname.indexOf('login') !== -1) {
      return <></>
    }
    if (location.pathname === '/' || location.pathname === '/home') {
      return <MainHeader />
    }
  }

  const isMenu = location.pathname.indexOf('menu') !== -1

  return (
    <section
      className={s.header_section}
      style={{ backgroundColor: headerBgColor ? '#f7f7f7' : '#FFFFFF' }}
    >
      <div className={s.header_container}>
        <div className={`${s.topmenu_button} ${s.marketcategories_button}`}>
          {!isMenu && !isMyProfile && (
            <div
              onClick={() => {
                if (onBackClick) {
                  onBackClick()
                } else {
                  backClickHandler()
                }
              }}
            >
              <img loading="lazy" src="/images/icons/back-icon.svg" alt="" />
            </div>
          )}
          <HeaderMobileTitle.Target
            as="div"
            className={`${s.topmenu_title} ${isMyProfile ? s.my_profile : {}}`}
          />
          <HeaderMobileTitle.Source>
            <></>
          </HeaderMobileTitle.Source>
        </div>
        <HeaderMobileRightButton.Target as="div" className={s.topmenu_links} />
        <HeaderMobileRightButton.Source>
          <></>
        </HeaderMobileRightButton.Source>
      </div>
    </section>
  )
}

export default React.memo(Header)
