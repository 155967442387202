import React, { useContext, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import nprogress from 'nprogress'
import Loader from 'application/presentation/common/Loader'
import { HeaderFooterContext } from 'application/presentation/app/HeaderFooter'
import Header, {
  HeaderMobileRightButton,
  HeaderMobileTitle,
} from 'application/presentation/common/Header'
import {
  MetaCanonical,
  MetaDescriptionProduct,
  MetaOG,
  MetaTitleProduct,
} from 'application/presentation/app/MetaTags'
import { useTranslation } from 'react-i18next'
import { ShareButton } from 'application/presentation/common/ActionComponents'
import splitByTwo from 'application/presentation/utils/splitByTwo'
import { useProduct } from 'application/domain/useCase/product/getProduct'
import { useShopRandomProducts } from 'application/domain/useCase/shop/getShop'

import ProductContent from './ProductContent'

const ProductPage = () => {
  const { t } = useTranslation()
  const params = useParams<{ id?: string }>()
  const footerContext = useContext(HeaderFooterContext)
  const location = useLocation()
  const { data, isLoading } = useProduct(params && params.id ? parseInt(params.id, 10) : 0)
  const {
    data: randomData,
    isLoading: randomIsLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useShopRandomProducts()

  const isIdeaFriendNickname = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const idea = searchParams.get('idea_friend')
    return idea ? idea : false
  }, [location])

  const product = useMemo(() => (data && data![0] ? data![0] : null), [data])
  const category = useMemo(() => (data && data!.category ? data.category : null), [data])
  const random =
    randomData && randomData.pages ? splitByTwo(randomData.pages.flatMap((page: any) => page)) : []

  useEffect(() => {
    footerContext?.showFooter(false)
    return () => footerContext?.showFooter(true)
  }, [footerContext])

  if (isLoading) {
    nprogress.start()
    return <Loader size={32} />
  }
  nprogress.done()
  return (
    <div>
      <MetaTitleProduct>{product ? product.name : ''}</MetaTitleProduct>
      <MetaDescriptionProduct>{product ? product.name : ''}</MetaDescriptionProduct>
      <MetaCanonical>{`${window.location.origin}/shop/category/${category ? category.slug : ''}/${
        product ? product.id : ''
      }`}</MetaCanonical>
      <MetaOG
        title={`${product ? product.name : ''} ${t('product_page.meta.title')}`}
        description={`${product ? product.name : ''} ${t('product_page.meta.description')}`}
        // @ts-ignore
        image={product ? product.photo[0] : ''}
      />
      <Header forceShow />
      <HeaderMobileTitle.Source>
        <></>
      </HeaderMobileTitle.Source>
      <HeaderMobileRightButton.Source>
        <ShareButton shareType="product" productId={product ? product.id : 0} />
        {/* <div className={s.right_header}>
          <div className={s.more_img}>
            <img src="/images/icons/3points.svg" alt="" />
          </div>
          <div
            className={s.share_img}
            onClick={() => {
              // dispatch({
              //   type: 'SET_SHAREWISHBS_STATUS',
              //   opened: true,
              //   wishData: prodData,
              // })
            }}
          >
            <img src="/images/icons/share-icon-app.svg" alt="" />
          </div>
        </div> */}
      </HeaderMobileRightButton.Source>
      {product && category ? (
        <ProductContent
          product={product}
          category={category}
          random={random}
          randomState={{
            isLoading: randomIsLoading,
            fetchNextPage,
            isFetchingNextPage,
          }}
          isIdeaFriendNickname={isIdeaFriendNickname}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(ProductPage)
