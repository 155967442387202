import React from 'react'
import cn from 'classnames'

import s from './ErrorMsg.module.scss'

type Props = {
  text: string
  containerClassName?: any
  className?: any
}

const ErrorMsg = ({ text, containerClassName, className }: Props) => {
  return (
    <div className={containerClassName || ''}>
      <div className={cn(s.msg, className || '')}>{text}</div>
    </div>
  )
}

export default React.memo(ErrorMsg)
