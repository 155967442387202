import React, { useCallback, useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import Loader from 'application/presentation/common/Loader'
import 'react-phone-input-2/lib/style.css'
import apiClient from 'application/data/apiClient'

import s from './InputPhone.module.scss'

type Props = {
  onChange: Function
  setIsRegistered?: Function
  setPhoneComplited?: Function
  setPhoneCheckIsLoading?: Function
  className?: any
  onChangePhone?: Function
  iconUrl?: string
  iconContainerClass?: string
  iconClass?: string
  defaultValue?: string
  title?: string
}

const InputPhone = (props: Props) => {
  const { className, iconUrl, iconClass, iconContainerClass, onChange, title, defaultValue } = props
  const [phone, setPhone] = useState<string>('')
  const [isRegistered, setIsRegistered] = useState<boolean>(false)
  const [phoneComplited, setPhoneComplited] = useState<boolean>(false)
  const [checkIsLoading, setCheckIsLoading] = useState<boolean>(false)

  useEffect(() => {
    if (defaultValue) {
      setPhone(defaultValue)
    }
  }, [defaultValue])

  const checkPhone = useCallback(
    (val: any) => {
      if (val && val.length >= 11) {
        setCheckIsLoading(true)
        setPhoneComplited(true)
        if (props.setPhoneCheckIsLoading) props.setPhoneCheckIsLoading(true)
        if (props.setPhoneComplited) props.setPhoneComplited(true)

        apiClient({
          url: `/api/v1/user/registered?phone=%2B${val}`,
          method: 'get',
        })
          .then((response) => {
            setCheckIsLoading(false)
            if (props.setPhoneCheckIsLoading) props.setPhoneCheckIsLoading(false)
            if (response.data.registered === true) {
              setIsRegistered(true)
              if (props.setIsRegistered) props.setIsRegistered(true)
              // if (this.props.isReg) this.props.isReg(true, true)
            } else {
              setIsRegistered(false)
              if (props.setIsRegistered) props.setIsRegistered(false)
              // if (this.props.isReg) this.props.isReg(false, true)
            }
          })
          .catch((error) => {
            setCheckIsLoading(false)
            setIsRegistered(false)
            if (props.setPhoneCheckIsLoading) props.setPhoneCheckIsLoading(false)
            // if (this.props.isReg) this.props.isReg(false, false)

            console.log(error)
          })
      } else {
        setPhoneComplited(false)
        if (props.setPhoneComplited) props.setPhoneComplited(false)
        // this.setState(() => ({
        //   isLoading: false,
        //   isReg: false,
        // }))
        // if (this.props.isReg) this.props.isReg(true, false)
      }
    },
    [props],
  )

  useEffect(() => {
    checkPhone(phone)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone])

  return (
    <div
      className={`${s.input_block} ${s.with_sel} ${iconUrl ? s.with_icon : ''} ${className || ''}`}
    >
      <div className={s.little_text}>{title || 'Введите номер телефона'}</div>

      {iconUrl && (
        <div className={`${s.icon} ${iconContainerClass || ''}`}>
          <img src={iconUrl} alt="" className={iconClass || ''} />
        </div>
      )}

      <PhoneInput
        country="ru"
        placeholder="Введите телефон"
        onlyCountries={['ru', 'ua', 'by', 'de']}
        value={phone}
        countryCodeEditable={false}
        containerClass={s.phoneInput}
        buttonClass={s.flag_dropdown}
        onChange={(phoneVal: string) => {
          setPhone(phoneVal)
          if (onChange) onChange(phoneVal)
          // this.setState({ phone })
          // this.checkPhone(phone)
          // this.props.onChangePhone ? this.props.onChangePhone(phone) : null
        }}
      />
      {phoneComplited ? (
        checkIsLoading ? (
          <div className={s.loader_mini}>
            <Loader size={10} color="#727272" />
          </div>
        ) : isRegistered ? (
          <img
            loading="lazy"
            className={`${s.accept_icon} ${s.show}`}
            src="/images/icons/more-gray-accept.svg"
            alt=""
          />
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(InputPhone)
