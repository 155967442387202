import { User } from 'application/domain/entity/user/User'
import getUserCity from 'application/presentation/utils/getUserCity'
import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'

import s from '../Menu.module.scss'

type Props = {
  notificationsCount: number
  user: User
}

const ProfileOther = ({ notificationsCount, user }: Props) => {
  const city = useMemo(() => {
    return getUserCity(user)
  }, [user])
  return (
    <div className={s.nav}>
      <NavLink to={`/${user.nickname}/settings`} className={s.nav_item}>
        <div className={s.img_block}>
          <img src="/images/icons/settings-icon.svg" alt="" />
        </div>
        <div className={s.text}>Настройки аккаунта</div>
        <div className={s.icon}>
          <img src="/images/icons/pencil-gray-icon.svg" alt="" />
        </div>
      </NavLink>
      <NavLink to="/notification" className={s.nav_item}>
        <div className={s.img_block}>
          <img src="/images/icons/alert_icon_red.svg" alt="" />
        </div>
        <div className={s.text}>Мои уведомления</div>
        {notificationsCount !== 0 && (
          <div className={s.count}>
            <span>{notificationsCount}</span>
          </div>
        )}
      </NavLink>
      <NavLink to="/donates" className={s.nav_item}>
        <div className={s.img_block}>
          <img src="/images/icons/rub_icon_red.svg" alt="" />
        </div>
        <div className={s.text}>Мои донаты</div>
      </NavLink>
      <NavLink to="/orders" className={s.nav_item}>
        <div className={s.img_block}>
          <img src="/images/icons/market_icon_red.svg" alt="" />
        </div>
        <div className={s.text}>Мои заказы</div>
      </NavLink>
      <NavLink to="/location" className={s.nav_item}>
        <div className={s.img_block}>
          <img src="/images/icons/geo-icon.svg" alt="" />
        </div>
        <div className={s.text}>{city}</div>
      </NavLink>
    </div>
  )
}

export default React.memo(ProfileOther)
