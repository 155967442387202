import apiClient from 'application/data/apiClient'
import { User } from 'application/domain/entity/user/User'
import { setUser, setUserToken } from 'application/domain/store/userReducer'
import { Dispatch } from 'redux'

const fetchSession = async (token?: string) => {
  const data = await apiClient
    .get(`/api/v1/sessions${token ? `?api_token=${token}` : ''}`)
    .then((response: any) => {
      if (response.data) {
        return response.data
      }
    })
    .catch((error: any) => {
      return error
    })
  return data
}

export const editUserData = async (session: User, dispatch: Dispatch) => {
  if (session) {
    const userData = JSON.stringify(session)
    const userToken = session.api_token
    localStorage.setItem('userToken', userToken)
    localStorage.setItem('userData', userData)
    apiClient.defaults.headers.Authorization = `Bearer ${userToken}`
    setUserToken(session.api_token)(dispatch)
    setUser(session)(dispatch)
  }
}

export const calculateNotifications = (notificationsData: any) => {
  let count = 0
  if (notificationsData) {
    const { friend, like, order, wish, comment } = notificationsData.new
    if (friend) {
      count += friend
    }
    if (like) {
      count += like
    }
    if (order) {
      count += order
    }
    if (wish) {
      count += wish
    }
    if (comment) {
      count += comment
    }
  }
  return count
}

export default fetchSession
