/* eslint-disable @typescript-eslint/naming-convention */
import React, { useMemo, useState, useCallback } from 'react'
import cn from 'classnames'
import PhotoSelectMW from 'application/presentation/common/ModalWindows/PhotoSelectMW/PhotoSelectMW'
import WishPhotoWishCommentMW from 'application/presentation/common/ModalWindows/WishPhotoWithCommentMW/WishPhotoWithCommentMW'
import _ from 'lodash'
import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import { useLocation, useNavigate } from 'react-router-dom'

import s from './WishOrderStatus.module.scss'

type Props = {
  wishData: any
}

const stagesCount = 5

const statusList = [
  { id: 1, title: 'Заказ ожидает оформления', info: '', status: 'preliminary_order', stage: 1 },
  {
    id: 2,
    title: 'Ожидаем оплату доставки заказа',
    info: '',
    status: 'payment_required',
    stage: 2,
  },
  { id: 3, title: 'Заказ создан и ожидает обработки', info: '', status: 'new', stage: 2 },
  { id: 4, title: 'Заказ принят к обработке', info: '', status: 'in_processing', stage: 2 },
  {
    id: 5,
    title: 'Товар заказан, выполняется доставка в город назначения',
    info: '',
    status: 'delivery',
    stage: 3,
  },
  { id: 6, title: 'В пункте cамовывоза', info: '', status: 'on_point_delivery', stage: 4 },
  { id: 7, title: 'Заказ доставлен и получен', info: '', status: 'processed', stage: 5 },
  {
    id: 8,
    title: 'Ожидает подтверждения начала заказа',
    info: '',
    status: 'confirmation',
    stage: 4,
  },
  {
    id: 9,
    title: 'Статус "Клиент готов к создания заявки" в агрегаторе на доставку',
    info: '',
    status: 'started_delivery',
    stage: 4,
  },
  {
    id: 10,
    title: 'Статус для создания заявки в агрегаторе на доставку (Яндекс)',
    info: '',
    status: 'in_delivery',
    stage: 4,
  },
  {
    id: 11,
    title: 'Ошибка в заказе',
    info: '',
    status: 'error',
    stage: 0,
  },
  {
    id: 12,
    title: 'Заказ отменен',
    info: '',
    status: 'cancelled',
    stage: 0,
  },
]

const WishOrderStatus = ({ wishData }: Props) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [photo, setPhoto] = useState<string>('')
  const [photoFile, setPhotoFile] = useState<any>('')
  const [opened, setOpened] = useState<boolean>(false)
  const [openedWishPhoto, setOpenedWishPhoto] = useState<boolean>(false)

  const status = useMemo(() => {
    if (wishData) {
      const { wish_order } = wishData
      return wish_order ? wish_order.status : 'preliminary_order'
    }
    return 'preliminary_order'
  }, [wishData])

  const statusStage = useMemo(() => {
    if (status) {
      const stat = _.find(statusList, { status: status })
      return stat ? stat.stage : 1
    }
    return 1
  }, [status])

  const currentState = useMemo(() => {
    if (status) {
      return _.find(statusList, { status: status })
    }
    return _.find(statusList, { status: 'preliminary_order' })
  }, [status])

  const cancelOrder = useCallback(() => {
    apiClient.post(`/api/v1/order/${wishData.wish_order.id}/cancel`)
    queryClient.invalidateQueries(['wish'])
  }, [wishData])

  return (
    <div className={s.status_order}>
      <div className={s.status_order_top}>
        <div className={s.left}>
          {/* <div className={s.status_number}>Статус заказа №:</div> */}
          <div className={s.status_order_text_bold}>{currentState ? currentState.title : ''}</div>
        </div>
        <div className={s.right}>
          {statusStage !== 0 && (
            <div className={s.status_progress_num}>
              {statusStage}/{stagesCount}
            </div>
          )}
        </div>
      </div>
      {statusStage !== 0 ? (
        <div className={s.status_order_progress}>
          <span className={cn(s.progress_item, statusStage >= 1 ? s.progress_active : {})} />
          <span className={cn(s.progress_item, statusStage >= 2 ? s.progress_active : {})} />
          <span className={cn(s.progress_item, statusStage >= 3 ? s.progress_active : {})} />
          <span className={cn(s.progress_item, statusStage >= 4 ? s.progress_active : {})} />
          <span className={cn(s.progress_item, statusStage >= 5 ? s.progress_active : {})} />
        </div>
      ) : null}
      {currentState && currentState.info ? (
        <div className={s.status_order_text}>{currentState.info}</div>
      ) : null}
      {statusStage === 0 ? (
        <a
          target="_blank"
          href="https://telegram.me/wishmen_official"
          className={s.completed_btn}
          onClick={() => {
            // history.push(`${history.location.pathname}/order`)
          }}
          rel="noreferrer"
        >
          <span className={s.img_completed}></span>
          <span className={s.text_completed}>Написать чат</span>
        </a>
      ) : null}
      {statusStage === 1 ? (
        <button
          type="button"
          className={s.completed_btn}
          onClick={() => {
            navigate(`${pathname}/order`)
          }}
        >
          <span className={s.img_completed}></span>
          <span className={s.text_completed}>Получить желание</span>
        </button>
      ) : (
        <></>
      )}
      {statusStage === 2 ? (
        <button type="button" className={s.completed_btn} onClick={() => cancelOrder()}>
          <span className={s.img_completed}></span>
          <span className={s.text_completed}>
            {status === 'payment_required' ? 'Оплатить доставку' : 'Отменить заказ'}
          </span>
        </button>
      ) : null}
      {statusStage === 5 ? (
        <button
          type="button"
          className={s.completed_btn}
          onClick={() => {
            setOpened(true)
          }}
        >
          <span className={s.img_delivered}>
            <img src="/images/icons/camera-icon-white.svg" alt="" />
          </span>
          <span className={s.text_completed}>Добавить фото</span>
        </button>
      ) : null}
      <PhotoSelectMW
        setPhoto={setPhoto}
        setPhotoFile={setPhotoFile}
        opened={opened}
        setOpened={setOpened}
        setOpenedWishPhoto={setOpenedWishPhoto}
      />
      <WishPhotoWishCommentMW
        photo={photoFile}
        wishId={wishData.id}
        setUploadedPhoto={photo}
        openedWishPhoto={openedWishPhoto}
        setOpenedWishPhoto={setOpenedWishPhoto}
        setOpened={setOpened}
      />
    </div>
  )
}

export default React.memo(WishOrderStatus)
