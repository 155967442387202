/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo } from 'react'
import { Sheet, PageContent } from 'framework7-react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { useAppSelector } from 'application/domain/store/hooks'
import { HideWishMutation } from 'application/domain/useCase/wish/editWish'

import style from '../MW.module.scss'

import s from './WishMoreMW.module.scss'

const WishMoreMW = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const opened = location.hash.indexOf('#more_mw') !== -1
  const { user, mwData } = useAppSelector(({ userReducer, modalReducer }) => {
    return {
      user: userReducer.user,
      mwData: modalReducer.wishMoreMW.data,
    }
  })
  const { wish_id, is_public } = useMemo(
    () => (mwData ? mwData : { wish_id: 0, is_public: false }),
    [mwData],
  )
  const hideWishMut = HideWishMutation(() => {
    ToastNotify(`Желание ${is_public ? 'скрыто' : 'отображено'}`)
  })

  const closeBS = useCallback(() => {
    if (location.hash.indexOf('#more_mw') !== -1) {
      window.history.back()
    }
  }, [location])

  // Модалка редактирования
  const openEditBS = useCallback(() => {
    closeBS()
    setTimeout(() => {
      navigate(`${location.pathname}/${wish_id}/edit`)
    }, 500)
  }, [closeBS, navigate, location.pathname, wish_id])

  // Скрыть желание
  const hideWish = useCallback(() => {
    closeBS()
    hideWishMut.mutate({
      wish_id: wish_id,
      is_public: is_public,
    })
  }, [wish_id, is_public, hideWishMut, closeBS])

  // Модалка удаления
  const openDeleteBS = useCallback(() => {
    closeBS()
    setTimeout(() => {
      navigate(`${location.pathname}?wish_id=${wish_id}#delete_wish`)
    }, 500)
  }, [closeBS, location.pathname, navigate, wish_id])

  return (
    <Sheet
      className={`${style.sheet_modal} ${style.auto_height}`}
      swipeToClose
      backdrop
      opened={opened}
      onSheetClosed={closeBS}
    >
      <div className={style.top_close_button} onClick={closeBS}>
        <div />
      </div>
      <PageContent className={s.wishBS_page_content}>
        <div id="inner-content-settings" className={s.inner_content}>
          <div className={s.settings_content}>
            <NavLink
              to={`/${user ? user!.nickname : ''}${
                location.pathname.indexOf(`/wishlist`) !== -1 ? `/wishlist` : ``
              }/${wish_id}/send`}
              className={s.item}
              onClick={closeBS}
            >
              <div className={s.title}>Отправить подарок другу</div>
              <img loading="lazy" src="/images/icons/plane.svg" alt="" />
            </NavLink>
            <div className={s.item} onClick={openEditBS}>
              <div className={s.title}>Редактировать</div>
              <img loading="lazy" src="/images/icons/pencil-black.svg" alt="" />
            </div>
            <div className={s.item} onClick={hideWish}>
              <div className={s.title}>{is_public ? 'Скрыть' : 'Показать'}</div>
              <img loading="lazy" src="/images/icons/hide-icon.svg" alt="" />
            </div>
            <div className={s.item} onClick={openDeleteBS}>
              <div className={`${s.title} ${s.trash}`}>Удалить</div>
              <img loading="lazy" src="/images/icons/trash.svg" alt="" />
            </div>
          </div>
        </div>
      </PageContent>
    </Sheet>
  )
}

export default React.memo(WishMoreMW)
