import apiClient from 'application/data/apiClient'

type CreateOrderProps = {
  wish_id: number
  onStart?: Function
  onError?: Function
  onSuccess?: Function
}

type ConfirmOrderProps = {
  order_id: number
  delivery_id: number
  recipient: string
  phone: string
  comment: string
  onStart?: Function
  onSuccess?: Function
  onError?: Function
}

type GetDeliveryDataProps = {
  deliveryMethod: {
    address: string
    aggregator: string
    id: number
    type: string
  }
  wish_id: number
  delivery_id: number
  deliveryAdress: string
  onStart?: Function
  onSuccess?: Function
  onError?: Function
}

export const createOrder = ({ wish_id, onStart, onError, onSuccess }: CreateOrderProps) => {
  if (onStart) {
    onStart()
  }
  apiClient
    .post(`/api/v1/order`, {
      wish_id,
    })
    .then(({ data }: any) => {
      console.log(data)
      if (onSuccess) {
        onSuccess(data)
      }
    })
    .catch((error: any) => {
      if (onError) {
        onError(error.response.data)
      }
    })
}

export const confirmOrder = ({
  order_id,
  delivery_id,
  recipient,
  phone,
  comment,
  onStart,
  onSuccess,
  onError,
}: ConfirmOrderProps) => {
  if (onStart) {
    onStart()
  }
  apiClient
    .post(`/api/v1/order/${order_id}/confirm`, {
      recipient,
      phone,
      delivery_id,
      comment,
      user_comment: comment,
    })
    .then(({ data }: any) => {
      console.log(data)
      if (onSuccess) {
        onSuccess(data)
      }
    })
    .catch(({ response }: any) => {
      if (onError) {
        onError(response.data)
      }
    })
}

export const getDeliveryData = ({
  deliveryMethod,
  deliveryAdress,
  delivery_id,
  onStart,
  onSuccess,
  onError,
  wish_id,
}: GetDeliveryDataProps) => {
  if (onStart) {
    onStart()
  }
  if ((deliveryMethod && deliveryMethod.type === 'pickup') || deliveryAdress) {
    apiClient({
      url: '/api/v1/order/delivery',
      method: 'POST',
      data: {
        outcome_address: deliveryMethod.type === 'pickup' ? deliveryMethod.address : deliveryAdress,
        delivery_method_id: delivery_id,
        wish_id: wish_id,
      },
    })
      .then(({ data }: any) => {
        if (onSuccess) {
          onSuccess(data)
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
  if (!deliveryAdress) {
    if (onError) {
      onError()
    }
  }
}
