import { User } from 'application/domain/entity/user/User'
import { Button } from 'application/presentation/common/ActionComponents'
import React, { useState } from 'react'

import s from './UserIdeasPage.module.scss'

type Props = {
  user: User | undefined
}

const TopBlock = ({ user }: Props) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  return (
    <div className={s.idea_container}>
      <div className={s.idea_container_txt}>
        В этом разделе идеи сюрпризов для пользователя видят его друзья
        {showMore ? (
          <span>
            , <b>{user ? (user.name ? user.name : user.nickname ? user.nickname : '') : ''}</b> не
            видит эти идеи. Вы так же можете предложить свой вариант и{' '}
            <span className={s.red_txt}>получить бонус</span> от Wishmen
          </span>
        ) : (
          '.'
        )}
      </div>
      {showMore && (
        <Button
          title="Добавить идею сюрприза"
          color="red"
          iconUrl="/images/icons/thin_plus_icon.svg"
          containerClassName={s.btn_container}
          className={s.btn}
          iconSize={{ width: '1.8rem', height: '1.8rem' }}
        />
      )}
      {/* {showMore && (
        <BtnComponent
          text="Добавить идею сюрприза"
          color="red"
          svgIcon={plusSvg}
          containerclassName={s.red_btn_container}
          iconContainerclassName={s.red_btn_icon}
          svgSize={{ width: 18, height: 18 }}
          className={s.red_btn}
          onPress={() =>
            navigation.push('search_page', { user_nickname: user.nickname, type: 'idea' })
          }
        />
      )} */}
      {!showMore && (
        <div className={s.idea_container_red_txt} onClick={() => setShowMore(true)}>
          Подробнее
        </div>
      )}
    </div>
  )
}

export default React.memo(TopBlock)
