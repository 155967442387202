import React from 'react'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import { useTranslation } from 'react-i18next'
import Loader from 'application/presentation/common/Loader'
import { useAppSelector } from 'application/domain/store/hooks'
import { useUser } from 'application/domain/useCase/user/getUser'

import UserDonates from './UserDonates'

const UserDonatesPage = (): React.ReactElement => {
  const { t } = useTranslation()
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user!,
  }))
  const profileQuery = useUser(user ? user.nickname : undefined)

  if (profileQuery.isLoading) {
    return (
      <>
        <HeaderMobileTitle.Source>{t('donates_page.my_donates')}</HeaderMobileTitle.Source>
        <Loader />
      </>
    )
  }

  return <UserDonates />
}

export default React.memo(UserDonatesPage)
