import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import cn from 'classnames'

import s from './NavTab.module.scss'

type Props = NavLinkProps & {
  children: React.ReactNode
}

const NavTab = ({ children, ...rest }: Props): React.ReactElement => {
  return (
    <NavLink
      className={cn({ [s.tab]: true, [s.viewMobile]: true })}
      // activeClassName={s.tab_active}
      {...rest}
    >
      {children}
    </NavLink>
  )
}

export default React.memo(NavTab)
