import apiClient from '../apiClient'

export const fetchOrder = async (id: number) => {
  const { data } = await apiClient.get(`/api/v1/order/${id}`)
  return data
}

export const fetchOrdersList = async () => {
  const { data } = await apiClient.get('/api/v1/order')
  return data
}
