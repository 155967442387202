import { fetchFriendsRequest } from 'application/data/api/friends'
import { FriendsRequestsResponse } from 'application/domain/response/response_types'
import { useQuery } from '@tanstack/react-query'

export const useFriendsRequest = (token: string) => {
  const query = useQuery<FriendsRequestsResponse>(['friendsRequest', token], fetchFriendsRequest, {
    retry: false,
    enabled: token ? true : false,
    refetchInterval: 10000,
  })

  return query
}
