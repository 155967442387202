import React from 'react'
import cn from 'classnames'

import s from './NavTabs.module.scss'

type Props = {
  children: React.ReactNode
}

const NavTabs = ({ children }: Props): React.ReactElement => {
  return <div className={cn({ [s.tabs]: true, [s.viewMobile]: true })}>{children}</div>
}

export default React.memo(NavTabs)
