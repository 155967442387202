import React, { useState } from 'react'

import s from './SecondBlock.module.scss'

const SecondBlock = () => {
  const [show, setShow] = useState(false)
  return (
    <div className={s.container}>
      <div className={s.background}>
        <div className={s.inner_content}>
          <div className={s.left}>
            <div className={s.title}>Что такое Wishmen?</div>
            <div className={`${s.subtitle} ${show ? s.show : ''}`}>
              Сервис, в котором вы можете собрать все свои желания с любимых маркетплейсов, добавить
              услуги лучших салонов. Создайте единый вишлист (список желаний) в одном приложении. С
              помощью Wishmen вы легко сможете поделиться своим вишлистом с друзьями, чтобы они
              помогли вашим мечтам исполниться, или постепенно исполнять свои желания
              самостоятельно.
            </div>
            <div className={s.link} onClick={() => setShow(!show)}>
              {show ? 'Скрыть' : 'Подробнее'}
            </div>
          </div>
          <div className={s.right}>
            <img src="/images/mainPage/second-main2.png" loading="lazy" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(SecondBlock)
