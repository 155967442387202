import React from 'react'
import { Meta, Link } from 'react-head'

type Props = {
  children?: string
  nickname?: string
  male?: string
}

export const MetaDescription = ({ children }: Props): React.ReactElement => {
  return <Meta name="description" content={children} />
}

export const MetaDescriptionProduct = ({ children }: Props): React.ReactElement => {
  return (
    <Meta
      name="description"
      content={`${children} - хочешь в подарок? Просто добавь это в свой вишлист на Wishmen, мы поможем тебе исполнить мечту.`}
    />
  )
}

export const MetaDescriptionCategory = ({ children }: Props): React.ReactElement => {
  return (
    <Meta
      name="description"
      content={`${children} в подарок! Создай свой список желаний на Wishmen и мы поможем получить всё бесплатно.`}
    />
  )
}

export const MetaDescriptionUser = ({ children, male }: Props): React.ReactElement => {
  return (
    <Meta
      name="description"
      content={`Узнай что хочет ${children} и помоги ${
        male === 'female' ? 'ему' : 'ей'
      } приобрести желаемое. Вишлист подарков на сайте Wishmen.`}
    />
  )
}

export const MetaDescriptionFriends = ({ children, nickname }: Props): React.ReactElement => {
  return (
    <>
      <Meta
        name="description"
        content={`${children} и список его друзей на сайте Wishmen — здесь исполняют желания.`}
      />
      <Link rel="canonical" href={`${window.location.origin}/${nickname}`} />
    </>
  )
}

export const MetaDescriptionMain = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Пришло время исполнять ваши желания. Must Have приложение на День рождения"
    />
  )
}

export const MetaDescriptionCatalog = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Каталог всех товаров и услуг, которые вы можете получить в подарок. Выбирай, что хочешь и добавляй это в свой вишлист на сайте Wishmen."
    />
  )
}

export const MetaDescriptionCollections = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Готовые подоборки и идеи для подарков на все случаи жизни. Не знаешь что подарить близкому человеку? Тогда читай наши советы."
    />
  )
}

export const MetaDescriptionFeed = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Узнай, что хотят твои друзья и помоги им приобрести желаемое. Подарки друзьям на сайте Wishmen."
    />
  )
}

export const MetaDescriptionNotifications = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Информация о новых поступлениях и выполненных заказах. Просто добавь товары в свой список желаний на Wishmen."
    />
  )
}

export const MetaDescriptionSettings = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Расскажите пользователям Wishmen о себе и своих желаниях, мы поможем вам их получить бесплатно"
    />
  )
}

export const MetaDescriptionOrder = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Узнайте подробности о вашем заказе и задавайте вопросы поддержке Wishmen"
    />
  )
}

export const MetaDescriptionPartnersInfo = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Узнайте ближе партнеров сервиса Wishmen, которые помогают нам осуществить ваши мечты."
    />
  )
}

export const MetaDescriptionSearch = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Поиск по каталогу всех товаров и услуг, которые вы можете получить в подарок. Выбирай, что хочешь и добавляй это в свой вишлист на сайте Wishmen."
    />
  )
}

export const MetaDescriptionGuaranty = (): React.ReactElement => {
  return <Meta name="description" content="Условия возврата товаров сервиса Wishmen" />
}

export const MetaDescriptionContacts = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Wishmen всегда открыты к контактам, рады вашим предложениям и вопросам."
    />
  )
}

export const MetaDescriptionFaq = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Перейдите в раздел Вопросы и ответы на карточке нужного товара. Проверьте, нет ли такого же вопроса от другого пользователя."
    />
  )
}

export const MetaDescriptionBalance = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Узнайте общую сумму баланса и узнайте куда ее можно потратить"
    />
  )
}

export const MetaDescriptionReferral = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Приглашайте друзей по реферальной ссылке и получайте бонусы!"
    />
  )
}

export const MetaDescriptionSanta = (): React.ReactElement => {
  return (
    <Meta
      name="description"
      content="Стань Тайным Сантой и сам без подарка не останешься! Вступи в игру и получи гарантированный новогодний подарок от незнакомца всего за 2 шага!"
    />
  )
}
