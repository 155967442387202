import apiClient from 'application/data/apiClient'
import React from 'react'
import { useDispatch } from 'react-redux'
import queryClient from 'application/data/apiClient/queryClient'
import { setUser, setUserToken } from 'application/domain/store/userReducer'

type AuthContextType = {
  login: Function
  logout: Function
}

export const AuthContext = React.createContext<AuthContextType>({
  login: () => {},
  logout: () => {},
})

export const Authorization = ({ children }: any) => {
  const dispatch = useDispatch()

  const auth = React.useMemo(
    () => ({
      login: async (data: any, beforeLoad: Function, callback: Function) => {
        if (beforeLoad) {
          beforeLoad()
        }
        let authDat
        if (data.password) {
          authDat = {
            phone: data.phone,
            password: data.password,
          }
        } else {
          authDat = {
            phone: data.phone,
          }
        }
        apiClient({
          url: '/api/v1/sessions',
          method: 'POST',
          data: authDat,
        })
          .then(async (response: any) => {
            if (response.data) {
              if (callback) {
                callback(response.data)
              }
            }
          })
          .catch((error: any) => {
            if (error && callback) {
              callback(error.response.data)
            }
          })
      },
      logout: async (callback?: Function) => {
        queryClient.cancelQueries(['session'])
        apiClient
          .delete('/api/v1/sessions')
          .then(() => {})
          .catch((error) => {})
        setUser(null)(dispatch)
        setUserToken(null)(dispatch)
        apiClient.defaults.headers.Authorization = ''
        localStorage.removeItem('userToken')
        localStorage.removeItem('userData')
        if (callback) {
          callback()
        }
      },
    }),
    [dispatch],
  )

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}
