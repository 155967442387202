// words = []
// принимает 3 слова в таком порядке: друг, друга, друзей; идея, идеи, идей и т.д.
const wordDeclination = (number: number, words: Array<string>) => {
  return words[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ]
}
export default wordDeclination
