import React from 'react'
import Skeleton from 'react-loading-skeleton'

import s from '../Menu.module.scss'

const LoaderPage = () => {
  return (
    <>
      <div className={s.nav}>
        <Skeleton
          style={{
            display: 'inline-block',
            height: '30px',
            width: '100%',
            marginBottom: '22px',
          }}
        />
        <Skeleton
          style={{
            display: 'inline-block',
            height: '30px',
            width: '100%',
            marginBottom: '22px',
          }}
        />
        <Skeleton
          style={{
            display: 'inline-block',
            height: '30px',
            width: '100%',
            marginBottom: '22px',
          }}
        />
        <Skeleton
          style={{
            display: 'inline-block',
            height: '30px',
            width: '100%',
            marginBottom: '22px',
          }}
        />
        <Skeleton
          style={{
            display: 'inline-block',
            height: '30px',
            width: '100%',
            marginBottom: '22px',
          }}
        />
      </div>

      <div className={s.profile_main}>
        <Skeleton
          style={{
            display: 'inline-block',
            height: '103px',
            width: '100%',
            marginBottom: '14px',
          }}
        />
        <Skeleton
          style={{
            display: 'inline-block',
            height: '103px',
            width: '100%',
            marginBottom: '14px',
          }}
        />
      </div>

      <div className={s.profile_info}>
        <div className={s.info_container}>
          <Skeleton
            style={{
              display: 'inline-block',
              height: '90px',
              width: '250px',
            }}
          />
          <Skeleton
            style={{
              display: 'inline-flex',
              height: '65px',
              width: '65px',
              borderRadius: '50%',
            }}
          />
        </div>
      </div>
    </>
  )
}

export default React.memo(LoaderPage)
