import apiClient from 'application/data/apiClient'
import axios from 'axios'

type PredictSearchType = {
  value: string
  onStart?: Function
  onSuccess?: Function
}
type FriendSearchType = {
  val: string
  user_nickname: string
  onStart: Function
  onSuccess: Function
  onError?: Function
}

type CitySearchType = {
  val: string
  onStart: Function
  onSuccess: Function
}

let ajaxRequest: any
const predictSearch = ({ value, onStart, onSuccess }: PredictSearchType) => {
  if (value === '') {
    return false
  }
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }
  ajaxRequest = axios.CancelToken.source()
  if (onStart) onStart()
  apiClient
    .get(`/api/v1/shop/predict?query=${value}`, {
      cancelToken: ajaxRequest.token,
    })
    .then((response) => {
      if (onSuccess) onSuccess(response.data)
    })
    .catch((error) => {
      console.log(error)
    })
}

export const friendSearch = ({
  val,
  user_nickname,
  onStart,
  onSuccess,
  onError,
}: FriendSearchType) => {
  if (val === '') {
    return false
  }
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }

  if (onStart) {
    onStart()
  }

  ajaxRequest = axios.CancelToken.source()
  apiClient({
    url: `/api/v1/${user_nickname}/friend?query=${val}`,
    method: 'get',
    cancelToken: ajaxRequest.token,
  })
    .then((response: any) => {
      if (onSuccess) {
        onSuccess(response.data)
      }
    })
    .catch((err: any) => {
      if (!axios.isCancel(err)) {
        if (onError) onError(err.response && err.response.data ? err.response.data : '')
      }
    })
}

export const citySearch = ({ val, onStart, onSuccess }: CitySearchType) => {
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }

  if (onStart) {
    onStart()
  }

  ajaxRequest = axios.CancelToken.source()
  apiClient({
    url: `/api/v1/cities${val ? `?title=${val}` : ''}`,
    method: 'get',
    cancelToken: ajaxRequest.token,
  })
    .then((response: any) => {
      if (onSuccess) {
        onSuccess(response.data)
      }
    })
    .catch((err: any) => {
      // if (axios.isCancel(err)) {
      // } else {
      // }
    })
}

export const shopSearch = ({ value, onStart, onSuccess }: PredictSearchType) => {
  if (ajaxRequest) {
    ajaxRequest.cancel()
  }

  if (onStart) {
    onStart()
  }

  ajaxRequest = axios.CancelToken.source()
  apiClient({
    url: `/api/v1/shop/search?query=${value ? value : ''}`,
    method: 'get',
    cancelToken: ajaxRequest.token,
  })
    .then((response: any) => {
      if (onSuccess) {
        onSuccess(response.data)
      }
    })
    .catch((err: any) => {
      // if (axios.isCancel(err)) {
      // } else {
      // }
    })
}

export default predictSearch
