/* eslint-disable @typescript-eslint/naming-convention */
import apiClient from 'application/data/apiClient'
import { SearchField } from 'application/presentation/common/ActionComponents'
import ProductItem from 'application/presentation/common/ProductItem'
import React, { useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import nprogress from 'nprogress'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from 'application/presentation/common/Loader'
import {
  MetaDescriptionProduct,
  MetaOG,
  MetaTitleProduct,
} from 'application/presentation/app/MetaTags'
import { useTranslation } from 'react-i18next'
import { useShopCategory, useShopSubCategory } from 'application/domain/useCase/shop/getShop'
import parseNumber from 'application/presentation/utils/parseNumber'

import s from './ShopCategoryPage.module.scss'
import SubCategories from './SubCategories'

const fetchCategory = async (pageParam: string) => {
  const { data } = await apiClient.get(pageParam)
  return data
}

const fetchSubCategory = async (id: string) => {
  const { data } = await apiClient.get(`/api/v1/shop/sub_category/${id}`)
  return data
}

const ShopCategoryPage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const params = useParams<{ slug?: string; partner_id?: string }>()

  const [isHorizontal, setIsHorizontal] = useState<boolean>(false)
  const categ_slug = useMemo(() => {
    return params && params.slug ? params.slug : ''
  }, [params])

  const partner_id = useMemo(() => {
    return params && params.partner_id ? params.partner_id : 0
  }, [params])

  const urlParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location])

  const requestString = useMemo(() => {
    const order = urlParams.get('order')
    const desc = urlParams.get('desc')
    const price = urlParams.get('price')
    return order || desc || price
      ? `?${order ? `order=${order}&` : ''}${desc ? `desc=${desc}&` : ''}${
          price ? `price=${price}` : ''
        }`
      : ''
  }, [urlParams])

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching, refetch } =
    useShopCategory({
      partner_id: partner_id ? parseInt(partner_id, 10) : undefined,
      categ_slug,
      requestParams: requestString,
    })

  const {
    data: subCategData,
    isLoading: subCategIsLoading,
    isFetching: subCategIsFetching,
    refetch: subCategRefetch,
  } = useShopSubCategory(categ_slug)

  const categTitleData = useMemo(() => {
    const catName = data && data.pages && data.pages[0] ? data.pages[0].name : ''
    const catCount = data && data.pages && data.pages[0] ? data.pages[0].products.total : 0
    return {
      catName,
      catCount,
    }
  }, [data])

  const categData = useMemo(() => {
    const dat = data && data.pages ? data.pages.flatMap((page: any) => page.products.data) : []
    const arr = []
    if (dat && dat.length) {
      for (let i = 0; i < dat.length; i += 2) {
        if (dat[i] && dat[i + 1]) {
          arr.push({
            0: dat[i],
            1: dat[i + 1],
          })
        } else if (dat[i]) {
          arr.push({ 0: dat[i] })
        }
      }
    }
    return arr
  }, [data])

  if (isLoading || subCategIsLoading) {
    nprogress.start()
    return (
      <>
        <SearchField containerClassName={s.search_container} withBackBtn withFilter />
      </>
    )
  }
  nprogress.done()

  return (
    <div>
      <MetaTitleProduct>{categTitleData.catName}</MetaTitleProduct>
      <MetaDescriptionProduct>{categTitleData.catName}</MetaDescriptionProduct>
      <MetaOG
        title={`${categTitleData.catName} ${t('shop_category_page.meta.title')}`}
        description={`${categTitleData.catName} ${t('shop_category_page.meta.description')}`}
      />
      <SearchField
        containerClassName={s.search_container}
        withBackBtn
        withFilter
        openSearchOnClick
      />
      <div className={s.title_container}>
        <div>
          <div className={s.header_black}>{categTitleData.catName}</div>
          <div className={s.count}>{parseNumber(categTitleData.catCount)} товаров</div>
        </div>
      </div>
      {subCategData && subCategData.child_category && subCategData.child_category.length ? (
        <SubCategories data={subCategData.child_category} />
      ) : (
        <></>
      )}
      <div className={s.all_categories_header_container}>
        <div className={s.header}>Товары категории</div>
        <div
          className={`${s.orientation_icon} ${isHorizontal ? s.horiz : ''}`}
          onClick={() => setIsHorizontal(!isHorizontal)}
        >
          <img
            src={
              isHorizontal
                ? '/images/icons/orientation_vert.svg'
                : '/images/icons/orientation_horiz.svg'
            }
            alt=""
          />
        </div>
      </div>
      <InfiniteScroll
        className={s.prod_list}
        dataLength={categData.length}
        next={() => fetchNextPage()}
        style={{ overflow: 'visible' }}
        hasMore={!!hasNextPage}
        loader={
          <div className="pagination_loader">
            <Loader size={16} />
          </div>
        }
        scrollableTarget="page_content"
      >
        {categData.map((item: any, i: number) =>
          isHorizontal ? (
            <>
              <div className={`${s.row} ${s.horizontal}`} key={`item_${i}`}>
                {item[0] ? <ProductItem item={item[0]} adaptive isHorizontal /> : <></>}
              </div>
              <div className={`${s.row} ${s.horizontal}`}>
                {item[1] ? <ProductItem item={item[1]} adaptive isLastItem isHorizontal /> : <></>}
              </div>
            </>
          ) : (
            <div className={s.row} key={`item_${i}`}>
              {item[0] ? <ProductItem item={item[0]} adaptive /> : <></>}
              {item[1] ? <ProductItem item={item[1]} adaptive isLastItem /> : <></>}
            </div>
          ),
        )}
      </InfiniteScroll>
    </div>
  )
}

export default React.memo(ShopCategoryPage)
