import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'

import { AuthUser } from '../entity/user/User'

type UserState = {
  user: AuthUser
  authData: {
    login: string
    password: string
  }
  token: string | null
  app_config: any
  refLink: string
  newNotificationsCount: number
  savedLink: string
}

const initialState: UserState = {
  user: null,
  token: null,
  authData: {
    login: '',
    password: '',
  },
  refLink: '',
  app_config: {},
  newNotificationsCount: 0,
  savedLink: '',
}

export const userReducer = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    setUserAction: (state, { payload: user }: PayloadAction<AuthUser>) => {
      state.user = user
    },
    setUserTokenAction: (state, { payload: token }: PayloadAction<string | null>) => {
      state.token = token
    },
    setUserAuthDataAction: (
      state,
      {
        payload: authData,
      }: PayloadAction<{
        login: string
        password: string
      }>,
    ) => {
      state.authData = authData
    },
    setRefLinkAction: (state, { payload: reflink }: PayloadAction<string>) => {
      state.refLink = reflink
    },
    setAppConfigAction: (state, { payload: app_config }: PayloadAction<any>) => {
      state.app_config = app_config
    },
    setNewNotificationsCountAction: (state, { payload: count }: PayloadAction<number>) => {
      state.newNotificationsCount = count
    },
    setSavedLinkAction: (state, { payload: link }: PayloadAction<string>) => {
      state.savedLink = link
    },
  },
})

const {
  setUserAction,
  setUserTokenAction,
  setAppConfigAction,
  setRefLinkAction,
  setNewNotificationsCountAction,
  setSavedLinkAction,
} = userReducer.actions

export const setUser = (user: AuthUser) => (dispatch: Dispatch) => dispatch(setUserAction(user))
export const setUserToken = (token: string | null) => (dispatch: Dispatch) =>
  dispatch(setUserTokenAction(token))

export const setAppConfig = (app_config: any) => (dispatch: Dispatch) =>
  dispatch(setAppConfigAction(app_config))

export const setRefLink = (reflink: string) => (dispatch: Dispatch) =>
  dispatch(setRefLinkAction(reflink))

export const setNewNotificationsCount = (count: number) => (dispatch: Dispatch) =>
  dispatch(setNewNotificationsCountAction(count))

export const setSavedLink = (link: string) => (dispatch: Dispatch) =>
  dispatch(setSavedLinkAction(link))

export default userReducer.reducer
