import React, { useContext, useMemo } from 'react'
import { Time } from 'application/presentation/common/uiComponents'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Balance } from 'application/domain/entity/balance/Balance'

import BalanceItemBS from './BalanceItemBS'
import s from './BalanceItem.module.scss'

type Props = {
  data: Balance
  t: any
}

const BalanceItem = (props: Props) => {
  const { data, t } = props
  const params = useParams<{ id?: string }>()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const bottomSheetOpened = typeof params.id === 'string' && parseInt(params.id, 10) === data.id

  const handleBottomSheetClose = () => {
    navigate(pathname.replace(/\/$/g, '').split('/').slice(0, -1).join('/'))
  }

  let title = ''
  switch (data.type) {
    case 'refund':
      title = `Поступление c ${data.sender.name}`
      break
    case 'down':
      title = t('balance_page.out')
      break
    case 'referral':
      title = `${t('balance_page.referral')} ${
        data.sender
          ? `${t('balance_page.for')} ${data.sender.name ? data.sender.name : data.sender.nickname}`
          : ''
      }`
      break
    default:
      title = t('balance_page.in')
      break
  }

  const wishName = useMemo((): string => {
    return data && data.sender && data.sender.name ? data?.sender?.name : ''
  }, [data])

  return (
    <>
      <NavLink to={`${pathname}/${data.id}`.replace(/[/]+/g, '/')} className={s.item}>
        <div className={s.left_half}>
          <img
            src={data.recipient.photo ? data.recipient.photo : '/images/icons/circle-logo.svg'}
            alt=""
          />
        </div>
        <div className={s.center}>
          <div className={s.title}>{title}</div>
          <div className={s.sum}>
            {data.type === 'down' ? `-` : `+`} {data.amount.toLocaleString('ru')}{' '}
            {t('general.chars.rub')}
          </div>
          <div className={s.type}>
            {data.type === 'referral'
              ? 'Кэшбек с доната'
              : data.type === 'refund'
              ? 'Пополнение баланса'
              : `${wishName}`}
          </div>
        </div>
        <div className={s.right_half}>
          <div className={s.time}>
            <Time time={data.created_at} />
          </div>
        </div>
      </NavLink>
      <BalanceItemBS
        opened={bottomSheetOpened}
        onClose={handleBottomSheetClose}
        data={data}
        t={t}
      />
    </>
  )
}

export default React.memo(BalanceItem)
