/* eslint-disable react/no-danger */
import React from 'react'
import cn from 'classnames'
import { ArticleLayout, ArticleLayoutContent } from 'application/presentation/common/ArticleLayout'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import ArticleContent from 'application/presentation/common/ArticleContent'
import { Title } from 'react-head'
import {
  MetaDescriptionContacts,
  MetaTitleContacts,
  MetaOG,
} from 'application/presentation/app/MetaTags'
import { useTranslation } from 'react-i18next'
import ContentLayout from 'application/presentation/common/ContentLayout'

import s from './ContactsPage.module.scss'

const ContactsPage = (): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <ContentLayout>
      <MetaTitleContacts />
      <MetaDescriptionContacts />
      <MetaOG
        title={t('contacts_page.meta.title') || ''}
        description={t('contacts_page.meta.description') || ''}
      />
      <ArticleLayout>
        <ArticleLayoutContent>
          <Title>Контакты</Title>
          <HeaderMobileTitle.Source>Контакты</HeaderMobileTitle.Source>
          <ArticleContent className={s.viewMobile}>
            <div
              className={s.subtitle}
              dangerouslySetInnerHTML={{ __html: t('contacts_page.text') }}
            />

            <div className={s['contacts-links']}>
              <div className={cn(s.title, s.red)}>{t('contacts_page.contacts_block.title')}</div>
              <div className={s['contacts-wrapper']}>
                <div className={s['item-container']}>
                  <a
                    href="mailto:support@wish.men"
                    onClick={() => {
                      window.location.href = 'mailto:support@wish.men'
                      return false
                    }}
                    className={`${s['contacts-item']}`}
                  >
                    <div className={s.left}>
                      <div className={s['img-block']}>
                        <svg
                          width="19"
                          height="14"
                          viewBox="0 0 19 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.2187 0H1.78126C0.799014 0 0 0.784995 0 1.75001V12.25C0 13.215 0.799014 14 1.78126 14H17.2187C18.201 14 19 13.215 19 12.25V1.75001C19 0.784995 18.201 0 17.2187 0ZM17.2187 1.16666C17.2994 1.16666 17.3762 1.1831 17.4463 1.21182L9.5 7.97811L1.55365 1.21182C1.62381 1.18314 1.70055 1.16666 1.78122 1.16666H17.2187ZM17.2187 12.8333H1.78126C1.45366 12.8333 1.1875 12.5718 1.1875 12.25V2.44441L9.11092 9.1909C9.22285 9.28602 9.36142 9.33332 9.5 9.33332C9.63858 9.33332 9.77715 9.28606 9.88908 9.1909L17.8125 2.44441V12.25C17.8125 12.5718 17.5463 12.8333 17.2187 12.8333Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className={s.right}>
                      <div className={s.title}>E-mail</div>
                      <div className={s.subtitle}>support@wish.men</div>
                    </div>
                  </a>
                </div>
                <div className={s['item-container']}>
                  <a
                    href="tel:88006000021"
                    onClick={() => {
                      window.location.href = 'tel:88006000021'
                      return false
                    }}
                    className={s['contacts-item']}
                  >
                    <div className={s.left}>
                      <div className={s['img-block']}>
                        <svg
                          width="17"
                          height="25"
                          viewBox="0 0 17 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.3613 25H2.64258C1.43101 25 0.445312 24.0143 0.445312 22.8027V2.19727C0.445312 0.985693 1.43101 0 2.64258 0H14.3613C15.5729 0 16.5586 0.985693 16.5586 2.19727V22.8027C16.5586 24.0143 15.5729 25 14.3613 25ZM2.64258 1.46484C2.23872 1.46484 1.91016 1.79341 1.91016 2.19727V22.8027C1.91016 23.2066 2.23872 23.5352 2.64258 23.5352H14.3613C14.7652 23.5352 15.0938 23.2066 15.0938 22.8027V2.19727C15.0938 1.79341 14.7652 1.46484 14.3613 1.46484H2.64258Z"
                            fill="#A4A4A4"
                          />
                          <path
                            d="M9.9668 4.39453H7.03711C6.63262 4.39453 6.30469 4.0666 6.30469 3.66211C6.30469 3.25762 6.63262 2.92969 7.03711 2.92969H9.9668C10.3713 2.92969 10.6992 3.25762 10.6992 3.66211C10.6992 4.0666 10.3713 4.39453 9.9668 4.39453Z"
                            fill="#A4A4A4"
                          />
                          <path
                            d="M8.49805 22.0703C8.90255 22.0703 9.23047 21.7424 9.23047 21.3379C9.23047 20.9334 8.90255 20.6055 8.49805 20.6055C8.09354 20.6055 7.76562 20.9334 7.76562 21.3379C7.76562 21.7424 8.09354 22.0703 8.49805 22.0703Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className={s.right}>
                      <div className={s.title}>{t('general.phone')}</div>
                      <div className={s.subtitle}>8 (800) 600-00-21</div>
                    </div>
                  </a>
                </div>
                <div className={s['item-container']}>
                  <a href="https://www.instagram.com/wishmen_ru/" className={s['contacts-item']}>
                    <div className={s.left}>
                      <div className={s['img-block']}>
                        <svg
                          width="22"
                          height="20"
                          viewBox="0 0 22 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.20226 19.6855H1.64341L2.74571 18.5832C3.34005 17.9889 3.7112 17.2158 3.81098 16.3772C2.26005 15.3594 1.12594 14.0204 0.518853 12.4847C-0.0878053 10.9502 -0.164041 9.26104 0.29841 7.59969C0.853283 5.60624 2.15472 3.78927 3.9629 2.48345C5.92781 1.0645 8.3739 0.314453 11.0368 0.314453C14.3913 0.314453 17.2188 1.27823 19.2134 3.10152C21.0104 4.74414 22 6.9598 22 9.34042C22 10.497 21.7645 11.6224 21.3001 12.6853C20.8195 13.7852 20.1157 14.7645 19.2083 15.5961C17.211 17.4265 14.3853 18.3941 11.0367 18.3941C9.79367 18.3941 8.49654 18.2282 7.33845 17.9236C6.24227 19.0481 4.75685 19.6855 3.20226 19.6855ZM11.0368 1.60586C5.6973 1.60586 2.42096 4.79016 1.54251 7.946C0.712391 10.9283 1.93608 13.7463 4.81591 15.4841L5.13769 15.6783L5.12779 16.054C5.10816 16.7976 4.93089 17.5138 4.61359 18.1612C5.38624 17.8998 6.08734 17.4258 6.63636 16.7772L6.90936 16.4547L7.31422 16.5758C8.45035 16.9156 9.77236 17.1027 11.0368 17.1027C17.682 17.1027 20.7086 13.079 20.7086 9.34042C20.7086 7.32686 19.8682 5.44967 18.3421 4.0547C16.5895 2.45267 14.0633 1.60586 11.0368 1.60586Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className={s.right}>
                      <div className={s.title}>{t('general.online_chat')}</div>
                      <div className={cn(s.subtitle, s['red-link'])}>
                        {t('general.open_online_chat')}
                      </div>
                    </div>
                  </a>
                </div>
                <div className={s['item-container']}>
                  <a href="https://goo.gl/maps/k7pypgSoa15E7wjs9" className={s['contacts-item']}>
                    <div className={s.left}>
                      <div className={s['img-block']}>
                        <svg
                          width="16"
                          height="23"
                          viewBox="0 0 16 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.80578 0C3.50169 0 0 3.50164 0 7.80573C0 9.53534 1.01829 12.1657 3.20463 16.0837C4.69655 18.7571 6.18159 21.0151 6.24424 21.1102L7.3176 22.7372C7.42582 22.9013 7.60918 23 7.80578 23C8.00238 23 8.18575 22.9013 8.29397 22.7372L9.36728 21.1103C9.42932 21.0163 10.9036 18.7777 12.4069 16.0837C14.5933 12.1659 15.6116 9.53552 15.6116 7.80573C15.6116 3.50164 12.1099 0 7.80578 0ZM11.3856 15.5137C9.90309 18.1701 8.45216 20.3735 8.391 20.4661L7.80578 21.3531L7.22075 20.4663C7.15927 20.373 5.6972 18.1498 4.22601 15.5137C2.19799 11.8794 1.16969 9.28605 1.16969 7.80573C1.16969 4.14661 4.14661 1.16969 7.80578 1.16969C11.465 1.16969 14.4419 4.14661 14.4419 7.80573C14.4419 9.28624 13.4136 11.8795 11.3856 15.5137Z"
                            fill="#A4A4A4"
                          />
                          <path
                            d="M7.80344 3.42871C5.41704 3.42871 3.47559 5.37021 3.47559 7.75657C3.47559 10.1429 5.41704 12.0844 7.80344 12.0844C10.1898 12.0844 12.1313 10.1429 12.1313 7.75657C12.1313 5.37021 10.1898 3.42871 7.80344 3.42871ZM7.80344 10.9147C6.06201 10.9147 4.64528 9.498 4.64528 7.75657C4.64528 6.01513 6.06201 4.5984 7.80344 4.5984C9.54492 4.5984 10.9616 6.01513 10.9616 7.75657C10.9616 9.498 9.54492 10.9147 7.80344 10.9147Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className={s.right}>
                      <div className={s.title}>{t('general.legal_adress')}</div>
                      <div className={s.subtitle}>{t('general.legal_adress_value')}</div>
                      <div className={cn(s.subtitle, s['red-link'])}>
                        {t('general.watch_on_map')}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div className={s.separator} />
              <div className={cn(s.title, s.red)}>{t('contacts_page.social_title')}</div>
              <div className={cn(s['contacts-wrapper'], s['social-links'])}>
                <div className={s['item-container']}>
                  <a href="https://vk.com/wishmen_ru" className={s['contacts-item']}>
                    <div className={s.left}>
                      <div className={cn(s['img-block'], s.vk)}>
                        <img src="/images/icons/vk-white-icon.svg" alt="VK.com" />
                      </div>
                    </div>
                    <div className={s.right}>
                      <div className={s.title}>{t('general.vk_group')}</div>
                      <div className={s.subtitle}>@wishmen_ru</div>
                    </div>
                  </a>
                </div>
                <div className={s['item-container']}>
                  <a href="https://www.instagram.com/wishmen_ru/" className={s['contacts-item']}>
                    <div className={s.left}>
                      <div className={cn(s['img-block'], s.inst)}>
                        <img src="/images/icons/inst-white-icon.svg" alt="Instagram" />
                      </div>
                    </div>
                    <div className={s.right}>
                      <div className={s.title}>{t('general.inst_account')}</div>
                      <div className={s.subtitle}>@wishmen_ru</div>
                    </div>
                  </a>
                </div>
                <div className={s['item-container']}>
                  <a href="https://www.facebook.com/wishmen.ru/" className={s['contacts-item']}>
                    <div className={s.left}>
                      <div className={cn(s['img-block'], s.fb)}>
                        <img src="/images/icons/fb-white-icon.svg" alt="Facebook" />
                      </div>
                    </div>
                    <div className={s.right}>
                      <div className={s.title}>{t('general.facebook_page')}</div>
                      <div className={s.subtitle}>@wishmen.ru</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </ArticleContent>
        </ArticleLayoutContent>
      </ArticleLayout>
    </ContentLayout>
  )
}

export default React.memo(ContactsPage)
