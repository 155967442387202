import React from 'react'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import { MetaDescriptionOrder, MetaTitleOrder } from 'application/presentation/app/MetaTags'
import nprogress from 'nprogress'
import { useTranslation } from 'react-i18next'
import { useOrdersList } from 'application/domain/useCase/order/getOrder'
import { useAppSelector } from 'application/domain/store/hooks'
import { useUser } from 'application/domain/useCase/user/getUser'

import UserOrders from './UserOrders'

const UserOrdersPage = (): React.ReactElement => {
  const { t } = useTranslation()
  const { user, token } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
    token: userReducer.token,
  }))
  const profileQuery = useUser(user ? user.nickname : undefined)
  const ordersQuery = useOrdersList()

  if (profileQuery.isLoading || ordersQuery.isLoading) {
    nprogress.start()
    return (
      <>
        <HeaderMobileTitle.Source>{t('orders_page.my_orders')}</HeaderMobileTitle.Source>
      </>
    )
  }

  const orders = Array.isArray(ordersQuery.data) ? ordersQuery.data : []

  if (!user) {
    return <></>
  }

  nprogress.done()
  return (
    <>
      <MetaTitleOrder />
      <MetaDescriptionOrder />
      <UserOrders orders={orders} t={t} />
    </>
  )
}

export default React.memo(UserOrdersPage)
