import Loader from 'application/presentation/common/Loader'
import React, { useCallback, useMemo } from 'react'
import Stories from 'application/presentation/common/InstaStories/Stories'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import { useShop } from 'application/domain/useCase/shop/getShop'
import { getArticle } from 'application/domain/useCase/article/getArticle'

import s from './ArticlePage.module.scss'
import PreviewStory from './components/PreviewStory'
import ProductStory from './components/ProductStory'

const CollectionsArticlePage = () => {
  const params = useParams<{ article_id?: string }>()
  const { data, isLoading } = useShop()
  const articles = useMemo(() => {
    return data ? data.articles : []
  }, [data])

  const articleId = useMemo(() => {
    return params && params.article_id ? params.article_id : ''
  }, [params])

  const initialIndex = useMemo(() => {
    return _.findIndex(articles, { slug: articleId })
  }, [articles, articleId])

  const parseData = useCallback((storyData: any) => {
    if (storyData) {
      let dat = []
      dat.push((props: any) => <PreviewStory data={storyData} isActive {...props} />)
      storyData.product.map((elem: any) => {
        dat.push((props: any) => <ProductStory data={elem} {...props} />)
      })
      return dat
    }
    return null
  }, [])

  return (
    <div className={s.page_content}>
      {isLoading ? (
        <Loader color="#fff" />
      ) : articles ? (
        <Stories
          fetchStory={getArticle}
          data={articles}
          templates={parseData}
          initialIndex={initialIndex}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default React.memo(CollectionsArticlePage)
