import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import { Button, SearchField } from 'application/presentation/common/ActionComponents'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import Loader from 'application/presentation/common/Loader'
import { IconSvg } from 'application/presentation/common/uiComponents'
import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'application/domain/store/hooks'
import getUserCity from 'application/presentation/utils/getUserCity'
import { citySearch } from 'application/presentation/utils/SearchFunc'
import { setUser } from 'application/domain/store/userReducer'

import s from './SelectLocationPage.module.scss'

const SelectLocationPage = () => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchVal, setSearchVal] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [citiesList, setCities] = useState<any>()
  const [saveIsLoading, setSaveIsLoading] = useState<boolean>(false)

  const city = useMemo(() => {
    if (user) {
      return getUserCity(user)
    }
    return ''
  }, [user])

  const [selected, setSelected] = useState<string>(city ? city : 'Чебоксары')

  const cityIsOnTop = useMemo(() => {
    if (!_.isEmpty(citiesList)) {
      let findedCity = _.find(citiesList, { title: city })
      return findedCity ? true : false
    }
    return false
  }, [citiesList, city])

  useEffect(() => {
    citySearch({
      val: searchVal,
      onStart: () => setIsLoading(true),
      onSuccess: ({ cities }: any) => {
        if (cities) {
          setCities(cities)
          setIsLoading(false)
        }
      },
    })
  }, [searchVal])

  const cityData = useMemo(() => {
    if (!searchVal && !cityIsOnTop && !_.isEmpty(citiesList)) {
      let arr = _.concat([{ title: city }], citiesList)
      return arr
    }
    return citiesList
  }, [citiesList, cityIsOnTop, city, searchVal])

  const selectLocation = useCallback(() => {
    setSaveIsLoading(true)
    apiClient({
      url: '/api/v1/user/geolocation',
      method: 'POST',
      data: {
        selected: selected,
      },
    })
      .then((response: any) => {
        queryClient.invalidateQueries(['session']).then(() => {
          //@ts-ignore
          setUser({
            ...user,
            geolocation: response.data.success.geolocation,
          })(dispatch)
          setSaveIsLoading(false)
          ToastNotify('Город изменен!')
          navigate(-1)
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }, [selected, user, dispatch, navigate])

  return (
    <div>
      <HeaderMobileTitle.Source>Выбор города</HeaderMobileTitle.Source>
      <SearchField
        value={searchVal}
        onChange={setSearchVal}
        placeholder={'Введите название'}
        withoutSearchBtn
        withIconBefore
      />
      {isLoading ? (
        <Loader />
      ) : cityData ? (
        <div className={s.container}>
          {cityData.map((item: any) => {
            return (
              <div
                className={cn({ [s.item]: true, [s.selected]: selected === item.title })}
                onClick={() => setSelected(item.title)}
                key={`city_${item.title}`}
              >
                {item.title}
                {selected === item.title ? (
                  <IconSvg src="/images/icons/red_accept.svg" className={s.icon_container} />
                ) : (
                  <></>
                )}
              </div>
            )
          })}
        </div>
      ) : (
        <></>
      )}
      <Button
        title="Применить"
        onClick={() => selectLocation()}
        color="red"
        containerClassName={s.btn_container}
        isLoading={saveIsLoading}
      />
    </div>
  )
}

export default React.memo(SelectLocationPage)
