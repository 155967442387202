/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { NavLink } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

import s from '../Menu.module.scss'

type Props = {
  balance: any
  referal: any
}

const BalanceAndRef = ({ balance, referal }: Props) => {
  return (
    <div className={s.profile_main}>
      <NavLink to="/balance" className={s.profile_item}>
        <div className={s.item_wrapper}>
          <div className={s.title}>
            <div className={s.name}>
              <div className={s.text}>Баланс</div>
              <div className={s.img_block}>
                <img src="/images/icons/rub_icon.svg" alt="balance" />
              </div>
            </div>
            <div className={s.sum}>
              {balance && !isNaN(balance.total) ? (
                `${Math.floor(balance.total)} Р`
              ) : (
                <Skeleton
                  style={{
                    display: 'inline-block',
                    height: '24px',
                    width: '50px',
                  }}
                />
              )}
            </div>
          </div>
          <div className={s.info}>
            <div className={s.text}>Общая сумма аккаунта</div>
            <div className={s.icon}>
              <img src="/images/icons/arrow-white-icon.svg" alt="" />
            </div>
          </div>
        </div>
      </NavLink>

      <NavLink to="/referral" className={s.profile_item}>
        <div className={s.item_wrapper}>
          <div className={s.title}>
            <div className={s.name}>
              <div className={s.text}>Моя ссылка</div>
              <div className={s.img_block}>
                <img src="/images/icons/share-white-icon.svg" alt="refferal" />
              </div>
            </div>
            <div className={s.sum}>
              {referal && !isNaN(referal.total) ? (
                `${Math.floor(referal.total)} Р`
              ) : (
                <Skeleton
                  style={{
                    display: 'inline-block',
                    height: '24px',
                    width: '50px',
                  }}
                />
              )}
            </div>
          </div>
          <div className={s.info}>
            <div className={s.text}>Делитесь - получайте вознаграждение</div>
            <div className={s.icon}>
              <img src="/images/icons/arrow-white-icon.svg" alt="arrow" />
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  )
}

export default React.memo(BalanceAndRef)
