/* eslint-disable no-useless-escape */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react'
// @ts-ignore
import processString from 'react-process-string'
import { NavLink } from 'react-router-dom'

const parseComment = (comment: string, mentioned_users: any, callback?: Function) => {
  const processed = processString([
    {
      regex: /\@([a-z0-9_\-]+?)( |\,|$|\.)/gim,
      fn: (key: number, result: any) => {
        const username = result[1]
        let nickname: string = ''
        const foundUsers = mentioned_users.filter((userItem: any) => {
          if (userItem.id.toString() === username) {
            nickname = userItem.nickname
            return true
          }
          if (userItem.nickname.toString() === username) {
            nickname = userItem.nickname
            return true
          }
          return false
        })
        if (!foundUsers.length) {
          return result[0]
        }
        return (
          <React.Fragment key={key}>
            <NavLink
              // className={s.ment_user}
              to={`/${nickname}`}
              style={{ color: '#ff3e6c' }}
              onClick={(e: any) => {
                // e.preventDefault()
                e.stopPropagation()
                // closeMW()
              }}
            >
              @{nickname}
            </NavLink>{' '}
          </React.Fragment>
        )
      },
    },
  ])(comment)
  return processed
}

export const clearPhotoInComment = (comment: string) => {
  const reg =
    /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/
  if (comment) {
    return comment.replace(reg, '').replace('[!image ()]', '')
  }
  return comment
}

export const getPhotoUrlFromComment = (comment: string) => {
  const reg =
    /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/
  if (comment) {
    const match = comment.match(reg)
    return match && match[0] ? match[0] : undefined
  }
  return undefined
}

export default parseComment
