import queryClient from 'application/data/apiClient/queryClient'
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/reduxStore'
import {
  deleteHistoryRequest,
  fetchHistory,
  fetchSearch,
  fetchTopRequests,
} from 'application/data/api/search'

type DeleteHistoryData = {
  id: string
}

export const DeleteHistoryMutation = () => {
  const mutation = useMutation(({ id }: DeleteHistoryData) => deleteHistoryRequest(id), {
    onMutate: async ({ id }) => {
      await queryClient.cancelQueries(['searchHistory'])
      const previousData = queryClient.getQueryData<any>(['searchHistory'])

      if (previousData) {
        const filteredData = Object.entries(previousData).filter((item: any) => item[0] !== `${id}`)
        let newData: any = {}
        for (let i = 0; i < filteredData.length; i++) {
          newData[filteredData[i][0]] = filteredData[i][1]
        }
        queryClient.setQueryData(['searchHistory'], newData)
      }
      return { previousData }
    },
    onError: (err, variables, context: any) => {
      if (context?.previousData) {
        queryClient.setQueryData(['searchHistory'], context.previousData)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['searchHistory'])
    },
  })
  return mutation
}

export const useSearchTop = () => {
  const query = useQuery(['searchTop'], fetchTopRequests)
  return query
}

const useSearchHistory = () => {
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const query = useQuery(['searchHistory'], fetchHistory, {
    enabled: user ? true : false,
  })

  return query
}

type SearchProps = {
  searchVal: string | Array<string>
  group_by?: string | Array<string>
  category?: string | Array<string>
}

export const getSearchResult = async ({ searchVal, group_by, category }: SearchProps) => {
  const url = `/api/v1/shop/search?query=${searchVal}${group_by ? `&group-by=${group_by}` : ''}${
    category ? `&category=${category}` : ''
  }`

  return fetchSearch(url)
}

export const useSearch = ({ searchVal, group_by, category }: SearchProps, config?: any) => {
  const query = useInfiniteQuery<any>(
    ['search', group_by ? 'category' : 'products', searchVal],
    ({
      pageParam = `/api/v1/shop/search?query=${searchVal}${
        group_by ? `&group-by=${group_by}` : ''
      }${category ? `&category=${category}` : ''}`,
    }) => fetchSearch(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page_url
      },
      enabled: searchVal !== '',
      ...config,
      //   refetchInterval: 10000,
    },
  )

  return query
}

export default useSearchHistory
