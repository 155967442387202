import React, { useState } from 'react'

import s from './InputPassword.module.scss'

type Props = {
  onChange: Function
  isWrong?: boolean
}

const InputPassword = ({ onChange, isWrong }: Props) => {
  const [isFocused, setIsFocused] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')

  return (
    <div className={`${s.input_block} ${s.password} ${isWrong ? s.wrong : ''}`}>
      <label className={s.input_label} htmlFor="password">
        <div className={`.little_text ${s.little_text} ${isFocused ? s.focus : ''}`}>
          Введите пароль
        </div>
        <input
          //   readOnly={!!this.props.isLoad}
          type="password"
          id="password"
          name="password"
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            if (!password || password.length === 0) setIsFocused(false)
          }}
          value={password}
          onChange={(e: any) => {
            setPassword(e.target.value)
            if (onChange) onChange(e.target.value)
          }}
        />
      </label>
    </div>
  )
}

export default React.memo(InputPassword)
