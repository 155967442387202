import ProductItem from 'application/presentation/common/ProductItem'
import React, { useMemo } from 'react'

import s from './CategoryItem.module.scss'

type Props = {
  data: any
}

const ShowMoreCard = ({ item, searchInCategory, isIdeaSearch }: any) => {
  return (
    <div className={s.more_card}>
      <div className={s.more_card_title}>Смотреть все результаты в {item.categ_name}</div>
      <div className={s.more_card_subtitle}>Мы нашли еще {item.total} вариантов</div>
      <div className={s.more_card_svg}>
        <img src="/images/icons/white-big-next-arrow.svg" alt="" />
        {/* <SvgXml xml={nextSvg} width={26} /> */}
      </div>
    </div>
  )
}

const CategoryItem = ({ data }: Props) => {
  const products = useMemo(() => {
    return data && data.products && data.products.length ? data.products : []
  }, [data])
  return (
    <div className={s.container}>
      <div className={s.title}>
        <div className={s.categ_name}>{data.name}</div>
        <div className={s.show_all}>Все</div>
      </div>
      {products && products.length ? (
        <div className={s.list}>
          {products.map((item: any) => (
            <ProductItem item={item} key={`product_${item.id}`} />
          ))}
          {data.total > 10 && (
            <ShowMoreCard
              item={{
                categ_name: data.name,
                total: data.total - 10,
              }}
            />
          )}
        </div>
      ) : (
        <></>
      )}
      {/* <FlatList
    data={data.products}
    renderItem={({ item: product }: any) =>
      product.categ_name ? (
        <ShowMoreCard item={product} searchInCategory={searchInCategory} />
      ) : (
        <ProductItem item={product} containerclassName={s.product} isIdea={isIdeaSearch} />
      )
    }
    horizontal
    showsHorizontalScrollIndicator={false}
    contentContainerclassName={s.list}
    nestedScrollEnabled
    keyExtractor={product => product.id.toString()}
  /> */}
    </div>
  )
}

export default React.memo(CategoryItem)
