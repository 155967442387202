import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Range as RangeSlider } from 'framework7-react'
import { useLocation, useNavigate } from 'react-router-dom'

import s from './FilterProductPage.module.scss'

type Props = {
  changeStage: Function
  filterList: any[]
}

const FILTER_DATA = {
  priceSlider: {
    min: 0,
    max: 999999,
  },
}

export const FilterContent = ({ changeStage, filterList }: Props) => {
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const { min, max } = FILTER_DATA.priceSlider

  const [priceRange, setPriceRange] = useState({ min, max })

  const updateRange = useCallback(
    ({ target: { name, value } }: any) => {
      setPriceRange({
        ...priceRange,
        [name]: Math.min(max, Math.max(min, value)),
      })
    },
    [min, max, priceRange],
  )

  const priceParams = useMemo(() => {
    const params = new URLSearchParams(search)
    const price = params.get('price')
    return price ? price.split(':') : null
  }, [search])

  useEffect(() => {
    if (priceParams) {
      setPriceRange({
        min: parseInt(priceParams[0], 10),
        max: parseInt(priceParams[1], 10),
      })
    }
  }, [priceParams])

  const doRequest = useCallback(() => {
    let request = pathname.replace('/filter', '?')
    if (filterList) {
      filterList.map((item: any) => {
        if (item.value) {
          request = `${request}${item.value}&`
        }
      })
    }
    request = `${request}price=${priceRange.min}:${priceRange.max}`
    navigate(request, { replace: true })
  }, [pathname, filterList, priceRange.min, priceRange.max, navigate])

  return (
    <>
      <div className={s.filter_content}>
        <div className={s.title}>Основные</div>
        <div className={s.filter_items}>
          {filterList &&
            filterList.map((item: any, i: number) => {
              return (
                <div
                  className={s.item}
                  key={`filter_item_${i}`}
                  onClick={() => {
                    changeStage('sort', item.id)
                  }}
                >
                  <div className={s.name}>{item.name}</div>
                  <div className={s.value}>
                    <span>{item.valueName}</span>
                    <svg
                      width="15"
                      height="9"
                      viewBox="0 0 15 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.83398 7.86095L7.83505 7.85993L13.7323 1.93414C14.174 1.49021 14.1724 0.772162 13.7284 0.330268C13.2845 -0.111569 12.5664 -0.109868 12.1246 0.334067L8.1653 4.31265L8.1653 4.60193C8.1653 3.97557 7.65757 4.78172 7.03122 4.78172C6.40487 4.78172 5.89714 3.97557 5.89714 4.60193L5.89714 4.31259L1.93785 0.334122C1.49601 -0.109813 0.777969 -0.111512 0.334034 0.330325C-0.110014 0.772219 -0.111545 1.49032 0.330179 1.93419L6.22739 7.85999L6.22846 7.86101C6.67177 8.30517 7.39214 8.30375 7.83398 7.86095Z"
                        fill="#000000"
                      />
                    </svg>
                  </div>
                </div>
              )
            })}
        </div>
        <div className={s.price_slider}>
          <div className={s.title}>Цена(Р)</div>
          <div className={s.inputs}>
            <div className={s.input_block}>
              <div className={s.text}>от</div>
              <input
                type="number"
                name="min"
                value={priceRange.min}
                onChange={updateRange}
                className={s.input}
              />
            </div>
            <div className={s.input_block}>
              <div className={s.text}>до</div>
              <input
                type="number"
                name="max"
                value={priceRange.max}
                onChange={updateRange}
                className={s.input}
              />
            </div>
          </div>
          <RangeSlider
            className={s.rc_filter}
            step={1}
            min={min}
            max={max}
            label={true}
            dual={true}
            color="pink"
            value={[priceRange.min, priceRange.max]}
            onRangeChange={(e) => setPriceRange({ min: e[0], max: e[1] })}
          />
        </div>
      </div>
      <button type="button" className={s.accept_button} onClick={() => doRequest()}>
        Показать
      </button>
    </>
  )
}

export default React.memo(FilterContent)
