import { useMutation } from '@tanstack/react-query'
import queryClient from 'application/data/apiClient/queryClient'
import { useSelector } from 'react-redux'
import {
  commentLikeRequest,
  deleteCommentRequest,
  editCommentRequest,
  sendCommentRequest,
} from 'application/data/api/comment'
import { useAppSelector } from 'application/domain/store/hooks'

type DataType = {
  comment_id: string
  type: string
  like: boolean
}

export const LikeCommentMutation = (wish_id: number, callback?: () => void) => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const mutation = useMutation(
    ({ comment_id, type }: DataType) => commentLikeRequest(comment_id, type),
    {
      onMutate: async ({ comment_id, like }) => {
        await queryClient.cancelQueries(['comments'])
        await queryClient.cancelQueries(['wish'])
        const previousData = queryClient.getQueryData<any>(['comments', wish_id])
        if (previousData) {
          queryClient.setQueryData(['comments', wish_id], {
            ...previousData,
            pages: previousData.pages.map((item: any) => {
              item.data.map((item2: any) => {
                if (item2.id === comment_id) {
                  if (like) {
                    const likeItem = {
                      id: 0,
                      target_id: comment_id,
                      target_type: 'comment',
                      user,
                    }
                    item2.likes.push(likeItem)
                  } else {
                    item2.likes = item2.likes.filter((item3: any) => item3.user.id !== user!.id)
                  }
                }
                return item2
              })
              return item
            }),
          })

          if (callback) {
            callback()
          }
        }
        return { previousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(['comments', wish_id], context.previousData)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['comments'])
        queryClient.invalidateQueries(['wish'])
      },
    },
  )
  return mutation
}

export const DeleteCommentMutation = (wish_id: number, callback?: () => void) => {
  const mutation = useMutation(({ comment_id }: any) => deleteCommentRequest(comment_id), {
    onMutate: async ({ comment_id }) => {
      await queryClient.cancelQueries(['comments'])
      await queryClient.cancelQueries(['wish'])
      const previousData = queryClient.getQueryData<any>(['comments', wish_id])
      if (previousData) {
        queryClient.setQueryData(['comments', wish_id], {
          ...previousData,
          pages: previousData.pages.map((item: any) => {
            return { ...item, data: item.data.filter((item2: any) => item2.id !== comment_id) }
          }),
        })

        if (callback) {
          callback()
        }
      }
      return { previousData }
    },
    onError: (err, variables, context: any) => {
      if (context?.previousData) {
        queryClient.setQueryData(['comments', wish_id], context.previousData)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['comments'])
      queryClient.invalidateQueries(['wish'])
    },
  })
  return mutation
}

type EditCommentType = {
  commentText: string
  mentions: any
  comment_id: string
  type: string
}

export const EditCommentMutation = (wish_id: number, callback?: () => void) => {
  const mutation = useMutation(
    ({ comment_id, commentText, mentions, type }: EditCommentType) =>
      editCommentRequest(comment_id, commentText, mentions, type),
    {
      onMutate: async ({ comment_id, commentText }) => {
        await queryClient.cancelQueries(['comments'])
        await queryClient.cancelQueries(['wish'])
        const previousData = queryClient.getQueryData<any>(['comments', wish_id])
        if (previousData) {
          queryClient.setQueryData(['comments', wish_id], {
            ...previousData,
            pages: previousData.pages.map((item: any) => {
              item.data.map((item2: any) => {
                if (item2.id === comment_id) {
                  item2.comment = commentText
                  item2.text = commentText
                }
                return item2
              })
              return item
            }),
          })

          if (callback) {
            callback()
          }
        }
        return { previousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(['comments', wish_id], context.previousData)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['comments'])
        queryClient.invalidateQueries(['wish'])
      },
    },
  )
  return mutation
}

type SendCommentType = {
  commentText: string
  mentions: any
}

export const SendCommentMutation = (wish_id: number, callback: () => void) => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))

  const mutation = useMutation(
    ({ commentText, mentions }: SendCommentType) =>
      sendCommentRequest(wish_id, commentText, mentions),
    {
      onMutate: async ({ commentText }) => {
        await queryClient.cancelQueries(['comments'])
        await queryClient.cancelQueries(['wish'])
        const previousData = queryClient.getQueryData<any>(['comments', wish_id])
        if (previousData) {
          const commentItem = {
            id: 0,
            target_id: wish_id,
            target_type: 'wish',
            text: commentText,
            user_id: user!.id,
            likes: [],
            user,
            created_at: new Date().getTime() / 1000,
          }
          const newData = { ...previousData }
          newData.pages[0].data.unshift(commentItem)
          queryClient.setQueryData(['comments', wish_id], newData)
        }
        if (callback) {
          callback()
        }
        return { previousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(['comments', wish_id], context.previousData)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['comments'])
        queryClient.invalidateQueries(['profile'])
        queryClient.invalidateQueries(['wish'])
      },
    },
  )
  return mutation
}
