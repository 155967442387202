import apiClient from '../apiClient'

export const fetchWish = async (wish_id?: number | string, isShortLink?: boolean) => {
  const { data } = await apiClient.get(`/api/v1/wish/${isShortLink ? 'short/' : ''}${wish_id}`)
  return data
}

export const fetchWishlist = async () => {
  const { data } = await apiClient.get('/api/v1/wish/my')
  return data
}

export const likeWish = async (wish_id?: number | string) => {
  const { data } = await apiClient.post<any>(`/api/v1/wish/${wish_id}/like`)
  return data
}

export const editWishRequest = async (id: number, edit_data: any) => {
  const { data } = await apiClient.post(`/api/v1/wish/${id}/edit`, edit_data)
  return data
}

export const deleteWishRequest = async (id: number) => {
  const { data } = await apiClient.delete(`/api/v1/wish/${id}`)
  return data
}
