import { Order } from 'application/domain/entity/order/Order'
import React from 'react'
import { NavLink } from 'react-router-dom'

import s from './OrderResultContent.module.scss'

type Props = {
  closeStage: Function
  orderData: Order
}
export const OrderResultContent = ({ orderData, closeStage }: Props) => {
  return orderData && orderData.id ? (
    <section className={s.result_section}>
      <div className={s.result_content}>
        <div className={s.success_img}>
          <img loading="lazy" src="/images/icons/big-plane.svg" alt="" />
        </div>
        <div className={s.title}>
          Заказ #{orderData.id}
          <br />
          успешно оформлен!
        </div>
        <div className={s.subtitle}>
          Рассчетная дата доставки {orderData.delivery_date}
          <br />
          Статус заказа размещен в разделе Мои заказы в Профиле.
        </div>
        <NavLink to="/orders/" className={s.orderLink}>
          <span>Перейти в раздел Мои заказы</span>
          <img loading="lazy" src="/images/icons/red-right-arrow.svg" alt="" />
        </NavLink>
        <button type="button" className={s.ok_button} onClick={() => closeStage()}>
          <img loading="lazy" src="/images/icons/ok-icon.svg" alt="" />
        </button>
      </div>
    </section>
  ) : (
    <></>
  )
}

export default React.memo(OrderResultContent)
