const CheckDonateSum = (val: any, available_amount: any) => {
  let msg = ''
  if (available_amount) {
    if (available_amount >= 200) {
      if (parseInt(val, 10) < 100) {
        msg = 'Сумма меньше минимальной - 100р'
      } else if (parseInt(val, 10) > parseInt(available_amount, 10)) {
        msg = `Сумма больше максимальной (${available_amount}р)`
      } else if (parseInt(available_amount, 10) - parseInt(val, 10) === 0) {
        msg = ''
      } else if (parseInt(available_amount, 10) - parseInt(val, 10) < 100) {
        msg = 'Оставшаяся сумма после доната будет меньше 100р'
      }
    } else if (parseInt(val, 10) === parseInt(available_amount, 10)) {
      msg = ''
    } else {
      msg = `При остатке меньше 200 рублей, платеж фиксированный - ${available_amount}р`
    }
  }

  return msg
}

export default CheckDonateSum
