import React, { useRef, useMemo, useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import DeleteFriendMW from '../ActionComponents/FriendsHeaderButton/DeleteFriendMW'

// import CategoryMoreMW from './CategoryMoreMW'
// import EditCategMW from './EditCategMW'
import FriendsActionMW from './FriendsActionMW'
import ProductMoreMW from './ProductMoreMW'
import SearchMW from './SearchMW'
import ShareMW from './ShareMW'
// import WishMoreMW from './WishMoreMW'

type MwContextType =
  | {
      openShareMW: Function
      openFriendsActionMW: Function
      openFriendDeleteMW: Function
    }
  | undefined

export const MwContext = React.createContext<MwContextType>(undefined)

type FriendMwProps = {
  name: string
  nickname: string
}

export const MwProvider = ({ children }: any) => {
  /* logic */
  const navigate = useNavigate()
  const location = useLocation()
  const [isMyProfile, setIsMyProfile] = useState<boolean>(false)
  const [shareLink, setShareLink] = useState<string>('')
  const [wishId, setWishId] = useState<number>(0)
  const [shareType, setShareType] = useState<any>('')
  const [shareMsg, setShareMsg] = useState<string>('')
  const [friendName, setFriendName] = useState<string>('')
  const [friendNickname, setFriendNickname] = useState<string>('')

  const modalWindow = useMemo(
    () => ({
      openShareMW: (open: boolean) => {
        if (open) {
          navigate(`${location.pathname}#share`)
        } else if (location.hash.indexOf('#share') !== -1) {
          window.history.back()
        }
      },
      openFriendsActionMW: (open: boolean, friendData: FriendMwProps) => {
        if (open) {
          setFriendName(friendData.name)
          setFriendNickname(friendData.nickname)
          navigate(`${location.pathname}#friend`)
        } else {
          if (location.hash.indexOf('#friend') !== -1) {
            window.history.back()
          }
          setFriendName('')
          setFriendNickname('')
        }
      },
      openFriendDeleteMW: (open: boolean, friendData: any) => {
        if (open) {
          navigate(`${location.pathname}#delete_friend`)
        } else if (location.hash.indexOf('#delete_friend') !== -1) {
          window.history.back()
        }
      },
    }),
    [location.hash, location.pathname, navigate],
  )

  return (
    <MwContext.Provider value={modalWindow}>
      {children}

      <FriendsActionMW data={{ name: friendName, nickname: friendNickname }} />
      {/* <CategoryMoreMW /> */}
      {/* <EditCategMW /> */}
      {/* <WishMoreMW /> */}
      <DeleteFriendMW />
      <ProductMoreMW />
      <ShareMW />
      <SearchMW />
    </MwContext.Provider>
  )
}

export const { Consumer } = MwContext
