/* eslint-disable react/react-in-jsx-scope */
import { createRoot } from 'react-dom/client'
import './bootstrap'
// import './css/framework7.bundle.min.css'
// import './css/nprogress.css'
import 'react-toastify/dist/ReactToastify.css'
import './css/app.scss'
// import './css/rc-checkbox.css'
import './i18n'

import App from './App'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root')
const root = createRoot(container!) // createRoot(container!) if you use TypeScript
root.render(<App />)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
