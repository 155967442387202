/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useMemo } from 'react'
import { Sheet, PageContent } from 'framework7-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import Loader from 'application/presentation/common/Loader'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { useAppSelector } from 'application/domain/store/hooks'
import { useUser } from 'application/domain/useCase/user/getUser'
import { HideCategoryMutation } from 'application/domain/useCase/wish_category/editWishCategory'

import style from '../MW.module.scss'
import s from '../WishMoreMW/WishMoreMW.module.scss'

const CategoryMoreMW = () => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const { data: profileData, isLoading } = useUser(user ? user.nickname : '')

  const { search, hash } = useLocation()
  const navigate = useNavigate()

  const opened = useMemo(() => {
    return hash.indexOf('#category_more') !== -1
  }, [hash])

  const categ_id = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const id = searchParams.get('category_id')
    return id ? parseInt(id, 10) : 0
  }, [search])

  const wishlist = useMemo(() => {
    return profileData && profileData.wish ? profileData.wish.items : []
  }, [profileData])

  const category = useMemo(() => {
    return categ_id && wishlist && wishlist.length ? _.find(wishlist, { id: categ_id }) : undefined
  }, [wishlist, categ_id])

  const permissionType = useMemo(() => {
    return category && category.permission ? category.permission.type : ''
  }, [category])

  const hideMutation = HideCategoryMutation(() => {
    ToastNotify(`Категория ${permissionType === 'allow' ? 'скрыта' : 'отображена'}!`)
  })

  const closeBS = useCallback(() => {
    if (hash.indexOf('#category_more') !== -1) {
      navigate(-1)
    }
  }, [hash, navigate])

  return (
    <div>
      <Sheet
        className={`${style.sheet_modal} ${style.auto_height}`}
        swipeToClose
        backdrop
        opened={opened}
        onSheetClosed={() => {
          if (hash.indexOf('#category_more') !== -1) {
            navigate(-1)
          }
        }}
      >
        <div className={style.top_close_button}>
          <div />
        </div>
        <PageContent className={s.wishBS_page_content}>
          <div className={s.inner_content}>
            {isLoading ? (
              <Loader />
            ) : (
              <div className={s.settings_content}>
                <div
                  className={s.item}
                  onClick={() => navigate(`?category_id=${categ_id}#edit`, { replace: true })}
                >
                  <div className={s.title}>Редактировать</div>
                  <img loading="lazy" src="/images/icons/pencil-black.svg" alt="" />
                </div>
                <div
                  className={s.item}
                  onClick={() => {
                    closeBS()
                    hideMutation.mutate({
                      category_id: categ_id,
                      permission: {
                        type: permissionType === 'allow' ? 'deny' : 'allow',
                        users: null,
                      },
                    })
                  }}
                >
                  <div className={s.title}>
                    {permissionType && permissionType === 'deny' ? 'Показать' : 'Скрыть'}
                  </div>
                  <img loading="lazy" src="/images/icons/hide-icon.svg" alt="" />
                </div>
                <div
                  className={s.item}
                  onClick={() =>
                    navigate(`?type=category&category_id=${categ_id}#share`, { replace: true })
                  }
                >
                  <div className={s.title}>Поделиться ссылкой</div>
                  <img loading="lazy" src="/images/icons/copy-icon.svg" alt="" />
                </div>
                <div
                  className={s.item}
                  onClick={() => navigate(`?category_id=${categ_id}#delete`, { replace: true })}
                >
                  <div className={`${s.title} ${s.trash}`}>Удалить категорию</div>
                  <img loading="lazy" src="/images/icons/trash.svg" alt="" />
                </div>
              </div>
            )}
          </div>
        </PageContent>
      </Sheet>
    </div>
  )
}

export default React.memo(CategoryMoreMW)
