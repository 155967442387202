import apiClient from '../apiClient'

export const fetchPartnersList = async (pageParam?: string) => {
  const { data } = await apiClient.get(pageParam || '/api/v1/partners_info')
  return data
}

export const fetchPartnersCategory = async (pageParam: string) => {
  const { data } = await apiClient.get(pageParam)
  return data
}
