/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { Time } from 'application/presentation/common/uiComponents'
import { User } from 'application/domain/entity/user/User'
import { Comment } from 'application/domain/entity/comment/Comment'

import s from './CommentsPage.module.scss'
import parseComment, { clearPhotoInComment, getPhotoUrlFromComment } from './parseComment'

type Props = {
  item: Comment
  user: User | null
  likeComment: Function
  answerComment: Function
}

const CommentItem = ({ item, user, likeComment, answerComment }: Props) => {
  const liked = useMemo(() => {
    let likedTemp: boolean = false
    item.likes.map((likeItem: any) => {
      if (user && likeItem.user!.id === user.id) {
        likedTemp = true
      }
      return true
    })
    return likedTemp
  }, [item, user])

  const likesCount = useMemo(() => {
    return item.likes ? item.likes.length : 0
  }, [item])

  const commentPhoto = useMemo(
    () => getPhotoUrlFromComment(item.text ? item.text : item.comment ? item.comment : ''),
    [item],
  )

  return item && item.user ? (
    <div className={`${s.comment_item} ${s.comment}`} key={item.id}>
      <div className={s.user_img}>
        <NavLink to={`/${item.user.nickname}`} className="link external">
          <img src={item.user.photo ? item.user.photo : 'photo'} alt="" />
        </NavLink>
      </div>
      <div className={s.center}>
        <div className={s.text}>
          <div className={s.comment_text}>
            <span>
              {item.user.name ? item.user.name : item.user.nickname}{' '}
              {commentPhoto ? 'поделился(ась) фотографией исполненного желания \n' : ''}
            </span>{' '}
            {parseComment(
              item.text
                ? clearPhotoInComment(item.text)
                : item.comment
                ? clearPhotoInComment(item.comment)
                : '',
              item.mentioned_user ? item.mentioned_user : [],
            )}
            {commentPhoto ? (
              <div className={s.wish_photo}>
                <img src={commentPhoto} alt="wish photo" />
              </div>
            ) : (
              <></>
            )}
          </div>
          <span className={s.time}>
            <Time time={item.created_at} />
          </span>
        </div>
        {item.type === 'payment' || item.amount ? (
          <div className={s.sum}>+ {item.amount} руб</div>
        ) : (
          ''
        )}

        <div className={s.actions_block}>
          <div
            className={s.answer}
            onClick={() => {
              //   answerCommentFunc(item.user.nickname)
              answerComment(item.user.nickname, item.user.id)
            }}
          >
            Ответить
          </div>
          <div className={s.point}>.</div>
          <div
            className={s.likes_count}
            onClick={() => {
              //   dispatch(
              //     setLikesMwStatus(true, item.type === 'payment' ? 'money' : 'comment', item.id),
              //   )
            }}
          >
            Нравится {likesCount}
          </div>
        </div>
      </div>
      <div
        className={s.right}
        onClick={() => {
          likeComment(item.id, item.type === 'payment' ? 'money' : 'comment', !liked)
        }}
      >
        <div className={`${s.likes} ${s.unliked}`}>
          <img
            src={liked ? '/images/icons/red-hearth.svg' : '/images/icons/hearth-icon.svg'}
            alt=""
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default React.memo(CommentItem)
