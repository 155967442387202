import React from 'react'
import { MetaTitleMain, MetaDescriptionMain, MetaOG } from 'application/presentation/app/MetaTags'

import FirstBlock from './components/FirstBlock'
import SecondBlock from './components/SecondBlock'
import ThirdBlock from './components/ThirdBlock'
import VideoBlock from './components/VideoBlock'
import FiveBlock from './components/FiveBlock'
import SixBlock from './components/SixBlock'
import SevenBlock from './components/SevenBlock'
import QuestionBlock from './components/QuestionBlock'
import ElevenBlock from './components/ElevenBlock'

const MainPage = () => {
  return (
    <section>
      <>
        <MetaTitleMain />
        <MetaDescriptionMain />
        <MetaOG
          title="Главная страница Wishmen"
          description="Пришло время исполнять ваши желания. Must Have приложение на День рождения"
        />
      </>
      <FirstBlock />
      <SecondBlock />
      <ThirdBlock />
      <VideoBlock />
      <FiveBlock />
      <SixBlock />
      <SevenBlock />
      <QuestionBlock />
      <ElevenBlock />
    </section>
  )
}

export default React.memo(MainPage)
