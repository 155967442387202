/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { AuthContext } from 'application/presentation/app/AuthContext'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'

import s from '../Menu.module.scss'

const ProfileOther = () => {
  const auth = useContext(AuthContext)
  return (
    <div className={s.profile_about}>
      <NavLink to="/about" className={`${s.about__item_link} link external`}>
        <div className={s.about__item}>
          <div className={s.item_name}>О проекте</div>
          <div className={s.arrow}>
            <img loading="lazy" src="/images/icons/black-right-trigger.svg" alt="" />
          </div>
        </div>
      </NavLink>
      <NavLink to="/bonus" className={`${s.about__item_link} link external`}>
        <div className={s.about__item}>
          <div className={s.item_name}>О бонусах</div>
          <div className={s.arrow}>
            <img loading="lazy" src="/images/icons/black-right-trigger.svg" alt="" />
          </div>
        </div>
      </NavLink>
      <NavLink to="/contacts" className={`${s.about__item_link} link external`}>
        <div className={s.about__item}>
          <div className={s.item_name}>Контакты</div>
          <div className={s.arrow}>
            <img loading="lazy" src="/images/icons/black-right-trigger.svg" alt="" />
          </div>
        </div>
      </NavLink>
      <NavLink to="/documentation" className={`${s.about__item_link} link external`}>
        <div className={s.about__item}>
          <div className={s.item_name}>Документация</div>
          <div className={s.arrow}>
            <img loading="lazy" src="/images/icons/black-right-trigger.svg" alt="" />
          </div>
        </div>
      </NavLink>
      <NavLink
        to="/"
        onClick={(e: any) => {
          // e.preventDefault()
          localStorage.removeItem('prev_url')
          localStorage.removeItem('closeUrl')
          auth.logout()
        }}
        className={s.about__item_link}
      >
        <div className={s.about__item}>
          <div className={`${s.item_name} ${s.logout}`}>Выйти</div>
          <div className={s.arrow}>
            <img loading="lazy" src="/images/icons/black-right-trigger.svg" alt="" />
          </div>
        </div>
      </NavLink>
    </div>
  )
}

export default React.memo(ProfileOther)
