import React from 'react'

import s from './ContentLayout.module.scss'

type Props = {
  children?: React.ReactNode
}

const ContentLayout = ({ children }: Props): React.ReactElement => (
  <section className={s.content}>{children}</section>
)

export default React.memo(ContentLayout)
