import React, { Fragment, useContext } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { Payment } from 'application/domain/entity/payment/Payment'

import s from './UserDonatesItem.module.scss'
import UserDonateOverlay from './UserDonateOverlay'
import UserDonatesItemView from './UserDonatesItemView'

type Props = {
  donate: Payment
  type: 'in' | 'out'
}

const UserDonatesItem = ({ donate, type }: Props): React.ReactElement => {
  const navigate = useNavigate()
  const params = useParams<{ id?: string }>()
  const { pathname } = useLocation()
  const opened = typeof params.id === 'string' && parseInt(params.id, 10) === donate.id

  const handleOverlayClose = () => {
    navigate(-1)
    // if (isPreviousLocationWithinApp()) {
    //   navigate(-1)
    // } else {
    //   navigate(pathname.replace(/\/$/g, '').split('/').slice(0, -1).join('/'))
    // }
  }

  return (
    <Fragment key={donate.id}>
      <UserDonateOverlay
        donate={donate}
        opened={opened}
        type={type}
        onClosed={handleOverlayClose}
      />
      <Link className={s.item} to={`${pathname}/${donate.id}`.replace(/[/]+/g, '/')}>
        <UserDonatesItemView donate={donate} type={type} />
      </Link>
    </Fragment>
  )
}

export default React.memo(UserDonatesItem)
