import { useQuery } from '@tanstack/react-query'
import { fetchNotificationsByType } from 'application/data/api/notifications'

const useNewNotifications = (type?: string, user_id?: number) => {
  const query = useQuery(['newNotifications', type], () => fetchNotificationsByType(type), {
    enabled: !!(user_id && user_id !== 0),
    refetchInterval: 10000,
  })

  return query
}

export default useNewNotifications
