import apiClient from 'application/data/apiClient'
import queryClient from 'application/data/apiClient/queryClient'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { RootState } from 'application/domain/store/reduxStore'

type EditCategDataType = {
  id: number
  name?: string
  permission?: {
    type: 'allow' | 'deny'
    users: any
  }
}

const editWishCategoryRequest = async (categ_id: number, data: any) => {
  const response = await apiClient({
    method: 'POST',
    url: `/api/v1/wish/category/${categ_id}`,
    data,
  })
  return response.data
}
export const EditWishCategMutation = (user_nickname?: string, callback?: Function) => {
  const mutation = useMutation(
    (data: EditCategDataType) => editWishCategoryRequest(data.id, data),
    {
      onMutate: async (data: EditCategDataType) => {
        await queryClient.cancelQueries(['profile'])
        await queryClient.cancelQueries(['myWishlist'])
        const profilePrevData = queryClient.getQueryData<any>(['profile', user_nickname])
        const myWishPrevData = queryClient.getQueryData<any>(['myWishlist'])

        if (myWishPrevData) {
          queryClient.setQueryData(['myWishlist'], {
            ...myWishPrevData,
            items: myWishPrevData.items.map((item: any) => {
              if (item.id === data.id) {
                return {
                  ...item,
                  name: data.name ? data.name : item.name,
                  permission: data.permission ? data.permission : item.permission,
                }
              }
              return item
            }),
          })
        }
        if (user_nickname) {
          if (profilePrevData) {
            queryClient.setQueryData(['profile', user_nickname], {
              ...profilePrevData,
              wish: {
                ...profilePrevData.wish,
                items: profilePrevData.wish.items.map((item: any) => {
                  if (item.id === data.id) {
                    return {
                      ...item,
                      name: data.name ? data.name : item.name,
                      permission: data.permission ? data.permission : item.permission,
                    }
                  }
                  return item
                }),
              },
            })
          }
        }
        if (callback) {
          callback()
        }
        return { profilePrevData, myWishPrevData }
      },
      onError: () => {},
      onSettled: () => {
        queryClient.invalidateQueries(['wish'])
        queryClient.invalidateQueries(['profile'])
        queryClient.invalidateQueries(['myWishlist'])
      },
    },
  )
  return mutation
}

type HideCategoryType = {
  category_id: number
  permission: {
    users: any
    type: 'deny' | 'allow'
  }
}

export const HideCategoryMutation = (callback?: () => void) => {
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const mutation = useMutation(
    ({ category_id, permission }: HideCategoryType) =>
      editWishCategoryRequest(category_id, { permission }),
    {
      onMutate: async ({ category_id, permission }) => {
        queryClient.cancelQueries(['profile'])
        const profilePrevData = queryClient.getQueryData<any>([
          'profile',
          user ? user.nickname : '',
        ])
        if (profilePrevData) {
          queryClient.setQueryData(['profile', user ? user.nickname : ''], {
            ...profilePrevData,
            wish: {
              ...profilePrevData.wish,
              items: profilePrevData.wish.items.map((item: any) => {
                return category_id === item.id
                  ? {
                      ...item,
                      permission: permission,
                    }
                  : item
              }),
            },
          })
        }
        if (callback) {
          callback()
        }
        return { profilePrevData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(['profile', user ? user.nickname : ''], context.profilePrevData)
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(['profile'])
      },
    },
  )
  return mutation
}
