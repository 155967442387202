import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'

type WishMoreMWData = {
  wish_id: number
  is_public: boolean
} | null

type ModalState = {
  wishMoreMW: {
    opened: boolean
    data: WishMoreMWData
  }
}

const initialState: ModalState = {
  wishMoreMW: {
    opened: false,
    data: null,
  },
}

export const modalReducer = createSlice({
  name: 'modalReducer',
  initialState,
  reducers: {
    setWishMoreMWAction: (state, { payload: data }: PayloadAction<WishMoreMWData>) => {
      if (data) {
        state.wishMoreMW.opened = true
        state.wishMoreMW.data = data
      } else {
        state.wishMoreMW.opened = false
      }
    },
  },
})

const { setWishMoreMWAction } = modalReducer.actions

export const setWishMoreMwData = (data: WishMoreMWData) => (dispatch: Dispatch) =>
  dispatch(setWishMoreMWAction(data))

export default modalReducer.reducer
