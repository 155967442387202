import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Sheet, PageContent } from 'framework7-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SearchField } from 'application/presentation/common/ActionComponents'
import Loader from 'application/presentation/common/Loader'
import predictSearch from 'application/presentation/utils/SearchFunc'

import style from '../MW.module.scss'

import s from './SearchMW.module.scss'
import SearchResults from './SearchResults'

const SearchMW = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const inputRef: any = useRef<any>(null)
  const opened = location.hash.indexOf('#search') !== -1
  const isIdeaFriendNickname = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const idea = searchParams.get('idea_friend')
    return idea ? idea : false
  }, [location])
  const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false)
  const [searchVal, setSearchVal] = useState<string>('')
  const [searchResult, setSearchResult] = useState<any>()

  useEffect(() => {
    if (searchVal !== '') {
      predictSearch({
        value: searchVal || '',
        onStart: () => setSearchIsLoading(true),
        onSuccess: (result: any) => {
          setSearchResult(result)
          setSearchIsLoading(false)
        },
      })
    }
  }, [searchVal])

  const doSearch = useCallback(() => {
    if (searchVal) {
      navigate(`/shop/results?query=${searchVal}&group-by=category`)
    }
  }, [searchVal, navigate])

  return (
    <div>
      <Sheet
        id="mobileBottomSheet"
        className={style.sheet_modal}
        swipeToClose
        backdrop
        onSheetOpened={() => {
          if (inputRef && inputRef.current) {
            inputRef?.current.focus()
          }
        }}
        opened={opened}
        onSheetClosed={() => {
          if (location.hash.indexOf('#search') !== -1) {
            window.history.back()
          }
        }}
      >
        <div className={`${style.top_close_button} top-close-button`}>
          <div />
        </div>
        <PageContent className={s.wishBS_page_content}>
          <div id="inner-content" className={`${s.inner_content} ${s.wish}`}>
            <div className={s.searchmw_content}>
              <SearchField
                onChange={setSearchVal}
                inputRef={inputRef}
                value={searchVal}
                onSubmit={doSearch}
                onSearchBtnClick={doSearch}
              />
              {searchIsLoading ? (
                <Loader size={16} />
              ) : searchVal !== '' && searchResult ? (
                <SearchResults
                  data={searchResult}
                  setSearchVal={setSearchVal}
                  //   historyData={historyData}
                  //   isHistoryArray={isHistoryArray}
                  searchVal={searchVal}
                  doSearch={doSearch}
                  isIdeaFriendNickname={isIdeaFriendNickname}
                />
              ) : (
                <>
                  {/* {shopIsLoading || !shopData ? (
                    <Loader size={16} />
                  ) : (
                    
                    <Articles data={shopData.articles} containerClassName={s.articles_container} />
                  )} */}
                </>
              )}
            </div>
          </div>
        </PageContent>
      </Sheet>
    </div>
  )
}

export default React.memo(SearchMW)
