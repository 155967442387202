import React from 'react'
import { useTranslation } from 'react-i18next'

import s from './UserDonatesEmpty.module.scss'

type Props = {
  type: 'in' | 'out'
}

const UserDonatesEmpty = ({ type }: Props): React.ReactElement => {
  const { t } = useTranslation()
  return (
    <div className={s.empty}>
      {type === 'in' ? t('donates_page.empty.in') : t('donates_page.empty.out')}
    </div>
  )
}

export default React.memo(UserDonatesEmpty)
