import { User } from 'application/domain/entity/user/User'
import React from 'react'

import s from './InputWithSearchUser.module.scss'

type Props = {
  item: User
  selectUser: Function
}

const UserItem = ({ item, selectUser }: Props) => {
  return (
    <div className={s.user_item} onClick={() => selectUser(item)}>
      <div className={s.user_img_container}>
        <img src={item.photo} alt="" className={s.user_img} />
      </div>
      <div className={s.user_info}>
        <div className={s.user_name}>
          {item.name ? item.name : item.nickname ? item.nickname : ''}
        </div>
        <div className={s.user_teg}>@{item.nickname}</div>
      </div>
    </div>
  )
}

export default React.memo(UserItem)
