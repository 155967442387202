/* eslint-disable no-case-declarations */
import queryClient from 'application/data/apiClient/queryClient'
import { useMutation } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import _filter from 'lodash/filter'
import _concat from 'lodash/concat'
import _find from 'lodash/find'
import { User } from 'application/domain/entity/user/User'
import { RootState } from 'application/domain/store/reduxStore'
import { friendActionRequest } from 'application/data/api/friends'

export type FriendActionType = {
  friend: any
  method: 'POST' | 'GET' | 'DELETE'
  callback: Function
}

export const FriendActionMutation = (type?: string) => {
  const { user } = useSelector(({ userReducer }: RootState) => ({
    user: userReducer.user,
  }))
  const friendMutation = useMutation(
    ({ friend, method }: FriendActionType) => friendActionRequest(friend.nickname, method),
    {
      onMutate: async ({ friend, method, callback }: FriendActionType) => {
        await queryClient.cancelQueries(['profile'])
        await queryClient.cancelQueries(['friendRequest'])
        const previousData = queryClient.getQueryData<any>(['profile', user ? user.nickname : ''])
        const friendPreviousData = queryClient.getQueryData<any>(['profile', friend.nickname])
        const requestsPreviousData = queryClient.getQueryData<any>(['friendRequest'])
        if (previousData) {
          switch (method) {
            case 'DELETE':
              queryClient.setQueryData(['profile', user ? user.nickname : ''], {
                ...previousData,
                friends: {
                  ...previousData.friends,
                  quantity:
                    previousData.relationship === 'friend'
                      ? previousData.friends.quantity - 1
                      : previousData.friends.quantity,
                  items: _filter(previousData.friends.items, (i: User) => i.id !== friend.id),
                },
                relationship: null,
              })
              break
            case 'POST':
              const existedFriend = _find(previousData.friends.items, { id: friend.id })
              queryClient.setQueryData(['profile', user ? user.nickname : ''], {
                ...previousData,
                friends: {
                  ...previousData.friends,
                  items: existedFriend
                    ? _concat(previousData.friends.items, friend)
                    : previousData.friends.items.map((item: User) => {
                        if (item.id === friend.id) {
                          //@ts-ignore
                          item.relationship =
                            item.relationship === 'incoming_request'
                              ? 'friend'
                              : 'outcoming_request'
                        }
                        return item
                      }),
                },
              })
              break
            default:
              break
          }
        }
        if (friendPreviousData) {
          switch (method) {
            case 'DELETE':
              queryClient.setQueryData(['profile', friend.nickname], {
                ...friendPreviousData,
                friends: {
                  ...friendPreviousData.friends,
                  quantity:
                    friendPreviousData.relationship === 'friend'
                      ? friendPreviousData.friends.quantity - 1
                      : friendPreviousData.friends.quantity,
                  items: friendPreviousData.friends.items.filter((item: User) => {
                    if (item.id !== friend.id) {
                      return item
                    }
                    return false
                  }),
                },
                relationship: null,
              })
              break
            case 'POST':
              queryClient.setQueryData(['profile', friend.nickname], {
                ...friendPreviousData,
                friends: {
                  ...friendPreviousData.friends,
                  items: friendPreviousData.friends.items.map((item: User) => {
                    if (item.id === friend.id) {
                      //@ts-ignore
                      item.relationship =
                        item.relationship === 'incoming_request' ? 'friend' : 'outcoming_request'
                    }
                    return item
                  }),
                },
                relationship:
                  friendPreviousData.relationship === 'incoming_request'
                    ? 'friend'
                    : 'outcoming_request',
              })
              break
            default:
              break
          }
        }

        if (requestsPreviousData) {
          queryClient.setQueryData(['friendRequest'], {
            ...requestsPreviousData,
            incoming_request: _filter(
              requestsPreviousData.incoming_request,
              (i: any) => friend.id !== i.id,
            ),
            outcoming_request: _filter(
              requestsPreviousData.outcoming_request,
              (i: any) => friend.id !== i.id,
            ),
          })
        }
        if (callback) {
          callback()
        }
        return { previousData, friendPreviousData, friend, requestsPreviousData }
      },
      onError: (err, variables, context: any) => {
        if (context?.previousData) {
          queryClient.setQueryData(['profile', user ? user.nickname : ''], context.previousData)
        }
        if (context?.friendPreviousData && context.friend) {
          queryClient.setQueryData(['profile', context.friend.nickname], context.friendPreviousData)
        }
        if (context?.requestsPreviousData) {
          queryClient.setQueryData(['friendRequest'], context.requestsPreviousData)
        }
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries(['profile'])
        queryClient.invalidateQueries(['friendRequest'])
      },
    },
  )
  return friendMutation
}
