/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import SwiperCore, { HashNavigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import cn from 'classnames'
import { HeaderMobileTitle, HeaderMobileRightButton } from 'application/presentation/common/Header'
import { useAppSelector } from 'application/domain/store/hooks'
import { useFriendsRequest } from 'application/domain/useCase/friends/getFriendsRequest'
import { User } from 'application/domain/entity/user/User'

import s from './UserFriendsRequestPage.module.scss'
import FriendItem from './components/FriendItem'

SwiperCore.use([HashNavigation])
export const UserFriendsRequestPage = () => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const { data: friendsRequest } = useFriendsRequest(user ? user.nickname : '')
  const params = {
    autoHeight: true,
  }
  const [activeSlide, setActiveSlide] = React.useState(0)
  const [swiper, setSwiper] = React.useState<any>()
  return (
    <div className={s.content_container}>
      <HeaderMobileTitle.Source>Заявки в друзья</HeaderMobileTitle.Source>
      <HeaderMobileRightButton.Source>
        <></>
      </HeaderMobileRightButton.Source>

      <div className={s.cart_nav_block}>
        <div className={s.cart_nav}>
          <div
            className={`${s.left} ${activeSlide === 0 ? s.active : ''}`}
            onClick={() => {
              swiper!.slideTo(0)
            }}
          >
            Входящие{' '}
            {friendsRequest && friendsRequest!.incoming_request.length
              ? `(${friendsRequest && friendsRequest!.incoming_request.length})`
              : ''}
          </div>
          <div
            className={`${s.right} ${activeSlide === 1 ? s.active : ''}`}
            onClick={() => {
              swiper!.slideTo(1)
            }}
          >
            Исходящие{' '}
            {friendsRequest && friendsRequest!.outcoming_request.length
              ? `(${friendsRequest && friendsRequest!.outcoming_request.length})`
              : ''}
          </div>
        </div>
      </div>
      <Swiper
        {...params}
        slidesPerView={1}
        className={`${s.swiper_container} ${s.swiper_container_friends}`}
        onSwiper={(sw) => {
          setSwiper(sw)
        }}
        onSlideChange={(sw) => {
          setActiveSlide(sw.activeIndex)
        }}
      >
        <SwiperSlide>
          <div className={cn(s.friendlist)}>
            {friendsRequest && friendsRequest!.incoming_request.length > 0 ? (
              <>
                <div className={cn(s.friends_container)}>
                  {friendsRequest!.incoming_request.map((item: User) => {
                    return (
                      <FriendItem
                        // user={user}
                        // page_user={page_user}
                        item={item}
                        key={item.id}
                        isMyProfile={true}
                        // friend={item}
                        // key={item.id}
                        // needAction={false}
                        actionInBottom
                        type="incoming_request"
                      />
                    )
                  })}
                </div>
              </>
            ) : (
              <div className={cn(s.empty_title)}>Нет входящих заявок</div>
            )}
          </div>
        </SwiperSlide>
        <SwiperSlide>
          {friendsRequest && friendsRequest!.outcoming_request.length > 0 ? (
            <>
              <div className={cn(s.friends_container)}>
                {friendsRequest!.outcoming_request.map((item: User) => {
                  return (
                    <FriendItem
                      // user={user}
                      // page_user={page_user}
                      item={item}
                      key={item.id}
                      isMyProfile={true}
                      // needAction={false}
                      actionInBottom
                      type="outcoming_request"
                    />
                  )
                })}
              </div>
            </>
          ) : (
            <div className={cn(s.empty_title)}>Нет исходящих заявок</div>
          )}
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default React.memo(UserFriendsRequestPage)
