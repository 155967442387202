/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import apiClient from 'application/data/apiClient'
import { PaymentProps } from 'application/domain/entity/payment/Payment'

// import ToastNotify from '../ToastNotify'

const allowedPaymentMethods = ['CARD', 'TOKENIZED_CARD']
const allowedCardNetworks = ['MASTERCARD', 'VISA']
let tokenizationParameters: any
// apiClient.get('/webapi/get_cloud').then((response) => {
//   tokenizationParameters = {
//     tokenizationType: 'PAYMENT_GATEWAY',
//     parameters: {
//       gateway: 'cloudpayments',
//       gatewayMerchantId: response.data,
//     },
//   }
// })

export const gpayBtnClick = ({ data, onStart, onSuccess, onError }: PaymentProps) => {
  const { auth_user } = data
  if (auth_user === null) {
    // ToastNotify('Вы не авторизованы!', 'error')
    onError('not auth')
    return false
  }

  onStart()

  const params = {
    amount: data.amount,
    wish: data.wish_id,
    type: data.type,
    comment: data.comment,
    to_balance: !!data.to_balance,
    onSuccess,
    onError,
  }
  onGooglePaymentButtonClicked(params)
}

const getGooglePaymentsClient = () => {
  return new google.payments.api.PaymentsClient({ environment: 'PRODUCTION' }) // enviroment PRODUCTION or TEST
}

// обработчик загрузки Google Pay API
window.onGooglePayLoaded = () => {
  const paymentsClient = getGooglePaymentsClient()
  // проверка устройства
  paymentsClient.isReadyToPay({ allowedPaymentMethods }).then((response: any) => {
    if (response.result) {
      localStorage.setItem('gpay', '1')
    } else {
      localStorage.setItem('gpay', '0')
    }
  })
}

// настройки
const getGooglePaymentDataConfiguration = () => {
  return {
    merchantId: 'BCR2DN6TTOZ7ZKJE', // выдается после регистрации в Google
    paymentMethodTokenizationParameters: tokenizationParameters,
    allowedPaymentMethods,
    cardRequirements: {
      allowedCardNetworks,
    },
  }
}

// информация о транзакции
const getGoogleTransactionInfo = (params: any) => {
  return {
    currencyCode: 'RUB',
    totalPriceStatus: 'FINAL',
    totalPrice: params.amount,
  }
}

// обработчик кнопки
const onGooglePaymentButtonClicked = (params: any) => {
  const paymentDataRequest: any = getGooglePaymentDataConfiguration()
  paymentDataRequest.transactionInfo = getGoogleTransactionInfo(params)

  const paymentsClient = getGooglePaymentsClient()
  paymentsClient
    .loadPaymentData(paymentDataRequest)
    .then((paymentData: any) => {
      processPayment(paymentData, params)
    })
    .catch(() => {
      params.onError('error')
    })
}

// обработка платежа
function processPayment(paymentData: any, params: any) {
  // localStorage.setItem('payment_prev_url', location.href)
  if (params.type === 'wish' || params.type === 'buy') {
    const data = {
      crypto: JSON.stringify(paymentData.paymentMethodToken.token),
      amount: params.amount,
      wish: params.wish,
      comment: params.comment,
      type: 'Google Pay',
      to_balance: !!params.to_balance,
    }
    gpayment(data, params)
  } else {
    const data = {
      crypto: JSON.stringify(paymentData.paymentMethodToken.token),
      amount: params.amount,
      order: params.wish,
      comment: params.comment,
      type: 'Google Pay',
    }
    gpayment(data, params)
  }
}

function gpayment(data: any, params: any) {
  // отправьте запрос на ваш сервер, а далее запросите API CloudPayments
  // для проведения оплаты
  //   localStorage.setItem('payment_prev_url', location.href)

  apiClient({
    method: 'POST',
    url: '/api/v1/payment',
    data,
  }).then((result) => {
    console.log(result)
    if (result.data.success || result.data.Success) {
      // оплата успешно завершена{
      params.onSuccess('success', result.data.success.id)
    } else if (result.data.Model) {
      // требуется 3-d secure
      // console.log('Пробуем 3ds')
      params.onSuccess('3ds', result.data)
    } else {
      params.onError('error', result.data.Model.CardHolderMessage)
    }
  })
}

export default gpayBtnClick
