import { useAppSelector } from 'application/domain/store/hooks'
import React from 'react'
import { Navigate } from 'react-router-dom'

type Props = {
  children: React.ReactElement
}

const PrivateRoute = ({ children }: Props) => {
  const isAuth = useAppSelector((state) => state.userReducer.user)

  if (!isAuth) {
    return <Navigate to="/" replace />
  }

  return children
}

export default React.memo(PrivateRoute)
