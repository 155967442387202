import apiClient from '../apiClient'

export const fetchOutcomePayments = async (pageParam?: string) => {
  const { data } = await apiClient.get(pageParam || '/api/v1/payment?outcome=true')
  return data
}

export const fetchPayments = async (pageParam?: string) => {
  const { data } = await apiClient.get(pageParam || '/api/v1/payment')
  return data
}

export const fetchPayment = async (id: number) => {
  const { data } = await apiClient.get(`/api/v1/payment/${id}`)
  return data
}

export const fetchPaymentByOrderId = async (id: string) => {
  const { data } = await apiClient.post(`/api/v1/payment/check/sber/${id}`)
  return data
}

export const fetchPaymentStatus = async (id: number) => {
  const { data } = await apiClient.post(`/api/v1/payment/status/${id}`)
  return data
}

export const fetchPayMethods = async () => {
  const { data } = await apiClient.get('/api/v1/payment/payment_systems')
  return data
}
