import { deleteWishRequest } from 'application/data/api/wish'
import queryClient from 'application/data/apiClient/queryClient'
import { useMutation } from '@tanstack/react-query'

export const deleteWish = (id: number) => {
  return deleteWishRequest(id)
}

export const DeleteWishMutation = (callback?: () => void) => {
  const mutation = useMutation(({ wish_id }: any) => deleteWishRequest(wish_id), {
    onMutate: async ({ wish_id, nickname, isIdea }) => {
      await queryClient.cancelQueries(['myWishlist'])
      await queryClient.cancelQueries(['ideas'])
      const previousData = queryClient.getQueryData<any>(['myWishlist'])
      const profilePrevData = queryClient.getQueryData<any>(['profile', nickname])
      const ideasPrevData = queryClient.getQueryData<any>(['ideas', nickname])
      if (previousData) {
        queryClient.setQueryData(['myWishlist'], {
          ...previousData,
          items: previousData.items.map((item: any) => {
            return {
              ...item,
              wish: item.wish.filter((item2: any) => item2.id !== wish_id),
            }
          }),
        })
      }
      if (isIdea) {
        if (ideasPrevData) {
          queryClient.setQueryData(['ideas', nickname], {
            ...ideasPrevData,
            items: ideasPrevData.items.map((item: any) => {
              return {
                ...item,
                wish: item.wish.filter((item2: any) => item2.id !== wish_id),
              }
            }),
          })
        }
        if (profilePrevData) {
          queryClient.setQueryData(['profile', nickname], {
            ...profilePrevData,
            wish_ideas: profilePrevData.wish_ideas - 1,
          })
        }
      }
      if (callback) {
        callback()
      }

      return { previousData, ideasPrevData, profilePrevData }
    },
    onError: (err, variables, context: any) => {
      if (context?.previousData) {
        queryClient.setQueryData(['myWishlist'], context.previousData)
      }
      if (context?.ideasPrevData) {
        queryClient.setQueryData(['ideas', variables.nickname], context.ideasPrevData)
      }
      if (context?.profilePrevData) {
        queryClient.setQueryData(['profile', variables.nickname], context.profilePrevData)
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['myWishlist'])
      queryClient.invalidateQueries(['profile'])
      queryClient.invalidateQueries(['ideas'])
    },
  })
  return mutation
}
