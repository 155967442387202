import React from 'react'
import cn from 'classnames'

import s from './ArticleLayoutContent.module.scss'

type Props = {
  children?: React.ReactNode
  title?: string
}

const ArticleLayoutContent = ({ children, title }: Props): React.ReactElement => {
  return (
    <div className={cn({ [s.content]: true, [s.content_mobile]: true })}>
      {typeof title === 'string' && <h1 className={s.contentTitle}>{title}</h1>}
      {children}
    </div>
  )
}

export default ArticleLayoutContent
