import React from 'react'
import Skeleton from 'react-loading-skeleton'

import s from '../PublicProfilePage.module.scss'

const ProfileLoaderPage = () => {
  return (
    <>
      <div style={{ padding: '2.3rem 3rem', backgroundColor: '#f7f7f7' }}>
        <div style={{ display: 'flex', marginBottom: '2.8rem' }}>
          <div style={{ width: 'calc(100% - 10rem)' }}>
            <Skeleton
              style={{
                display: 'inline-block',
                height: '29px',
                width: '100%',
                marginBottom: '10px',
              }}
            />
            <Skeleton
              style={{
                display: 'inline-block',
                height: '37px',
                width: '100%',
                marginBottom: '10px',
              }}
            />
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Skeleton
              style={{
                display: 'inline-block',
                height: '76px',
                width: '76px',
                borderRadius: '50%',
              }}
            />
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <Skeleton
              style={{
                display: 'inline-block',
                height: '24px',
                width: '70%',
              }}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Skeleton
              style={{
                display: 'inline-block',
                height: '24px',
                width: '100%',
              }}
            />
          </div>
        </div>
      </div>
      <div className={s.wishlist_container}>
        <Skeleton
          style={{
            display: 'inline-block',
            height: '30px',
            width: 'calc(100% - 50px)',
            margin: '0 25px',
          }}
        />

        <div style={{ display: 'block', padding: '1rem 2.5rem 2.5rem' }}>
          <Skeleton
            style={{
              border: '1px solid transparent',
              height: '186px',
              marginBottom: '25px',
            }}
          />
        </div>
        <Skeleton
          style={{
            display: 'inline-block',
            height: '30px',
            width: 'calc(100% - 50px)',
            margin: '0 25px 10px',
          }}
        />
        <Skeleton
          style={{
            display: 'inline-block',
            height: '78px',
            width: 'calc(100% - 60px)',
            margin: '5px 30px 36px',
          }}
        />
        <Skeleton
          style={{
            display: 'inline-block',
            height: '78px',
            width: 'calc(100% - 60px)',
            margin: '5px 30px 36px',
          }}
        />
      </div>
    </>
  )
}

export default React.memo(ProfileLoaderPage)
