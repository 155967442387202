/* eslint-disable react/no-danger */
import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import ProgressBar from 'react-customizable-progressbar'
import { ArticleLayout, ArticleLayoutContent } from 'application/presentation/common/ArticleLayout'
import ArticleContent from 'application/presentation/common/ArticleContent'
import { Title } from 'react-head'
import { HeaderMobileTitle } from 'application/presentation/common/Header/Header'
import { useAppSelector } from 'application/domain/store/hooks'

import s from './AboutPage.module.scss'

const AboutPage = (): React.ReactElement => {
  const { isAuth } = useAppSelector(({ userReducer }) => ({ isAuth: userReducer.user }))

  const handleChatClick = () => {
    if (typeof window.jivo_api !== 'undefined') {
      window.jivo_api.open()
    }
  }

  // TODO: add header title
  return (
    <ArticleLayout>
      <ArticleLayoutContent>
        <Title>О проекте</Title>
        <HeaderMobileTitle.Source>О проекте</HeaderMobileTitle.Source>
        <ArticleContent className={s.viewMobile}>
          <div className={s['block-first']}>
            <div className={s.left}>
              <div className={s.subtitle}>
                Wishmen — это новый способ <br /> исполнения ваших желаний.
              </div>
              <div className={s.text}>
                Здесь каждый может создавать собственный вишлист, получать подарки и донатить на
                желания тем, кто дорог. <br />
                <br /> Мы предлагаем более 100 тысяч товаров и услуг от проверенных партнеров: от
                крупных интернет-магазинов до локальных брендов. Все, чтобы вы нашли то самое
                желание в нашем каталоге и исполнили свою мечту!
              </div>
            </div>
            <div className={s.right}>
              <div className={s.circlestat}>
                <div className={s.progressBar}>
                  <ProgressBar
                    radius={100}
                    strokeWidth={15}
                    trackStrokeWidth={15}
                    pointerStrokeWidth={0}
                    progress={25}
                    transition="1s ease-in-out"
                    trackTransition="0"
                    strokeColor="#FF3E6C"
                    initialAnimation
                  />
                </div>
                <div className={cn(s['user-circle'], s.first)}>
                  <img src="/images/aboutPage/paren.png" alt="Парень" />
                  <div className={s.sum}>+ 200 Р</div>
                </div>
                <div className={cn(s['user-circle'], s.second)}>
                  <img src="/images/aboutPage/madama.png" alt="Девушка" />
                  <div className={s.sum}>+ 700 Р</div>
                </div>

                <div className={s['circle-content']}>
                  <img src="/images/aboutPage/image6.png" alt="Apple AirPods Pro" />
                </div>
              </div>
              <div className={s.subinfo}>
                Желание вашего друга:
                <br />
                Apple AirPods Pro
              </div>
            </div>
          </div>
          <div className={s.separator} />
          <div className={s['block-second']}>
            <div className={s.left}>
              <div className={s.title}>Делаем желания ближе</div>
            </div>
            <div className={s.right}>
              <div className={s.text}>
                Мы знаем, как приятно получать и дарить подарки. Именно поэтому соединили в одном
                сервисе все лучшее, чтобы вы с легкостью могли радовать самых близких, и, конечно,
                самих себя.
              </div>
            </div>
          </div>
          <div className={s.separator} />

          <div className={s['block-third']}>
            <div className={s.left}>
              <img src="/images/aboutPage/iphone-wishlist.png" alt="Скриншот приложения" />
            </div>
            <div className={s.right}>
              <div className={s.title}>Все в одном сервисе</div>
              <div className={s.subtitle}>
                Добавляйте желание из каталога в вишлист, делитесь им с друзьями и собирайте донаты
                на мечту — без поисков и лишней суеты, напрямую через платформу.
              </div>
              <div className={s['btn-block']}>
                <Link to="/shop" className={cn(s.btn, s['red-btn'])}>
                  Каталог желаний
                </Link>
              </div>
            </div>
          </div>

          <div className={cn(s['block-third'], s.reverse)}>
            <div className={s.left}>
              <img
                className={s['house-img']}
                src="/images/aboutPage/house-model.png"
                alt="План квартиры"
              />
            </div>
            <div className={s.right}>
              <div className={s.title}>Без лимитов и границ</div>
              <div className={s.subtitle}>
                Для вашей мечты не может быть ограничений. Желайте что угодно — от смартфона до
                квартиры — и получайте подарок, где бы вы не находились, с доставкой или
                самовывозом.
              </div>
              <div className={s['btn-block']}>
                <Link to={isAuth ? '/wishlist' : '/login'} className={cn(s.btn, s['red-btn'])}>
                  Создать свой список желаний
                </Link>
              </div>
            </div>
          </div>

          <div className={s['block-fourth']}>
            <div className={s.title}>Просто и удобно</div>
            <div className={s.subtitle}>
              В Wishmen все устроено так, чтобы мечты воплощались просто. Для легкого выбора —
              фильтры и умный поиск, для вдохновения — подборка идей, для удобства — разнообразные
              системы оплаты.
            </div>
            <div className={s['btn-block']}>
              <a href="/collections" className={cn(s.btn, s['white-btn'])}>
                Готовые подборки
              </a>
            </div>
          </div>

          <div className={cn(s.separator, s.high)} />

          <div className={s['block-five']}>
            <div className={s.title}>Возможности</div>
            <div className={s.subtitle}>
              Раньше вопрос о подарках и мечтах был дилеммой. С Wishmen все желанное становится
              ближе — в одном сервисе, а вам остается только добавить то, что нравится!
            </div>

            <div className={s['flex-cards']}>
              <div className={s.card}>
                <div className={s['img-block']}>
                  <img src="/images/aboutPage/chel.svg" alt="Пользователь" />
                </div>
                <div className={s.title}>Для пользователей</div>
                <div className={s.subtitle}>
                  <p>Что подарить?</p>
                  <p>Как накопить на путешествие?</p>
                  <p>О каком подарке мечтает мама?</p>
                  <p>Как отправить подарок в другой город?</p>
                </div>
                <div className={s['btn-block']}>
                  <Link to={isAuth ? '/wishlist' : '/login'} className={cn(s.btn, s['red-btn'])}>
                    Зарегистрироваться
                  </Link>
                </div>
              </div>
              <div className={s.card}>
                <div className={s['img-block']}>
                  <img src="/images/aboutPage/3chela.svg" alt="Партнер" />
                </div>
                <div className={s.title}>Для партнеров</div>
                <div className={s.subtitle}>
                  <p>Какой канал продаж выбрать?</p>
                  <p>Как узнать, чего хотят клиенты и пользователи?</p>
                  <p>Что предложить, чтобы усилить свой бренд?</p>
                  <p>Как доставить продукт до покупателя?</p>
                </div>
                <div className={s['btn-block']}>
                  <Link to="/partners" className={cn(s.btn, s['white-btn'])}>
                    Оставить заявку
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className={cn(s.separator, s.high)} />

          <div className={s['block-six']}>
            <div className={s.title}>Мы становимся лучше</div>
            <div className={s.subtitle}>
              Каждый день мы стремимся стать для вас еще удобнее — и ваша обратная связь делает нас
              лучше. Формируйте вишлисты, получайте желания и оставляйте отзывы команде Wishmen там,
              где удобно: в соцсетях, по телефону или на почте pr@wish.men!
            </div>
            <div className={s['links-block']}>
              <Link to="/documents" className={s['link-item']}>
                Юридические данные
              </Link>
              <Link to="/contacts" className={s['link-item']}>
                Мы в социальных сетях
              </Link>
              <button className={s['link-item']} type="button" onClick={handleChatClick}>
                Открыть онлайн-чат
              </button>
            </div>
          </div>
        </ArticleContent>
      </ArticleLayoutContent>
    </ArticleLayout>
  )
}

export default React.memo(AboutPage)
