import BottomSheet from 'application/presentation/common/ModalWindows/BottomSheet'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import s from './ReferalInfoBS.module.scss'

type Props = {
  opened: boolean
  onClose: () => void
}

const ReferalInfoBS = (props: Props) => {
  const { t } = useTranslation()
  const { opened, onClose } = props
  const [sheetOpened, setSheetOpened] = useState(opened)
  const handleSheetClosed = () => {
    setSheetOpened(false)

    if (opened) {
      onClose()
    }
  }

  useEffect(() => {
    setSheetOpened(opened)
  }, [opened])

  return (
    <BottomSheet opened={sheetOpened} onSheetClosed={handleSheetClosed}>
      <div className={s.content}>
        <div className={s.headline}>{t('referral_page.info_modal.title')}</div>
        {/* <img className={s.background_star} src="/images/icons/gray_star.svg" alt="" /> */}
        <div className={s.img_block}>
          <img src="/images/referral_info.png" alt="" />
        </div>
        <div
          className={s.title}
          dangerouslySetInnerHTML={{ __html: t('referral_page.info_modal.text1') }}
        />
        <div
          className={s.text}
          dangerouslySetInnerHTML={{ __html: t('referral_page.info_modal.text2') }}
        />
      </div>
    </BottomSheet>
  )
}

export default React.memo(ReferalInfoBS)
