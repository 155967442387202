import React, { useState } from 'react'
import { VelocityTransitionGroup } from 'velocity-react'

import s from './QuestionBlock.module.scss'

const QuestionItem = ({ item }: any) => {
  const [active, setActive] = useState(false)

  return (
    <div className={s.question}>
      <div className={s.wrapper} onClick={() => setActive(!active)}>
        <div className={`${s.quest} ${active ? s.active : ''}`}>{item.question}</div>
      </div>
      <VelocityTransitionGroup
        enter={{ animation: 'slideDown' }}
        leave={{ animation: 'slideUp' }}
        duration={200}
      >
        {active && (
          <div dangerouslySetInnerHTML={{ __html: item.answer }} className={`${s.answer}`} />
        )}
      </VelocityTransitionGroup>
    </div>
  )
}

const QuestionBlock = () => {
  const arr = [
    {
      id: 1,
      question: 'Что такое Wishmen?',
      answer:
        'Это сервис по созданию списков желаний. Представляет собой сайт и приложение с каталогом товаров и услуг из более чем 100 тыс. наименований. С его помощью пользователи создают списки желаний (вишлисты) из каталога сервиса, могут делиться ими, а также перечислять суммы (донаты) на желания других.',
    },
    {
      id: 2,
      question: 'Как я могу пригласить друга на платформу?',
      answer:
        'Перейдите в личный кабинет в раздел “Мои друзья”. Указав в поисковой строке номер телефона пользователя или его имя, вы можете проверить не зарегистрирован ли он в системе. Если пользователь с данным номером в системе отсутствует, вы можете пригласить его нажав на кнопку “Пригласить” или на значок “+” в правом верхнем углу.',
    },
    {
      id: 3,
      question: 'В каких регионах работает Wishmen?',
      answer:
        'Мы запустились совсем недавно и работаем над улучшением платформы. Сейчас Wishmen доступен в Чебоксарах (Чувашская Республика), но совсем скоро мы добавим и другие регионы для возможности заказов . Следите за нашими новостями.',
    },
    {
      id: 4,
      question: 'Что такое желание?',
      answer:
        'Желание - это любой товар или услуга, представленный в разделе “Каталог”, который вы можете добавить себе в список.',
    },
    {
      id: 5,
      question: 'Как добавить желание?',
      answer:
        'Рядом с каждой позицией в каталоге желаний есть значок “+”. Нажав на него, выбранная позиция добавится в список ваших желаний.',
    },
    {
      id: 6,
      question: 'Сколько желаний я могу добавить в wishlist(вишлист)?',
      answer: 'Ограничений по количеству желаний нет.',
    },
    {
      id: 7,
      question: 'Что делать после того, как желание будет исполнено?',
      answer: `После того, как ваше желание исполнится, нужно оформить его получение. <br />
      1. Нажмите Получить желание, затем заполните необходимые данные. <br />
      2. Выберите способ доставки: <br />
      - в пункт выдачи <br />
      - доставку курьером на удобный вам адрес. <br /> 
      При выборе доставки курьером может взиматься дополнительная плата.`,
    },
    {
      id: 8,
      question: 'Как осуществляется возврат, если товар пришел не качественный или бракованный?',
      answer: `1. Верните бракованный/некачественный товар в пункт выдачи. <br />
      2. Будет произведена проверка товара. <br />
      3. В случае подтверждения повреждения или брака деньги будут возвращены обратно на счёт Wishmen, либо произведен обмен товара на новый.`,
    },
  ]

  return (
    <div className={s.container}>
      <div className={s.inner_content}>
        <div className={s.title}>Вопросы и ответы</div>
        <div className={s.question_block}>
          {arr.map((item) => {
            return <QuestionItem item={item} key={item.id} />
          })}
        </div>
      </div>
    </div>
  )
}

export default React.memo(QuestionBlock)
