import apiClient from '../apiClient'

export const fetchUser = async (nickname?: string) => {
  const { data } = await apiClient.get(`/api/v1/${nickname}`)
  return data
}

export const fetchSession = async (token?: string) => {
  if (token) {
    apiClient.defaults.headers.Authorization = `Bearer ${token}`
  }
  const { data } = await apiClient.get('/api/v1/sessions')
  return data ? data.success : undefined
}

export const fetchSessionByToken = async (token?: string) => {
  if (token) {
    apiClient.defaults.headers.Authorization = `Bearer ${token}`
    const { data } = await apiClient.get('/api/v1/sessions')
    return data ? data.success : null
  }
  return null
}

export const loginRequest = async (phone: string, password?: string) => {
  const authData = password
    ? {
        phone,
        password,
      }
    : { phone }
  const { data } = await apiClient.post('/api/v1/sessions', authData)
  return data
}

export const logoutRequest = async () => {
  const { data } = await apiClient.delete('/api/v1/sessions')
  return data
}
