import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import apiClient from 'application/data/apiClient'
import { ArticleLayout, ArticleLayoutContent } from 'application/presentation/common/ArticleLayout'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import ArticleContent from 'application/presentation/common/ArticleContent'
import { Title } from 'react-head'
import { MetaDescriptionFaq, MetaTitleFaq, MetaOG } from 'application/presentation/app/MetaTags'
import { useTranslation } from 'react-i18next'
import Loader from 'application/presentation/common/Loader'
import ContentLayout from 'application/presentation/common/ContentLayout'

import s from './FaqPage.module.scss'
import FaqCategories from './FaqCategories'
import FaqCategory, { Category } from './FaqCategory'

const fetchFaqCategories = async (): Promise<Category> => {
  const response = await apiClient.get<Category>('/api/v1/article/category/3')

  return response.data
}

const FaqPage = (): React.ReactElement => {
  const { t } = useTranslation()
  const { isLoading, data } = useQuery(['faqCategories'], fetchFaqCategories)

  const reqCount = data && data.subcategories ? data.subcategories.length : 0
  const [categIsLoading, setCategIsLoading] = useState<boolean>(true)
  const [subCategories, setSubcategories] = useState()

  useEffect(() => {
    const subCateg: any = []
    if (data) {
      data.subcategories.map((item: any) => {
        apiClient.get(`/api/v1/article/category/${item.id}`).then((response) => {
          subCateg.push(response.data)
          if (subCateg.length === reqCount) {
            setSubcategories(subCateg)
            setCategIsLoading(false)
          }
        })
        return true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleChatClick = () => {
    if (typeof window.jivo_api !== 'undefined') {
      window.jivo_api.open()
    }
  }

  if (isLoading || categIsLoading) {
    return (
      <>
        <HeaderMobileTitle.Source>{t('faq_page.title')}</HeaderMobileTitle.Source>
        <Loader />
      </>
    )
  }

  return (
    <ContentLayout>
      <MetaTitleFaq />
      <MetaDescriptionFaq />
      <MetaOG
        title={t('faq_page.meta.title') || ''}
        description={t('faq_page.meta.description') || ''}
      />
      <HeaderMobileTitle.Source>{t('faq_page.title')}</HeaderMobileTitle.Source>
      <ArticleLayout>
        <ArticleLayoutContent>
          <Title>{t('faq_page.title')}</Title>
          <ArticleContent className={s.viewMobile}>
            <div className={s.headline}>{t('general.categories_text')}</div>
            {data?.subcategories ? (
              <FaqCategories>
                {data.subcategories.map((category, i) => (
                  <FaqCategory
                    key={category.id}
                    category={category}
                    subCategory={subCategories![i]}
                  />
                ))}
              </FaqCategories>
            ) : null}
          </ArticleContent>
        </ArticleLayoutContent>
      </ArticleLayout>
    </ContentLayout>
  )
}

export default React.memo(FaqPage)
