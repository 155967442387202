import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Mention, MentionsInput } from 'react-mentions'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { useAppSelector } from 'application/domain/store/hooks'
import { SendCommentMutation } from 'application/domain/useCase/comments/commentsActions'

import s from './SendCommentField.module.scss'

type Props = {
  wish_id: number
  mentionedUserData: any
  commentText: string
  setCommentText: Function
  commentPlayText: string
  setCommentPlayText: Function
  answerComment?: Function
  white?: boolean
  mentionsRef: any
}

const SendCommentField = ({
  wish_id,
  mentionedUserData,
  white,
  commentPlayText,
  commentText,
  setCommentPlayText,
  setCommentText,
  mentionsRef,
}: Props) => {
  const { t } = useTranslation()
  const { user } = useAppSelector(({ userReducer }) => ({ user: userReducer.user }))

  const [commentMentions, setCommentMentions] = useState<any>([])
  const [mentionOpened, setMentionOpened] = useState<boolean>(false)
  const toast = ToastNotify

  const sendMutation = SendCommentMutation(wish_id, () => {
    toast('Комментарий добавлен!')
  })

  const inputComment = useCallback(
    (e: any, commentPlayTxt?: string) => {
      setCommentText(e.target.value)
      if (commentPlayTxt) setCommentPlayText(commentPlayTxt)
    },
    [setCommentPlayText, setCommentText],
  )

  const sendComment = useCallback(
    (mentions?: any[]) => {
      if (commentPlayText !== '') {
        sendMutation.mutate({ commentText: commentPlayText, mentions })
        setCommentText('')
        setCommentPlayText('')
      }
    },
    [commentPlayText, sendMutation, setCommentPlayText, setCommentText],
  )

  return (
    <div className={s.input_comment}>
      <div className={s.user_img}>
        <img
          loading="lazy"
          alt=""
          src={user && user.photo ? user.photo : 'https://cdn.wish.men/user_male_default.png'}
        />
      </div>
      {/* @ts-ignore */}
      <MentionsInput
        className={`${s.comment_input} resizable`}
        placeholder="Введите комментарий"
        value={commentText}
        maxLength={400}
        onChange={(e: any, newVal: string, newPlayTextVal: string, mentions: any[]) => {
          inputComment(e, newPlayTextVal)
          if (mentions) setCommentMentions(mentions)
        }}
      >
        <Mention
          trigger="@"
          className={s.mentions}
          appendSpaceOnAdd
          data={mentionedUserData}
          renderSuggestion={(entry: any) => {
            setMentionOpened(true)
            const mentUser = mentionedUserData.filter((item: any) => item.id === entry.id)
            return (
              <div>
                <img src={mentUser && mentUser[0].photo ? mentUser[0].photo : ''} alt="" />
                <span>{entry.display}</span>
              </div>
            )
          }}
          // @ts-ignore
          displayTransform={(id: number, name: string) => {
            return `@${name}`
          }}
        />
      </MentionsInput>
      <button
        type="button"
        className={s.send_button}
        onClick={() => {
          sendComment(commentMentions)
        }}
      >
        <img src="/images/icons/white-send-button.svg" alt="" />
      </button>
    </div>
  )
}

export default React.memo(SendCommentField)
