import { useQuery } from '@tanstack/react-query'
import { fetchDeliveryMethods } from 'application/data/api/delivery'
import { useAppSelector } from 'application/domain/store/hooks'

export const useDeliveryMethods = (product_id: number) => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const query = useQuery(
    ['delivery_methods', product_id, user ? user.geolocation : ''],
    () => fetchDeliveryMethods(product_id),
    {
      enabled: product_id !== 0,
    },
  )
  return query
}
