import React, { useContext, useEffect, useMemo } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { HeaderMobileRightButton, HeaderMobileTitle } from 'application/presentation/common/Header'
import InfiniteScroll from 'react-infinite-scroll-component'
import Loader from 'application/presentation/common/Loader'
import Button from 'application/presentation/common/ActionComponents/Button'
import { useReferrals } from 'application/domain/useCase/referrals/getReferrals'
import { useReferralBalanceInfinite } from 'application/domain/useCase/balance/getBalance'
import { useAppSelector } from 'application/domain/store/hooks'
import checkDate from 'application/presentation/utils/checkDate'
import { Balance } from 'application/domain/entity/balance/Balance'

import TopBlock from './TopBlock'
import s from './BalanceAndRefPage.module.scss'
import BalanceItem from './BalanceItem'
import ReferalInfoBS from './ReferalInfoBS'
import ReferalUsersBS from './ReferalUsersBS'

type Props = {
  t: any
}

const ReferalPage = ({ t }: Props) => {
  const { token, user, refLink } = useAppSelector(({ userReducer }) => ({
    token: userReducer.token,
    user: userReducer.user,
    refLink: userReducer.refLink,
  }))
  const { isLoading, data, fetchNextPage, hasNextPage } = useReferralBalanceInfinite(token || '')

  const { pathname } = useLocation()
  const navigate = useNavigate()
  const BottomSheetOpenedInfo = pathname.indexOf('/info') !== -1
  const BottomSheetOpenedUsers = pathname.indexOf('/list') !== -1

  const { data: refUsersData, isLoading: refUsersIsLoading } = useReferrals()

  const handleBottomSheetClose = () => {
    navigate(pathname.replace(/\/$/g, '').split('/').slice(0, -1).join('/'))
  }

  const shareProfile = () => {
    const name = user ? (user.name ? user.name : user.nickname) : ''
    try {
      navigator.share({
        title: name,
        url: `${window.location.origin}/${user?.nickname}?ref=${refLink}`,
        text: `Посмотрите желания ${name} на Wish.men`,
        // image: user.photo,
      })
    } catch (error) {
      console.log('Error sharing', error)
    }
  }

  const allData = useMemo(() => {
    return data && data.pages ? data.pages.flatMap((page: any) => page.transactions.data) : []
  }, [data])

  const totalSumData = useMemo(() => {
    return data && data.pages
      ? {
          getLast7: data.pages[0].getLast7,
          getLast30: data.pages[0].getLast30,
          total: data.pages[0].total,
        }
      : {
          getLast7: 0,
          getLast30: 0,
          total: 0,
        }
  }, [data])

  const today = useMemo(() => {
    return new Date()
  }, [])
  const todayArr = useMemo(() => {
    return allData.filter((item: Balance) => {
      return checkDate('today', item.created_at)
    })
  }, [allData])
  const yesterdayArr = useMemo(() => {
    return allData.filter((item: Balance) => {
      return checkDate('yesterday', item.created_at)
    })
  }, [allData])
  const otherdayArr = useMemo(() => {
    return allData.filter((item: Balance) => {
      return checkDate('otherday', item.created_at)
    })
  }, [allData])

  if (isLoading || refUsersIsLoading) {
    return (
      <div className={s.content}>
        <HeaderMobileTitle.Source>Партнерская программа</HeaderMobileTitle.Source>
        <Loader />
      </div>
    )
  }
  return (
    <>
      {/* <MetaTitleReferral />
      <MetaDescriptionReferral /> */}
      {/* <MetaOG
        title="Партнерская программа Wishmen"
        description="Приглашайте друзей по реферальной ссылке и получайте бонусы!"
      /> */}
      <div className={s.content}>
        <HeaderMobileTitle.Source>Партнерская программа</HeaderMobileTitle.Source>
        <HeaderMobileRightButton.Source>
          <NavLink to="/referral/info" className={s.header_btn}>
            <img src="/images/icons/info-icon.svg" alt="info" />
          </NavLink>
        </HeaderMobileRightButton.Source>
        <TopBlock
          type="referral"
          totalSum={totalSumData.total}
          getLast7={totalSumData.getLast7}
          getLast30={totalSumData.getLast30}
          t={t}
        />

        <div className={s.ref_btn}>
          {!refUsersData?.quantity ? (
            <div className={s.ref_text}>У вас нет рефералов</div>
          ) : (
            <NavLink to="/referral/list">Список рефералов</NavLink>
          )}
        </div>

        <div className={s.red_title}>
          <div className={s.text}>{t('referral_page.for_invite')}</div>
        </div>

        <Button
          title={t('referral_page.share_ref_link')}
          className={s.share_button_block}
          onClick={() => {
            shareProfile()
          }}
          color="red"
        />

        <div className={s.red_title}>
          <div className={s.text}>{t('referral_page.reward_history')}</div>
        </div>
        <div className={s.content_block}>
          <InfiniteScroll
            className={s.history_content_block}
            dataLength={allData.length}
            next={fetchNextPage}
            style={{ display: 'flex', flexDirection: 'column' }}
            hasMore={!!hasNextPage}
            loader={<Loader />}
            scrollableTarget="page_content"
          >
            {allData.length === 0 ? (
              <div className={s.empty_history}>{t('referral_page.empty')}</div>
            ) : (
              <>
                {todayArr.length ? (
                  <div className={s.history_items_block}>
                    <div className={s.title}>{t('general.today')}</div>
                    <div className={s.items_container}>
                      {todayArr.map((item: Balance) => {
                        return <BalanceItem data={item} t={t} key={item.id} />
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {yesterdayArr.length ? (
                  <div className={s.history_items_block}>
                    <div className={s.title}>{t('general.yesterday')}</div>
                    <div className={s.items_container}>
                      {yesterdayArr.map((item: Balance) => {
                        return <BalanceItem data={item} t={t} key={item.id} />
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {otherdayArr.length ? (
                  <div className={s.history_items_block}>
                    <div className={s.title}>{t('general.earlier')}</div>
                    <div className={s.items_container}>
                      {otherdayArr.map((item: Balance) => {
                        return <BalanceItem data={item} t={t} key={item.id} />
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </>
            )}
          </InfiniteScroll>
        </div>
        <ReferalInfoBS opened={BottomSheetOpenedInfo} onClose={handleBottomSheetClose} />
        <ReferalUsersBS opened={BottomSheetOpenedUsers} onClose={handleBottomSheetClose} />
      </div>
    </>
  )
}

export default React.memo(ReferalPage)
