import React from 'react'

import s from './CloseButton.module.scss'

type Props = {
  containerClassName?: string
  onClick?: Function
}

const CloseButton = ({ containerClassName, onClick }: Props) => {
  return (
    <div
      className={`${s.container} ${containerClassName || ''}`}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1.67773"
          y1="17.5858"
          x2="17.2635"
          y2="2"
          stroke="#D4D4D4"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <line
          x1="17.2635"
          y1="17.6777"
          x2="1.67773"
          y2="2.09195"
          stroke="#D4D4D4"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </div>
  )
}

export default React.memo(CloseButton)
