/* eslint-disable no-case-declarations */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Sheet, PageContent } from 'framework7-react'
import { useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import Loader from 'application/presentation/common/Loader'
import ToastNotify from 'application/presentation/app/ToastNotify'
import { useAppSelector } from 'application/domain/store/hooks'
import { useUser } from 'application/domain/useCase/user/getUser'
import { useProduct } from 'application/domain/useCase/product/getProduct'
import useWish from 'application/domain/useCase/wish/getWish'

import style from '../MW.module.scss'

import s from './ShareMW.module.scss'

const ShareMW = () => {
  const { user } = useAppSelector(({ userReducer }) => ({
    user: userReducer.user,
  }))
  const { hash, search } = useLocation()
  const navigate = useNavigate()
  const opened = useMemo(() => {
    return hash.indexOf('#share') !== -1
  }, [hash])

  const refLink = useMemo(() => {
    return user && user.ref_hash ? user.ref_hash : ''
  }, [user])

  const shareType = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const type = searchParams.get('sharetype')
    return type ? type : undefined
  }, [search])

  const shareDataFromParams = useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const nickname = searchParams.get('nickname')
    const prodId = searchParams.get('product_id')
    const wishId = searchParams.get('wish_id')
    return {
      nickname: nickname ? nickname : undefined,
      product_id: prodId ? parseInt(prodId, 10) : 0,
      wish_id: wishId ? parseInt(wishId, 10) : 0,
    }
  }, [search])

  const closeMW = useCallback(() => navigate(-1), [navigate])

  const { data: profileData, isLoading: profileIsLoading } = useUser(shareDataFromParams.nickname)
  const { data: productData, isLoading: productIsLoading } = useProduct(
    shareDataFromParams.product_id,
  )
  const { data: wishData, isLoading: wishIsLoading } = useWish(shareDataFromParams.wish_id)

  const userCategories = useMemo(() => {
    return profileData
      ? profileData.wish.items.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      : [{ label: 'Мои желания', value: 0 }]
  }, [profileData])

  const [selectedCategory, setSelectedCategory] = useState<any>()

  useEffect(() => {
    if (userCategories) {
      setSelectedCategory(userCategories[0].value)
    }
  }, [userCategories])

  const getShareData = useCallback(
    (shareData: any) => {
      let title
      let url
      let image
      switch (shareType) {
        case 'category':
          const categ = _.find(userCategories, { value: selectedCategory })
          title = `Категория "${categ ? categ.label : ''}" пользователя ${
            shareData.name ? shareData.name : shareData.nickname
          }`
          url = `${location.origin}/${shareData.nickname}${`?ref=${refLink}${
            categ && categ.value !== -2 ? `&category=${categ.value}` : ''
          }`}`
          image = shareData.photo
            ? shareData.photo
            : 'https://cdn.wish.men/icons/product-without-photo.png'
          break
        case 'wish':
          title = `Желание ${
            shareData.user.name ? shareData.user.name : shareData.user.nickname
          } - ${shareData.name}`
          url = `${location.origin}/${shareData.user.nickname}/${shareData.id}?ref=${refLink}`
          image = shareData.photo
          break
        case 'product':
          title = `Посмотрите ${shareData[0].name} на Wish.men`
          url = `${location.origin}${location.pathname}?ref=${refLink}`
          image = shareData[0].photo ? shareData[0].photo[0] : ''
          break
        case 'idea':
          title = `Посмотрите идеи на Wish.men`
          url = `${location.origin}/${shareData.nickname}/ideas/${
            wishData ? wishData.id : 0
          }?ref=${refLink}?ref=${refLink}`
          image = wishData ? wishData.photo : 'https://cdn.wish.men/icons/product-without-photo.png'
          break
        case 'ideas':
          title = `Посмотрите идеи на Wish.men`
          url = `${location.origin}/${shareData.nickname}/ideas?ref=${refLink}`
          image = shareData.photo
            ? shareData.photo
            : 'https://cdn.wish.men/icons/product-without-photo.png'
          break
        case 'article':
          title = `Посмотрите ${shareData.title} на Wish.men`
          url = `${location.origin}/collections/${shareData.article_category_id}/${shareData.slug}?ref=${refLink}`
          image = shareData.preview
          break

        case 'profile':
          title = `Посмотрите желания ${
            shareData.name ? shareData.name : shareData.nickname
          } на Wish.men`
          url = `${location.origin}/${shareData.nickname}?ref=${refLink}`
          image = shareData.photo
          break

        case 'santa':
          title = `Привет! Присоединяйся в игру "Тайный Санта", вместе веселее!`
          url = `${location.origin}/secret_santa?ref=${refLink}`
          break

        default:
          title = ''
          url = ''
          image = ''
          break
      }
      const data = {
        title,
        url,
        image,
      }
      return data
    },
    [refLink, selectedCategory, shareType, userCategories, wishData],
  )

  const data = useMemo(() => {
    switch (shareType) {
      case 'product':
        if (productData) {
          return getShareData(productData)
        }
        break
      case 'article':
        break
      case 'wish':
        if (wishData) {
          return getShareData(wishData)
        }
        break
      default:
        if (profileData) {
          return getShareData(profileData)
        }
        break
    }
  }, [shareType, profileData, getShareData, productData, wishData])

  const share = useCallback(() => {
    return navigator.share({
      url: data?.url,
      title: data?.title,
    })
  }, [data])

  return (
    <div>
      <Sheet
        className={`${style.sheet_modal} ${style.auto_height}`}
        swipeToClose
        backdrop
        opened={opened}
        onSheetClosed={() => {
          if (location.hash.indexOf('#share') !== -1) {
            closeMW()
          }
        }}
      >
        <div
          className={`${style.top_close_button} top-close-button`}
          onClick={() => {
            closeMW()
          }}
        >
          <div />
        </div>
        <PageContent className={s.wishBS_page_content}>
          {(shareType === 'product' && productIsLoading) ||
          ((shareType === 'idea' || shareType === 'wish') && wishIsLoading) ||
          (shareType !== 'product' && profileIsLoading) ? (
            <Loader />
          ) : data ? (
            <>
              <div className={s.container}>
                <div className={`${s.item_wrapper} ${s.item_wrapper_half}`}>
                  <div
                    className={s.item}
                    onClick={() => {
                      navigator.clipboard.writeText(data.url)
                      ToastNotify('Ссылка скопирована!')
                    }}
                  >
                    <div className={s.icon}>
                      <div className={s.icon_inner}>
                        <img src="/images/icons/copy_gray_icon.svg" alt="" />
                      </div>
                    </div>
                    <div className={s.item_txt}>Копировать</div>
                  </div>
                </div>
                <div className={`${s.item_wrapper} ${s.item_wrapper_half}`}>
                  <div className={s.item} onClick={() => share()}>
                    <div className={s.icon}>
                      <div className={s.icon_inner}>
                        <img src="/images/icons/share_gray_icon.svg" alt="" />
                      </div>
                    </div>
                    <div className={s.item_txt}>Отправить</div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>Нет данных</div>
          )}
        </PageContent>
      </Sheet>
    </div>
  )
}

export default React.memo(ShareMW)
