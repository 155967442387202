import React from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import cn from 'classnames'

import s from './MenuItem.module.scss'

type Props = NavLinkProps

const MenuItem = (props: Props): React.ReactElement => {
  return (
    <NavLink
      className={cn({
        [s.menuItemMobile]: true,
      })}
      // activeClassName={s.menuItem_active}
      {...props}
    />
  )
}

export default MenuItem
