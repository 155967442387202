import { InputLabel, InputPhone } from 'application/presentation/common/InputComponents'
import { HeaderMobileTitle } from 'application/presentation/common/Header'
import Loader from 'application/presentation/common/Loader'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import ToastNotify from 'application/presentation/app/ToastNotify'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  confirmOrder,
  createOrder,
  getDeliveryData,
} from 'application/presentation/common/Payments/Order'
import Moment from 'react-moment'
import { Button, PayButtons, PaySelector } from 'application/presentation/common/ActionComponents'
import { CardForm, ErrorMsg, OffertaInfo } from 'application/presentation/common/uiComponents'
import bankPay from 'application/presentation/common/Payments/payFunctions/BankPayment'
import apayBtnClick from 'application/presentation/common/Payments/payFunctions/PaymentApplePay'
import gpayBtnClick from 'application/presentation/common/Payments/payFunctions/PaymentGpay'
import balanceBtnClick from 'application/presentation/common/Payments/payFunctions/PaymentBalance'
import ThreeDsFrame from 'application/presentation/common/Payments/3dsStage/ThreeDsFrame'
import queryClient from 'application/data/apiClient/queryClient'
import { HeaderFooterContext } from 'application/presentation/app/HeaderFooter'
import { MetaDescriptionOrder, MetaTitleOrder } from 'application/presentation/app/MetaTags'
import { useAppSelector } from 'application/domain/store/hooks'
import useWish from 'application/domain/useCase/wish/getWish'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeliveryMethods } from 'application/domain/useCase/delivery/getDelivery'
import { PaymentMethodType, PaymentProps } from 'application/domain/entity/payment/Payment'
import { Order } from 'application/domain/entity/order/Order'

import { OrderResultContent } from './OrderResultContent'
import s from './OrderPage.module.scss'
import DeliveryMethodsWrapper from './components/DeliveryMethodsWrapper'

let timeout: any = null

const OrderPage = () => {
  const footer = useContext(HeaderFooterContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams<{ wishid?: string }>()
  const { user, selectedPayMethod } = useAppSelector(({ userReducer, payReducer }) => ({
    user: userReducer.user,
    selectedPayMethod: payReducer.selectedPayMethod,
  }))
  const wishid = useMemo(() => {
    return params && params.wishid ? parseInt(params.wishid, 10) : 0
  }, [params])
  const { data, isLoading: wishIsLoading } = useWish(wishid)
  const wishData = useMemo(() => (data ? data : undefined), [data])

  const productId = useMemo(() => {
    return wishData && wishData.product ? wishData.product.id : 0
  }, [wishData])

  const { data: deliveryMethodsData, isLoading: isDeliveryMethodsLoading } =
    useDeliveryMethods(productId)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stage, setStage] = useState<number>(1)
  const [orderResult, setOrderResult] = useState<any>()
  const [deliveryData, setDeliveryData] = useState<any>({ type: '', name: '', address: '' })
  const [threeDsData, setThreeDsData] = useState<any>()
  const [isSelectDeliveryStage, setIsSelectDeliveryStage] = useState<boolean>(false)
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<number>(1)
  const [recipient, setRecipient] = useState<string>(user && user.name ? user.name : '')
  const [phone, setPhone] = useState<string>(user ? user.phone : '')
  const [comment, setComment] = useState<string>('')
  const [deliveryAdress, setDeliveryAdress] = useState<string>('')
  const [deliveryDataIsLoading, setDeliveryDataIsLoading] = useState<boolean>(false)
  const [paymentId, setPaymentId] = useState<number>(0)
  const [nameError, setNameError] = useState<boolean>(false)

  useEffect(() => {
    footer?.showFooter(false)

    return () => {
      footer?.showFooter(true)
    }
  }, [footer])

  const deliveryMethod = useMemo(() => {
    if (deliveryMethodsData) {
      if (selectedDeliveryMethod) {
        const method = _.find(deliveryMethodsData, { id: selectedDeliveryMethod })
        if (method) {
          return method
        }
        setSelectedDeliveryMethod(deliveryMethodsData[0].id)
        return deliveryMethodsData[0]
      }
    } else {
      return { type: '', name: '', address: '' }
    }
  }, [deliveryMethodsData, selectedDeliveryMethod])

  const getData = useCallback(
    (id: number) => {
      if (wishData) {
        getDeliveryData({
          wish_id: wishData.id,
          deliveryMethod,
          deliveryAdress,
          delivery_id: id,
          onStart: () => setDeliveryDataIsLoading(true),
          onSuccess: (response: any) => {
            setDeliveryData(response)
            setDeliveryDataIsLoading(false)
          },
        })
      }
    },
    [deliveryAdress, deliveryMethod, wishData],
  )

  useEffect(() => {
    getData(selectedDeliveryMethod)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeliveryMethod])

  // useEffect(() => {
  //   clearTimeout(timeout)
  //   timeout = setTimeout(() => {
  //     getData(selectedDeliveryMethod)
  //   }, 300)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deliveryAdress, selectedDeliveryMethod])

  const onPayStart = useCallback(() => {
    console.log('payStart')
    setIsLoading(true)
  }, [])

  const onPaySuccess = useCallback(
    (status: string, response?: any) => {
      setIsLoading(false)
      queryClient.invalidateQueries(['profile'])
      queryClient.invalidateQueries(['wish'])
      if (status === 'success') {
        setPaymentId(response)
        setStage(4)
      } else {
        setThreeDsData(response)
        setStage(3)
      }
    },
    [setStage, setThreeDsData],
  )
  const onPayError = useCallback(() => {
    console.log('payError')
    setIsLoading(false)
    setStage(5)
  }, [setStage])

  const payProcess = useCallback(
    (type: PaymentMethodType, amount: number, order: number) => {
      const payData = {
        auth_user: user,
        amount: amount,
        wish_id: order,
        type: 'order',
        // comment,
      }
      const paymentFuncData: PaymentProps = {
        //@ts-ignore
        data: payData,
        onStart: onPayStart,
        onSuccess: onPaySuccess,
        onError: onPayError,
      }
      if (type === 'bank') bankPay(paymentFuncData)
      if (type === 'apay') apayBtnClick(paymentFuncData)
      if (type === 'gpay') gpayBtnClick(paymentFuncData)
      if (type === 'balance') balanceBtnClick(paymentFuncData)
    },
    [user, onPayError, onPaySuccess, onPayStart],
  )

  const create = useCallback(
    (payMethod: PaymentMethodType) => {
      // Создаем заказ в статусе "предзаказ"
      createOrder({
        wish_id: wishData?.id || 0,
        onStart: () => setIsLoading(true),
        onError: () => {
          setIsLoading(false)
          ToastNotify('Не удалось создать заказ')
        },
        onSuccess: (response: Order) =>
          // Подтверждаем заказ
          confirmOrder({
            order_id: response.id,
            delivery_id: deliveryData.id,
            recipient,
            phone,
            comment: comment,
            onSuccess: (confirmResponse: Order) => {
              setOrderResult(confirmResponse)
              if (confirmResponse.status === 'new') {
                // Показываем успешный результат
                setStage(4)
              } else {
                // Совершаем оплату
                if (payMethod !== 'bank') {
                  payProcess(payMethod, confirmResponse.unpaid_amount, confirmResponse.id)
                } else {
                  setStage(2)
                }
              }
              setIsLoading(false)
            },
            onError: () => {
              setIsLoading(false)
              ToastNotify('Не удалось подтвердить заказ')
            },
          }),
      })
    },
    [wishData, deliveryData, recipient, phone, comment, payProcess],
  )

  if (wishIsLoading || !deliveryMethod) {
    return (
      <>
        <Loader />
        <MetaTitleOrder />
        <MetaDescriptionOrder />
        <HeaderMobileTitle.Source>Оформление заказа</HeaderMobileTitle.Source>
      </>
    )
  }

  return (
    <>
      <MetaTitleOrder />
      <MetaDescriptionOrder />
      <HeaderMobileTitle.Source>Оформление заказа</HeaderMobileTitle.Source>
      {wishData ? (
        isSelectDeliveryStage ? (
          <DeliveryMethodsWrapper
            data={deliveryMethodsData}
            value={selectedDeliveryMethod}
            onChange={(id: number) => {
              setSelectedDeliveryMethod(id)
              setIsSelectDeliveryStage(false)
            }}
          />
        ) : stage === 2 ? (
          <CardForm
            payProcess={() =>
              payProcess(
                'bank',
                orderResult ? orderResult.unpaid_amount : 0,
                orderResult ? orderResult.id : 0,
              )
            }
            payIsLoading={isLoading}
          />
        ) : stage === 3 ? (
          <ThreeDsFrame ThreeDsData={threeDsData} onComplete={onPaySuccess} />
        ) : stage === 4 ? (
          <OrderResultContent orderData={orderResult} closeStage={() => navigate(-1)} />
        ) : (
          <div className={s.content}>
            <div className={s.title}>Оформление заказа на {wishData.name}</div>
            <div className={s.order_form}>
              <InputLabel
                title="Ваше ФИО"
                value={recipient}
                onChange={(val: string) => {
                  setRecipient(val)
                  setNameError(false)
                }}
              />
              {nameError ? (
                <ErrorMsg text="Введите свою фамилию и имя" containerClassName={s.err_msg} />
              ) : (
                <></>
              )}
              <InputPhone
                title="Телефон"
                onChange={setPhone}
                defaultValue={user ? user.phone : ''}
                className={s.phone_input}
              />
              <InputLabel
                title="Способ получения заказа"
                value={deliveryMethod.name}
                onChange={() => {}}
                readonly
                onClick={() => setIsSelectDeliveryStage(true)}
              />
              <InputLabel
                title={
                  deliveryMethod.type === 'pickup'
                    ? t('order_mw.issuing_address')
                    : 'Введите адрес доставки'
                }
                onChange={setDeliveryAdress}
                readonly={deliveryMethod.type === 'pickup'}
                value={deliveryMethod.type === 'pickup' ? deliveryMethod.address : deliveryAdress}
                // onBlur={() => getData(selectedDeliveryMethod)}
              />
              <div className={s.delivery_date}>
                <div>{t('general.provisional_delivery_date')}</div>
                <div className={s.date}>
                  {deliveryMethod.type === 'delivery' && deliveryAdress.length === 0 ? (
                    'введите адрес'
                  ) : deliveryDataIsLoading ? (
                    <Loader size={10} containerClassName={s.mini_loader} />
                  ) : deliveryData ? (
                    <Moment locale="ru" format="DD.MM.YYYY">
                      {deliveryData.delivery_date}
                    </Moment>
                  ) : (
                    'Не удалось получить данные о доставке'
                  )}
                </div>
              </div>
              <InputLabel title="Ваш комментарий" value={comment} onChange={setComment} />

              {deliveryMethod.type === 'pickup' ? (
                <div className={s.pickup_method_block}>
                  <Button
                    title={t('general.checkout_text') || ''}
                    isLoading={isLoading || deliveryDataIsLoading}
                    color="red"
                    className={s.order_btn}
                    onClick={() => {
                      if (wishData.product && wishData.product.id === 153793) {
                        // @ts-ignore
                        ym(65140243, 'reachGoal', 'banner-order')
                      }
                      if (recipient.length > 10) {
                        create('without_pay')
                      } else {
                        setNameError(true)
                      }
                    }}
                  />
                </div>
              ) : deliveryMethod.type === 'delivery' ? (
                <>
                  <PaySelector containerClassName={s.pay_selector} />
                  <div className={s.separator} />
                  <div className={s.delivery_info}>
                    <div className={s.total_list_block}>
                      <div className={s.total_item}>
                        <div className={s.title}>
                          {t('general.products_text')} ({t('general.paid_text')})
                        </div>
                        <div className={s.sum}>
                          {wishData && wishData.amount_collected
                            ? wishData.amount_collected.toLocaleString('ru')
                            : 0}{' '}
                          {t('general.chars.rub')}
                        </div>
                      </div>
                      <div className={s.total_item}>
                        <div className={s.title}>Доставка</div>
                        <div className={s.sum}>
                          {deliveryData && deliveryData.price
                            ? `${deliveryData.price.toLocaleString('ru')} ${t('general.chars.rub')}`
                            : ''}
                        </div>
                      </div>
                      <div className={s.total_item}>
                        <div className={s.title}>К оплате</div>
                        <div className={`${s.sum} ${s.red}`}>
                          {deliveryData.price && deliveryData.price
                            ? `${(
                                deliveryData.price +
                                wishData.full_price -
                                wishData.amount_collected
                              ).toLocaleString('ru')} ${t('general.chars.rub')}`
                            : ''}
                        </div>
                      </div>
                    </div>
                    <OffertaInfo />
                    <PayButtons
                      isLoading={isLoading || deliveryDataIsLoading}
                      selected_id={selectedPayMethod}
                      disabled={deliveryMethod.type !== 'pickup' && !deliveryAdress ? true : false}
                      blockBalance
                      apayOnClick={() => {
                        create('apay')
                      }}
                      bankOnClick={() => {
                        create('bank')
                      }}
                      balanceOnClick={() => {
                        create('balance')
                      }}
                      gpayOnClick={() => {
                        create('gpay')
                      }}
                      tinkoffOnClick={() => {}}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        )
      ) : (
        <div>Желание не найдено</div>
      )}
    </>
  )
}

export default React.memo(OrderPage)
