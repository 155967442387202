import apiClient from '../apiClient'

export const fetchShop = async () => {
  const { data } = await apiClient.get(`/api/v1/shop`)
  return data
}

export const fetchShopParentCategories = async () => {
  const { data } = await apiClient.get('/api/v1/shop/category?parent=0')
  return data
}

export const fetchRandomShop = async (pageParam?: string) => {
  const { data } = await apiClient.get(pageParam || '/api/v1/shop/random')
  return data
}

export const fetchMainShop = async () => {
  const { data } = await apiClient.get('/api/v1/shop/main')
  return data
}

export const fetchShopSubCategory = async (id: number | string) => {
  const { data } = await apiClient.get(`/api/v1/shop/sub_category/${id}`)
  return data
}

export const fetchShopCategory = async (pageParam: string) => {
  const { data } = await apiClient.get(pageParam)
  return data
}
