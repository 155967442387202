import { fetchNotifications } from 'application/data/api/notifications'
import { NotificationsList } from 'application/domain/entity/notifications/Notification'
import { Pagination } from 'application/domain/entity/Pagination'
import { useInfiniteQuery } from '@tanstack/react-query'

export const getNotifications = async () => fetchNotifications('/api/v1/notification?page=1')

export const useNotifications = (token: string, config?: any) => {
  const query = useInfiniteQuery<Pagination<NotificationsList>>(
    ['notifications'],
    ({ pageParam = '/api/v1/notification?page=1' }) => fetchNotifications(pageParam),
    {
      getNextPageParam: (lastPage: Pagination<any>) => {
        return lastPage.next_page_url
      },
      enabled: token ? true : false,
      retry: false,
      refetchInterval: 10000,
      ...config,
    },
  )
  return query
}

export const calculateNotifications = (notificationsData: any) => {
  let count = 0
  if (notificationsData) {
    const { friend, like, order, wish, comment } = notificationsData.new
    if (friend) {
      count += friend
    }
    if (like) {
      count += like
    }
    if (order) {
      count += order
    }
    if (wish) {
      count += wish
    }
    if (comment) {
      count += comment
    }
  }
  return count
}
