import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
// import scopeSvg from 'images/icons/light_gray_scope.svg'
// import plusSvg from 'images/icons/add-button.svg'

import IdeaItem from './components/IdeaItem'
import s from './IdeaContent.module.scss'

type Props = {
  data: any
  user_nickname: string
}

const IdeaContent = ({ data, user_nickname }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const mainIdea = useMemo(() => {
    let mainItem: any = []
    if (data && data.items) {
      data.items.map((item: any) => {
        item.wish.map((item2: any) => {
          if (
            !mainItem.likes_count ||
            (item2.likes_count && item2.likes_count >= mainItem.likes_count)
          ) {
            mainItem = item2
          }
        })
      })
    }
    return mainItem
  }, [data])

  const ideasList = useMemo(() => {
    return data && data.items
      ? data.items.map((item: any) => {
          return { ...item, wish: item.wish.filter((item2: any) => item2.id !== mainIdea.id) }
        })
      : []
  }, [data, mainIdea])

  return (
    <div>
      <div
        className={s.search_input}
        onClick={() => navigate(`${location.pathname}?idea_friend=${user_nickname}#search`)}
      >
        <div className={s.first_icon}>
          <img src="/images/icons/light_gray_scope.svg" alt="" />
        </div>
        <div className={s.search_txt}>Начните вводить идею</div>
        <div className={s.last_icon}>
          <img src="/images/icons/add-button.svg" alt="" />
        </div>
      </div>
      {mainIdea && mainIdea.id ? (
        <div className={s.category_container}>
          <div className={s.red_title}>Лучшая идея</div>
          <IdeaItem item={mainIdea} isMainIdea />
        </div>
      ) : (
        <></>
      )}

      {!data.quantity || data.quantity === 0 ? (
        <div className={s.empty_ideas}>Пользователю пока не предложили ни одной идеи</div>
      ) : (
        <div className={s.category_container}>
          <div className={s.red_title}>Остальные идеи</div>
          {ideasList && ideasList.length ? (
            ideasList.map((item: any) => {
              return item.wish
                ? item.wish.map((item2: any) => {
                    return item2 && item2.idea ? (
                      <IdeaItem key={item.short} item={item2} />
                    ) : (
                      <React.Fragment key={item2} />
                    )
                  })
                : null
            })
          ) : (
            <div className={s.empty_ideas}>Идей нет</div>
          )}
        </div>
      )}
    </div>
  )
}

export default React.memo(IdeaContent)
