import React from 'react'

import s from './ArticleLayout.module.scss'

type Props = {
  children?: React.ReactNode
}

const ArticleLayout = ({ children }: Props): React.ReactElement => {
  return <section className={`${s.layout} ${s.layout_mobile}`}>{children}</section>
}

export default ArticleLayout
