import apiClient from '../apiClient'

export const fetchComments = async (wish_id: number, pageParam?: string) => {
  const { data } = await apiClient.get(pageParam || `/api/v1/wish/${wish_id}/donates?per=20`)
  return data
}

export const commentLikeRequest = async (comment_id: string, type: string) => {
  const { data } = await apiClient({
    url: `/api/v1/${type}/${comment_id}/like`,
    method: 'POST',
  })

  return data
}

export const deleteCommentRequest = async (comment_id: string) => {
  const { data } = await apiClient({
    url: `/api/v1/comment/${comment_id}/delete`,
    method: 'DELETE',
  })

  return data
}
export const editCommentRequest = async (
  comment_id: string,
  commentText?: string,
  mentions?: any,
  type?: string,
) => {
  const sendData =
    type === 'money'
      ? mentions
        ? { comment: commentText, users: mentions }
        : { text: commentText }
      : mentions
      ? { text: commentText, users: mentions }
      : { text: commentText }

  const { data } = await apiClient({
    url: `/api/v1/${type === 'money' ? 'money' : 'comment'}/${comment_id}/edit`,
    method: 'POST',
    data: sendData,
  })

  return data
}

export const sendCommentRequest = async (
  wish_id?: number | string,
  commentText?: string,
  mentions?: any,
) => {
  if (wish_id !== 0) {
    const { data } = await apiClient({
      url: `/api/v1/wish/${wish_id}/comment`,
      method: 'POST',
      data: mentions ? { text: commentText, users: mentions } : { text: commentText },
    })

    return data
  }
  return false
}
