import React, { useMemo } from 'react'
import InputMask from 'react-input-mask'
import PhoneInput from 'react-phone-input-2'

import s from './InputLabelHorizontal.module.scss'

type Props = {
  title: string
  value: string
  placeholder?: string
  className?: string
  onChange: Function
  componentType?: 'date' | 'phone' | 'comment' | 'nickname' | 'link' | 'email'
  subtitle?: string
  hidePadding?: boolean
}

const InputLabelHorizontal = ({
  title,
  className,
  onChange,
  placeholder,
  componentType,
  subtitle,
  value,
  hidePadding,
}: Props) => {
  const wordCount = value ? value.length : 0

  const innerClass = useMemo(() => {
    switch (componentType) {
      case 'phone':
        return s.checked_phone
      case 'comment':
        return s.textarea
      case 'email':
        return s.checked_email
      default:
        return ''
    }
  }, [componentType])
  return (
    <label className={`${s.label} ${innerClass} ${className} ${hidePadding ? s.hide_padding : ''}`}>
      {componentType === 'comment' ? (
        <div className={s.input_text}>
          <div>{title}</div>
          <div className={s.word_count}>
            <span>{wordCount}</span> / 567
          </div>
        </div>
      ) : (
        <div className={s.input_text}>{title}</div>
      )}

      <div className={s.input_block}>
        {componentType === 'date' ? (
          <InputMask
            mask="99.99.9999"
            type="text"
            value={value || ''}
            onChange={(e: any) => onChange(e.target.value)}
          />
        ) : componentType === 'comment' ? (
          <textarea
            className="resizable"
            placeholder={placeholder}
            maxLength={567}
            onChange={(e) => onChange(e.target.value)}
            value={value || ''}
          />
        ) : componentType === 'phone' ? (
          <PhoneInput
            country="ru"
            countryCodeEditable={false}
            placeholder={placeholder}
            onlyCountries={['ru', 'ua', 'by', 'de']}
            value={value || ''}
            containerClass={s.phoneInput}
            dropdownClass={s.phoneDropdown}
            buttonClass={s.flag_dropdown}
            onChange={(phone: any) => onChange(phone)}
          />
        ) : (
          <input
            type="text"
            placeholder={placeholder}
            value={value || ''}
            onChange={(e: any) => onChange(e.target.value)}
          />
        )}
        {subtitle ? (
          <div className={s.subtitle}>
            {subtitle}
            {componentType === 'nickname' ? value : ''}
          </div>
        ) : (
          <></>
        )}
      </div>
    </label>
  )
}

export default React.memo(InputLabelHorizontal)
