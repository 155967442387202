import { fetchSession, fetchSessionByToken, fetchUser } from 'application/data/api/user'
import { User } from 'application/domain/entity/user/User'
import { useQuery } from '@tanstack/react-query'

export const getCurrentSession = async () => fetchSession()
export const getServerSideSession = async (token?: string) => fetchSessionByToken(token)

export const getUser = async (nickname: string) => fetchUser(nickname)

export const useUser = (nickname?: string, config?: any) => {
  const query = useQuery<User>(['profile', nickname], () => fetchUser(nickname), {
    retry: false,
    refetchInterval: 10000,
    enabled: nickname ? true : false,
    ...config,
  })

  return query
}
